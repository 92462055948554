import { Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

const TermProtection = ({ component: Component, auth, ...rest }) => (
    <Route
        render={(props) => {

            if (auth.maintanceStatus === "true") {

                return <Redirect to={`/maintenance`} />
            }
            if (auth.isAuthenticated && auth.term == 0) {

                return <Component {...props} />

            } else {
                console.log("private inside 3")

                return <Redirect to={`/`} />
            }
        }}
        {...rest}
    />
)

TermProtection.propTypes = {
    auth: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => ({
    auth: state.authReducer,
})

export default connect(mapStateToProps)(TermProtection)
// export default connect(mapStateToProps, null, null, {
//   pure: false,
// })(PrivateRoute);