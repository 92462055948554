import { Link } from "react-scroll";

import { connect } from 'react-redux';
import React, { useEffect, useState, useRef } from "react";
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import { Dropdown } from 'react-bootstrap';
import { logOutUser, homeScreen, termScreen } from "../../actions/auth"
import { fetchAllNotification, markNotificationAsRead, removeNotification } from "../../actions/notification"
import { useHistory, useLocation } from "react-router-dom";
import 'react-tabs/style/react-tabs.css';
import AlertNotification from "../Modals/ErrorAlert";
import NewsTicker from "react-advanced-news-ticker";
import "./style.css";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import NotificationHeaderDropdown from "../Notification/NotificationHeaderDropdown";
import { toastMessageNotification } from "../../utils/toastMessage";

function ThemeSwitch() {
    const globeVideo = document.getElementById("nucoin-globe-video");
    const coinVideo = document.getElementById("nucoin-coin-video");

    if (document.documentElement.getAttribute('data-theme') === 'light') {

        document.documentElement.setAttribute('data-theme', 'dark');
        localStorage.setItem('selected-theme', 'dark');
        if (globeVideo) globeVideo.src = '/assets/video/dark3dglobe.mp4';
        if (coinVideo) coinVideo.src = '/assets/video/dark3dcoin.mp4';
    } else {

        document.documentElement.setAttribute('data-theme', 'light');
        localStorage.setItem('selected-theme', 'light');

        if (globeVideo) globeVideo.src = '/assets/video/light3dglobe.mp4';
        if (coinVideo) coinVideo.src = '/assets/video/light3dcoin.mp4';
    }
}

// function NotificationDrop(isShowing) {

//     if (isShowing) {
//         document.getElementById('zIndexMob').classList.add("position-relative")
//     }
//     else if (isShowing == false) {
//         return(document.getElementById('zIndexMob').classList.add("position-relative"));
//     } else {
//         document.getElementById('zIndexMob').classList.remove("position-relative")
//     }
// }

function Header(props) {
    const { isAuthenticated, user, logOutUser, homeScreen, isTeamScreen, allNotification, waitingFor, totalUnreadNotificationCount, markNotificationAsRead, allNotificationCount, removeNotification, fetchAllNotification, headerNotifications, termScreen, isTermScreen } = props;
    const history = useHistory();
    const [currentItem, setCurrentItem] = useState(0);
    const [news, setNewsList] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [isHomeScreen, setIsHomeScreen] = useState(true);
    const location = useLocation();

    useEffect(() => {

        if (location.pathname === '/' || location.pathname === '') setIsHomeScreen(true);
        else setIsHomeScreen(false);

        if (location.pathname === '/login' || location.pathname === '/register' || location.pathname === '/forgot-credential' || location.pathname === '/verify-otp' || location.pathname === '/login-verification' || location.pathname === '/update-password') {
            if (document.getElementById('example').classList.contains("container")) document.getElementById('example').classList.remove("container");
        } else {
            if (!document.getElementById('example').classList.contains("container")) document.getElementById('example').classList.add("container");
        }

    }, [location.pathname]);

    const ref = useRef(null);
    const LogOut = () => {
        logOutUser()
    };



    useEffect(() => {


        const theme = localStorage.getItem('selected-theme');
        if (theme === 'dark') document.documentElement.setAttribute('data-theme', 'dark');
    }, [])

    useEffect(() => {

        setNewsList(headerNotifications);
        setRefresh(true);
        setTimeout(() => {
            setRefresh(false);
        }, 100)

    }, [headerNotifications])

    useEffect(() => {

        console.log("newNews", news)

    }, [news])

    function MobileMenu() {

        if (document.getElementById('burger').classList.contains('is-open')) {
            document.getElementById('burger').classList.remove('is-open');
            document.getElementById('main-nav').classList.remove('is-open');
            document.getElementById('zIndexMob').classList.remove("position-relative");
        } else {
            document.getElementById('burger').classList.add('is-open');
            document.getElementById('main-nav').classList.add('is-open');
            document.getElementById('zIndexMob').classList.add("position-relative");
        }

    }

    useEffect(() => {
        console.log('header ', window.location.pathname)
        window.scrollTo(0, 0);
    });

    const timeBetweenDates = (ttl) => {


        var moment = require('moment');

        var localDate = new Date();
        var currentTime = moment.utc(localDate).format()
        var diff = moment.duration(moment(ttl).diff(moment(currentTime)));
        let a;


        //One to show conversion
        if (diff.get("months") > 0) {
            // setTimeDiff(diff.get("months") === 1 ? `${diff.get("months")} month remaining` : `${diff.get("months")} months remaining`)

            a = diff.get("months") === 1 ? `Disappearing in  ${diff.get("months")} month` : `Disappearing in  ${diff.get("months")} months`

        }
        else if (diff.get("days") > 0) {
            // setTimeDiff(diff.get("days") === 1 ? `${diff.get("days")} day remaining` : `${diff.get("days")} days remaining`)
            a = diff.get("days") === 1 ? `Disappearing in  ${diff.get("days")} day` : `Disappearing in  ${diff.get("days")} days`

        }
        else if (diff.get("hours") > 0) {
            // setTimeDiff(diff.get("hours") === 1 ? `${diff.get("hours")} hour remaining` : `${diff.get("hours")} hours remaining`)
            a = diff.get("hours") === 1 ? `Disappearing in  ${diff.get("hours")} hour` : `Disappearing in  ${diff.get("hours")} hours`
        }

        else if (diff.get("minutes") > 0) {
            // setTimeDiff(diff.get("minutes") === 1 ? `${diff.get("minutes")} minute remaining` : `${diff.get("minutes")} minutes remaining`)
            a = diff.get("minutes") === 1 ? `Disappearing in  ${diff.get("minutes")} minute` : `Disappearing in  ${diff.get("minutes")} minutes`

        }

        else if (diff.get("seconds") > 0) {
            a = diff.get("seconds") === 1 ? `Disappearing in  ${diff.get("seocnds")} second` : `Disappearing in  ${diff.get("seconds")} seconds`

        }

        else if (diff.get("seconds") <= 0 || diff.get("months") <= 0 || diff.get("days") <= 0 || diff.get("hours") <= 0 || diff.get("minutes") <= 0) {
            a = "This notification has been expired will disappear once you refresh the page."

        }
        return a

    }


    const [modalVisibility, setModalVisibility] = useState({
        errorModalShow: false,
        alertTitlt: "",
        alertMessage: ""
    });

    if (window.location.pathname === '/login' || window.location.pathname === '/register' || window.location.pathname === '/forgot-credential' || window.location.pathname === '/verify-otp' || window.location.pathname === '/login-verification' || window.location.pathname === '/update-password' || window.location.pathname === '/notifications') return null;

    return (
        <>
            {console.log("termScreen", isTermScreen)}
            <div className="row">

                <div className="col-12">
                    <div data-aos="fade-zoom-in" data-aos-duration={1500}>

                        {
                            news.length > 0 && !refresh && false ?

                                <NewsTicker
                                    ref={ref}
                                    id="nt-example2"
                                    // direction={Directions.UP}
                                    rowHeight={60}
                                    maxRows={1}
                                    speed={300}
                                    duration={6000}
                                    hasMoved={() => {
                                        var index = ref.current.state.items[0].key;
                                        setCurrentItem(news[index]);
                                    }}
                                >
                                    {news.map((item, i) => (
                                        <div key={i}>
                                            {ReactHtmlParser(item.message)}
                                        </div>
                                    ))}
                                </NewsTicker>
                                : null
                        }
                        {
                            // if user is login but on the home screen
                            isAuthenticated && !(isHomeScreen) ?
                                <nav id="navbar" className={"navbar navbar-expand-lg navbar-light Destkop-Navbar px-0 py-5"}>
                                    {
                                        isTermScreen === true ? null :
                                            <Link style={{ cursor: 'pointer' }} className="navbar-brand d-flex align-items-center" to="/" onClick={() => { history.push("/") }}>
                                                <span className="Logo-Txt">NUC</span>
                                                <img src="/assets/images/nucoin-logo.png" alt="" />
                                                <span className="Logo-Txt">IN</span>

                                            </Link>
                                    }
                                    <ul className="navbar-nav ml-auto">
                                        {

                                            isTermScreen === true ? null : <NotificationHeaderDropdown />
                                        }

                                        {
                                            isTermScreen === true ? null :
                                                <li className="nav-item">
                                                    {/* <Dropdown className="User" id="User" onToggle={NotificationDrop}> */}
                                                    <Dropdown className="User" id="User">
                                                        <Dropdown.Toggle id="dropdown-basic">
                                                            <span className="material-icons user-icon">
                                                                <PersonOutlineIcon />
                                                            </span>
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu>

                                                            <Dropdown.Item><Link onClick={() => history.push("/")} to="#">Home</Link></Dropdown.Item>
                                                            <Dropdown.Item><Link onClick={() => history.push("/profile")} to="#">{user.username}</Link></Dropdown.Item>
                                                            <Dropdown.Item><Link onClick={() => history.push('/kyc')} to="#">Kyc</Link></Dropdown.Item>
                                                            <Dropdown.Item onClick={LogOut}>Logout</Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </li>
                                        }
                                        {/* <li className="nav-item">
                                            <a href={() => false} onClick={ThemeSwitch} className="nav-link pointer">
                                                <div className="dark-mode">
                                                    <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 24 24" width="20px" fill="#1BD3ED" className="transform-rotate">
                                                        <path d="M0 0h24v24H0z" fill="none" />
                                                        <path d="M10 2c-1.82 0-3.53.5-5 1.35C7.99 5.08 10 8.3 10 12s-2.01 6.92-5 8.65C6.47 21.5 8.18 22 10 22c5.52 0 10-4.48 10-10S15.52 2 10 2z">
                                                        </path>
                                                    </svg>
                                                </div>
                                            </a>
                                        </li> */}
                                    </ul>
                                </nav> :
                                <nav id="navbar" className="navbar navbar-expand-lg navbar-light Destkop-Navbar px-0 py-5">
                                    <Link className="navbar-brand d-flex align-items-center" to="#" onClick={() => { history.push("/") }} style={{ cursor: 'pointer' }}>
                                        <span className="Logo-Txt">NUC {isTeamScreen}</span>
                                        <img src="/assets/images/nucoin-logo.png" alt="" />
                                        <span className="Logo-Txt">IN</span>
                                    </Link>
                                    {/* // if user is on the team screen and not login */}
                                    {isTeamScreen === true ? null :
                                        <ul className="navbar-nav ml-auto">
                                            <li className="nav-item active">
                                                <Link
                                                    activeClass="active"
                                                    to="home"
                                                    spy={true}
                                                    smooth={true}
                                                    offset={-70}
                                                    duration={500}
                                                >Home
                                                </Link>

                                            </li>
                                            <li className="nav-item">
                                                <Link
                                                    activeClass="active"
                                                    to="roadmap"
                                                    spy={true}
                                                    smooth={true}
                                                    offset={-70}
                                                    duration={500}
                                                >Roadmap
                                                </Link>

                                            </li>
                                            <li className="nav-item">
                                                <Link
                                                    activeClass="active"
                                                    to="about"
                                                    spy={true}
                                                    smooth={true}
                                                    offset={-70}
                                                    duration={500}
                                                >About Us
                                                </Link>

                                            </li>

                                            <li className="nav-item">
                                                <Link
                                                    activeClass="active"
                                                    to="services"
                                                    spy={true}
                                                    smooth={true}
                                                    offset={-70}
                                                    duration={500}
                                                >Services
                                                </Link>

                                            </li>
                                            {/* <li className="nav-item">
                                                <Link
                                                    activeClass="active"
                                                    to="team"
                                                    spy={true}
                                                    smooth={true}
                                                    offset={-70}
                                                    duration={500}
                                                >Team
                                                </Link>

                                            </li> */}
                                            <li className="nav-item">
                                                <Link
                                                    activeClass="active"
                                                    to="contact"
                                                    spy={true}
                                                    smooth={true}
                                                    offset={-70}
                                                    duration={500}
                                                >Contact
                                                </Link>

                                            </li>
                                            <li className="nav-item">
                                                <a rel="noopener noreferrer" target="_blank" href="https://explorer.nucoin.com.au">Explorer</a>
                                            </li>
                                            {/* // show notification if user on home screen and login as well */}
                                            {isAuthenticated === true ? <NotificationHeaderDropdown /> : null}
                                            {/* // show drop down icon or singup login btn if user on home screen and login or not login */}
                                            {
                                                isAuthenticated === true ? <li className="nav-item">
                                                    <Dropdown onClick={MobileMenu} id="User" className="User">
                                                        <Dropdown.Toggle id="dropdown-basic">
                                                            <span className="material-icons user-icon">
                                                                <PersonOutlineIcon />
                                                            </span>
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                            <Dropdown.Item>
                                                                <Link onClick={() => {
                                                                    homeScreen(false)
                                                                    history.push("/profile")

                                                                }}
                                                                    to="#">{user.username}
                                                                </Link>
                                                            </Dropdown.Item>

                                                            <Dropdown.Item>
                                                                <Link onClick={() => {
                                                                    homeScreen(false)
                                                                    history.push("/kyc")


                                                                }}>KYC
                                                                </Link>
                                                            </Dropdown.Item>

                                                            <Dropdown.Item onClick={() => {
                                                                LogOut()
                                                            }}>Logout
                                                            </Dropdown.Item>
                                                            {/* <hr className="mb-2 mt-0" /> */}
                                                            {/* <div className="text-center w-100">
                                                                <button type="button" onClick={() => {
                                                                    homeScreen(false)
                                                                    history.push("/select-account")


                                                                }} class="Read-More">Pay Id</button>
                                                            </div> */}
                                                            {/* <div className="text-center w-100">
                                                                <button onClick={toastMessageNotification} type="button" class="Read-More">Toast Push</button>
                                                            </div> */}
                                                        </Dropdown.Menu>
                                                    </Dropdown>

                                                </li>

                                                    : <><li className="nav-item">
                                                        <Link onClick={() => { history.push("/login") }} to={() => false} className="nav-link">Login</Link>
                                                    </li>
                                                        <li className="nav-item">
                                                            <Link onClick={() => { history.push("/register") }} to={() => false} className="nav-link">Signup</Link>
                                                        </li></>
                                            }


                                            {/* <li className="nav-item">
                                                <a href={() => false} onClick={ThemeSwitch} className="nav-link">
                                                    <div className="dark-mode">
                                                        <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 24 24" width="20px" fill="#1BD3ED" className="transform-rotate">
                                                            <path d="M0 0h24v24H0z" fill="none" />
                                                            <path d="M10 2c-1.82 0-3.53.5-5 1.35C7.99 5.08 10 8.3 10 12s-2.01 6.92-5 8.65C6.47 21.5 8.18 22 10 22c5.52 0 10-4.48 10-10S15.52 2 10 2z">
                                                            </path>
                                                        </svg>
                                                    </div>
                                                </a>
                                            </li> */}
                                        </ul>

                                    }


                                </nav>
                        }
                        {
                            isAuthenticated && !(isHomeScreen) ?
                                <nav id="navbar" className={"navbar navbar-expand-lg navbar-light Mobile-Navbar pb-5 "}>
                                    <Link style={{ cursor: 'pointer' }} className="navbar-brand d-flex align-items-center" to="/">


                                        <span onClick={() => {
                                            homeScreen(true)
                                            history.push("/")
                                        }} className="Logo-Txt">NUC</span>
                                        <img onClick={() => {
                                            homeScreen(true)
                                            history.push("/")
                                        }} src="/assets/images/nucoin-logo.png" alt="" />
                                        <span onClick={() => {
                                            homeScreen(true)
                                            history.push("/")
                                        }} className="Logo-Txt">IN</span>

                                    </Link>
                                    <div className="device">
                                        <div className="">
                                            <button id="burger" onClick={MobileMenu} className="open-main-nav">
                                                <span className="burger" />
                                                <span className="burger-text">Menu</span>
                                            </button>
                                            {/* <a href={() => false} onClick={ThemeSwitch} className="nav-link">
                                                <div className="dark-mode">
                                                    <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 24 24" width="20px" fill="#1BD3ED" className="transform-rotate">
                                                        <path d="M0 0h24v24H0z" fill="none" />
                                                        <path d="M10 2c-1.82 0-3.53.5-5 1.35C7.99 5.08 10 8.3 10 12s-2.01 6.92-5 8.65C6.47 21.5 8.18 22 10 22c5.52 0 10-4.48 10-10S15.52 2 10 2z">
                                                        </path>
                                                    </svg>
                                                </div>
                                            </a> */}
                                            <NotificationHeaderDropdown />
                                            <nav className="main-nav" id="main-nav">

                                                <ul>
                                                    <li>
                                                        <a href={() => false} className="nav-link">
                                                            <Link
                                                                activeClass="active"
                                                                onClick={() => {
                                                                    homeScreen(true)
                                                                    history.push("/")
                                                                }}
                                                                to="#"
                                                                spy={true}
                                                                smooth={true}
                                                                offset={-70}
                                                                duration={500}
                                                            >Home
                                                            </Link>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href={() => false} className="nav-link">
                                                            <Link
                                                                activeClass="active"
                                                                to="#"
                                                                onClick={() => {
                                                                    homeScreen(false)
                                                                    history.push("/profile")
                                                                }}
                                                                spy={true}
                                                                smooth={true}
                                                                offset={-70}
                                                                duration={500}
                                                            >{user.username}
                                                            </Link>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href={() => false} className="nav-link">
                                                            <Link
                                                                activeClass="active"
                                                                to="#"
                                                                onClick={() => {
                                                                    homeScreen(false)
                                                                    history.push("/kyc")
                                                                }}
                                                                spy={true}
                                                                smooth={true}
                                                                offset={-70}
                                                                duration={500}
                                                            >Kyc
                                                            </Link>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href={() => false} className="nav-link">
                                                            <Link
                                                                activeClass="active"
                                                                onClick={LogOut}
                                                                to="#"
                                                                spy={true}
                                                                smooth={true}
                                                                offset={-70}
                                                                duration={500}
                                                            >Logout
                                                            </Link>
                                                        </a>
                                                    </li>

                                                </ul>

                                            </nav>
                                        </div>
                                    </div>
                                </nav>

                                // user is on the home screen weather login or not

                                : <nav id="navbar" className={"navbar navbar-expand-lg navbar-light Mobile-Navbar pb-5 "} >
                                    {
                                        isTermScreen === true ? null :
                                            <Link style={{ cursor: 'pointer' }} className="navbar-brand d-flex align-items-center" to="/">
                                                <span onClick={() => {
                                                    homeScreen(true)
                                                    history.push("/")
                                                }} className="Logo-Txt">NUC</span>
                                                <img onClick={() => {
                                                    homeScreen(true)
                                                    history.push("/")
                                                }} src="/assets/images/nucoin-logo.png" alt="" />
                                                <span onClick={() => {
                                                    homeScreen(true)
                                                    history.push("/")
                                                }} className="Logo-Txt">IN</span>
                                            </Link>
                                    }
                                    <div className="device">
                                        <div className="">
                                            {isTeamScreen === true || termScreen === true ? null :
                                                <button id="burger" onClick={MobileMenu} className="open-main-nav">
                                                    <span className="burger" />
                                                    <span className="burger-text">Menu</span>
                                                </button>
                                            }
                                            {/* <a href={() => false} onClick={ThemeSwitch} className="nav-link">
                                                <div className="dark-mode">
                                                    <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 24 24" width="20px" fill="#1BD3ED" className="transform-rotate">
                                                        <path d="M0 0h24v24H0z" fill="none" />
                                                        <path d="M10 2c-1.82 0-3.53.5-5 1.35C7.99 5.08 10 8.3 10 12s-2.01 6.92-5 8.65C6.47 21.5 8.18 22 10 22c5.52 0 10-4.48 10-10S15.52 2 10 2z">
                                                        </path>
                                                    </svg>
                                                </div>
                                            </a> */}
                                            {/* // if user login then show notification else null */}
                                            {isAuthenticated === true ? <NotificationHeaderDropdown /> : null}
                                            <nav className="main-nav" id="main-nav">
                                                {isTeamScreen === true ? null :
                                                    <ul>
                                                        <li className="nav-item active">
                                                            <a href={() => false} className="nav-link">
                                                                <Link
                                                                    activeClass="active"
                                                                    to="home"
                                                                    spy={true}
                                                                    smooth={true}
                                                                    offset={-70}
                                                                    duration={500}
                                                                >Home
                                                                </Link>
                                                            </a>
                                                        </li>

                                                        <li>
                                                            <a href={() => false} className="nav-link">
                                                                <Link
                                                                    activeClass="active"
                                                                    to="roadmap"
                                                                    spy={true}
                                                                    smooth={true}
                                                                    offset={-70}
                                                                    duration={500}
                                                                >Roadmap
                                                                </Link>
                                                            </a>
                                                        </li>

                                                        <li>
                                                            <a href={() => false} className="nav-link">
                                                                <Link
                                                                    activeClass="active"
                                                                    to="about"
                                                                    spy={true}
                                                                    smooth={true}
                                                                    offset={-70}
                                                                    duration={500}
                                                                >About Us
                                                                </Link>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href={() => false} className="nav-link">
                                                                <Link
                                                                    activeClass="active"
                                                                    to="services"
                                                                    spy={true}
                                                                    smooth={true}
                                                                    offset={-70}
                                                                    duration={500}
                                                                >Services
                                                                </Link>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href={() => false} className="nav-link">
                                                                <Link
                                                                    activeClass="active"
                                                                    to="team"
                                                                    spy={true}
                                                                    smooth={true}
                                                                    offset={-70}
                                                                    duration={500}
                                                                >Team
                                                                </Link>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href={() => false} className="nav-link">
                                                                <Link
                                                                    activeClass="active"
                                                                    to="contact"
                                                                    spy={true}
                                                                    smooth={true}
                                                                    offset={-70}
                                                                    duration={500}
                                                                >Contact
                                                                </Link>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a rel="noopener noreferrer" className="nav-link" target="_blank" href="https://explorer.nucoin.com.au">Explorer</a>
                                                        </li>


                                                        {isAuthenticated === true ? (
                                                            <React.Fragment>
                                                                <li>
                                                                    <a href={() => false} className="nav-link">
                                                                        <Link onClick={() => {
                                                                            homeScreen(false)

                                                                            history.push("/profile")
                                                                        }} to="#">{user.username}</Link></a></li>
                                                                <li> <a href={() => false} className="nav-link"><Link onClick={() => {
                                                                    homeScreen(false)
                                                                    history.push('/kyc')
                                                                }} to="#">KYC</Link></a></li>
                                                                <li> <a href={() => false} className="nav-link"> <Link onClick={LogOut}>Logout</Link></a></li>
                                                            </React.Fragment>
                                                        ) :
                                                            <>
                                                                <li className="nav-item">
                                                                    <a href={() => false} className="nav-link">
                                                                        <Link onClick={() => { history.push("/login") }} to="#" className="nav-link">Login</Link>
                                                                    </a>
                                                                </li>
                                                                <li className="nav-item">
                                                                    <a href={() => false} className="nav-link">
                                                                        <Link onClick={() => { history.push("/register") }} to={() => false} className="nav-link">Signup</Link>
                                                                    </a>
                                                                </li>
                                                            </>

                                                        }

                                                    </ul>
                                                }
                                            </nav>
                                        </div>
                                    </div>
                                </nav>
                        }
                    </div>
                </div>
            </div>



            <AlertNotification showErroModal={modalVisibility.errorModalShow} title={"Alert"} message={"Are you sure you want to remove this notification?"} onCloseErrorModal={() => {
                setModalVisibility({
                    errorModalShow: false,
                    alertTitlt: "",
                    alertMessage: ""
                })
            }
            } />
        </>
    );
}

const mapStateToProps = (state) => ({
    isAuthenticated: state.authReducer.isAuthenticated,
    user: state.authReducer.user,
    isTeamScreen: state.authReducer.isTeamScreen,
    waitingFor: state.commonReducer.waitingFor,
    isTermScreen: state.authReducer.isTermScreen,
    notifications: state.Notification.notifications,
    announcements: state.Notification.announcements,
    headerNotifications: state.Notification.header.data
})

export default connect(mapStateToProps, { logOutUser, homeScreen, fetchAllNotification, markNotificationAsRead, removeNotification, termScreen })(Header)