import { SET_CURRENT_USER, HOME_SCREEN, TEAM_SCREEN,TERM_SCREEN } from '../types/auth'

// import * as commonType from '../common/common.types'
//For Login
import jwt_decode from 'jwt-decode'
import { isEmpty } from 'lodash'
import { toastMessageFailure, toastMessageSuccess } from "../utils/toastMessage"
/**
 * Signin User
 * @param userData
 * @returns
 */
// set universal header for token and set current user
export const setUpUser = (accessToken) => async (dispatch) => {
    //setAuthToken(accessToken)
    const decodedUser = jwt_decode(accessToken)
    //Set current User
    dispatch(setCurrentUser(decodedUser))

}

export const setCurrentUser = (decodedUser) => {
    //LOGOUT Case
    if (isEmpty(decodedUser)) {
        return {
            type: SET_CURRENT_USER,
            payload: decodedUser,
        }
    }
    //LOGIN Case
    if (!isEmpty(decodedUser)) {
        return {
            type: SET_CURRENT_USER,
            payload: decodedUser,
        }
    }
}

export const logOutUser = (code) => async (dispatch) => {
    if (code === 401 || code === 403) {
        //
        localStorage.removeItem("jwtToken")
        localStorage.removeItem("lastSelectWallet")
        localStorage.removeItem("isShown")
        localStorage.setItem("logout", code)
        window.location.replace("/")
    }
    else if (code === 600) {
        localStorage.removeItem("jwtToken")
        localStorage.removeItem("lastSelectWallet")
        localStorage.removeItem("isShown")
        localStorage.setItem("logout", code)
        window.location.replace("/")

    }
    else {
        localStorage.removeItem("jwtToken")
        localStorage.removeItem("lastSelectWallet")
        localStorage.removeItem("isShown")
        localStorage.setItem("logout", 200)
        window.location.replace("/")
    }

}
export const logOut = () => async (dispatch) => {
    let logout = localStorage.getItem("logout")
    if (logout) {
        if (logout === "401" || logout === "403") {
            toastMessageFailure("Your session has been expired please login again")
        }
        else if (logout === "600") {
            toastMessageFailure("You must accept and read the terms and conditions before using the website.")
        }
        else {
            toastMessageSuccess("You have successfully logged out of the system.")
        }
        localStorage.removeItem("logout")
    }
}

export const homeScreen = (value) => {

    return {
        type: HOME_SCREEN,
        payload: value
    }
}

export const termScreen = (value) => {

    return {
        type: TERM_SCREEN,
        payload: value
    }
}

export const teamScreen = (value) => {

    return {
        type: TEAM_SCREEN,
        payload: value
    }
}


