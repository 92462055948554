//import Header from "../Layout/Header";
//import Footer from "../Layout/Footer";
import { fetchAllNotification, markNotificationAsRead, removeNotification } from "../../actions/notification"
//import Launch from '@material-ui/icons/Launch';
import DeleteIcon from '@material-ui/icons/Delete';
import React, { Component } from "react";
import { connect } from "react-redux";
import isEmpty from "../../utils/isEmpty"
import timeAgo from 'time-ago';
import { Link } from 'react-router-dom'
import InfiniteScroll from 'react-infinite-scroll-component'
import config from "../../_config"
//import store from "../../store";
//import { CLEAR_NOTIFICATION_FEED } from '../../types/notification';
import YesOrNoModal from "../Modals/YesOrNoModal"
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { toastMessageSuccess } from "../../utils/toastMessage"
import 'react-tabs/style/react-tabs.css';
import Swal from 'sweetalert';
import { markNotificationAsRemoveById, getAnnouncement, getAllNotifications, getUnreadNotifcations } from 'notificationsdk';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

class NotificationList extends Component {

    constructor(props) {
        super(props)
        this.state = {
            pageNumber: 1,
            pageSize: 30,
            endMessage: '',
            hasMore: true,
            message: "",
            yesOrNoModalShow: false,
            deleteNotificationID: "",
            notificationId: 0
        }
    }

    redirectToDetail = (id) => {
        this.setState({ ...this.state, notificationId: id }, () => {
            document.getElementById('redirect-to-detail-notification').click();
        });
    }

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    timeBetweenDates = (ttlTime) => {
        // console.log("tttt call", ttlTime)
        var moment = require('moment');
        var localDate = new Date();
        var currentTime = moment.utc(localDate).format()
        var diff = moment.duration(moment(ttlTime).diff(moment(currentTime)));
        let timeRemaining

        let a;

        //One to show conversion
        if (diff.get("months") > 0) {
            // setTimeDiff(diff.get("months") === 1 ? `${diff.get("months")} month remaining` : `${diff.get("months")} months remaining`)

            a = diff.get("months") === 1 ? `Disappearing in ${diff.get("months")} month` : `Disappearing in ${diff.get("months")} months`

        }
        else if (diff.get("days") > 0) {
            // setTimeDiff(diff.get("days") === 1 ? `${diff.get("days")} day remaining` : `${diff.get("days")} days remaining`)
            a = diff.get("days") === 1 ? `Disappearing in ${diff.get("days")} day` : `Disappearing in ${diff.get("days")} days`

        }
        else if (diff.get("hours") > 0) {
            // setTimeDiff(diff.get("hours") === 1 ? `${diff.get("hours")} hour remaining` : `${diff.get("hours")} hours remaining`)
            a = diff.get("hours") === 1 ? `Disappearing in ${diff.get("hours")} hour` : `Disappearing in ${diff.get("hours")} hours`
        }

        else if (diff.get("minutes") > 0) {
            // setTimeDiff(diff.get("minutes") === 1 ? `${diff.get("minutes")} minute remaining` : `${diff.get("minutes")} minutes remaining`)
            a = diff.get("minutes") === 1 ? `Disappearing in ${diff.get("minutes")} minute` : `Disappearing in ${diff.get("minutes")} minutes`

        }

        else if (diff.get("seconds") > 0) {
            a = diff.get("seconds") === 1 ? `Disappearing in ${diff.get("seocnds")} second` : `Disappearing in ${diff.get("seconds")} seconds`

        }

        else if (diff.get("seconds") <= 0 || diff.get("months") <= 0 || diff.get("days") <= 0 || diff.get("hours") <= 0 || diff.get("minutes") <= 0) {
            a = "This notification has been expired will disappear once you refresh the page."

        }
        return a
    }

    fetchMoreAnnouncements = () => {
        getAnnouncement(parseInt((this.props.announcements.data.length / 20) + 1)).catch(e => { });;
    }

    fetchMoreNotifications = () => {
        getAllNotifications(parseInt((this.props.notifications.all.data.length / 20) + 1)).catch(e => { });
    }

    fetchMoreUnreadNotifications = () => {
        getUnreadNotifcations(parseInt((this.props.notifications.unread.data.length / 20) + 1)).catch(e => { });;
    }

    removeClick = (notificationID) => {
        this.setState({ deleteNotificationID: notificationID }, () => {
            this.setState({ confirmationMessage: `Are you sure you want to remove this notification?`, yesOrNoModalShow: true })
        });
    }

    removeNotification = () => {
        markNotificationAsRemoveById(this.state.deleteNotificationID).catch(e => { });;
        toastMessageSuccess("Notification removed.")
    }

    render() {
        return (
            <>
                <div className="BlackStarsBack">
                    <div id="Blackstars" />
                    <div id="Blackstars2" />
                    <div id="Blackstars3" />
                </div>
                <YesOrNoModal msg={this.state.confirmationMessage} showYesOrNoModal={this.state.yesOrNoModalShow}
                    onCloseYesOrNoModal={(value) => {
                        if (value === true) {
                            this.setState({ yesOrNoModalShow: false }, () => {
                                this.removeNotification()

                            })
                        } else {
                            this.setState({ yesOrNoModalShow: false })
                        }
                    }}
                >
                </YesOrNoModal>
                <div className="row">
                    <div className="col-12">
                        <div data-aos="fade-zoom-in" data-aos-duration={1500}>
                            <nav id="navbar" className={"navbar navbar-expand-lg navbar-light Destkop-Navbar px-0 py-5"}>
                                <Link style={{ cursor: 'pointer' }} className="navbar-brand d-flex align-items-center" to="/" onClick={() => { this.props.history.push("/") }}>
                                    <span className="Logo-Txt">NUC</span>
                                    <img src="assets/images/nucoin-logo.png" alt="" />
                                    <span className="Logo-Txt">IN</span>
                                </Link>
                            </nav>
                            <nav id="navbar" className={"navbar navbar-expand-lg navbar-light Mobile-Navbar px-0 py-5"}>
                                <Link style={{ cursor: 'pointer' }} className="navbar-brand d-flex align-items-center" to="/" onClick={() => { this.props.history.push("/") }}>
                                    <span className="Logo-Txt">NUC</span>
                                    <img src="assets/images/nucoin-logo.png" alt="" />
                                    <span className="Logo-Txt">IN</span>
                                </Link>
                            </nav>
                        </div>
                    </div>
                </div>
                <div className="row mb-40">
                    <div className="col-md-8 offset-md-2 col-sm-12">
                        <div className="NotificationList">
                            <div className="card Box">
                                <div className="card-header bg-transparent border-bottom pl-0">
                                    <div className="d-flex flex-wrap align-items-start">
                                        <div className="me-2">
                                            <h3 className="card-title mt-1 mb-0 WhiteColor"><strong>Notifications</strong></h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body p-0">
                                    <Tabs className="TabsList NotificationTabsList">
                                        <TabList className="px-3 border-0">
                                            <Tab>Announcement</Tab>
                                            <Tab>All</Tab>
                                            <Tab>Unread</Tab>
                                            {/* <Tab>Archive</Tab> */}
                                        </TabList>
                                        <TabPanel>
                                            <ul className="list-group list-group-flush p-0">
                                                {
                                                    this.props.announcements.data.length === 0 ? <div style={{ textAlign: 'center', marginTop: '10px' }}>
                                                        <p style={{ color: 'white' }}>Currently, there is no announcements</p>
                                                    </div> : <InfiniteScroll
                                                        dataLength={this.props.announcements.announcement_count}
                                                        hasMore={this.props.announcements.announcement_count > this.props.announcements.data.length}
                                                        next={this.fetchMoreAnnouncements}
                                                        loader={
                                                            this?.props?.waitingFor.includes(
                                                                'fetch-notification-loader'
                                                            ) ? (
                                                                <div style={{ textAlign: 'center' }}><img alt="loading..." style={{ height: '20px', width: '20px', marginRight: '8px' }} src="/assets/images/spinner3.gif"></img></div>
                                                            ) : null
                                                        }
                                                        endMessage={
                                                            <p className="text-center WhiteColor my-3">
                                                                <b>Your device has reached the end of the notification</b>
                                                            </p>
                                                        }
                                                    >
                                                        {
                                                            this.props.announcements.data.map((item) => (
                                                                <li style={{ cursor: 'pointer' }} className="py-3 px-0 border-bottom position-relative">
                                                                    <a href={() => false}>
                                                                        <div className="d-flex">
                                                                            <div className="mr-3">
                                                                                <img src="assets/images/nucoin-logo.png" alt="" className="avatar-md h-auto d-block rounded" />
                                                                            </div>
                                                                            
                                                                            <div className="align-self-center overflow-hidden me-auto">
                                                                                <div>
                                                                                    {
                                                                                        <div onClick={() => { this.redirectToDetail(item.id) }}>
                                                                                            <h5 className={`font-16 ${item.userpreferences.is_read ? 'WhiteColor' : 'GrayColor'}`}>
                                                                                                <strong>
                                                                                                    {item.title}
                                                                                                </strong>
                                                                                            </h5>
                                                                                            {
                                                                                                item.message.length < 200 ? <p className={`mb-0 font-16 ${item.userpreferences.is_read ? 'WhiteColor' : 'GrayColor'}`}>{ReactHtmlParser(item.message)}</p> :
                                                                                                    <p className={`mb-0 font-16 ${item.userpreferences.is_read ? 'WhiteColor' : 'GrayColor'}`}>{ReactHtmlParser(item.message.substring(0, 200) + "...More")}</p>
                                                                                            }
                                                                                            
                                                                                            <p className={`mb-0 ${item.userpreferences.is_read ? 'WhiteColor' : 'GrayColor'}`}>
                                                                                                <span key="t-min-ago">{timeAgo.ago(item.create_time) || 'N/A'}</span>
                                                                                            </p>
                                                                                            <p className="white-color m-0">{this.timeBetweenDates(item.ttl)}</p>
                                                                                        </div>
                                                                                    }
                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                    </a>

                                                                </li>
                                                            ))
                                                        }
                                                    </InfiniteScroll>
                                                }
                                            </ul>
                                        </TabPanel>
                                        <TabPanel>
                                            <ul className="list-group Notification_List list-group-flush p-0">
                                                {
                                                    this.props.notifications.all.data.length === 0 ? <div style={{ textAlign: 'center', marginTop: '10px' }}>
                                                        <p style={{ color: 'white' }}>Currently, there is no notification</p>
                                                    </div>
                                                        :
                                                        <InfiniteScroll
                                                            dataLength={this.props.notifications.all.total_count}
                                                            hasMore={this.props.notifications.all.data.length < this.props.notifications.all.total_count}
                                                            next={this.fetchMoreNotifications}
                                                            loader={
                                                                this?.props?.waitingFor.includes(
                                                                    'fetch-notification-loader'
                                                                ) ? (
                                                                    <div style={{ textAlign: 'center' }}><img alt="loading..." style={{ height: '20px', width: '20px', marginRight: '8px' }} src="/assets/images/spinner3.gif"></img></div>
                                                                ) : null
                                                            }
                                                            endMessage={
                                                                <p className="text-center WhiteColor my-3">
                                                                    <b>Your device has reached the end of the notification</b>
                                                                </p>
                                                            }
                                                        >
                                                            {
                                                                this.props.notifications.all.data.map((item) => (
                                                                    <>
                                                                        {
                                                                            <li style={{ cursor: 'pointer' }} className="py-3 px-0 border-bottom position-relative">
                                                                                <a href={() => false}>
                                                                                    <div className="d-flex">
                                                                                        <div className="mr-3">
                                                                                            <img src="assets/images/nucoin-logo.png" alt="" className="avatar-md h-auto d-block rounded" />
                                                                                        </div>
                                                                                        <div className="align-self-center overflow-hidden me-auto">
                                                                                            <div onClick={() => { this.redirectToDetail(item.id) }}>
                                                                                                <h5 className={`font-16 ${item.userpreferences.is_read ? 'WhiteColor' : 'GrayColor'}`}>
                                                                                                    <strong >
                                                                                                        {item.title}
                                                                                                    </strong>
                                                                                                </h5>
                                                                                                {
                                                                                                    item.message.length < 200 ? <p className={`mb-0 font-16 ${item.userpreferences.is_read ? 'WhiteColor' : 'GrayColor'}`}>{ReactHtmlParser(item.message)}</p> :
                                                                                                        <p className={`mb-0 font-16 ${item.userpreferences.is_read ? 'WhiteColor' : 'GrayColor'}`}>{ReactHtmlParser(item.message.substring(0, 200) + "...More")}</p>
                                                                                                }
                                                                                                <p className={`mb-0 ${item.userpreferences.is_read ? 'WhiteColor' : 'GrayColor'}`}>
                                                                                                    <span key="t-min-ago">{timeAgo.ago(item.create_time) || 'N/A'}</span>
                                                                                                </p>
                                                                                                {
                                                                                                    item.ttl ? <p style={{ color: "red" }}>{this.timeBetweenDates(item.ttl)}</p> : ""
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </a>
                                                                                {
                                                                                    item.ttl ? "" :
                                                                                        <div style={{ position: 'absolute', bottom: '10px', right: '0px' }}>
                                                                                            <DeleteIcon style={{ color: '#808080', width: '30px', height: '30px' }} onClick={() => { this.removeClick(item.id) }} />
                                                                                        </div>
                                                                                }
                                                                            </li>
                                                                        }
                                                                    </>
                                                                ))
                                                            }
                                                        </InfiniteScroll>
                                                }
                                            </ul>
                                        </TabPanel>
                                        <TabPanel>
                                            <ul className="list-group list-group-flush p-0">
                                                {
                                                    this.props.notifications.all.data.length === 0 ? <div style={{ textAlign: 'center', marginTop: '10px' }}>
                                                        <p style={{ color: 'white' }}>Currently, there is no notification</p>
                                                    </div>
                                                        :
                                                        <InfiniteScroll
                                                            dataLength={this.props.notifications.unread.unread_count}
                                                            hasMore={this.props.notifications.unread.data.length < this.props.notifications.unread.unread_count}
                                                            next={this.fetchMoreUnreadNotifications}
                                                            loader={
                                                                this?.props?.waitingFor.includes(
                                                                    'fetch-notification-loader'
                                                                ) ? (
                                                                    <div style={{ textAlign: 'center' }}><img alt="loading..." style={{ height: '20px', width: '20px', marginRight: '8px' }} src="/assets/images/spinner3.gif"></img></div>
                                                                ) : null
                                                            }
                                                            endMessage={
                                                                <p className="text-center WhiteColor my-3">
                                                                    <b>Your device has reached the end of the notification</b>
                                                                </p>
                                                            }
                                                        >
                                                            {
                                                                this.props.notifications.unread.data.map((item) => (
                                                                    <li style={{ cursor: 'pointer' }} className="py-3 px-0 border-bottom position-relative">
                                                                        <a href={() => false}>
                                                                            <div className="d-flex">
                                                                                <div className="mr-3">
                                                                                    <img src="assets/images/nucoin-logo.png" alt="" className="avatar-md h-auto d-block rounded" />
                                                                                </div>
                                                                                <div className="align-self-center overflow-hidden me-auto">
                                                                                    <div>
                                                                                        {
                                                                                            <div onClick={() => { this.redirectToDetail(item.id) }}>
                                                                                                <h5 className="font-16 GrayColor">
                                                                                                    <strong>
                                                                                                        {item.title}
                                                                                                    </strong>
                                                                                                </h5>
                                                                                                {
                                                                                                    item.message.length < 200 ? <p className="mb-0 font-16 GrayColor">{ReactHtmlParser(item.message)}</p> :
                                                                                                        <p className="mb-0 font-16 GrayColor">{item.message.substring(0, 200) + "...More"}</p>
                                                                                                }
                                                                                                <p className="mb-0 GrayColor">
                                                                                                    <span key="t-min-ago">{timeAgo.ago(item.create_time) || 'N/A'}</span>
                                                                                                </p>
                                                                                                {
                                                                                                    item.ttl ?
                                                                                                        <p style={{ color: 'red' }}>{this.timeBetweenDates(item.ttl)}</p> : ""
                                                                                                }
                                                                                            </div>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </a>
                                                                        {
                                                                            item.ttl ? "" :
                                                                                <div style={{ position: 'absolute', bottom: '10px', right: '0px' }}>
                                                                                    <DeleteIcon style={{ color: '#808080', width: '30px', height: '30px' }} onClick={() => { this.removeClick(item.id) }} />
                                                                                </div>
                                                                        }
                                                                    </li>
                                                                ))
                                                            }
                                                        </InfiniteScroll>
                                                }

                                            </ul>
                                        </TabPanel>
                                    </Tabs>



                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Link id="redirect-to-detail-notification" style={{ display: 'none' }} to={`/notification/detail/${this.state.notificationId}`}></Link>

                {/* <Footer /> */}
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    waitingFor: state.commonReducer.waitingFor,
    auth: state.authReducer,
    notifications: state.Notification.notifications,
    announcements: state.Notification.announcements,
})

export default connect(mapStateToProps, { fetchAllNotification, markNotificationAsRead, removeNotification })(NotificationList)