export const GET_ALL_NOTIFICATION = 'GET_ALL_NOTIFICATION'
export const TOTAL_NOTIFICATION_COUNT = "TOTAL_NOTIFICATION_COUNT"
export const SOCKET_CONNECTION = "SOCKET_CONNECTION"
export const ADD_NEW_NOTIFICATION = "ADD_NEW_NOTIFICATION"
export const MARK_NOTIFICATION_AS_READ = "MARK_NOTIFICATION_AS_READ"
export const TOTAL_NOTIFICATION_READ_COUNT = "TOTAL_NOTIFICATION_READ_COUNT"
export const CLEAR_NOTIFICATION_FEED = "CLEAR_NOTIFICATION_FEED"
export const CURRENT_COUNT = "CURRENT_COUNT"
export const REMOVE_NOTIFICATION = "REMOVE_NOTIFICATION"
export const SET_ALL_NOTIFICATION = "SET_ALL_NOTIFICATION"
export const GET_ALL_HEADER_NOTIFICATION = "GET_ALL_HEADER_NOTIFICATION"
export const REMOVE_HEADER_NOTIFICATION = "REMOVE_HEADER_NOTIFICATION"