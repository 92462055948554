import { Component } from "react";
import LocationIcon from '@material-ui/icons/LocationOn';
import CallIcon from '@material-ui/icons/Call';
import EmailIcon from '@material-ui/icons/Email';
import PrintIcon from '@material-ui/icons/Print';
import PersonIcon from '@material-ui/icons/Person';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import Footer from "../Layout/Footer";
import "../Modals/WebCam/style.css"
import { WebcamCapture } from "../Modals/WebCam/Webcam"
import { toastMessageFailure } from "../../utils/toastMessage";
import ChooseWalletModal from "../Modals/WebCam/ChooseWalletModal"
import ConfirmationModal from "../Modals/WebCam/ConfirmationModal"
import InfoSuccess from "../Modals/InfoSuccess";
import WalletPinModal from "../Modals/WebCam/WalletPinModal"
import { getPaymentRegisterWalletProfile } from "../../api/wallet/wallet"
import QRCode from "react-qr-code";
import config from "../../_config"
import { connect } from "react-redux";

class ShopCardDetail extends Component {

    componentRef = null;

    state = {
        imagePath: "",
        chooseWalletModalShow: false,
        chooseWallet: "",
        chooseWalletMnemonics: "",
        confirmationModalShow: false,
        successModalShow: false,
        alertTitlt: "",
        alertMessage: "",
        printStatus: "",
        printLoader: false,
        name: "",
        wallet: ""
    }
    componentDidMount = async () => {
        if (this?.props?.isShowShopRegisterScreen === false) {
            this.props.history.push("/wallet-listing")
            return
        }
        try {
            this.setState({ printLoader: true })
            let { data } = await getPaymentRegisterWalletProfile(this.props.auth.user.username)
            this.setState({ printStatus: data?.profile?.printed_status, wallet: data?.profile?.account_id })
            this.setState({ name: data?.profile?.first_name + " " + data?.profile?.last_name })
            this.setState({ printLoader: false })

        }
        catch (e) {
            this.setState({ printLoader: false })

        }



    }




    render() {
        console.log("auth",`${config.walletNucoinServiceApiUrl}/v1/nucoin/wallet/read-shop-card-image?username=${"huzefa"}`)
        return (
            <>
                <div className="BlackStarsBack">
                    <div id="Blackstars" />
                    <div id="Blackstars2" />
                    <div id="Blackstars3" />
                </div>

                <div className="row" id="zIndexMob">
                    <div className="col-md-6 offset-md-3">
                        <div ref={ref => this.componentRef = ref} className="AccountAddress text-center ShopDetail">
                            {/* <h4>Capture Image to Proceed dfdddyour Account</h4> */}
                            <div className="SetPass ShopInfo">

                                <div className="d-flex justify-content-between align-items-center">
                                    <div>
                                        <div style={{ border: "5px solid #fff", padding: "5px" }}>
                                            <QRCode style={{ color: 'blue' }} className="qr-code" fill="#fff" size={140} value={this.props.auth.user.username}></QRCode>
                                        </div>
                                    </div>
                                    <div>
                                        <img src={`${config.walletNucoinServiceApiUrl}/v1/nucoin/wallet/read-shop-card-image?username=${this.props.auth.user.username}`} />
                                        <h6 className="mt-2">{this.props.auth.user.username}</h6>
                                    </div>
                                </div>

                                <hr style={{ borderColor: "#fff" }} />
                                <div className="position-relative">
                                    <ul className="text-left list-inline">
                                        <li>
                                            <PersonIcon /> <span>{this.state.name}</span>
                                        </li>
                                        <li>
                                            <CallIcon /> <span>{this?.props?.auth?.user?.mobile_number}</span>
                                        </li>
                                        <li>
                                            <EmailIcon /> <span>{this?.props?.auth?.user?.emails}</span>
                                        </li>



                                        <li>
                                            <PrintIcon />
                                            {this.state.printLoader === true ? <img alt="loading..." style={{ height: '20px', width: '20px', marginRight: '8px' }} src="/assets/images/spinner3.gif"></img> :

                                                this.state.printStatus !== 0 ? <span>Printed</span> : <span>Printing In Progress</span>
                                            }
                                        </li>
                                        <li>
                                            <AccountBalanceWalletIcon /> <span>{this.state.wallet}</span>
                                        </li>
                                    </ul>
                                    {/* <div style={{ position: "absolute", top: 0, right: 0, border: "5px solid #fff", padding: "5px" }}>
                                        <QRCode style={{ color: 'blue' }} className="qr-code" fill="#fff" size={120} value={this.props.auth.user.username}></QRCode>
                                    </div> */}
                                </div>
                                <div style={{ display: "flex", justifyContent: "center" }}>
                                    <button onClick={() => { this.props.history.push("/wallet-listing") }} type="button" class="btn btn-secondary Cancel" >
                                        Back
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </>
        )
    }
}


const mapStateToProps = (state) => ({
    isShowShopRegisterScreen: state.walletReducer.isShowShopRegisterScreen,
    auth: state.authReducer,
})

export default connect(mapStateToProps, {})(ShopCardDetail)
