import React, { Component } from "react";
import { toastMessageInfo } from "../../../utils/toastMessage";
import { showRandomMnemonicsScreen, showCopyMnemoncisScreen } from "../../../actions/wallet";
import { connect } from "react-redux";
import Header from "../../Layout/Header";
import Footer from "../../Layout/Footer";
import ContentCopyIcon from '@material-ui/icons/FileCopy';
class CopyMnemonics extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mnemonicsArray: [],
            isError: false,
            message: "",
            isLoading: false,
            disable: "",
            shuffleArray: []
        }
    }

    componentDidMount() {

        if (this?.props?.isShowCopyMnemonicsScreen === false) {
            this.props.history.push("/create-wallet")
            return
        }
        else {
            var mnemoncisExist = this?.props?.location?.state?.walletMnemonics
            if (mnemoncisExist) {
                var splitMnemoncisArray = this.props.location.state.walletMnemonics.split(" ");
                this.setState({ mnemonicsArray: splitMnemoncisArray })
            }

        }

    }
    shuffleMnemoncisAndGoNext = () => {

        var shuffleArray = this.shuffleMnemonics(this.state.mnemonicsArray)
        this.props.showRandomMnemonicsScreen(true)
        this.props.showCopyMnemoncisScreen(false)
        //console.log("mnemoncis copy mnemonics","original mnemonics",this?.props?.location?.state?.walletMnemonics,"shffle",shuffleArray,"walletPublicKey",this?.props?.location?.state?.walletPublicKey,"wallet",this?.props?.location?.state?.walletAddress)
        // update redux
        this.props.history.push("/mnemonics-test", {
            originalMnemonics: this?.props?.location?.state?.walletMnemonics,
            shuffleMnemonics: shuffleArray,
            walletAddress: this?.props?.location?.state?.walletAddress,
            walletPublicKey: this?.props?.location?.state?.walletPublicKey,
            walletPassword: this?.props?.location?.state?.walletPassword,
            walletType: this?.props?.location?.state?.walletType,
            accountName: this?.props?.location?.state?.accountName,
        })
    }

    shuffleMnemonics = (array) => {
        var currentIndex = array.length;
        let temporaryValue, randomIndex;
        // While there remain elements to shuffle...
        while (0 !== currentIndex) {
            // Pick a remaining element...
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex -= 1;

            // And swap it with the current element.
            temporaryValue = array[currentIndex];
            array[currentIndex] = array[randomIndex];
            array[randomIndex] = temporaryValue;
        }
        return array;
    }

    copyMnemonics = async () => {
        const el = document.createElement('textarea');
        el.value = this.props.location.state.walletMnemonics;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        toastMessageInfo("You successfully copy the mnemonics!")
    }


    render() {

        return (
            <>
                <div className="BlackStarsBack">
                    <div id="Blackstars" />
                    <div id="Blackstars2" />
                    <div id="Blackstars3" />
                </div>
                <div className="row" id="zIndexMob">
                    <div className="col-md-6 offset-md-3">
                        <div className="AccountAddress text-center">
                            <div className="MneMonics">
                                <h4 className="text-center">Please copy down the mnemonics for your new account below. If you loose these mnemonics, you will risk loosing your coins (wealth). you will have to confirm the mnemonic on the next screen</h4>
                                <ul className="list-inline mb-4">
                                    {this.state.mnemonicsArray.map((item, index) => (
                                        <li>{item}</li>
                                    ))}
                                </ul>
                                <button onClick={this.shuffleMnemoncisAndGoNext} type="button" className="btn btn-primary Submit">Continue</button>
                                <button onClick={() => {
                                    this.props.history.push("/create-wallet")
                                }} type="button" className="btn btn-secondary Cancel" data-dismiss="modal">Cancel</button>
                                {/* <i onClick={this.copyMnemonics} style={{ color: 'white' }} className="fa fa-copy" type="button"><span style={{ marginLeft: '10px', color: 'white' }}>Copy</span></i> */}
                                <div className="d-flex justify-content-end mt-3">
                                    <span onClick={this.copyMnemonics} style={{ color: 'white', cursor: 'pointer' }}>Copy <ContentCopyIcon style={{ color: 'white', marginLeft: '5px' }} /></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </>
        )
    }

}



const mapStateToProps = (state) => ({
    isShowCopyMnemonicsScreen: state.walletReducer.isShowCopyMnemonicsScreen
})

export default connect(mapStateToProps, { showRandomMnemonicsScreen, showCopyMnemoncisScreen })(CopyMnemonics)
