import { Component } from "react";
import Footer from "../../../Layout/Footer";
import "../../../Modals/WebCam/style.css"
import { QrReader } from 'react-qr-reader';
import { toastMessageFailure } from "../../../../utils/toastMessage";
import { connect } from "react-redux";
import { getBalanceByAddress, getPosTerminalUserProfile, getProductListing } from "../../../../api/pos/pos";
import config from '../../../../_config/index';
import { setUserDetail } from '../../../../actions/paymentPos';
import ErrorAlert from "../../../Modals/ErrorAlert";
import ConfirmPaymentModal from "../ConfirmPaymentModal/ConfirmPaymentModal";

class POSTerminal extends Component {

    state = {
        data: {},
        items: {
            products: [],
            categories: [],
            category: 'all'
        },
        totalBill: 0,
        isLoading: true,
        errorModalShow: false,
        alertTitle: "",
        alertMessage: "",
        showConfirmModal: false
    }

    componentDidMount() {
        if (!localStorage.getItem('pos-user')) this.props.history.push('/pos/terminal');

        this.props.setUserDetail(JSON.parse(localStorage.getItem('pos-user')));

        getBalanceByAddress(JSON.parse(localStorage.getItem('pos-user')).account_id).then(({ data }) => {
            this.setState({ ...this.state, data: { ...JSON.parse(localStorage.getItem('pos-user')), balance: data.balance } }, () => {
                this.props.setUserDetail(this.state.data);
            });
        }).catch(e => { })

        getProductListing().then(({ data }) => {
            this.setState({ ...this.state, items: data, isLoading: false });
        }).catch(e => {
            this.setState({ ...this.state, isLoading: false });
        })
    }

    onProceedModal = () => {
        if (!this.state.imagePath) {
            toastMessageFailure("Please provide a selfie image to proceed further.")

        }
        else {
            this.setState({ chooseWalletModalShow: true })
        }
    }

    onProcess(e) {
        e.preventDefault();
        this.setState({ ...this.state, showConfirmModal: true });
    }

    render() {
        const { products } = this.state.items;
        const { user_name, balance, first_name, middle_name, last_name, account_id } = this.props.userDetail;
        const { isLoading, totalBill } = this.state;

        return (
            <>
                <div className="BlackStarsBack">
                    <div id="Blackstars" />
                    <div id="Blackstars2" />
                    <div id="Blackstars3" />
                </div>
                <div className="row" id="zIndexMob">
                    <div className="col-md-10 offset-md-1">
                        <div className="AccountAddress text-center">
                            <h4>Product Listing Section</h4>
                            <div className="Box SetPass text-left ProductList">
                                <div className="d-flex justify-content-between align-items-center">
                                    <div>
                                        <h1>{first_name} {middle_name || ''} {last_name}</h1>
                                        <h2>{account_id}</h2>
                                        <h2>{balance} NUC</h2>
                                    </div>
                                    <div><h1>Total Bill: {totalBill} NUC</h1></div>
                                </div>



                                <div>
                                    {
                                        products.map((product, index) => {
                                            const { id, title, category, description, price, resource_url, isChecked } = product;
                                            return (
                                                <div style={{ cursor: 'pointer',borderBottom: "1px solid #fff" }} onClick={() => {
                                                    this.state.items.products[index].isChecked = !this.state.items.products[index].isChecked;

                                                    let totalBill = this.state.totalBill;

                                                    if (this.state.items.products[index].isChecked) totalBill += this.state.items.products[index].price;
                                                    else totalBill -= this.state.items.products[index].price;
                                                    // alert(`${parseFloat(totalBill)} - ${parseFloat(this.props.userDetail.balance)}`)
                                                    if (parseFloat(totalBill) > parseFloat(this.props.userDetail.balance)) {
                                                        this.state.items.products[index].isChecked = !this.state.items.products[index].isChecked;
                                                        return this.setState({ ...this.state, errorModalShow: true, alertTitle: 'Low balance', alertMessage: `Total bill exceeding from the user's account balance ${this.props.userDetail.balance} NUC` })
                                                    }

                                                    this.setState({ ...this.state, totalBill: totalBill });
                                                }} key={id} className="d-flex justifycontent-between align-items-center mb-3 pb-3">
                                                    <div>
                                                        <img style={{ width: '100px', height: '100px' }} src={resource_url} alt="Profile Picture" />
                                                    </div>
                                                    <div className="mx-4">
                                                        <div style={{ color: 'white' }}><label style={{color: "#1ff8f2", fontSize: "20px", fontWeight: "bold", marginBottom: "5px"}}>{title}</label></div>
                                                        <div style={{ color: 'white' }}><p className="m-0">{description}</p></div>
                                                        <div style={{ color: 'white' }}><label className="m-0" style={{color: "#1ff8f2", fontWeight: "bold"}}>Price: {price} NUC</label></div>
                                                    </div>
                                                    <div>
                                                        <input type="checkbox" onClick={() => {

                                                        }} checked={isChecked} />
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>


                                {/* <div className="container-fluid" style={{ padding: '10px' }}>
                                    {
                                        products.map((product, index) => {
                                            const { id, title, category, description, price, resource_url, isChecked } = product;
                                            return (
                                                <div style={{ cursor: 'pointer' }} onClick={() => {
                                                    this.state.items.products[index].isChecked = !this.state.items.products[index].isChecked;

                                                    let totalBill = this.state.totalBill;

                                                    if (this.state.items.products[index].isChecked) totalBill += this.state.items.products[index].price;
                                                    else totalBill -= this.state.items.products[index].price;
                                                    // alert(`${parseFloat(totalBill)} - ${parseFloat(this.props.userDetail.balance)}`)
                                                    if (parseFloat(totalBill) > parseFloat(this.props.userDetail.balance)) {
                                                        this.state.items.products[index].isChecked = !this.state.items.products[index].isChecked;
                                                        return this.setState({ ...this.state, errorModalShow: true, alertTitle: 'Low balance', alertMessage: `Total bill exceeding from the user's account balance ${this.props.userDetail.balance} NUC` })
                                                    }

                                                    this.setState({ ...this.state, totalBill: totalBill });
                                                }} key={id} className="row">
                                                    <div className="col-2">
                                                        <img style={{ width: '100px', height: '100px' }} src={resource_url} alt="Profile Picture" />
                                                    </div>
                                                    <div className="col-10">
                                                        <div className="row">
                                                            <div className="col-10">
                                                                <div className="row" style={{ color: 'white' }}>{title}</div>
                                                                <div className="row" style={{ color: 'white' }}>{description}</div>
                                                                <div className="row" style={{ color: 'white' }}>Price: {price} NUC</div>
                                                            </div>
                                                            <div className="col-2">
                                                                <input type="checkbox" onClick={() => {
                                                                    // this.state.items.products[index].isChecked = !this.state.items.products[index].isChecked;
                                                                    // this.setState({ ...this.state, items: { ...this.state.items } });
                                                                }} checked={isChecked} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div> */}
                                <div style={{ display: "flex", justifyContent: "center", marginTop: '10px' }}>
                                    <button onClick={() => { this.props.history.go(-1) }} type="button" class="btn btn-secondary Cancel" >
                                        Go Back
                                    </button>
                                    {
                                        totalBill > 0 && <button disabled={this.state.isLoading} onClick={this.onProcess.bind(this)} type="button" className="btn btn-primary Submit">
                                            Confirm Payment
                                        </button>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ErrorAlert showErroModal={this.state.errorModalShow} title={this.state.alertTitle} message={this.state.alertMessage} onCloseErrorModal={() =>
                    this.setState({
                        errorModalShow: false,
                        alertTitle: "",
                        alertMessage: ""
                    })} />
                <ConfirmPaymentModal closeModalAndRedirect={() => {
                    this.setState({ ...this.state, showConfirmModal: false }, () => {
                        this.props.history.push('/pos/terminal')
                    })
                }} closeModal={() => {
                    this.setState({ ...this.state, showConfirmModal: false })
                }} items={products.filter(_ => _.isChecked === true)} totalBill={totalBill} showConfirmModal={this.state.showConfirmModal} userDetail={this.props.userDetail} />
                <Footer />
            </>
        )
    }
}


const mapStateToProps = (state) => ({
    isShowShopRegisterScreen: state.walletReducer.isShowShopRegisterScreen,
    userDetail: state.PaymentPosReduser.userDetails
})

export default connect(mapStateToProps, { setUserDetail })(POSTerminal)
