// import { Modal } from "bootstrap";
import React, { useEffect } from 'react';

export default (props) => {


    return (
        <React.Fragment>
            <button style={{ display: 'none' }} id="roadmap-modal-btn" type="button" className="btn btn-primary" data-toggle="modal" data-target="#roadmap-modal">
            Launch demo modal
            </button>
            <div  className="modal fade" id="roadmap-modal" tabindex="-1" role="dialog" aria-labelledby="roadmap-modal-title">
            <div style={{ minWidth: '80%' }} className="modal-dialog modal-dialog-centered SetPass roadmapModal" role="document">
                <div className="modal-content">
                {/* <hr style={{ width: '100%' }}/> */}
                <div className="modal-header">
                    <h4 className="modal-title text-center w-100">Road Map - Board</h4>
                </div>
                <div className="modal-body">
                <div id="roadmap-content" className='row'>
                    <div className='col-3' style={{ marginRight: '-4px', marginLeft: '4px' }}>
                        <div className='card'>
                            <div className='card-header bg-white p-2'>
                                <h5 className='m-0 text-center'>All</h5>
                            </div>
                            <div className="RoadMapCardScroll">
                                {
                                    props.roadMapList.map((item, index) => (

                                        <div className='card-body p-2' style={{ backgroundColor: "#f2f2f2", margin: '5px' }}>
                                            <div className='border-bottom pb-1'>
                                                <strong  className="m-0">{item.fields.Goal}</strong>

                                                <p className='m-0'>{item.fields.Description}</p>
                                                <span style={{ color: "rgb(17, 174, 170)" }}>{`${item.fields['Due date']}`.split('-').reverse().join('-')}</span>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                    <div className='col-3' style={{ marginRight: '-4px' }}>
                        <div className='card RoadMapCard'>
                            <div className='card-header bg-white p-2'>
                                <h5 className='m-0 text-center'>Completed</h5>
                            </div>
                            <div className="RoadMapCardScroll">
                                {
                                    props.roadMapList.map((item, index) => (
                                        item.length !== 0 ?
                                            item.fields.Status === 'Complete' ? (

                                                <div className='card-body p-2' style={{ backgroundColor: "#f2f2f2", margin: '5px' }}>
                                                    <div className='border-bottom pb-1'>
                                                        <strong  className="m-0">{item.fields.Goal}</strong>

                                                        <p className='m-0'>{item.fields.Description}</p>
                                                        <span style={{ color: "rgb(17, 174, 170)" }}>{`${item.fields['Due date']}`.split('-').reverse().join('-')}</span>
                                                    </div>
                                                </div>

                                            ) :
                                                (
                                                    null
                                                )
                                            :
                                            (
                                                <h4>No data Found</h4>
                                            )
                                    ))
                                }
                            </div>
                        </div>
                    </div>

                    <div className='col-3' style={{ marginRight: '-4px' }}>
                        <div className='card RoadMapCard'>
                            <div className='card-header bg-white p-2'>
                                <h5 className='m-0 text-center'>In Progress</h5>
                            </div>
                            <div className="RoadMapCardScroll">
                                {
                                    props.roadMapList.map((item, index) => (
                                        item.length !== 0 ?
                                            item.fields.Status === 'In progress' ? (

                                                <div className='card-body p-2' style={{ backgroundColor: "#f2f2f2", margin: '5px' }}>
                                                    <div className='border-bottom pb-1'>
                                                        <strong  className="m-0">{item.fields.Goal}</strong>

                                                        <p className='m-0'>{item.fields.Description}</p>
                                                        <span style={{ color: "rgb(17, 174, 170)" }}>{`${item.fields['Due date']}`.split('-').reverse().join('-')}</span>
                                                    </div>
                                                </div>
                                            ) :
                                                (
                                                    null
                                                )
                                            :
                                            (
                                                <h4>No data Found</h4>
                                            )
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                    <div className='col-3' style={{ marginRight: '-4px' }}>
                        <div className='card RoadMapCard'>
                            <div className='card-header bg-white p-2'>
                                <h5 className='m-0 text-center'>Planning</h5>
                            </div>
                            <div className="RoadMapCardScroll">
                                {
                                    props.roadMapList.map((item, index) => (
                                        item.length !== 0 ?
                                            item.fields.Status === 'Planning' ? (
                                                <div className='card-body p-2' style={{ backgroundColor: "#f2f2f2", margin: '5px' }}>
                                                    <div className='border-bottom pb-1'>
                                                        <strong  className="m-0">{item.fields.Goal}</strong>

                                                        <p className='m-0'>{item.fields.Description}</p>
                                                        <span style={{ color: "rgb(17, 174, 170)" }}>{`${item.fields['Due date']}`.split('-').reverse().join('-')}</span>
                                                    </div>
                                                </div>
                                            ) :
                                                (
                                                    null
                                                )
                                            :
                                            (
                                                <h4>No data Found</h4>
                                            )
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                    <div className="w-100 text-right">
                        <button type="button" className="btn btn-primary Submit m-0 mt-3" onClick={() => {
                            document.getElementById('roadmap-modal-btn').click();
                        }} >Close</button>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>
        </React.Fragment>
    );
}