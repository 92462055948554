import Header from "../Layout/Header";
import Footer from "../Layout/Footer";

import 'react-dropzone-uploader/dist/styles.css'
import Dropzone from 'react-dropzone-uploader'
import countryConfig from "../../_config/countryConfig";
import { getUserPersonalDetailForKyc, getUserAddressDetailForKyc, addCustomerDetail, checkLedger, getKycProgress, getToggleValue, loginNft } from "../../api/kyc/kyc"
import { predictDocumentQuality, validatePassport, validateLicence, validatePhotoID, validateSelfie } from "../../api/AI/AI"
import { toastMessageSuccess, toastMessageFailure } from "../../utils/toastMessage"
import { validateFile } from "../../_helpers/fileSizeTypeValidator";
import config from "../../_config"
import React from "react";
import ErrorAlert from "../Modals/ErrorAlert"
import InfoSuccess from "../Modals/InfoSuccess"
import GreenInfo from "../Modals/GreenInfo"
import LoadingOverlay from "../../Loader";
import { connect } from "react-redux";
import { setUpUser, logOutUser } from "../../actions/auth"
import { WarningMessageAlert, ErrorMessageAlert } from "../../utils/alert"
import { ErrorMessage } from "../../messages/ErrorMessage"
import './styles.css'
import axios from "axios"
class Kyc extends React.Component {
    state = {
        formFieledDisable: false,
        firstName: "",
        lastName: "",
        middleName: "",
        email: "",
        phone: "",
        addressLine1: "",
        addressLine2: "",
        city: "",
        province: "",
        addressType: "Select Address Type",
        postCode: "",
        country: "Select Country",
        isError: false,
        message: "",
        isLoading: false,
        disabled: "",
        isLoadingDocument: false,

        imageFile: "",
        errorModalShow: false,
        successModalShow: false,

        // Disable picture
        showCustomerAddressFilledSuccess: false,
        submitButtonShow: true,

        passportDisable: true,
        driverLicenseDisable: true,
        photoIdDisable: true,
        selfieDisable: true,

        // passport successText
        passportUplaodSuccessText: "",
        passportUplaod: false,
        drivingLicenseUpload: false,
        photoIDUpload: false,
        slefieUplaod: false,
        exchangeRegistration: "",
        greenModalShow: false,
        // Image Pth
        passportImagePath: "",
        licenceImagePath: "",
        photoIdImagePath: "",
        selfieImagePath: "",
        passportImagePathDoc: "",
        photoIDImagePathDoc: "",
        selfieImagePathDoc: "",
        licenseImagePathDoc: "",
        kycStatusDone: false,
        showImage: false,

        showPassportDropBox: true,
        showLicenceDropBox: true,
        showPhotoIDDropBox: true,
        showSelfieDropBox: true,
        redirectUrl: "",
        customerFillFilled: "Your address field is already filled please upload the below images to complete KYC verification",
        userCountry: "",
        isErrorAlert: false
    }

    componentWillMount = async () => {

        try {
            let redirect = new URLSearchParams(this.props.location.search).get("redirect")
            let authentication = new URLSearchParams(this.props.location.search).get("authentication")

            if (redirect !== null) {


                let { data } = await loginNft(authentication)
                localStorage.setItem('jwtToken', data.data.token)
                this.props.setUpUser(data.data.token)
                this.getUserPersonaDetail()

            }
            else {
                this.getUserPersonaDetail()

            }

        }

        catch (err) {

            if (err?.response?.status === 403) {
                //alert("hit theree logout")

                this.props.logOutUser(err?.response?.status)
            }
            else if (err.message === "Network Error" || err?.response?.status === 500) {
                toastMessageFailure(ErrorMessage.networkError)

            }

        }
    }


    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    fetchMyPassportImage = async () => {
        try {
            const docResponse = await axios.get(
                `${config.apiUrl}/read_image?path=${this.state.passportImagePath}`,
                {
                    responseType: 'blob',
                }
            );
            const { data } = docResponse;
            this.setState({
                passportImagePathDoc: data
            })
        }
        catch (e) {

        }
    }

    fetchMyPhotIDImage = async () => {
        try {
            const docResponse = await axios.get(
                `${config.apiUrl}/read_image?path=${this.state.photoIdImagePath}`,
                {
                    responseType: 'blob',
                }
            );
            const { data } = docResponse;
            this.setState({
                photoIDImagePathDoc: data
            })
        }
        catch (e) {

        }
    }


    fetchMySelfieImage = async () => {
        try {
            const docResponse = await axios.get(
                `${config.apiUrl}/read_image?path=${this.state.selfieImagePath}`,
                {
                    responseType: 'blob',
                }
            );

            const { data } = docResponse;
            this.setState({
                selfieImagePathDoc: data
            })
        }
        catch (e) {

        }

    }

    fetchLicenseImage = async () => {
        try {
            const docResponse = await axios.get(
                `${config.apiUrl}/read_image?path=${this.state.licenceImagePath}`,
                {
                    responseType: 'blob',

                }
            );

            const { data } = docResponse;
            this.setState({

                licenseImagePathDoc: data
            })
        }
        catch (e) {

        }

    }


    getUserPersonaDetail = async () => {

        this.setState({ isLoadingDocument: true });
        try {

            const { data } = await getUserPersonalDetailForKyc();
            const addressDetail = await getUserAddressDetailForKyc();
            const response = await getKycProgress()
            let kycValue = response?.data.data?.kyc_status


            // now we check kyc progress
            if (kycValue === 1) {
                this.setState({
                    kycStatusDone: true
                })
            }
            else {
                this.setState({
                    kycStatusDone: false
                })
            }

            this.setState({ email: data.data.email, firstName: data.data.first_name, middleName: data.data.middle_name, lastName: data.data.last_name, phone: data.data.mobile_number, userCountry: data?.data?.country_name })
            if (addressDetail.data.data.length >= 1) {
                // alert("your cusomer address there")
                this.setState({
                    formFieledDisable: true,//disable all field
                    showCustomerAddressFilledSuccess: true,//show customer success text
                    submitButtonShow: false,//hide btn
                    addressLine1: addressDetail.data.data[0].address_line1,
                    addressLine2: addressDetail.data.data[0].address_line2,
                    city: addressDetail.data.data[0].city_town_suburb,
                    province: addressDetail.data.data[0].state_province,
                    addressType: addressDetail.data.data[0].address_type,
                    postCode: addressDetail.data.data[0].post_code,
                    country: addressDetail.data.data[0].country,

                })

                // // now we check kyc progress
                // if (kycValue === 1) {
                //     this.setState({
                //         kycStatusDone: true
                //     })
                // }
                // else {
                //     this.setState({
                //         kycStatusDone: false
                //     })
                // }

                // if all document validated
                if (
                    response?.data?.data?.photo_id_doc_validated === 1 && response?.data?.data?.selfie_doc_validated === 1 && (response?.data?.data?.driver_lic_doc_validated === 1 || response?.data?.data?.passport_doc_validated === 1)
                ) {

                    this.setState({
                        photoIdDisable: true,
                        driverLicenseDisable: true,
                        passportDisable: true,
                        selfieDisable: true,

                        photoIDUpload: true,
                        slefieUplaod: true,

                        showPhotoIDDropBox: false,
                        showSelfieDropBox: false,
                        customerFillFilled: "Your KYC is completed. Now you can proceed with further operation on our website like, you can create and access wallet."

                    })

                    // set Images
                    let PassportPath = response?.data?.passportImagePath
                    if (PassportPath) {
                        let formatPassportPath = PassportPath.substring(PassportPath.indexOf(':') + 3, PassportPath.lastIndexOf('}') - 1)
                        this.setState({ passportImagePath: formatPassportPath })
                    }


                    let licensePath = response?.data?.licenseImagePath
                    if (licensePath) {
                        let formatLicensePath = licensePath.substring(licensePath.indexOf(':') + 3, licensePath.lastIndexOf('}') - 1)
                        this.setState({ licenceImagePath: formatLicensePath })
                    }


                    let photoIdPath = response?.data?.photoIdImagePath
                    if (photoIdPath) {
                        let formatPhotoIdPath = photoIdPath.substring(photoIdPath.indexOf(':') + 3, photoIdPath.lastIndexOf('}') - 1)
                        this.setState({ photoIdImagePath: formatPhotoIdPath }, () => {
                            this.fetchMyPhotIDImage()
                        })

                    }
                    // console.log("selfie", ret, typeof ret)
                    let selfiePath = response?.data?.selfieImagePath
                    if (selfiePath) {
                        let formatSelfiePath = selfiePath.substring(selfiePath.indexOf(':') + 3, selfiePath.lastIndexOf('}') - 1)
                        this.setState({ selfieImagePath: formatSelfiePath }, () => {
                            this.fetchMySelfieImage()
                        })

                    }
                    if (response?.data?.data?.driver_lic_doc_validated === 1) {
                        this.setState({ drivingLicenseUpload: true, showLicenceDropBox: false })
                        await this.fetchLicenseImage()

                    }
                    if (response?.data?.data?.passport_doc_validated === 1) {
                        this.setState({ passportUplaod: true, showPassportDropBox: false })
                        await this.fetchMyPassportImage()

                    }
                }

                //second condition if passport or driver license not fill
                else if (response?.data?.data?.driver_lic_doc_validated === 0 && response?.data?.data?.passport_doc_validated === 0) {


                    this.setState({
                        photoIdDisable: true,
                        selfieDisable: true,
                        passportDisable: false,
                        driverLicenseDisable: false

                    })
                }

                // if driving license or passport is uplaoded
                else if (response?.data?.data?.driver_lic_doc_validated === 1 || response?.data?.data?.passport_doc_validated === 1) {


                    if (response?.data?.data?.driver_lic_doc_validated === 1) {

                        let licensePath = response?.data?.licenseImagePath
                        if (licensePath) {
                            let formatLicensePath = licensePath.substring(licensePath.indexOf(':') + 3, licensePath.lastIndexOf('}') - 1)
                            this.setState({ licenceImagePath: formatLicensePath }, () => {
                                this.fetchLicenseImage()
                            })

                        }

                        this.setState({
                            drivingLicenseUpload: true,
                            passportDisable: true,
                            driverLicenseDisable: true,
                            showLicenceDropBox: false

                        })
                        if (response?.data?.data?.photo_id_doc_validated === 1) {
                            this.setState({
                                photoIDUpload: true,
                                photoIdDisable: true,
                                showPhotoIDDropBox: false,
                                selfieDisable: false,
                                showSelfieDropBox: true


                            })
                            let photoIdPath = response?.data?.photoIdImagePath
                            if (photoIdPath) {
                                let formatPhotoIdPath = photoIdPath.substring(photoIdPath.indexOf(':') + 3, photoIdPath.lastIndexOf('}') - 1)
                                this.setState({ photoIdImagePath: formatPhotoIdPath }, () => {
                                    this.fetchMyPhotIDImage()
                                })

                            }
                        }
                        else {
                            this.setState({
                                photoIDUpload: false,
                                photoIdDisable: false,
                                showPhotoIDDropBox: true

                            })

                        }

                    }
                    if (response?.data?.data?.passport_doc_validated === 1) {
                        // alert("passport uplaod")

                        let PassportPath = response?.data?.passportImagePath
                        if (PassportPath) {
                            let formatPassportPath = PassportPath.substring(PassportPath.indexOf(':') + 3, PassportPath.lastIndexOf('}') - 1)
                            this.setState({ passportImagePath: formatPassportPath }, () => {
                                this.fetchMyPassportImage()
                            })
                        }

                        this.setState({
                            passportUplaod: true,
                            passportDisable: true,
                            driverLicenseDisable: true,
                            showPassportDropBox: false

                        })
                        if (response?.data?.data?.photo_id_doc_validated === 1) {
                            //("photo id upload")
                            this.setState({
                                photoIDUpload: true,
                                photoIdDisable: true,
                                showPhotoIDDropBox: false,
                                selfieDisable: false,
                                showSelfieDropBox: true

                            })
                            let photoIdPath = response?.data?.photoIdImagePath
                            if (photoIdPath) {
                                let formatPhotoIdPath = photoIdPath.substring(photoIdPath.indexOf(':') + 3, photoIdPath.lastIndexOf('}') - 1)
                                this.setState({ photoIdImagePath: formatPhotoIdPath }, () => {
                                    this.fetchMyPhotIDImage()
                                })

                            }
                        }
                        else {
                            // alert("photo not upload")
                            this.setState({
                                photoIDUpload: false,
                                photoIdDisable: false,
                                showPhotoIDDropBox: true

                            })

                        }

                    }

                }

                else if (
                    response?.data.message === "KYC Process not started" ||
                    (response?.data?.data?.passport_doc_loaded === 1 || response?.data?.data?.passport_doc_validated === null) ||
                    (response?.data?.data?.driver_lic_doc_loaded === 1 || response?.data?.data?.driver_lic_doc_validated === null)




                ) {
                    // alert("i am in this condition")

                    this.setState({ passportDisable: false, driverLicenseDisable: false })
                }

                this.setState({ isLoadingDocument: false });

            }
            //address exist if end
            else {

                this.setState({
                    isLoadingDocument: false,
                    formFieledDisable: false,
                    showCustomerAddressFilledSuccess: false,
                    submitButtonShow: true,
                })
            }
        }
        catch (err) {

            this.setState({ isLoadingDocument: false });
            // jwt not authorizes logout the user
            //let redirect = new URLSearchParams(this.props.location.search).get("redirect")
            if (err?.response?.status === 403) {

                this.props.logOutUser(err?.response?.status)
            } else if (err.message === "Network Error") {
                toastMessageFailure("Sorry! We are facing some network issues. Please try again later.")
            }
            else if (err?.response?.data) {
                toastMessageFailure("Sorry! We are facing some network issues. Please try again later.")
            }

        }

    }

    onSubmit = async () => {
        if (this.state.addressLine1 === '' || this.state.addressLine1 === null) {
            return this.setState({ isError: true, isErrorAlert: false, message: 'Please enter address line 1 it is required!' });
        }
        else if (this.state.city === '' || this.state.city === null) {
            return this.setState({ isError: true, isErrorAlert: false, message: 'Please enter your city it is required!' });
        }
        else if (this.state.province === '' || this.state.province === null) {
            return this.setState({ isError: true, isErrorAlert: false, message: 'Please enter your state or province it is required!' });
        }
        else if (this.state.addressType === '' || this.state.addressType === null || this.state.addressType === "Select Address Type") {
            return this.setState({ isError: true, isErrorAlert: false, message: 'Please select the address type it is required!' });
        }
        else if (this.state.country === '' || this.state.country === null || this.state.country === "Select Country") {
            return this.setState({ isError: true, isErrorAlert: false, message: 'Please select the country it is reuired!' });
        }
        this.setState({ isError: false, message: '', isLoading: true, disabled: "none", isErrorAlert: false });
        try {
            await addCustomerDetail({
                "address_line1": this.state.addressLine1,
                "address_line2": this.state.addressLine2,
                "city_town_suburb": this.state.city,
                "state_province": this.state.province,
                "country": this.state.country,
                "post_code": this.state.postCode,
                "address_type": this.state.addressType
            }

            )
            this.setState({ isLoading: false, disabled: "", showCustomerAddressFilledSuccess: true, formFieledDisable: true, submitButtonShow: false, passportDisable: false, driverLicenseDisable: false });
            toastMessageSuccess("Customer address detail added successfully.")
        }
        catch (err) {
            this.setState({ isLoading: false, disabled: "" });
            if (err?.response?.status === 403) {

                this.props.logOutUser(err?.response?.status)
            }
            else if (err.message === "Network Error" || err?.response?.status === 500) {
                this.setState({ isError: true, isErrorAlert: false, message: ErrorMessage.networkError })
            }
            else if (err?.response?.data) {
                if (err?.response?.status === 500) {

                    return this.setState({ isError: false, message: err?.response?.data?.errors[0]?.message, isErrorAlert: true });
                }
                this.setState({ isError: true, isErrorAlert: false, message: err?.response?.data?.errors[0]?.message })
            }
        }
    }

    getExchangeLedgeStatus = async () => {

        try {

            let dataObject = {
                email: this.state.email,
                phone: this.state.phone
            }

            let response = await checkLedger(dataObject)

            if (response?.data?.message === "user not exist") {
                this.setState({
                    exchangeRegistration: ``
                })

            }
            else {
                this.setState({
                    exchangeRegistration: ``
                })

            }
        }
        catch (e) {

            this.setState({
                exchangeRegistration: ``
            })

        }


    }

    handleChangeStatus = ({ ...meta }, status, file) => {
        // alert(status)
        if (status === 'rejected_file_type') {
            // alert("File type not accepted");
            toastMessageFailure("File type not accepted")
        }
    }

    // Upload passport
    uploadPassport = async (file) => {

        const fileValidationResponse = validateFile(file);

        if (!fileValidationResponse || fileValidationResponse.error) {

            this.setState({
                errorModalShow: true,
                alertTitlt: "Fail to upload image",
                alertMessage: "Something wen wrong.Please try again"
            })
            return { url: `` };
        }
        // check toggle value
        let toggleValue = 0
        try {
            toggleValue = await getToggleValue("toggle_kyc", this.state.country, this.props.auth.user.username)
            toggleValue = toggleValue.data.calculated_toggle
        }
        catch (e) {
            toggleValue = 0

        }


        const body = new FormData()
        body.append('file', file.file)
        body.append('email', this.props.auth.user.username)
        body.append('document_type', "passport")
        body.append('kyc_toggle_flag', toggleValue)
        //body.append('kyc_toggle_flag', 3)

        try {
            this.setState({ isLoadingDocument: true })
            let checkDocumentQualityResponse = await predictDocumentQuality(body)

            // image is not of good quality
            if (checkDocumentQualityResponse.data.valid === false) {
                this.setState({
                    isLoadingDocument: false,
                    errorModalShow: true,
                    alertTitlt: "Fail to upload image",
                    alertMessage: checkDocumentQualityResponse.data.msg ? checkDocumentQualityResponse.data.msg : "Image is not good quality",

                })
                return { url: "" }
            }
            // image is ok
            else if (checkDocumentQualityResponse.data.flag === true) {

                //  upload passport
                body.append('document_path', checkDocumentQualityResponse.data.document_path);
                body.append('passport_channel_source', config.KYC_CHANNEL_SOURCE);

                // upload passport
                try {
                    let response = await validatePassport(body)

                    // if upload fail
                    if (response.data.validation === false) {
                        this.setState({
                            isLoadingDocument: false,
                            errorModalShow: true,
                            alertTitlt: "Fail to upload passport",
                            alertMessage: response.data.msg ? response.data.msg : "We are facing some issues to validate passport"

                        })
                        return { url: "" }
                    }
                    //if upload successs
                    else if (response.data.validation === true) {
                        this.setState({
                            isLoadingDocument: false,
                            successModalShow: true,
                            alertTitlt: "Congratulations!",
                            alertMessage: "Your passport uploaded successfully",
                            passportUplaod: true,
                            photoIdDisable: false,
                            passportDisable: true,
                            driverLicenseDisable: true
                        })
                        // return { url: "" }
                    }
                    //something else case
                    else {
                        this.setState({
                            isLoadingDocument: false,
                            errorModalShow: true,
                            alertTitlt: "Fail to upload passport",
                            alertMessage: "We are facing some issues to validate passport"
                        })
                        return { url: "" }
                    }


                }
                //exception in passport upload
                catch (e) {
                    this.setState({
                        isLoadingDocument: false,
                        errorModalShow: true,
                        alertTitlt: "Fail to upload image",
                        alertMessage: e.msg ? e.msg : "We are facing some issues to validate passport"
                    })
                    return { url: "" }

                }

            }

            //something else response in predict picture

            else {
                this.setState({
                    isLoadingDocument: false,
                    errorModalShow: true,
                    alertTitlt: "Fail to upload image",
                    alertMessage: "Fail to upload an image. We are facing some issues to predict document quality"
                })
                return { url: "" }
            }


        }
        // exception in predict picture
        catch (e) {

            this.setState({
                isLoadingDocument: false,
                errorModalShow: true,
                alertTitlt: "Fail to upload image",
                alertMessage: e.msg ? e.msg : "We are facing some issues to validate passport"
            })
            return { url: "" }

        }

    }

    //Upload Drving License
    uploadDrvingLicence = async (file) => {
        // alert("drving")

        const fileValidationResponse = validateFile(file);


        if (!fileValidationResponse || fileValidationResponse.error) {
            this.setState({
                errorModalShow: true,
                alertTitlt: "Fail to upload image",
                alertMessage: "Something wen wrong.Please try again"
            })
            return { url: `` };
        }
        let toggleValue = 0
        try {
            toggleValue = await getToggleValue("toggle_kyc", this.state.country, this.props.auth.user.username)
            toggleValue = toggleValue.data.calculated_toggle
        }
        catch (e) {
            toggleValue = 0
        }

        const body = new FormData()
        body.append('file', file.file)
        body.append('email', this.props.auth.user.username)
        body.append('document_type', "driver_license")
        //body.append('kyc_toggle_flag', toggleValue)
        body.append('kyc_toggle_flag', toggleValue)
        try {
            this.setState({ isLoadingDocument: true })
            let checkDocumentQualityResponse = await predictDocumentQuality(body)

            // image is not of good quality
            if (checkDocumentQualityResponse.data.valid === false) {
                this.setState({
                    isLoadingDocument: false,
                    errorModalShow: true,
                    alertTitlt: "Fail to upload image",
                    alertMessage: checkDocumentQualityResponse.data.msg ? checkDocumentQualityResponse.data.msg : "Image is not good quality",

                })
                return { url: "" }
            }
            // image is ok
            else if (checkDocumentQualityResponse.data.flag === true) {

                //  upload passport
                body.append('document_path', checkDocumentQualityResponse.data.document_path);
                body.append('driver_lic_channel_source', config.KYC_CHANNEL_SOURCE);


                // upload passport
                try {
                    let response = await validateLicence(body)
                    // if upload fail
                    if (response.data.validation === false) {
                        this.setState({
                            isLoadingDocument: false,
                            errorModalShow: true,
                            alertTitlt: "Fail to upload licence",
                            alertMessage: response.data.msg ? response.data.msg : "We are facing some issues to validate driving license"

                        })
                        return { url: "" }
                    }
                    //if upload successs
                    else if (response.data.validation === true) {
                        this.setState({
                            isLoadingDocument: false,
                            successModalShow: true,
                            alertTitlt: "Congratulations!",
                            alertMessage: "Your driving license uploaded successfully",
                            drivingLicenseUpload: true,
                            photoIdDisable: false,
                            passportDisable: true,
                            driverLicenseDisable: true
                        })
                    }
                    //something else case
                    else {
                        this.setState({
                            isLoadingDocument: false,
                            errorModalShow: true,
                            alertTitlt: "Fail to upload licence",
                            alertMessage: "We are facing some issues to validate licence document"
                        })
                        return { url: "" }
                    }
                }
                //exception in passport upload
                catch (e) {
                    this.setState({
                        isLoadingDocument: false,
                        errorModalShow: true,
                        alertTitlt: "Fail to upload image",
                        alertMessage: e.msg ? e.msg : "We are facing some issues to validate driving license"
                    })
                    return { url: "" }

                }

            }

            //something else response in predict picture

            else {
                this.setState({
                    isLoadingDocument: false,
                    errorModalShow: true,
                    alertTitlt: "Fail to upload image",
                    alertMessage: "Fail to upload an image. We are facing some issues to predict document quality"
                })
                return { url: "" }
            }


        }
        // exception in predict picture
        catch (e) {

            this.setState({
                isLoadingDocument: false,
                errorModalShow: true,
                alertTitlt: "Fail to upload image",
                alertMessage: e.msg ? e.msg : "We are facing some issues to validate driving license"
            })
            return { url: "" }

        }

    }


    //Upload Drving License
    uploadPhotoID = async (file) => {
        // alert("hpotID")

        const fileValidationResponse = validateFile(file);


        if (!fileValidationResponse || fileValidationResponse.error) {
            this.setState({
                errorModalShow: true,
                alertTitlt: "Fail to upload image",
                alertMessage: "Something wen wrong.Please try again"
            })
            return { url: `` };
        }

        let toggleValue = 0
        try {
            toggleValue = await getToggleValue("toggle_kyc", this.state.country, this.props.auth.user.username)
            toggleValue = toggleValue.data.calculated_toggle
        }
        catch (e) {
            toggleValue = 0

        }

        const body = new FormData()
        body.append('file', file.file)
        body.append('email', this.props.auth.user.username)
        body.append('document_type', "photo_id")
        body.append('kyc_toggle_flag', toggleValue)
        // body.append('kyc_toggle_flag', 3)






        try {
            this.setState({ isLoadingDocument: true })
            let checkDocumentQualityResponse = await predictDocumentQuality(body)

            // image is not of good quality
            if (checkDocumentQualityResponse.data.valid === false) {
                this.setState({
                    isLoadingDocument: false,
                    errorModalShow: true,
                    alertTitlt: "Fail to upload photoID",
                    alertMessage: checkDocumentQualityResponse.data.msg ? checkDocumentQualityResponse.data.msg : "Image is not good quality",

                })
                return { url: "" }
            }
            // image is ok
            else if (checkDocumentQualityResponse.data.flag === true) {

                //  upload passport
                body.append('document_path', checkDocumentQualityResponse.data.document_path);
                body.append('photo_id_channel_source', config.KYC_CHANNEL_SOURCE);
                // upload passport
                try {
                    let response = await validatePhotoID(body)
                    // if upload fail
                    if (response.data.validation === false) {
                        this.setState({
                            isLoadingDocument: false,
                            errorModalShow: true,
                            alertTitlt: "Fail to upload photoID",
                            alertMessage: response.data.msg ? response.data.msg : "We are facing some issues to validate photoID"

                        })
                        return { url: "" }
                    }
                    //if upload successs
                    else if (response.data.validation === true) {
                        this.setState({
                            isLoadingDocument: false,
                            successModalShow: true,
                            alertTitlt: "Congratulations!",
                            alertMessage: "Your photo ID uploaded successfully",
                            photoIDUpload: true,
                            selfieDisable: false,
                            passportDisable: true,
                            driverLicenseDisable: true,
                            photoIdDisable: true
                        })
                    }
                    //something else case
                    else {
                        this.setState({
                            isLoadingDocument: false,
                            errorModalShow: true,
                            alertTitlt: "Fail to upload photID",
                            alertMessage: "We are facing some issues to validate photoID document"
                        })
                        return { url: "" }
                    }


                }
                //exception in passport upload
                catch (e) {
                    this.setState({
                        isLoadingDocument: false,
                        errorModalShow: true,
                        alertTitlt: "Fail to upload image",
                        alertMessage: e.msg ? e.msg : "We are facing some issues to validate photoID document"
                    })
                    return { url: "" }

                }

            }

            //something else response in predict picture

            else {
                this.setState({
                    isLoadingDocument: false,
                    errorModalShow: true,
                    alertTitlt: "Fail to upload image",
                    alertMessage: "Fail to upload an image. We are facing some issue to predict document quality"
                })
                return { url: "" }
            }


        }
        // exception in predict picture
        catch (e) {

            this.setState({
                isLoadingDocument: false,
                errorModalShow: true,
                alertTitlt: "Fail to upload image",
                alertMessage: e.msg ? e.msg : "We are facing some issues to validate photoID document"
            })
            return { url: "" }
        }

    }


    //Upload Drving License
    validateSelfie = async (file) => {
        // alert("slefie")

        const fileValidationResponse = validateFile(file);

        if (!fileValidationResponse || fileValidationResponse.error) {
            this.setState({
                errorModalShow: true,
                alertTitlt: "Fail to upload image",
                alertMessage: "Something wen wrong.Please try again"
            })
            return { url: `` };
        }

        let toggleValue = 0
        try {
            toggleValue = await getToggleValue("toggle_kyc", this.state.country, this.props.auth.user.username)
            toggleValue = toggleValue.data.calculated_toggle
        }
        catch (e) {
            toggleValue = 0
        }
        //toggleValue = 1
        const body = new FormData()
        body.append('file', file.file)
        body.append('email', this.props.auth.user.username)
        body.append('document_type', "selfie")
        body.append('kyc_toggle_flag', toggleValue)
        // body.append('kyc_toggle_flag', 3)
        //  upload passport
        body.append('selfie_doc_channel_source', config.KYC_CHANNEL_SOURCE);

        // upload passport
        try {
            this.setState({ isLoadingDocument: true })
            let response = await validateSelfie(body)
            // if upload fail
            if (response.data.valid === false) {
                this.setState({
                    isLoadingDocument: false,
                    errorModalShow: true,
                    alertTitlt: "Fail to upload selfie",
                    alertMessage: response.data.msg ? response.data.msg : "Fail to upload an image. We are facing some issue to predict document quality",

                })
                return { url: "" }
            }
            //if upload successs
            else if (response.data.valid === true) {
                try {
                    await this.getExchangeLedgeStatus()
                    await this.updateAccountStatus({ "username": this.props.auth.user.username })


                }
                catch (e) {

                }
                this.setState({
                    isLoadingDocument: false,
                    kycStatusDone: true,
                    greenModalShow: true,
                    alertTitlt: "Congratulations!",
                    alertMessage: `Thank you for completing your KYC verification, your account is now active.`,
                    photoIDUpload: true,
                    selfieDisable: true,
                    passportDisable: true,
                    driverLicenseDisable: true,
                    photoIdDisable: true,
                    customerFillFilled: "Your KYC is completed. Now you can proceed with further operation on our website like, you can create and access wallet."
                })
            }
            //something else case
            else {
                this.setState({
                    isLoadingDocument: false,
                    errorModalShow: true,
                    alertTitlt: "Fail to upload selfie",
                    alertMessage: "Fail to upload an image. We are facing some issues to upload selfie document"
                })
                return { url: "" }
            }


        }
        //exception in selfie upload
        catch (e) {

            this.setState({
                isLoadingDocument: false,
                errorModalShow: true,
                alertTitlt: "Fail to upload image",
                alertMessage: e.msg ? e.msg : "Fail to upload an image. We are facing some issue to upload selfie"
            })
            return { url: "" }
        }
    }

    render() {
        return (

            <>
                <div className="BlackStarsBack">
                    <div id="Blackstars" />
                    <div id="Blackstars2" />
                    <div id="Blackstars3" />
                </div>
                {this.state.isLoadingDocument === true ? <LoadingOverlay></LoadingOverlay> : null}

                <ErrorAlert showErroModal={this.state.errorModalShow} title={this.state.alertTitlt} message={this.state.alertMessage} onCloseErrorModal={() =>
                    this.setState({
                        errorModalShow: false,
                        alertTitlt: "",
                        alertMessage: ""
                    })} />

                <GreenInfo showGreenModal={this.state.greenModalShow} title={this.state.alertTitlt} message={this.state.alertMessage} exchangeMessage={this.state.exchangeRegistration} exchangeHeading="" onCloseGreenModal={() => {
                    let redirect = new URLSearchParams(this.props.location.search).get("redirect")
                    if (redirect !== null) {
                        window.location.href = redirect

                    }
                    else {
                        this.props.history.push("/")

                    }

                }
                } />

                <InfoSuccess showSuccessModal={this.state.successModalShow} title={this.state.alertTitlt} message={this.state.alertMessage} onCloseSuccessModal={() => {

                    this.setState({
                        successModalShow: false,
                        alertTitlt: "",
                        alertMessage: ""
                    })

                }
                } />

                <div className="row" id="zIndexMob">
                    <div className="col-md-10 offset-md-1">
                        <div className="AccountAddress text-center">
                            <div className="Box SetAccount kycverification">
                                {/* <h4>KYC Verification {this.state.kycStatusDone === true ? <spam style={{ color: 'green' }}>Completed</spam> : <p style={{ color: 'red' }}>UnComplete</p>}</h4> */}
                                {
                                    this.state.kycStatusDone === true ? <h4>KYC Verification<span style={{ color: 'green' }}> (Completed)</span></h4> : <h4>KYC Verification<span style={{ color: '#808080' }}> (Incompleted)</span></h4>
                                }



                                <div className="row">
                                    <div className="col-sm-12 col-md-6 col-lg-4">
                                        <div className="form-group mb-4">
                                            <input disabled={true} name="fname" className="form-control mb-2" value={this.state.firstName} placeholder="First Name" type="text" />
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-6 col-lg-4">
                                        <div className="form-group mb-4">
                                            <input disabled={true} name="mname" className="form-control mb-2" value={this.state.middleName} placeholder="Middle Name" type="text" />
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-6 col-lg-4">
                                        <div className="form-group mb-4">
                                            <input disabled={true} name="lname" className="form-control mb-2" value={this.state.lastName} placeholder="Last Name" type="text" />
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-6 col-lg-4">
                                        <div className="form-group mb-4">
                                            <input disabled={true} name="email" className="form-control mb-2" value={this.state.email} placeholder="Email" type="email" />
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-6 col-lg-4">
                                        <div className="form-group mb-4">
                                            <input disabled={true} name="phone" className="form-control mb-2" value={this.state.phone} placeholder="Phone Number" type="text" />
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-6 col-lg-4">
                                        <div className="form-group mb-4">
                                            <input disabled={this.state.formFieledDisable} onChange={this.onChange} name="addressLine1" value={this.state.addressLine1} className="form-control mb-2" placeholder="Address Line 1" type="text" />
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-6 col-lg-4">
                                        <div className="form-group mb-4">
                                            <input disabled={this.state.formFieledDisable} onChange={this.onChange} name="addressLine2" value={this.state.addressLine2} className="form-control mb-2" placeholder="Address Line 2" type="text" />
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-6 col-lg-4">
                                        <div className="form-group mb-4">
                                            <input disabled={this.state.formFieledDisable} onChange={this.onChange} name="city" value={this.state.city} className="form-control mb-2" placeholder="City / Town / Suburb" type="text" />
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-6 col-lg-4">
                                        <div className="form-group mb-4">
                                            <input disabled={this.state.formFieledDisable} onChange={this.onChange} name="province" value={this.state.province} className="form-control mb-2" placeholder="State / Province" type="text" />
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-6 col-lg-4">
                                        <div className="form-group mb-4">
                                            <select disabled={this.state.formFieledDisable} onChange={this.onChange} name="addressType" className="form-control">
                                                <option>{this.state.addressType}</option>
                                                <option value="Resident">Resident</option>
                                                <option value="Work">Work</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-6 col-lg-4">
                                        <div className="form-group mb-4">
                                            <input disabled={this.state.formFieledDisable} onChange={this.onChange} name="postCode" value={this.state.postCode} className="form-control mb-2" placeholder="Postcode" type="text" />
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-6 col-lg-4">
                                        <div className="form-group mb-4">
                                            <select disabled={this.state.formFieledDisable} onChange={this.onChange} name="country" className="form-control">
                                                <option>{this.state.country}</option>
                                                {countryConfig.recdata?.map((country, idx) =>
                                                    <React.Fragment key={idx}>
                                                        <option key={country.name} value={country.name}>{country.name}</option>
                                                    </React.Fragment>
                                                )}
                                            </select>
                                        </div>
                                    </div>




                                    {
                                        this.state.isError ? <WarningMessageAlert style={{ display: "flex" }} message={this.state.message}></WarningMessageAlert> : null
                                    }
                                    {
                                        this.state.isErrorAlert ? <ErrorMessageAlert message={this.state.message}></ErrorMessageAlert> : null
                                    }
                                    {
                                        this.state.submitButtonShow === true ? <div className="col-12 text-right"><button style={{ pointerEvents: this.state.disabled }} className="btn btn-primary Submit m-0" onClick={this.onSubmit}>
                                            {this.state.isLoading === true ? <img alt="loading..." style={{ height: '20px', width: '20px', marginRight: '8px' }} src="/assets/images/spinner3.gif"></img> : null}
                                            Submit

                                        </button></div> : null
                                    }


                                </div>
                            </div>
                        </div>
                    </div>
                </div>



                <div className="row">
                    <div className="col-md-10 offset-md-1">
                        {
                            this.state.showCustomerAddressFilledSuccess === true ? <h4 className="mb-3 ColorToggle">{this.state.customerFillFilled}</h4> : null
                        }
                        {this.state.showCustomerAddressFilledSuccess === true ?
                            <>
                                <div className="row">
                                    <div className="col-sm-12 col-md-5 col-lg-5">

                                        {
                                            this.state.passportUplaod === true ?
                                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                    <p style={{ color: 'green', fontWeight: 'bold' }}>Your passport successfully validated</p>
                                                    <img style={{ width: '20px', height: '20px', marginLeft: '4px', fontWeight: 'bold' }} src="/assets/images/success.png" alt="" />
                                                </div> : this.state.drivingLicenseUpload === true ? <p style={{ color: 'green', fontWeight: 'bold' }}>Don't need to verify your passport</p> : <p style={{ color: '#808080', fontWeight: 'bold' }}>Passport Image</p>
                                        }
                                        {
                                            this.state.showPassportDropBox === false ? <img
                                                className='kyc-img'
                                                width='100%'
                                                height='250px'
                                                src={this.state.passportImagePathDoc ? URL.createObjectURL(this.state.passportImagePathDoc) : '/assets/images/notFound.jpeg'}
                                                alt='passport-doc'
                                            /> : <Dropzone
                                                styles={{ dropzone: { minHeight: 250, maxHeight: 250 } }}
                                                onChangeStatus={this.handleChangeStatus}
                                                getUploadParams={this.uploadPassport}
                                                disabled={this.state.passportDisable}
                                                accept="image/*"
                                            ></Dropzone>

                                        }





                                    </div>
                                    <div className="col-sm-12 col-md-2 col-lg-2">
                                        <div className="V-Line"></div>
                                    </div>
                                    <div className="col-sm-12 col-md-5 col-lg-5">
                                        {
                                            this.state.drivingLicenseUpload === true ?
                                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                    <p style={{ color: 'green', fontWeight: 'bold' }}>Your driving license successfully validated</p>
                                                    <img style={{ width: '20px', height: '20px', marginLeft: '4px', fontWeight: 'bold' }} src="/assets/images/success.png" alt="" />
                                                </div> : this.state.passportUplaod === true ? <p style={{ color: 'green', fontWeight: 'bold' }}>Don't need to verify your driving license</p> : <p style={{ color: '#808080', fontWeight: 'bold' }}>Driving License</p>
                                        }

                                        {
                                            this.state.showLicenceDropBox === false ? <img
                                                className='kyc-img'
                                                width='100%'
                                                height='250px'
                                                src={this.state.licenseImagePathDoc ? URL.createObjectURL(this.state.licenseImagePathDoc) : '/assets/images/notFound.jpeg'}
                                                alt='passport-doc'
                                            /> :
                                                <Dropzone
                                                    styles={{ dropzone: { minHeight: 250, maxHeight: 250 } }}
                                                    accept="image/*"
                                                    onChangeStatus={this.handleChangeStatus}
                                                    getUploadParams={this.uploadDrvingLicence}
                                                    disabled={this.state.driverLicenseDisable}

                                                ></Dropzone>
                                        }
                                    </div>
                                </div>




                                <div className="row" style={{ marginTop: '30px' }}>
                                    <div className="col-sm-12 col-md-5 col-lg-5">

                                        {
                                            this.state.photoIDUpload === true ?
                                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                    <p style={{ color: 'green', fontWeight: 'bold' }}>Your photoID successfully validated</p>
                                                    <img style={{ width: '20px', height: '20px', marginLeft: '4px', fontWeight: 'bold' }} src="/assets/images/success.png" alt="" />
                                                </div> : <p style={{ color: '#808080', fontWeight: 'bold' }}>PhotoID Image</p>
                                        }


                                        {
                                            this.state.showPhotoIDDropBox === false ? <img
                                                className='kyc-img'
                                                width='100%'
                                                height='250px'
                                                src={this.state.photoIDImagePathDoc ? URL.createObjectURL(this.state.photoIDImagePathDoc) : '/assets/images/notFound.jpeg'}
                                                alt='PhotID-doc'
                                            /> :
                                                <Dropzone
                                                    styles={{ dropzone: { minHeight: 250, maxHeight: 250 } }}
                                                    onChangeStatus={this.handleChangeStatus}
                                                    getUploadParams={this.uploadPhotoID}
                                                    disabled={this.state.photoIdDisable}
                                                    accept="image/*"
                                                ></Dropzone>
                                        }

                                    </div>
                                    <div className="col-sm-12 col-md-2 col-lg-2">
                                        <div className="V-Line-And"></div>
                                    </div>
                                    <div className="col-sm-12 col-md-5 col-lg-5">

                                        {
                                            this.state.slefieUplaod === true ?
                                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                    <p style={{ color: 'green', fontWeight: 'bold' }}>Your selfie successfully validated</p>
                                                    <img style={{ width: '20px', height: '20px', marginLeft: '4px', fontWeight: 'bold' }} src="/assets/images/success.png" alt="" />
                                                </div> : <p style={{ color: '#808080', fontWeight: 'bold' }}>Selfie Image</p>
                                        }

                                        {
                                            this.state.showSelfieDropBox === false ? <img
                                                className='kyc-img'
                                                width='100%'
                                                height='250px'
                                                src={this.state.selfieImagePathDoc ? URL.createObjectURL(this.state.selfieImagePathDoc) : '/assets/images/notFound.jpeg'}
                                                alt='slefie-doc'
                                            /> :

                                                <Dropzone
                                                    styles={{ dropzone: { minHeight: 250, maxHeight: 250 } }}
                                                    accept="image/*"

                                                    getUploadParams={this.validateSelfie}
                                                    disabled={this.state.selfieDisable}
                                                    onChangeStatus={this.handleChangeStatus}
                                                ></Dropzone>
                                        }
                                    </div>
                                </div>
                            </>
                            : null}

                        {/* End Next Block */}
                    </div>
                </div>
                <Footer />

            </>


        )
    }
}



const mapStateToProps = (state) => ({

    auth: state.authReducer

})
export default connect(mapStateToProps, { logOutUser, setUpUser })(Kyc)


