import React, { Component } from "react";

import { storeWallet } from '../../../api/wallet/wallet';
import ErrorAlert from "../../Modals/ErrorAlert";
import InfoSuccess from "../../Modals/InfoSuccess";
import { showRandomMnemonicsScreen, showCopyMnemoncisScreen } from "../../../actions/wallet";
import { addNewNotification } from "../../../actions/notification"
import { createNotification } from "../../../api/general/general"
import { logOutUser } from "../../../actions/auth";
import config from "../../../_config"
import { connect } from "react-redux";
import Header from "../../Layout/Header";
import Footer from "../../Layout/Footer";
import $ from "jquery"

const CryptoJS = require("crypto-js");

let userEnterMnemonics = [];
class MnemonicsTest extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            disable: "",
            errorModalShow: false,
            successModalShow: false,
            walletMnemonics: "",
            shuffleWalletMnemonics: [],
        }
    }

    SelectShuffleMnemoncis = (e) => {


        var selectedWord = document.getElementById("selectWord" + e).innerText;

        if ($("#selectWord" + e).hasClass('un-select-list-item') === false) {
            let listItemHtml = '<li   id="amanBox' + selectedWord + '">' + selectedWord + '</li>'
            $('#appendText').append(listItemHtml);
            $("#selectWord" + e).addClass('un-select-list-item')
            userEnterMnemonics.push(selectedWord)

        }
        else {
            $('#amanBox' + selectedWord).remove();
            $("#selectWord" + e).removeClass('un-select-list-item')

            userEnterMnemonics = userEnterMnemonics.filter(e =>
                e !== selectedWord

            )
        }


    }

    resetMnemonicText = (e) => {
        userEnterMnemonics = []
        var listItems = $("#shuffleList li");

        listItems.each(function (idx, li) {
            if ($("#selectWord" + idx).hasClass('un-select-list-item') === true) {
                $("#selectWord" + idx).removeClass('un-select-list-item')
            }
        });
        $('#appendText').empty()

    }

    componentDidMount() {

        if (this.props.isShowRandomMnemonicsScreen === false) {
            this.props.history.push("/create-wallet")
            return
        }
        this.setState({
            shuffleWalletMnemonics: this.props.location.state.shuffleMnemonics
        })
    }
    createWallet = async () => {
        let userEnterMnemonicsToString = userEnterMnemonics.toString()
        let userMnemoncis = userEnterMnemonicsToString.split(',').join(' ')
        let actualMnemoncis = this?.props?.location?.state?.originalMnemonics

        //console.log("mnemonics last test", "actual mnemoncis", this?.props?.location?.state?.originalMnemonics, "usermnameonics", userMnemoncis)
        if (userMnemoncis !== actualMnemoncis) {
            this.setState({ alertTitlt: "Mnemonic Mismatch", disable: "", isLoading: false, errorModalShow: true, alertMessage: "Your mnemonics not matched with the provided mnemonics" })
        }

        else {

            this.setState({ isLoading: true, disable: "none" });
            try {
                await storeWallet({
                    "accountName": this?.props?.location?.state?.accountName,
                    "walletPassword": this?.props?.location?.state?.walletPassword,
                    "walletAddress": this?.props?.location?.state?.walletAddress,
                    "walletPublickey": this.props.location.state.walletPublicKey,
                    "walletType": this?.props?.location?.state?.walletType
                });

                // encrypt mnemonics with password
                let mnemonnicsToString = this?.props?.location?.state?.originalMnemonics.trim().toString()
                let encryptedData = CryptoJS.AES.encrypt(mnemonnicsToString, this?.props?.location?.state?.walletPassword).toString();
                localStorage.setItem(this?.props?.location?.state?.walletAddress, encryptedData)

                userEnterMnemonics = []
                // let notifyObject = {
                //     "platform_subscriber_id": config.platform_subscriber_id,
                //     "sender": config.sender,
                //     "receiver": [this.props.auth.user.username],
                //     "message": `You created a wallet with the name:${this?.props?.location?.state?.accountName} and your address is ${this?.props?.location?.state?.walletAddress}`,
                //     "category": "success",
                //     "title": config.NotificationTitle[0],
                //     "action": "",
                //     "task": "",
                //     "is_read": false,
                //     "ttl": null,
                //     create_time: Date.now()
                // }
                // console.log("notifyObject", notifyObject)

                //this.props.addNewNotification(notifyObject)
                //createNotification(notifyObject)




                this.setState({
                    isLoading: false, disable: "",
                    alertTitlt: "Congratulations! Wallet Created",
                    successModalShow: true,
                    alertMessage: `Your wallet with the name ${this.props.location.state.accountName} is created successfully`,
                })


            }
            catch (err) {

                if (err?.response?.status === 403) {
                    this.props.logOutUser(err?.response?.status)
                }
                else if (err.message === "Network Error") {
                    this.setState({ alertTitlt: "", disable: "", isLoading: false, errorModalShow: true, alertMessage: "Sorry! We are facing some network issue.Please try again later." })
                }

                if (err?.response?.data) {
                    this.setState({ alertTitlt: "", disable: "", isLoading: false, errorModalShow: true, alertMessage: err?.response?.data?.errors[0]?.message })
                }


            }
        }
    }

    render() {

        return (

            <>

                <div className="BlackStarsBack">
                    <div id="Blackstars" />
                    <div id="Blackstars2" />
                    <div id="Blackstars3" />
                </div>
                <InfoSuccess showSuccessModal={this.state.successModalShow} title={this.state.alertTitlt} message={this.state.alertMessage} onCloseSuccessModal={() => {
                    this.props.showRandomMnemonicsScreen(false)
                    this.props.showCopyMnemoncisScreen(false)
                    this.setState({
                        successModalShow: false
                    }, () => {
                        this.props.history.push("/wallet-listing")
                    })


                }} />

                <ErrorAlert showErroModal={this.state.errorModalShow} title={this.state.alertTitlt} message={this.state.alertMessage} onCloseErrorModal={() =>
                    this.setState({
                        errorModalShow: false
                    })} />
                <div className="row" id="zIndexMob">
                    <div className="col-md-6 offset-md-3">
                        <div className="AccountAddress text-center">
                            <div className="MneMonics">
                                <h4 className="text-center">You must now confirm your mnemonic phrase. Select the words below in the correct order to finalize your account creation.</h4>
                                <div className="MneMonicsCaptcha mb-4">

                                    {/* <div className="row" id="appendText" style={{ marginLeft: '17px', marginTop: '15px' }}>
                                        </div> */}
                                    <ul id="appendText" className="mb-2">
                                        {/* <li>Jump</li>
                                            <li>Color</li>
                                            <li>Jump</li>
                                            <li>Color</li>
                                            <li>Jump</li>
                                            <li>Color</li>
                                            <li>Jump</li>
                                            <li>Color</li>
                                            <li>Jump</li>
                                            <li>Color</li>
                                            <li>Jump</li>
                                            <li>Color</li> */}
                                    </ul>
                                </div>

                                {/* <div className="row">
                                        {this.state.shuffleWalletMnemonics.map(item => {
                                            return (
                                                <div className="col-md-2 outset" id={"bottomText" + item}>
                                                    <div className="box-shadow p-0 outset">
                                                        <p className="text-center p-1 MainbottomText" onClick={this.SelectShuffleMnemoncis} style={{ width: '100%', color: '#fff', marginTop: '15px', cursor: 'pointer', border: '3px', borderStyle: 'inset', background: '#474a60' }} >{item}</p>

                                                    </div>
                                                </div>

                                            )
                                        })}
                                    </div> */}


                                <ul id="shuffleList" className="list-inline mb-4">
                                    {this.state.shuffleWalletMnemonics.map((item, index) => (
                                        <li className="select-list-item" id={"selectWord" + index} onClick={() => { this.SelectShuffleMnemoncis(index) }}><span><i className="fa fa-plus" /></span>{item}</li>

                                        // <p onClick={this.SelectShuffleMnemoncis}  >{item}</p>
                                    ))}




                                </ul>
                                <button style={{ pointerEvents: this.state.disable }} onClick={this.createWallet} type="button" className="btn btn-primary Submit">
                                    {this.state.isLoading === true ? <img alt="loading..." style={{ height: '20px', width: '20px', marginRight: '8px' }} src="/assets/images/spinner3.gif"></img> : null}
                                    Continue

                                </button>


                                <button style={{ pointerEvents: this.state.disable }} onClick={this.resetMnemonicText} type="button" className="btn btn-secondary Cancel" data-dismiss="modal">Reset</button>




                            </div>
                        </div>
                    </div>
                </div>

                <Footer />
            </>
        )
    }

}

const mapStateToProps = (state) => ({
    auth: state.authReducer,
    isShowRandomMnemonicsScreen: state.walletReducer.isShowRandomMnemonicsScreen,
})
export default connect(mapStateToProps, { showRandomMnemonicsScreen, showCopyMnemoncisScreen, logOutUser, addNewNotification })(MnemonicsTest)
