// import { Modal } from "bootstrap";
import React from 'react';
import Modal from 'react-bootstrap/Modal'
import timeAgo from 'time-ago';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import downloadAsJson from '../../../../utils/downloadAsJson';
import LoadingOverlay from "../../../../Loader";
import moment from 'moment';

const timeAgoFormator = (timeStamp)=>{

    let formatedTime = moment(timeStamp.includes("GMT") || timeStamp.includes("Z")? timeStamp : timeStamp+" GMT");

    if(timeAgo.ago(formatedTime) == 'NaN year ago') {
       formatedTime = moment(timeStamp.includes("GMT") || timeStamp.includes("Z")? timeStamp : timeStamp+" Z");
    }



    return timeAgo.ago(formatedTime);
}
class ViewHistory extends React.Component {


    onNextBtnClick = () => {
        this.props.onNextBtnClick(this.props.account, this.props.currentPage + 1)


    }

    onPreviousBtnClick = () => {
        this.props.onPreviousBtnClick(this.props.account, this.props.currentPage - 1)
    }


    render() {
        //alert(this.props.pageNumber)
       
        return (
            <Modal dialogClassName="SetPass"
                show={this.props.showHistoryModal}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                {this.props.isLoading === true ? <LoadingOverlay></LoadingOverlay> : null}

                <div style={{ margin: '16px' }}>
                    <button onClick={

                        this.props.onCloseMintModal
                    } style={{ color: 'white' }} type="button" className="close" id="#transactionHistoryModal" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">x</span>
                    </button>
                </div>


                <Modal.Body>
                    <h4 class="text-center mb-5">Transaction History</h4>

                    <div className="Responsive">
                        <table className="table PanelBody text-center">
                            <thead>
                                <tr>
                                    <th><p>Tx Hash</p></th>
                                    <th><p>Account</p></th>
                                    <th><p>In / Out</p></th>
                                    <th><p>Method</p></th>
                                    <th><p>Balance</p></th>
                                    <th><p>Time</p></th>
                                    {/* <th><p>Status</p></th> */}
                                </tr>
                            </thead>
                            <tbody>

                                {
                                    this.props.transactionHistory.length > 0 ? this.props.transactionHistory.map(({ _id, hash, method, to_address, signer, timestamp, status }, index) => (
                                        <tr key={_id}>
                                            <td className="Visit">
                                                <a rel="noopener noreferrer" target="_blank" href={`https://explorer.nucoin.com.au/extrinsic/${hash}`}>{hash.substring(0, 9)}</a>
                                            </td>
                                            <td className="Visit"><a rel="noopener noreferrer" className="text-center" href={`https://explorer.nucoin.com.au/account/${this.props.account === to_address ? signer : to_address}`} target="_blank">{this.props.account === to_address ? signer.substring(0, 9) : to_address.substring(0, 9)}</a></td>
                                            <td className="Visit">{this.props.account === signer ?
                                                <ArrowDownwardIcon style={{ color: 'red', fontSize: '20px' }}></ArrowDownwardIcon>

                                                : <ArrowUpward style={{ color: 'green', fontSize: '20px' }} ></ArrowUpward>
                                            }</td>
                                            <td className="Visit">
                                                <p>{method}</p>
                                            </td>
                                            <td className="Visit">
                                                <p>{JSON.parse(this.props.transactionHistory[index].data)[0].value || 'N/A'}</p>
                                            </td>
                                            <td className="Visit">
                                                <p>{timeAgoFormator(timestamp) || 'N/A'}</p>
                                            </td>
                                            {/* <td className="Visit">{status === 'Transfer' ? <i title="Success" className="fas fa-check-circle text-success Fsize_20 pr-1 align-center" aria-hidden="true"></i> : <i title="Errored" className="fas fa-times-circle text-danger Fsize_20 pr-1 align-center" aria-hidden="true"></i>}</td> */}
                                        </tr>
                                    )) : <div><p>No Record Found</p></div>
                                }

                            </tbody>
                        </table>
                    </div>
                    <div className='d-small-block'>
                        <div className='pull-left float-xs-none'>
                            <button disabled={this.props.currentPage === 0} type="button" className="btn btn-primary Submit mb-2" onClick={this.onPreviousBtnClick} >Previous</button>
                            <button disabled={this.props.totalPage !== 0 ? this.props.totalPage - this.props.currentPage === 1 : true} type="button" class="btn btn-secondary Cancel mb-2" onClick={this.onNextBtnClick}>Next</button>

                            {/* <button disabled={page === 0} style={{ marginBottom: '20px' }} onClick={() => changePage(page - 1)} className="btn btn-primary nextBtn next">Previous</button>
                        <button disabled={page === (meta.pages - 1)} style={{ marginBottom: '20px', marginLeft: '20px' }} onClick={() => changePage(page + 1)} className="btn btn-primary nextBtn next">Next</button> */}


                        </div>
                        <div className='pull-right float-xs-none'>
                            <button disabled={this.props.transactionHistory.length === 0} type="button" className="btn btn-primary Submit mb-2" onClick={() => {

                                downloadAsJson(
                                    this.props.transactionHistory
                                        .map(({ is_signed, signer, nonce, to_address, hash, section, method, data, timestamp, status }) => ({ is_signed, signer, nonce, to_address, hash, section, method, data, timestamp, status })), `transactions-${new Date().toLocaleTimeString().replaceAll(' ', '_')}`);
                            }

                            }>Download Data</button>
                        </div>
                    </div>
                </Modal.Body >
            </Modal >
        );

    }

}


export default ViewHistory;
