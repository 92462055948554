import React, { Component, useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import CampaignIcon from '@material-ui/icons/RecordVoiceOver';
import { connect } from 'react-redux';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import { Link } from '@material-ui/core';
import { Link as LinkTo } from 'react-router-dom';
import timeAgo from 'time-ago';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import NotificationImportantIcon from '@material-ui/icons/NotificationImportant';

// function NotificationDrop(isShowing) {
//     if (isShowing) {
//         document.getElementById('zIndexMob').classList.add("position-relative")
//     }
//     else if (isShowing == false) {
//         return(document.getElementById('zIndexMob').classList.add("position-relative"));
//     } else {
//         document.getElementById('zIndexMob').classList.remove("position-relative")
//     }
// }

const NotificationHeaderDropdown = (props) => {

    const [notificationId, setNotificationId] = useState(0);

    const timeBetweenDates = (ttl) => {
        let moment = require('moment');

        let localDate = new Date();
        let currentTime = moment.utc(localDate).format()
        let diff = moment.duration(moment(ttl).diff(moment(currentTime)));
        let a;

        if (diff.get("months") > 0) {
            a = diff.get("months") === 1 ? `Disappearing in  ${diff.get("months")} month` : `Disappearing in  ${diff.get("months")} months`
        } else if (diff.get("days") > 0) {
            a = diff.get("days") === 1 ? `Disappearing in  ${diff.get("days")} day` : `Disappearing in  ${diff.get("days")} days`
        } else if (diff.get("hours") > 0) {
            a = diff.get("hours") === 1 ? `Disappearing in  ${diff.get("hours")} hour` : `Disappearing in  ${diff.get("hours")} hours`
        } else if (diff.get("minutes") > 0) {
            a = diff.get("minutes") === 1 ? `Disappearing in  ${diff.get("minutes")} minute` : `Disappearing in  ${diff.get("minutes")} minutes`
        } else if (diff.get("seconds") > 0) {
            a = diff.get("seconds") === 1 ? `Disappearing in  ${diff.get("seocnds")} second` : `Disappearing in  ${diff.get("seconds")} seconds`
        } else if (diff.get("seconds") <= 0 || diff.get("months") <= 0 || diff.get("days") <= 0 || diff.get("hours") <= 0 || diff.get("minutes") <= 0) {
            a = "This notification has been expired will disappear once you refresh the page."
        }
        return a
    }

    useEffect(() => {
        if (notificationId !== 0) document.getElementById('redirect-to-detail').click();
    }, [notificationId]);


    return (
        <div className="Notifi_Drop">
            {/* <Dropdown id="Notification" size="lg" className="User" onToggle={NotificationDrop}> */}
            <Dropdown id="Notification" size="lg" className="User">
                <Dropdown.Toggle id="dropdown-basic">
                    <span className="material-icons user-icon">
                        <NotificationsIcon /><span style={{ color: 'red' }}>{props.notifications.unread.unread_count || ''}</span>
                    </span>
                </Dropdown.Toggle>
                <LinkTo id="redirect-to-detail" style={{ display: 'none' }} to={`/notification/detail/${notificationId}`}></LinkTo>
                <Dropdown.Menu>

                    <div class="p-3 Notifi_Head">
                        <div class="row align-items-center">
                            <div class="col">
                                <h6 class="m-0" key="t-notifications"> Notifications </h6>
                            </div>
                            <div class="col-auto">
                                <LinkTo to="/notifications" className="small">View All</LinkTo>

                            </div>
                        </div>
                    </div>

                    <Tabs className="TabsList">
                        <TabList className="px-3 border-0">
                            <Tab><label style={{ marginBottom: '0px', cursor: 'pointer' }} title="Announcements"><CampaignIcon ></CampaignIcon></label></Tab>
                            <Tab><label style={{ marginBottom: '0px', cursor: 'pointer' }} title="All Notifications"><NotificationsActiveIcon></NotificationsActiveIcon></label></Tab>
                            <Tab><label style={{ marginBottom: '0px', cursor: 'pointer' }} title="Unread Notifications"><NotificationImportantIcon></NotificationImportantIcon></label></Tab>
                            {/* <Tab><ArchiveIcon></ArchiveIcon></Tab> */}
                        </TabList>
                        <TabPanel>
                            {
                                props.announcements.data.length === 0 ? <div style={{ textAlign: 'center' }}>
                                    <p>Currently, there are no announcements</p>
                                </div>
                                    :
                                    <div className="DropDownScroll">
                                        {
                                            props.announcements.data.map((item) => (
                                                // <Dropdown.Item style={{ background: 'transparent linear-gradient(243deg, #e44f89 0%, #aa2fdc 100%) 0% 0% no-repeat padding-box' }} onClick={() => setNotificationId(item.id)} className="text-reset notification-item">

                                                <Dropdown.Item style={{ background: item.userpreferences.is_read ? '#fff' : '#e4e4e4' }} onClick={() => setNotificationId(item.id)} className="text-reset notification-item">
                                                    <div className="row py-2 align-items-center">
                                                        <div class="avatar-xs col-2">
                                                            <span class="avatar-title bg-primary rounded-circle">
                                                                <NotificationsIcon className="font-16" />
                                                            </span>
                                                        </div> <div class="col-10">
                                                            <h6 class="mb-0 font-16">{item.title}</h6>
                                                            <div class="font-12 text-muted" style={{height: "80px"}}>
                                                                {ReactHtmlParser(item.message)}
                                                                {/* <label key="t-min-ago" className='white-color'>{timeAgo.ago(item.create_time) || 'N/A'}</label> */}
                                                            </div>
                                                            <div class="font-12 text-muted">
                                                                <label key="t-min-ago" className='white-color'>{timeAgo.ago(item.create_time) || 'N/A'}</label>
                                                            </div>
                                                            {/* <p style={{ color: 'red' }}>{timeBetweenDates(item.ttl)}</p> */}
                                                        </div>
                                                    </div>
                                                </Dropdown.Item>
                                            ))
                                        }
                                    </div>
                            }
                        </TabPanel>

                        <TabPanel>
                            {
                                props.notifications.all.data.length === 0 ? <div style={{ textAlign: 'center' }}>
                                    <p>Currently, there is no notification</p>
                                </div>
                                    :
                                    <div className="DropDownScroll">
                                        {
                                            props.notifications.all.data.map((item) => (
                                                <Dropdown.Item style={{ background: item.userpreferences.is_read ? '#fff' : '#e4e4e4' }} onClick={() => setNotificationId(item.id)} className="text-reset notification-item">
                                                    <div className="row py-2 align-items-center">
                                                        <div class="avatar-xs col-2">
                                                            <span class="avatar-title bg-primary rounded-circle">
                                                                <NotificationsIcon className="font-16" />
                                                            </span>
                                                        </div>
                                                        <div class="col-10">
                                                            <h6 class="mb-0 font-16">{item.title}</h6>

                                                            <div class="font-12 text-muted">
                                                                {ReactHtmlParser(item.message)}
                                                                <label key="t-min-ago">{timeAgo.ago(item.create_time) || 'N/A'}</label>
                                                            </div>
                                                            {
                                                                // item.ttl ? <p style={{ color: "red" }}>{timeBetweenDates(item.ttl)}</p> : null
                                                            }
                                                        </div>
                                                    </div>
                                                </Dropdown.Item>
                                            ))
                                        }
                                    </div>
                            }
                        </TabPanel>

                        <TabPanel>
                            {
                                props.notifications.unread.data.length === 0 ? <div style={{ textAlign: 'center' }}>
                                    <p>Currently, there is no notification</p>
                                </div>
                                    :
                                    <div className="DropDownScroll">
                                        {
                                            props.notifications.unread.data.map((item) => (
                                                <Dropdown.Item style={{ background: '#e4e4e4' }} onClick={() => setNotificationId(item.id)} className="text-reset notification-item">

                                                    <div className="row py-2 align-items-center">
                                                        <div class="avatar-xs col-2">
                                                            <span class="avatar-title bg-primary rounded-circle">
                                                                <NotificationsIcon className="font-16" />
                                                            </span>
                                                        </div> <div class="col-10">
                                                            <h6 class="mb-0 font-16">{item.title}</h6>
                                                            <div class="font-12 text-muted">
                                                                {ReactHtmlParser(item.message)}
                                                                <label key="t-min-ago">{timeAgo.ago(item.create_time) || 'N/A'}</label>
                                                            </div>
                                                            {
                                                                // item.ttl ? <p style={{ color: "red" }}>{timeBetweenDates(item.ttl)}</p> : null
                                                            }
                                                        </div>
                                                    </div>
                                                </Dropdown.Item>
                                            ))
                                        }
                                    </div>
                            }
                        </TabPanel>
                    </Tabs>
                </Dropdown.Menu>
            </Dropdown>
        </div>
    )
}

const mapStateToProps = state => ({
    notifications: state.Notification.notifications,
    announcements: state.Notification.announcements
})

export default connect(mapStateToProps, {})(NotificationHeaderDropdown);