import { Component } from "react";
import Footer from "../Layout/Footer";
import "../Modals/WebCam/style.css"
import { WebcamCapture } from "../Modals/WebCam/Webcam"
import { toastMessageFailure } from "../../utils/toastMessage";
import ChooseWalletModal from "../Modals/WebCam/ChooseWalletModal"
import ConfirmationModal from "../Modals/WebCam/ConfirmationModal"
import InfoSuccess from "../Modals/InfoSuccess";
import WalletPinModal from "../Modals/WebCam/WalletPinModal"
import { connect } from "react-redux";

class ShopRegister extends Component {

    state = {
        imagePath: "",
        chooseWalletModalShow: false,
        chooseWallet: "",
        chooseWalletMnemonics: "",
        confirmationModalShow: false,
        successModalShow:false,
        alertTitlt:"",
        alertMessage:""
    }

    componentDidMount(){
        if (this?.props?.isShowShopRegisterScreen === false) {
            this.props.history.push("/wallet-listing")
            return
        }
    }

    onProceedModal = () => {
        if (!this.state.imagePath) {
            toastMessageFailure("Please provide a selfie image to proceed further.")

        }
        else {
            this.setState({ chooseWalletModalShow: true })
            // this.props.onProceedWebcamModal(this.state.imagePath)
            //this.resetState()
        }
    }

    render() {
        return (
            <>
                <div className="BlackStarsBack">
                    <div id="Blackstars" />
                    <div id="Blackstars2" />
                    <div id="Blackstars3" />
                </div>

                <div className="row" id="zIndexMob">
                    <div className="col-md-8 offset-md-2">
                        <div className="AccountAddress text-center">
                            <h4>Capture Image to Proceed your Account</h4>
                            <div className="Box SetPass">


                                <WebcamCapture onImageCapture={(path) => {
                                    this.setState({ imagePath: path })
                                }} />

                                <div style={{ display: "flex", justifyContent: "center" }}>
                                    <button onClick={() => { this.props.history.push("/wallet-listing") }} type="button" class="btn btn-secondary Cancel" >
                                        Cancel
                                    </button>
                                    <button onClick={this.onProceedModal} type="button" className="btn btn-primary Submit">
                                        PROCEED
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <ChooseWalletModal
                    showChooseWalletModal={this.state.chooseWalletModalShow}
                    onCloseChooseWalletModal={() => {
                        this.setState({ chooseWalletModalShow: false })
                    }}
                    onProceedChooseWalletModal={(mnemonics, wallet) => {

                        this.setState({ chooseWallet: wallet, chooseWalletMnemonics: mnemonics, confirmationModalShow: true }, () => {

                        })
                    }}
                ></ChooseWalletModal>


                <ConfirmationModal
                    showConfirmationModal={this.state.confirmationModalShow}
                    wallet={this.state.chooseWallet}
                    onCloseConfirmationModal={(value) => {
                        if (value) {

                            this.setState({ confirmationModalShow: false, chooseWalletModalShow: false, walletPinModalShow: true })

                        }
                        else {
                            this.setState({ confirmationModalShow: false })
                        }

                    }}
                ></ConfirmationModal>

                <WalletPinModal

                    showWaletPinModal={this.state.walletPinModalShow}
                    wallet={this.state.chooseWallet}
                    mnemonics={this.state.chooseWalletMnemonics}
                    imagePath={this.state.imagePath}
                    onCloseWalletPinModal={() => {
                        this.setState({ walletPinModalShow: false })
                    }}
                    onSuccessConfirmationModal={() => {
                        this.setState({ walletPinModalShow: false }, () => {
                            this.setState({
                                transferSuccess: false, alertTitlt: "Congratulations!", successModalShow: true, alertMessage: `Your wallet successfully registered.`
                            })
                        })
                    }}
                ></WalletPinModal>

                <InfoSuccess showSuccessModal={this.state.successModalShow} title={this.state.alertTitlt} message={this.state.alertMessage} onCloseSuccessModal={() => {

                    // if we open success modal on transfer coin then we will call getUserAllWallet to get the actual wallet balance after transfer
                    if (this.state.transferSuccess === true) {
                        this.setState({
                            successModalShow: false,
                            alertTitlt: "",
                            alertMessage: ""
                        }, () => {
                            this.getUserAllWallet()
                        })
                    }
                    else {
                       this.props.history.push("/wallet-listing")
                        this.setState({
                            successModalShow: false,
                            alertTitlt: "",
                            alertMessage: ""
                        })
                    }
                }
                } />
                <Footer />
            </>
        )
    }
}


const mapStateToProps = (state) => ({
    isShowShopRegisterScreen: state.walletReducer.isShowShopRegisterScreen
})

export default connect(mapStateToProps, {  })(ShopRegister)
