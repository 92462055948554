import { Component } from "react";
import Footer from "../../Layout/Footer";
import "../../Modals/WebCam/style.css"
import { QrReader } from 'react-qr-reader';
import { toastMessageFailure } from "../../../utils/toastMessage";
import { connect } from "react-redux";

class POSTerminal extends Component {

    state = {
        imagePath: "",
        chooseWalletModalShow: false,
        chooseWallet: "",
        chooseWalletMnemonics: "",
        confirmationModalShow: false,
        successModalShow: false,
        alertTitlt: "",
        alertMessage: ""
    }

    componentDidMount() {

    }

    onProceedModal = () => {
        if (!this.state.imagePath) {
            toastMessageFailure("Please provide a selfie image to proceed further.")

        }
        else {
            this.setState({ chooseWalletModalShow: true })
        }
    }

    render() {
        return (
            <>
                <div className="BlackStarsBack">
                    <div id="Blackstars" />
                    <div id="Blackstars2" />
                    <div id="Blackstars3" />
                </div>

                <div className="row" id="zIndexMob">
                    <div className="col-md-6 offset-md-3">
                        <div className="AccountAddress text-center">
                            <h4>Scan QR code here</h4>
                            <div className="Box SetPass QrScan">

                                <QrReader
                                    onResult={(result, error) => {
                                        if (!!result) {
                                            console.log();
                                            this.props.history.push(`/pos/terminal/${result?.text}`);
                                        }

                                        if (!!error) {
                                            console.info(error);
                                        }
                                    }}
                                    style={{ width: '70%', height: '20%' }}
                                />
                                <div className="laser"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </>
        )
    }
}


const mapStateToProps = (state) => ({
    isShowShopRegisterScreen: state.walletReducer.isShowShopRegisterScreen
})

export default connect(mapStateToProps, {})(POSTerminal)
