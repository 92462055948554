import { Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

const PrivateRoute = ({ component: Component, auth, ...rest }) => (
    <Route
        render={(props) => {

            if (auth.maintanceStatus === "true") {

                return <Redirect to={`/maintenance`} />
            }
            if (auth.isAuthenticated) {
                console.log("private", auth.term)

                if (auth.term == 0) {
                    console.log("private inside 1", auth.term)
                    return <Redirect to={`/term-and-condition`} />
                }

                else {
                    console.log("private inside 2")
                    return <Component {...props} />
                }

            } else {
                console.log("private inside 3")

                return <Redirect to={`/`} />
            }
        }}
        {...rest}
    />
)

PrivateRoute.propTypes = {
    auth: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => ({
    auth: state.authReducer,
})

export default connect(mapStateToProps)(PrivateRoute)
// export default connect(mapStateToProps, null, null, {
//   pure: false,
// })(PrivateRoute);