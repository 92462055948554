import React, { useState } from 'react';
import { propTypes } from 'react-bootstrap/esm/Image';
import Webcam from "react-webcam";


const WebcamComponent = () => <Webcam />;

const videoConstraints = {
    width: 220,
    height: 200,
    facingMode: "user"
};

export const WebcamCapture = (props) => {

    const [image,setImage]=useState('');
    const webcamRef = React.useRef(null);

    
    const capture = React.useCallback(
        () => {
        const imageSrc = webcamRef.current.getScreenshot();
        setImage(imageSrc)
        props.onImageCapture(imageSrc)
        });


    return (
        <div className="webcam-container">
            <div className="webcam-img">

            {
                image == '' ?
                    <Webcam
                        audio={false}
                        height={230}
                        ref={webcamRef}
                        screenshotFormat="image/jpeg"
                        width={260}
                        
                        alt="Permission is required to open camera"
                        videoConstraints={videoConstraints}
                    /> : <img src={image} />
            }
            </div>
            <div>
                {image != '' ?
                    <button style={{margin:"20px 0px",width:220}} onClick={(e) => {
                        e.preventDefault();
                        setImage('')
                        props.onImageCapture("")
                    }}
                    className="Read-More">
                        Retake Image</button> :
                    <button  style={{margin:"20px 0px",width:220}} onClick={(e) => {
                        e.preventDefault();
                        capture();
                    }}
                    className="Read-More">Capture Image</button>
                }
            </div>
        </div>
    );
};