import invoke from '../../utils/invoke';
import { encrypt } from '../../utils/utils';
import config from '../../_config';


export const getBalance = (address) => {

    return invoke({
        method: 'GET',
        baseURL: config.walletBlockchainServiceApiUrl,
        route: `v1/bc/wallet/balance/${address}`,

    });
}


export const transferCoin = (data) => {
    data = encrypt(data)
    return invoke({
        method: 'POST',
        baseURL: config.walletBlockchainServiceApiUrl,
        route: "v1/bc/wallet/transfer",
        data: data

    });
}

export const createWallet = () => {

    return invoke({
        method: 'POST',
        baseURL: config.walletBlockchainServiceApiUrl,
        route: "v1/bc/wallet/create"
    });
}

export const minerRegistration = (data) => {

    data = encrypt(data)

    return invoke({
        method: 'POST',
        baseURL: config.minerBlockchainServiceApiUrl,
        route: "v1/bc/miner/register",
        data: data,
        headers: {
            Authorization: localStorage.getItem('jwtToken')
        }
    });
}


export const checkUserCanMint = (address) => {

    return invoke({
        method: 'GET',
        baseURL: config.mintBlockchainServiceApiUrl,
        route: `v1/bc/mint/lastMint/${address}`,

        headers: {
            Authorization: localStorage.getItem('jwtToken')
        }

    });
}


export const finalMintCoin = (data) => {
    data = encrypt(data)
    return invoke({
        method: 'POST',
        baseURL: config.mintBlockchainServiceApiUrl,
        route: `v1/bc/mint/coinMint`,
        data: data,

        headers: {
            Authorization: localStorage.getItem('jwtToken')
        }

    });
}











