import React, { Component } from "react";
import { updatePassword } from '../../../api/authentication/authentication';
import { toastMessageSuccess } from "../../../utils/toastMessage"

import { WarningMessageAlert,ErrorMessageAlert,InfoMessageAlert } from "../../../utils/alert"
import { ErrorMessage } from "../../../messages/ErrorMessage"


class UpdatePassword extends Component {
    state = {
        otpCode: "",
        password: "",
        confirmPassword: "",
        isError: false,
        message: "",
        isLoading: false,
        disable: "",
        // hide and show password and confirm password
        passwordType: "password",
        hideAndShowLabelTextPassword: "Show",

        confirmPasswordType: "password",
        hideAndShowLabelTextConfirmPassword: "Show",
        isErrorAlert: false




    }
    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
            [e.target.name.concat('error')]: '',
        })

    }

    updatePassword = async () => {
        if (this.state.otpCode === '' || this.state.otpCode === null) {
            return this.setState({ isError: true, isErrorAlert: false, message: 'OTP Code is required!' });
        }
        else if (this.state.otpCode.length !== 6) {
            return this.setState({ isError: true, isErrorAlert: false, message: 'Please enter the 6 digit otp code!' });
        }
        else if (this.state.password === '' || this.state.password === null) {
            return this.setState({ isError: true, isErrorAlert: false, message: 'Password required!' });
        }
        else if (this.state.password !== this.state.confirmPassword) {
            return this.setState({ isError: true, isErrorAlert: false, message: 'Password and confirm password not matched!' });
        }
        this.setState({ isError: false, isErrorAlert: false, message: null, isLoading: true, disable: "none" });
        try {
            await updatePassword(
                {
                    password: this.state.password,
                    confirmPassword: this.state.confirmPassword,
                    otpCode: this.state.otpCode
                }
            );
            this.setState({ isLoading: false, disable: "", message: "" });
            toastMessageSuccess("Your password update successfully'")
            this.props.history.push("/login")
        }
        catch (err) {

            if (err?.response?.data) {
                if (err?.response?.status === 500) {

                    return this.setState({ isError: false, disable: "", isLoading: false, message: err?.response?.data?.errors[0]?.message, isErrorAlert: true });
                }

                return this.setState({ isError: true, isErrorAlert: false, disable: "", isLoading: false, message: err?.response?.data?.errors[0]?.message });
            }
            else if (err.message === "Network Error") {

                return this.setState({ isError: true, isErrorAlert: false, disable: "", isLoading: false, message:ErrorMessage.networkError });

            }

        }

    }


    submitUpdatePassword = async (e) => {
        e.preventDefault();
        this.updatePassword()
    };

    onKeyPress = (e) => {
        const enterOrSpace =
            e.key === "Enter" ||
            e.which === 13
        if (enterOrSpace === true) {
            this.updatePassword()
        }
    };

    hideAndShowPassword = (value) => {

        if (value === "Password") {
            if (this.state.passwordType === "text") {
                this.setState({
                    passwordType: "password",
                    hideAndShowLabelTextPassword: "Show"
                })
            }
            else if (this.state.passwordType === "password") {
                this.setState({
                    passwordType: "text",
                    hideAndShowLabelTextPassword: "Hide"
                })
            }
        }
        else if (value === "confirmPassword") {

            if (this.state.confirmPasswordType === "text") {
                this.setState({
                    confirmPasswordType: "password",
                    hideAndShowLabelTextConfirmPassword: "Show"
                })
            }
            else if (this.state.confirmPasswordType === "password") {

                this.setState({
                    confirmPasswordType: "text",
                    hideAndShowLabelTextConfirmPassword: "Hide"
                })
            }
        }
    }



    render() {
        return (
            <>
                <div className="LoginStyle">
                    <div className="bg-img-fix">
                        <div className="row">
                            <div className="col-xl-5 col-lg-5 col-md-6 col-sm-12 bg-white z-index2 relative p-a0 content-scroll skew-section left-bottom">
                                <div className="LoginForm">
                                    <div className="logo-header text-center pt-5 pb-4">
                                        <a href="index.html" className="navbar-brand d-flex align-items-center">
                                            <span className="Logo-Txt">NUC</span>
                                            <img src="/assets/images/nucoin-logo.png" alt="" />
                                            <span className="Logo-Txt">IN</span>
                                        </a>
                                    </div>
                                    <a href="/" className="BackArrow">Back To Home Page</a>
                                    <p className="my-5">Update Password </p>

                                    <div className="form-group">
                                        <div className="alert alert-success" role="alert">

                                            <p style={{ fontSize: '16px' }}>
                                                If your provided email or phone number exists in our database, you will receive an OTP code at your selected choice in a few minutes.<br></br> <b>Please enter that code in the below box to update the password.</b><br></br>NOTE: If it doesn’t appear within a few minutes, check your spam folder.
                                            </p>

                                        </div>
                                    </div>


                                    <div className="form-group">
                                        <input autoFocus={true} onChange={this.onChange}
                                            onKeyPress={this.onKeyPress} name="otpCode" className="form-control mb-2" placeholder="Please enter 6 digit otp code" type="text" />



                                    </div>



                                    <div className="form-group position-relative">
                                        <input onChange={this.onChange}
                                            onKeyPress={this.onKeyPress} name="password" className="form-control mb-2" placeholder="Password" type={this.state.passwordType} />
                                        <label style={{ cursor: "pointer" }} onClick={() => this.hideAndShowPassword("Password")}>{this.state.hideAndShowLabelTextPassword}</label>
                                    </div>
                                    <div className="form-group position-relative">
                                        <input onChange={this.onChange}
                                            onKeyPress={this.onKeyPress} name="confirmPassword" className="form-control mb-2" placeholder="Confirm Password" type={this.state.confirmPasswordType} />
                                        <label style={{ cursor: "pointer" }} onClick={() => this.hideAndShowPassword("confirmPassword")}>{this.state.hideAndShowLabelTextConfirmPassword}</label>
                                    </div>



                                    {
                                        this.state.isError ? <WarningMessageAlert message={this.state.message}></WarningMessageAlert> : null
                                    }
                                    {
                                        this.state.isErrorAlert ? <ErrorMessageAlert message={this.state.message}></ErrorMessageAlert> : null
                                    }

                                    <div className="text-center my-5">

                                        <a href={() => false} style={{ pointerEvents: this.state.disable }} onClick={(event) => this.submitUpdatePassword(event)} className="site-button button-md btn-block text-white" type="button">
                                            {this.state.isLoading === true ? <img alt="loading..." style={{ height: '20px', width: '20px', marginRight: '8px' }} src="/assets/images/spinner3.gif"></img> : null}
                                            Update Password</a>
                                    </div>

                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-5 col-md-6 col-sm-12 content-scroll">
                                <div className="text-white ContentSide max-w400">
                                    <h1 className="mb-4">Update Password</h1>
                                    <p>Follow instructions in the form displayed to update password.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>

        )
    }

}

export default UpdatePassword
