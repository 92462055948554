import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import React, { Component } from "react";

import { connect } from 'react-redux';
import { teamScreen } from "../../actions/auth"

class Team extends Component {
    componentDidMount() {
        this.props.teamScreen(true)
    }
    componentWillUnmount() {
        this.props.teamScreen(false)
    }
    render() {
        return (
            <>
                <div className="BlackStarsBack">
                    <div id="Blackstars" />
                    <div id="Blackstars2" />
                    <div id="Blackstars3" />
                </div>
                <div className="row mb-40" id="zIndexMob">
                    <div className="col-12">
                        <div className="mb-5 text-center">
                            <h1 className="section-title">
                                Meet Our Team Members
                            </h1>
                        </div>
                    </div>
                    <div className="row our-team mb-4">
                        <div className="col-lg-3 col-md-4">
                            <div className="rounded-lg card bg-member-card">
                                <div className="TeamImg">
                                    <img src={"/assets/images/member-1.jpeg"} alt="" className="rounded-top img-fluid" />
                                </div>
                                <div className="text-center border-0 card-body">
                                    <div className="BlackColor">
                                        <div className="member-name">
                                            Hussein Faraj
                                        </div>
                                        <div className="member-position">
                                            Chief Executive Officer
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8 col-md-8">
                            <div className="About-Team">
                                <h4>Hussein Faraj</h4>
                                <h4>Chief Executive Officer</h4>
                                <p>Founder and CEO of Advantage group Australasia, president of the United Shia Islamic Foundation, Vice President of Madif Al Ameer Youth, Co-founder of regenerate earth, Owner of Chicken Licious Rockdale, Chi Time and CL Gourmet. 9 years’ experience in law enforcement/security, with 3 years supervision and training experience. During the past 17 years been involved in over 7 billion dollars of international contracts and alliances, while trading over $20 millions locally. Extensive experience in international finance and Middle east politics. Strategic partner to over 100 global networks and various fortune 500 companies. Extensive experience in real estate, project ROI Strategies and feasibilities. Founder of Techno Industries, co Founder/ CEO and strategic analyst of NU Coin.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row our-team mb-4">
                        <div className="col-lg-3 col-md-4">
                            <div className="rounded-lg card bg-member-card">
                                <div className="TeamImg">
                                    <img src={"/assets/images/member-2.png"} alt="" className="rounded-top img-fluid" />
                                </div>
                                <div className="text-center border-0 card-body">
                                    <div className="BlackColor">
                                        <div className="member-name">
                                            Nick Peterson
                                        </div>
                                        <div className="member-position">
                                            Strategy and Legal Affairs
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8 col-md-8">
                            <div className="About-Team">
                                <h4>Nick Peterson</h4>
                                <h4>Strategy and Legal Affairs</h4>
                                <p>Executive responsible for Strategic Development and Legal Affairs, Nick has over 30 years’ experience in international law, business, government relations as a lawyer and Investment Banker. He has postgraduate qualifications in law and Commerce with particular expertise in international arbitrage between legal jurisdictions. He has worked in leading accounting and legal firms as well as senior executive roles with Governments. He has and continues to manage multiple litigation in different jurisdictions; design financial products, structured investments, finance and syndications. Nick is coordinating the legal teams in the different jurisdictions to provide for designing financial products and investment structures that commercialise the technology involved in the Nu Coin Platform. He is co-ordinating the longer-term development of the NUCoin free-trade financial centre including the designing of the laws to recognise and regulate Digital Autonomous Corporations, Digital residency and crypto-asset exchanges. The world’s first digital asset jurisdiction haven is being designed by this team. He has particular interest in banking and the development of the digital asset exchange network that is fast competing against the traditional global banking infrastructure.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row our-team mb-4">
                        <div className="col-lg-3 col-md-4">
                            <div className="rounded-lg card bg-member-card">
                                <div className="TeamImg">
                                    <img src={"/assets/images/member-3.png"} alt="" className="rounded-top img-fluid" />
                                </div>
                                <div className="text-center border-0 card-body">
                                    <div className="BlackColor">
                                        <div className="member-name">
                                            Luay Mohsen
                                        </div>
                                        <div className="member-position">
                                            Chief Technology Officer
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8 col-md-8">
                            <div className="About-Team">
                                <h4>Luay Mohsen</h4>
                                <h4>Chief Technology Officer</h4>
                                <p>DevOps, Software Engineer using technology stacks on Cloud Amazon Web Services AWS both infrastructure and compute services like Lambda with Python, Glue Job ETL, MySQL and Oracle RDS, technologies. Java developer experienced in designing and developing enterprise micro-services solutions using Play Framework Java technologies. Python and Pytest framework for end to end testing and system integration. Apigee Proxy designer and developer for API Gateway development. AngularJS experience for front end development. Excellent analytical, design and problem solving abilities. Experience with full Agile Environment. Extremely motivated and committed to delivering the highest standard of work.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row our-team mb-4">
                        <div className="col-lg-3 col-md-4">
                            <div className="rounded-lg card bg-member-card">
                                <div className="TeamImg">
                                    <img src={"/assets/images/member-4.jpeg"} alt="" className="rounded-top img-fluid" />
                                </div>
                                <div className="text-center border-0 card-body">
                                    <div className="BlackColor">
                                        <div className="member-name">
                                            Muzammil Abbas
                                        </div>
                                        <div className="member-position">
                                            COO & Prresident - GCC Operations
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8 col-md-8">
                            <div className="About-Team">
                                <h4>Muzammil Abbas</h4>
                                <h4>COO & President - GCC Operations</h4>
                                <p>With an Engineering Background and a Masters in Business Administration, Muzammil has successfully implemented the combination of his technical and management skills in many successful business ventures.
                                    With more than 20 years of experience in International trade he is an Entrepreneur with an avid experience in structuring deals in industries like Finance, Mining, Engineering, Pharmaceutical, Consumer Goods and Information Technology. He has been instrumental in financing and supporting many start ups and new businesses.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row our-team mb-4">
                        <div className="col-lg-3 col-md-4">
                            <div className="rounded-lg card bg-member-card">
                                <div className="TeamImg">
                                    <img src={"/assets/images/member-5.jfif"} alt="" className="rounded-top img-fluid" />
                                </div>
                                <div className="text-center border-0 card-body">
                                    <div className="BlackColor">
                                        <div className="member-name">
                                            Hamza Yasin
                                        </div>
                                        <div className="member-position">
                                            Blockchain Developer
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8 col-md-8">
                            <div className="About-Team">
                                <h4>Hamza Yasin</h4>
                                <h4>Blockchain Developer</h4>
                                <p>1ST PRIZE WINNER – INTERNATIONAL CONFERENCE ON OPEN SOURCE SYSTEMS AND TECHNOLOGIES IN UET. I'm a Blockchain Expert and a Javascript developer having experience in Swing framework, Java core, Nodejs, Javascript, Solidity Smart Contracts, Ethereum, bitcoin, tron, ripple, stellar, cryptonote, Hyperledger, Ethereum or other blockchain forks, Centralized, Decentralized or Hybrid Crypto Exchanges and Private Blockchain Development.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row our-team mb-4">
                        <div className="col-lg-3 col-md-4">
                            <div className="rounded-lg card bg-member-card">
                                <div className="TeamImg">
                                    <img src={"/assets/images/member-6.jfif"} alt="" className="rounded-top img-fluid" />
                                </div>
                                <div className="text-center border-0 card-body">
                                    <div className="BlackColor">
                                        <div className="member-name">
                                            Zain Ul Abedin
                                        </div>
                                        <div className="member-position">
                                            Blockchain Developer
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8 col-md-8">
                            <div className="About-Team">
                                <h4>Zain Ul Abedin</h4>
                                <h4>Blockchain Developer</h4>
                                <p>I am proficient at integrating multiple languages which includes ReactJS, NodeJS, Hyperledger Fabric (IBM), Docker, Solidity and SQL / NoSQL Databases. Moreover, I am efficient in developing digital currency wallets & smart contracts. I am highly inclined towards big data as I know how beneficial it is for Enterprise Business Solutions.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row our-team mb-4">
                        <div className="col-lg-3 col-md-4">
                            <div className="rounded-lg card bg-member-card">
                                <div className="TeamImg">
                                    <img src={"/assets/images/member-7.jpeg"} alt="" className="rounded-top img-fluid" />
                                </div>
                                <div className="text-center border-0 card-body">
                                    <div className="BlackColor">
                                        <div className="member-name">
                                            Raja Fayyaz
                                        </div>
                                        <div className="member-position">
                                            Artificial Intelligence & Machine Learning
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8 col-md-8">
                            <div className="About-Team">
                                <h4>Raja Fayyaz</h4>
                                <h4>Artificial Intelligence & Machine Learning</h4>
                                <p>Experienced Artificial Intelligence engineer who has done 70+ freelancing machine and deep learning projects. Attended Pi School of AI after getting a full scholarship in Rome, Italy, and got 2 conference publications in undergraduate studies. One conference presentation i.e. "Classifying ALcoholics and Control Patients Using Deep Learning and Peak Visualization Methods" got the best student paper award in WSPML 2019 which was held in Bangkok, Thailand.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row our-team mb-4">
                        <div className="col-lg-3 col-md-4">
                            <div className="rounded-lg card bg-member-card">
                                <div className="TeamImg">
                                    <img style={{ objectFit: 'contain' }} src={"/assets/images/member-8.png"} alt="" className="rounded-top w-100 img-fluid" />
                                </div>
                                <div className="text-center border-0 card-body">
                                    <div className="BlackColor">
                                        <div className="member-name">
                                            Mohamed Hassan
                                        </div>
                                        <div className="member-position">
                                            Multifaceted Technical Engineer
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8 col-md-8">
                            <div className="About-Team">
                                <h4>Mohamed Hassan</h4>
                                <h4>Multifaceted Technical Engineer</h4>
                                <p>Multifaceted technical career with 25+ years’ experience and consistent record of achievement. Results-driven IT Manager with a solid history of effective leadership and operational support; adept at providing highquality service and managing all aspects of multimillion-dollar IT infrastructure and development. Design and deliver leading-edge enterprise solutions precisely aligned with current and future business needs, ensuring consistent operational continuity. Seamlessly manage and execute change, mitigating risk and leveraging best practices to improve productivity and reduce costs. Skilled manager and project leader, effectively directing multiple operations and concurrent projects to achieve corporate and client objectives.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row our-team mb-4">
                        <div className="col-lg-3 col-md-4">
                            <div className="rounded-lg card bg-member-card">
                                <div className="TeamImg">
                                    <img src={"/assets/images/member-9.png"} alt="" className="rounded-top img-fluid" />
                                </div>
                                <div className="text-center border-0 card-body">
                                    <div className="BlackColor">
                                        <div className="member-name">
                                            Fahad Aziz
                                        </div>
                                        <div className="member-position">
                                            Full Stack Developer
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8 col-md-8">
                            <div className="About-Team">
                                <h4>Fahad Aziz</h4>
                                <h4>Full Stack Developer</h4>
                                <p>I am a result-oriented professional,proficient in Software Development and Scripting Languages to enhance the performance of Applications, venturing out to the field of emerging technologies starting with the Blockchain and MERN Stack.Currently working with Spectroblock as a software engineer.
                                    .</p>
                            </div>
                        </div>
                    </div>
                    <div className="row our-team mb-4">
                        <div className="col-lg-3 col-md-4">
                            <div className="rounded-lg card bg-member-card">
                                <div className="TeamImg">
                                    <img src={"/assets/images/member-10.png"} alt="" className="rounded-top img-fluid" />
                                </div>
                                <div className="text-center border-0 card-body">
                                    <div className="BlackColor">
                                        <div className="member-name">
                                            Omer Iftikhar
                                        </div>
                                        <div className="member-position">
                                            Full Stack Developer
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8 col-md-8">
                            <div className="About-Team">
                                <h4>Omer Iftikhar</h4>
                                <h4>Full Stack Developer</h4>
                                <p>Full Stack Engineer with working experience in JavaScript, React, Redux, Node, SQL databases & NoSQL databases like MongoDB. I'm familiar with Python and it's web framework Flask as well. I have extensive experience working with AWS. Working in the software industry for more than 2 years now, I have developed myself not only individually but as a team player as well.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row our-team mb-4">
                        <div className="col-lg-3 col-md-4">
                            <div className="rounded-lg card bg-member-card">
                                <div className="TeamImg">
                                    <img src={"/assets/images/member-11.png"} alt="" className="rounded-top img-fluid" />
                                </div>
                                <div className="text-center border-0 card-body">
                                    <div className="BlackColor">
                                        <div className="member-name">
                                            Ali Zaryab
                                        </div>
                                        <div className="member-position">
                                            UI/UX Full Stack Developer
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8 col-md-8">
                            <div className="About-Team">
                                <h4>Ali Zaryab</h4>
                                <h4>UI/UX Full Stack Developer</h4>
                                <p>Full Stack Engineer UI/UX customer web design with working experience in HTML, CSS, HTML5, CSS3, Sass, Bootstrap, Web, Responsiveness, JavaScript / jQuery, Shopify plus more. Working in the software industry for more than 3 years and have developed and designed many websites as well.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row our-team mb-4">
                        <div className="col-lg-3 col-md-4">
                            <div className="rounded-lg card bg-member-card">
                                <div className="TeamImg">
                                    <img src={"/assets/images/member-12.jpg"} alt="" className="rounded-top img-fluid" />
                                </div>
                                <div className="text-center border-0 card-body">
                                    <div className="BlackColor">
                                        <div className="member-name">
                                            Reza Motevallizadah
                                        </div>
                                        <div className="member-position">
                                            Cloud Software Architecture Engineer
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8 col-md-8">
                            <div className="About-Team">
                                <h4>Reza Motevallizadah</h4>
                                <h4>Cloud Software Architecture Engineer</h4>
                                <p>My background is in DevOps, Cloud and Security and worked in varity of projects in large enterprises around the world. For the past 3 years started to work on distributed ledgers and blockchain networks including Ethereum, Corda, and Hyperledger. Doing lots of coding in Python and Golang for my business and personal projects.</p>
                            </div>
                        </div>
                    </div>

                    <div className="row our-team mb-4">
                        <div className="col-lg-3 col-md-4">
                            <div className="rounded-lg card bg-member-card">
                                <div className="TeamImg">
                                    <img src={"/assets/images/member-13.jpg"} alt="" className="rounded-top img-fluid" />
                                </div>
                                <div className="text-center border-0 card-body">
                                    <div className="BlackColor">
                                        <div className="member-name">
                                            Vikrant Navalgund
                                        </div>
                                        <div className="member-position">
                                            Software Security Engineer
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8 col-md-8">
                            <div className="About-Team">
                                <h4>Vikrant Navalgund</h4>
                                <h4>Software Security Engineer</h4>
                                <p>Implement, test and operate advanced software security techniques in compliance with technical reference architecture Perform on-going security testing and code review to improve software security Troubleshoot and debug issues that arise Provide engineering designs for new software solutions to help mitigate security vulnerabilities Contribute to all levels of the architecture Maintain technical documentation Consult team members on secure coding practices Develop a familiarity with new tools and best practices</p>
                            </div>
                        </div>
                    </div>
                    <div className="row our-team mb-4">
                        <div className="col-lg-3 col-md-4">
                            <div className="rounded-lg card bg-member-card">
                                <div className="TeamImg">
                                    <img src={"/assets/images/member-14.jpeg"} alt="" className="rounded-top img-fluid" />
                                </div>
                                <div className="text-center border-0 card-body">
                                    <div className="BlackColor">
                                        <div className="member-name">
                                            Muhammad Hassan
                                        </div>
                                        <div className="member-position">
                                            Web/Mobile & Blockchain Developer
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8 col-md-8">
                            <div className="About-Team">
                                <h4>Muhammad Hassan</h4>
                                <h4>Web/Mobile & Blockchain Developer</h4>
                                <p>Web/Mobile Application and Blockchain Development with experience in online workflow and data management and social media handling.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row our-team mb-4">
                        <div className="col-lg-3 col-md-4">
                            <div className="rounded-lg card bg-member-card">
                                <div className="TeamImg">
                                    <img src={"/assets/images/member-15.jpeg"} alt="" className="rounded-top img-fluid" />
                                </div>
                                <div className="text-center border-0 card-body">
                                    <div className="BlackColor">
                                        <div className="member-name">
                                            Abdullah Ahmed
                                        </div>
                                        <div className="member-position">
                                            Web & Blockchain Developer
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8 col-md-8">
                            <div className="About-Team">
                                <h4>Abdullah Ahmed</h4>
                                <h4>Web & Blockchain Developer</h4>
                                <p>Web Application and Blockchain Development with experience in Hyperledger Fabric, ERC-20, BEP-20 and Deploying sub-grap on The Graph(BlockChain).</p>
                            </div>
                        </div>
                    </div>
                    <div className="row our-team mb-4">
                        <div className="col-lg-3 col-md-4">
                            <div className="rounded-lg card bg-member-card">
                                <div className="TeamImg">
                                    <img src={"/assets/images/member-16.jpg"} alt="" className="rounded-top img-fluid" />
                                </div>
                                <div className="text-center border-0 card-body">
                                    <div className="BlackColor">
                                        <div className="member-name">
                                            Nivine khanafer
                                        </div>
                                        <div className="member-position">
                                            Executive Assitant
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8 col-md-8">
                            <div className="About-Team">
                                <h4>Nivine khanafer</h4>
                                <h4>Executive Assitant</h4>
                                <p>I have a degree in science economics, studied in the university of lebanon. Financial capacity officer of advantage group Australia. Vice chair of Madif Al Ameer Youth. Speciality in event management, brand management, corporate branding and book keeping. Over 15 years experience in management and development . Translator and liaison officer focused on cultural sensitive and cross regional diplomacy Fluent in arabic, English and intermediate in French.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row our-team mb-4">
                        <div className="col-lg-3 col-md-4">
                            <div className="rounded-lg card bg-member-card">
                                <div className="TeamImg">
                                    <img src={"/assets/images/member-17.jpg"} alt="" className="rounded-top img-fluid" />
                                </div>
                                <div className="text-center border-0 card-body">
                                    <div className="BlackColor">
                                        <div className="member-name">
                                            Ibrahim Hamidy
                                        </div>
                                        <div className="member-position">
                                            Ethics Officer
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8 col-md-8">
                            <div className="About-Team">
                                <h4>Ibrahim Hamidy</h4>
                                <h4>Ethics Officer</h4>
                                <p>Over 30 years experience in ethical and professional practices, Specialise in the fields of ethical behaviour, honesty and governance. President of Madif Al Ameer Youth, Middle east liaison officer with over 5 years of direct middle east corporate networking. Middle east behavioural specialist and customs advisor, fluent in Persian and arabic, understand and speak majority of arabic slangs. Specialise in non usary principles and practices.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row our-team mb-4">
                        <div className="col-lg-3 col-md-4">
                            <div className="rounded-lg card bg-member-card">
                                <div className="TeamImg">
                                    <img src={"/assets/images/member-4.png"} alt="" className="rounded-top img-fluid" />
                                </div>
                                <div className="text-center border-0 card-body">
                                    <div className="BlackColor">
                                        <div className="member-name">
                                            Imran Bhojani
                                        </div>
                                        <div className="member-position">
                                            UAE Country Head
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8 col-md-8">
                            <div className="About-Team">
                                <h4>Imran Bhojani</h4>
                                <h4>UAE Country Head</h4>
                                <p>Imran graduated with a gold medal in Statistics in India and topped in his MBA program in US. Has over 15 years of experience and believes that only way to run a successful business is with values and relationship. His expertise in strategizing, planning and project management has helped him achieved major breakthroughs in various companies that he manages. He is currently associated with the following companies: 1. Director of Radiance International Inc: A nauru company which provides refugee accommodation services to Govt of Australia and Procurement services to various departments of Nauru Govt 2. Director of Fuerte Solutions Pvt Ltd: A software consulting company in Hyderabad, India 3. CEO & Director of Codeblaze Technolgies Pvt Ltd: An IT staffing solutions provider in Vadodara, India 4. Director of Collabu Technologies Pte Ltd: A Singapore company which is developing a first of its kind community management software called CollabDeen - a SAAS product 5. Trustee of G A Bhojani & family trust which undertakes community service programs for widows, orphans & economically underprivileged. He has been awarded and honoured by several dignitaries including a Member of Parliament in UK for his contribution towards the community</p>
                            </div>
                        </div>
                    </div>
                    <div className="row our-team mb-4">
                        <div className="col-lg-3 col-md-4">
                            <div className="rounded-lg card bg-member-card">
                                <div className="TeamImg">
                                    <img src={"/assets/images/member-19.jpg"} alt="" className="rounded-top img-fluid" />
                                </div>
                                <div className="text-center border-0 card-body">
                                    <div className="BlackColor">
                                        <div className="member-name">
                                            Peter Soulios
                                        </div>
                                        <div className="member-position">
                                            Chief Liaison Officer
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8 col-md-8">
                            <div className="About-Team">
                                <h4>Peter Soulios</h4>
                                <h4>Chief Liaison Officer</h4>
                                <p>Co Founder of advantage property expert, CEO of superbsouvlaki food manufacturing and distribution 30 plus years experience in hospitality and venue management. Preferred catering company for Sydney Olympics over a decade experience in international trade and intercountry relations. Liaison and relationship officer for various cross country developments. Specialise is relationship building between Middle east, China and Europe. Network coordinator for 100 plus global partners including several Fortune 500 companies Primary focus and expertise in agricultural and food security projects. Partner with various soil rejuvenation organisations with primary focus on reforestation and food security globally.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row our-team mb-4">
                        <div className="col-lg-3 col-md-4">
                            <div className="rounded-lg card bg-member-card">
                                <div className="TeamImg">
                                    <img src={"/assets/images/member-20.jpg"} alt="" className="rounded-top img-fluid" />
                                </div>
                                <div className="text-center border-0 card-body">
                                    <div className="BlackColor">
                                        <div className="member-name">
                                            Anthony Weinman
                                        </div>
                                        <div className="member-position">
                                            Investor, HR & Office Administration
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8 col-md-8">
                            <div className="About-Team">
                                <h4>Anthony Weinman</h4>
                                <h4>Investor, HR & Office Administration</h4>
                                <p>I am the Vice President of the Sri Lankan catholic association which has a membership 680 families, the President of our local church Our Lady Of The Rossry In Kellyville, Senior Member of the Lions Club of Kingslangley, my education, Advance diploma in business management, advance diploma in commercial Shipping, Advance diploma in Marine Law trained in Singapore n Hongkong, worked For American President Lines in Sydney as Manager Imports, Zim Lines National Maintenance n Repair Manager all 5 states in Australia. National Claims Manager Yang Ming Lines Sydney, Registered licence Holder for Real Estate in Residential and acreage.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row our-team mb-4">
                        <div className="col-lg-3 col-md-4">
                            <div className="rounded-lg card bg-member-card">
                                <div className="TeamImg">
                                    <img src={"/assets/images/member-21.jpeg"} alt="" className="rounded-top img-fluid" />
                                </div>
                                <div className="text-center border-0 card-body">
                                    <div className="BlackColor">
                                        <div className="member-name">
                                            Kevin Bowen
                                        </div>
                                        <div className="member-position">
                                            Chief Marketing Officer
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8 col-md-8">
                            <div className="About-Team">
                                <h4>Kevin Bowen</h4>
                                <h4>Chief Marketing Officer</h4>
                                <p>- Qualified infantry Section Commander and instructor, The Australian Army. Served 2003-present (inactive) - Bachelor of Commerce (Finance & Marketing), University of NSW, 2007 - Advanced Diploma of Management , TAFE QLD, 2014 - Graduate Diploma of Strategic Leadership tafe QLD 2017 - Advanced diploma of leadership and management, Hamel 2015 - Diploma of Work Health and Safety, Hamel, 2014 - Diploma of Vocational Education and Training, TAFE QLD, 2014 - Diploma of Project Management, Hamel, 2013 - Diploma of Business, Hamel, 2013 - Diploma of Security & Risk Management, Hamel, 2013 - Diploma of Management, Hamel, 2013 - Certificate IV in Training & Assessment, Hamel, 2013 - Certificate IV in Work Health and Safety, Hamel, 2013 - AMSRS Certificate in Market Research, Australian Market & Social Research Society, 2007</p>
                            </div>
                        </div>
                    </div>
                </div>

                <Footer />
            </>

        )
    }

}
const mapStateToProps = (state) => ({
    auth: state.authReducer,

})

export default connect(mapStateToProps, { teamScreen })(Team)