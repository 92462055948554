// import { Modal } from "bootstrap";
import React from 'react';
import Modal from 'react-bootstrap/Modal'
//import MneMonics from "../../MneMonics"
import { transferCoin } from '../../../../api/blockchainapi/blockchainapi';
import RedInfoDialogue from "../../../Modals/RedInfoDialogue";
import { showValidateMnemonicsScreen } from "../../../../actions/wallet";
import { connect } from "react-redux";
import { WarningMessageAlert, ErrorMessageAlert } from "../../../../utils/alert"
import { updateTransferCoinLimit, transferLimitCheck } from '../../../../api/wallet/wallet';
import { errorMessageHandler } from '../../../../utils/utils';


const CryptoJS = require("crypto-js");


class SetPasswordForCoinTransfer extends React.Component {
    state = {
        password: "",
        isError: "",
        message: "",
        alertTitlt: "",
        disable: "",
        transactionBlockHash: "",
        transactionSuccess: false,
        redInfoModalShow: false,
        // hide and show password
        passwordType: "password",
        hideAndShowLabelText: "Show",
        isErrorAlert: false
    }

    submitPassword = async () => {

        if (this.state.password === '' || this.state.password === null) {
            return this.setState({ isError: true, isErrorAlert: false, message: 'Password is required!' });
        }
        // check if from address exist in local
        let fromExist = localStorage.getItem(this.props.fromAddress)
        this.setState({ isError: false, isErrorAlert: false, message: "" })

        if (fromExist) {
            try {
                const encryptedData = localStorage.getItem(this.props.fromAddress)

                // decrypt data using password
                var mnemonicsValue = await CryptoJS.enc.Utf8.stringify(CryptoJS.AES.decrypt(encryptedData, this.state.password))
                if (`${mnemonicsValue}`.includes("string")) mnemonicsValue = JSON.parse(mnemonicsValue).string;

                // if we got the mnemoncs
                if (mnemonicsValue) {
                    try {
                        let transferObject = {
                            "secret": mnemonicsValue,
                            "recipient": this.props.toAddress,
                            "amount": this.props.amount
                        }

                        this.setState({ isError: null, message: null, isLoading: true, disable: "none" });

                        // let { data } = await transferLimitCheck({ receiverWallet: this.props.toAddress, transferredAmount: this.props.amount })

                        // if (data.data.canTransfer === false) {
                        //     this.setState({ isLoading: false, isError: true, isErrorAlert: false, disable: "", message: data.message })
                        //     return
                        // }

                        let response = await transferCoin(transferObject)
                        // await updateTransferCoinLimit(({ receiverWallet: this.props.toAddress, transferredAmount: this.props.amount }))

                        this.setState({ isLoading: false, disable: "", })


                        if (response.data.transferred.status === "ExtrinsicSuccess") {
                            this.setState({ transactionBlockHash: response.data.transferred.blockHash, transactionSuccess: true }, () => {
                                this.closeModal()
                            })
                        }
                        else {
                            this.setState({ isError: false, isErrorAlert: true, isLoading: false, disable: "", message: "Sorry! We are facing some problem to transfer coin.Try to increase your balance atleast one coin greater then your transfer amount." })
                        }
                    }
                    catch (e) {
                        console.log("rrrr", e.message)

                        this.setState({ isError: false, isErrorAlert: true, isLoading: false, disable: "", message: e.message })
                    }
                }
                // if got null in decrypting
                else {
                    this.setState({ isError: true, isErrorAlert: false, message: "You are entering a wrong password" })
                }
            }
            // if we got mulformed in decrypting
            catch (e) {
                this.setState({ isError: true, isErrorAlert: false, message: "You are entering a wrong password" })
            }
        }
        else {

            this.setState({
                redInfoModalShow: true
            })
            this.props.showValidateMnemonicsScreen(true, "transfer")
            this.props.history.push("/validate-mnemonics", { walletAddress: this.props.fromAddress, walletName: this.props.walletName, receiverAddress: this.props.toAddress, amount: this.props.amount })
        }

    }

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
            [e.target.name.concat('error')]: '',
        })
    }
    closeModal = () => {

        this.props.onClosePasswordModal(this.state.transactionBlockHash, this.state.transactionSuccess)
        // set all state to default after closing modal
        this.setState({
            password: "",
            isError: "",
            message: "",
            disable: "",
            transactionBlockHash: "",
            passwordType: "password",
            hideAndShowLabelText: "Show",
            transactionSuccess: false,
            isErrorAlert: false
        })

    }

    hideAndShowPassword = () => {
        if (this.state.passwordType === "text") {

            this.setState({
                passwordType: "password",
                hideAndShowLabelText: "Show"

            })
        }
        else if (this.state.passwordType === "password") {
            this.setState({
                passwordType: "text",
                hideAndShowLabelText: "Hide"

            })
        }
    }

    render() {
        return (
            <Modal dialogClassName="SetPass"
                show={this.props.showPasswordModal}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <RedInfoDialogue showRedInfoModal={this.state.redInfoModalShow} onCloseRedInfoModal={(proceed) => {
                    if (proceed === true) {
                        this.setState({
                            redInfoModalShow: false
                        }, () => {

                            this.props.showValidateMnemonicsScreen(true, "passwordReset")
                            this.props.history.push("/validate-mnemonics", { walletAddress: this.props.fromAddress, walletName: this.props.walletName })
                        })
                    }
                    else {

                        this.setState({
                            redInfoModalShow: false,
                        })
                    }
                }
                } />

                <Modal.Body>
                    <h4 className="text-center mb-5">{this.props.headerLabel}</h4>
                    <div class="form-group mb-4 position-relative">
                        <input autoFocus={true} onChange={this.onChange} name="password" className="form-control mb-2" placeholder="Enter Password" type={this.state.passwordType} />
                        <label style={{
                            cursor: "pointer", color: 'white',
                            position: 'absolute', top: '9px', right: '0px'
                        }} onClick={() => this.hideAndShowPassword("Password")}>{this.state.hideAndShowLabelText}</label>

                    </div>

                    {
                        this.state.isError ? <WarningMessageAlert message={this.state.message}></WarningMessageAlert> : null
                    }
                    {
                        this.state.isErrorAlert ? <ErrorMessageAlert message={this.state.message}></ErrorMessageAlert> : null
                    }
                    <button style={{ pointerEvents: this.state.disable }} onClick={this.submitPassword} type="button" class="btn btn-primary Submit">
                        {this.state.isLoading === true ? <img alt="loading..." style={{ height: '20px', width: '20px', marginRight: '8px' }} src="/assets/images/spinner3.gif"></img> : null}
                        Click To Transfer

                    </button>
                    <button style={{ pointerEvents: this.state.disable }} type="button" class="btn btn-secondary Cancel" onClick={this.closeModal}>Cancel</button>



                    <a href={() => false} className="float-right mt-3" style={{ color: 'white', pointerEvents: this.state.disable, cursor: 'pointer' }} onClick={() => {
                        this.setState({
                            redInfoModalShow: true
                        })
                    }}>
                        Reset Password
                    </a>
                </Modal.Body>
            </Modal>
        );

    }

}

SetPasswordForCoinTransfer.defaultProps = {
    headerLabel: "Set Password",

}

export default connect(null, { showValidateMnemonicsScreen })(SetPasswordForCoinTransfer)
