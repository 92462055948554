const STAGE = process.env.REACT_APP_STAGE || 'development';
console.log('REACT_APP_STAGE:', STAGE);

/*
  Common config are already in config object
  Rest of them will be added in below switch statement based on STAGE
*/

let config: any = {
  maxKYCFileSizeMB: 10,
  recipientAddress: '5FvS99c5uB7cQtJb44HE6oDnG6VELNX4h7zqvYzpLHFNJWNu',
  //recipientAddress: '5FWg8nF132VCf92JPgYHJmetcrJt4wSAXCkFVWSPtja8mu2b',
  validFileMimes: ['image/png', 'image/jpg', 'image/jpeg'],
  KYC_CHANNEL_SOURCE: `NU COIN APP - ${STAGE}`,
  KYC_CHANNEL_SOURCE_ADMIN: `NU COIN APP ADMIN PANEL - ${STAGE}`,
  goldMinerFees: 100000,
  silverMinerFees: 3000,
  usi: 86,
  minerRegistrationType: ["SILVER", "GOLD"],
  paymentType: ["PAYPAL", "BITPAY"],
  notifcationSender: "@NUCOIN",
  NotificationTitle: ["Wallet Created", "Miner Registered"],
  NotificationCategory: ["header"],
  roadMapApiUrl: 'https://api.airtable.com',
  roadMapAccessToken: 'keyT2qhsKgS1cD4WC',
  sudoWallet: "5DkB3fiQs4EkYTu4zZXZHz41FstXUu1mhL9ak9XfN5w9zJv8"


};

switch (STAGE) {
  case 'development':
    config = {
      ...config,
      //Artificial api url
      apiUrlAIIdentity: 'https://pyapitest.nucoin.net.au',
      dashboardMonitoringApiUrl: 'https://ainodesapitest.nucoin.net.au',

      // nucoin api local url
      //apiUrl: 'http://localhost:8000/api',
      //authNucoinServiceApiUrl: 'http://localhost:2000/api',
      // walletNucoinServiceApiUrl: 'http://localhost:4000/api',
      // minerNucoinServiceApiUrl: 'http://localhost:7000/api',
      //kycNucoinServiceApiUrl: 'http://localhost:12000/api',

      // nucoin api dev url
      apiUrl: 'https://apitest.nucoin.net.au/api',

      // authNucoinServiceApiUrl: 'http://localhost:2000/api',
      authNucoinServiceApiUrl: 'https://dev.authapi.nucoin.net.au/api',

      walletNucoinServiceApiUrl: 'https://dev.walletapi.nucoin.net.au/api',
      minerNucoinServiceApiUrl: 'https://dev.minerapi.nucoin.net.au/api',
      kycNucoinServiceApiUrl: 'https://dev.kycapi.nucoin.net.au/api',

      // blockchain api url
      mintBlockchainServiceApiUrl: 'https://dev.usimintapi.nucoin.net.au',
      walletBlockchainServiceApiUrl: 'https://dev.usiwalletapi.nucoin.net.au',
      // minerBlockchainServiceApiUrl: 'https://dev.usiminerapi.nucoin.net.au',
      minerBlockchainServiceApiUrl: 'http://localhost:8004',


      notificationUrl: 'https://dev.notificationapi.nucoin.net.au',
      socketConnectionUrl: "wss://dev.notificationapi.nucoin.net.au/ws/project",
      //projectId: "ecfdd151e22f29d1151f3cb20af22ddd",

      projectId: "7ab0e2f66c99d6724680eebbb81b829a",
      //projectId: "7622cb10d00bfb54c15e5ff615b23ee3",



      //projectId: "327b5566ebe3b29600530116de1814d9",



      domainName: "https://nucoin.net.au",
      // paypalPaymentURL: "http://localhost:3001/v1/api/payment/paypal",
      // paypalPaymentURL: "https://dev.payment.nftcitytest.com.au/v1/api/paypal/checkout",
      paypalPaymentURL: "https://dev.payment.nftcitytest.com.au/v1/api/bitpay/checkout",

      // explorer url
      explorerURL: "https://apiexplorer.nucoin.com.au/v1/api",

      bitpayData: {
        paymentURL: "https://dev.payment.nftcitytest.com.au/v1/api/bitpay/checkout",
        subscriber_id: 4,
        payment_subscription_id: 3
      },

      paypalData: {
        paymentURL: "https://dev.payment.nftcitytest.com.au/v1/api/paypal/checkout",
        subscriber_id: 2,
        payment_subscription_id: 1
      },

      dollarConvertorUrl: "https://dev.payment.nftcitytest.com.au/v1/api/rate/currency"
    };
    break;
  case 'stage':
    config = {
      ...config,
      //Artificial api url
      apiUrlAIIdentity: 'https://pyapitest.nucoin.net.au',
      dashboardMonitoringApiUrl: 'https://ainodesapitest.nucoin.net.au',

      // nucoin api local url
      //apiUrl: 'http://localhost:8000/api',
      //authNucoinServiceApiUrl: 'http://localhost:2000/api',
      // walletNucoinServiceApiUrl: 'http://localhost:4000/api',
      // minerNucoinServiceApiUrl: 'http://localhost:7000/api',
      // kycNucoinServiceApiUrl: 'http://localhost:12000/api',

      // nucoin api dev url
      apiUrl: 'https://stg.api.nucoin.net.au/api',
      authNucoinServiceApiUrl: 'https://stg.authapi.nucoin.net.au/api',
      walletNucoinServiceApiUrl: 'https://stg.walletapi.nucoin.net.au/api',
      minerNucoinServiceApiUrl: 'https://stg.minerapi.nucoin.net.au/api',
      kycNucoinServiceApiUrl: 'https://stg.kycapi.nucoin.net.au/api',

      // blockchain api url
      mintBlockchainServiceApiUrl: 'https://stg.usimint.nucoin.net.au',
      walletBlockchainServiceApiUrl: 'https://stg.usiwallet.nucoin.net.au/api',
      minerBlockchainServiceApiUrl: 'https://stg.usiminer.nucoin.net.au',

      // notification url
      notificationUrl: 'https://notificationapi.nucoin.com.au/api',
      socketConnectionUrl: "wss://notificationapi.nucoin.com.au/ws/project",
      projectId: "92f3838ec2b63d6a91a49efad2cac860",

      domainName: "https://stg.nucoin.net.au",
      paypalPaymentURL: "https://dev.payment.nftcitytest.com.au/v1/api/payment/paypal/nuc/checkout",

      // explorer url
      explorerURL: "https://apiexplorer.nucoin.com.au/v1/api"
    };
    break;

  case 'production':
    config = {
      ...config,

      //Artificial api url
      apiUrlAIIdentity: 'https://pyapi.nucoin.com.au',
      dashboardMonitoringApiUrl: 'https://ainodesapi.nucoin.com.au',
      // nucoin api url
      apiUrl: 'https://svcapi.nucoin.com.au/api',
       authNucoinServiceApiUrl: 'https://authapi.nucoin.com.au/api',
       walletNucoinServiceApiUrl: 'https://walletapi.nucoin.com.au/api',
      minerNucoinServiceApiUrl: 'https://minerapi.nucoin.com.au/api',
      kycNucoinServiceApiUrl: 'https://kycapi.nucoin.com.au/api',
      // kycNucoinServiceApiUrl: 'http://localhost:12000/api',



     // minerNucoinServiceApiUrl:'http://localhost:8000/api',
     // authNucoinServiceApiUrl: 'http://localhost:2000/api',
     // walletNucoinServiceApiUrl: 'http://localhost:4000/api',
      // apiUrl: 'http://localhost:8000/api',
      // kycNucoinServiceApiUrl: 'http://localhost:12000/api',






      // blockchain api url
      mintBlockchainServiceApiUrl: 'https://usimintapi.nucoin.com.au',
    //  mintBlockchainServiceApiUrl: 'http://192.168.100.5:8004',
      walletBlockchainServiceApiUrl: 'https://usiwalletapi.nucoin.com.au',
      minerBlockchainServiceApiUrl: 'https://usiminerapi.nucoin.com.au',

      

      //Notification Url
      notificationUrl: 'https://notificationapi.nucoin.com.au',
      socketConnectionUrl: "wss://notificationapi.nucoin.com.au/ws/project",
      projectId: "a1dc59183c6a3a1f725939bc5d363cb9",


      domainName: "https://nucoin.com.au",
      paypalPaymentURL: "https://payment.nucoin.com.au/v1/api/payment/paypal/nuc/checkout",

      // explorer url
      explorerURL: "https://api.explorer.nucoin.com.au/v1/api",

      bitpayData: {
        paymentURL: "https://payment.nucoin.com.au/v1/api/bitpay/checkout",
        subscriber_id: 3,
        payment_subscription_id: 3
      },

      paypalData: {
        paymentURL: "https://payment.nucoin.com.au/v1/api/paypal/checkout",
        subscriber_id: 2,
        payment_subscription_id: 2
      },

      dollarConvertorUrl: "https://payment.nucoin.com.au/v1/api/rate/currency"

    };
    break;
}
export default config;