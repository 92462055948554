// import { Modal } from "bootstrap";
import React from 'react';
import Modal from 'react-bootstrap/Modal'
//import { connect } from "react-redux";
import { toastMessageFailure } from '../../utils/toastMessage';
import { WarningMessageAlert,ErrorMessageAlert } from "../../utils/alert"
const CryptoJS = require("crypto-js");




class PasswordForMnemoncisRetreival extends React.Component {
    state = {
        password: "",
        isError: "",
        message: "",
        passwordType: "password",
        hideAndShowLabelText: "Show",
        isErrorAlert:false
    }


    submitPassword = async () => {



        if (this.state.password === '' || this.state.password === null) {
            return this.setState({ isError: true,isErrorAlert:false, message: 'Password is required!' });
        }
        // check if from address exist in local
        let fromExist = localStorage.getItem(this.props.fromAddress)
        //alert(fromExist)

        if (fromExist) {
            try {
                const encryptedData = localStorage.getItem(this.props.fromAddress)

                // decrypt data using password
                var mnemonicsValue = await CryptoJS.enc.Utf8.stringify(CryptoJS.AES.decrypt(encryptedData, this.state.password))



                // if we got the mnemoncs
                if (mnemonicsValue) {
                    //clear all the state
                    this.setState({
                        password: "",
                        isError: "",
                        message: "",
                        passwordType: "password",
                        hideAndShowLabelText: "Show"

                    }, () => {
                        this.props.onClosePasswordForMnemoncisRetreivalModal(true, mnemonicsValue)

                    })



                }
                // if got null in decrypting
                else {
                    this.setState({ isError: true,isErrorAlert:false, message: "You are entering a wrong password" })
                }
            }
            // if we got mulformed in decrypting
            catch (e) {
                this.setState({ isError: true,isErrorAlert:false, message: "You are entering a wrong password" })
            }
        }
        else {

            toastMessageFailure("We do apologize, your private key (mnemonics) are not available on this device, please try this feature on the device where you have created your wallet for the first time")
        }


    }

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
            [e.target.name.concat('error')]: '',
        })
    }


    hideAndShowPassword = () => {
        if (this.state.passwordType === "text") {

            this.setState({
                passwordType: "password",
                hideAndShowLabelText: "Show"

            })
        }
        else if (this.state.passwordType === "password") {
            this.setState({
                passwordType: "text",
                hideAndShowLabelText: "Hide"

            })
        }
    }

    render() {
        return (
            <Modal dialogClassName="SetPass"
                show={this.props.showPasswordForMnemoncisRetreivalModal}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >


                <Modal.Body>


                    <h4 className="text-center mb-5">Enter Password</h4>
                    <div class="form-group mb-4 position-relative">
                        <input autoFocus={true} onChange={this.onChange} name="password" className="form-control mb-2" placeholder="Enter Password" type={this.state.passwordType} />
                        <label style={{
                            cursor: "pointer", color: 'white',
                            position: 'absolute', top: '9px', right: '0px'
                        }} onClick={() => this.hideAndShowPassword("Password")}>{this.state.hideAndShowLabelText}</label>

                    </div>

                    {
                        this.state.isError ? <WarningMessageAlert message={this.state.message}></WarningMessageAlert> : null
                    }
                    {
                        this.state.isErrorAlert ? <ErrorMessageAlert message={this.state.message}></ErrorMessageAlert> : null
                    }
                    <button onClick={this.submitPassword} type="button" class="btn btn-primary Submit">

                        Proceed

                        </button>
                    <button type="button" class="btn btn-secondary Cancel" onClick={() => {
                        this.setState({
                            password: "",
                            isError: "",
                            message: "",
                            passwordType: "password",
                            hideAndShowLabelText: "Show"

                        }, () => {
                            this.props.onClosePasswordForMnemoncisRetreivalModal(false, "")
                        })
                    }}>Cancel</button>





                </Modal.Body>
            </Modal >
        );

    }

}


export default PasswordForMnemoncisRetreival
