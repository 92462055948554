
import React from 'react';
import Modal from 'react-bootstrap/Modal'


class SelectPaypmentMethodModal extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            paymentType: "",
        }
    }
    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })

    }

    submitPaymentType = async () => {
        // alert(this.state.paymentType)
        this.props.onProceddPaymentMethodModal(this.state.paymentType)

    }



    render() {

        return (

            <Modal dialogClassName="SetPass text-center"
                show={this.props.showPaymentMethodModal}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >

                <Modal.Body>

                    <h4 class="text-center mb-3">Select Payment Method</h4>
                    <ul style={{ color: 'white' }}>

                    </ul>
                    <div name="paymentType" onChange={this.onChange} className="form-group d-flex align-items-center">
                        <div className="custom_radio">
                            <input className="d-none" type="radio" id="Gold" name="paymentType" value="PAYPAL" /> <label for="Gold">Paypal</label>
                        </div>
                        <div className="custom_radio">
                            <input className="d-none" type="radio" id="Silver" name="paymentType" value="BITPAY" /> <label for="Silver">BitPay</label>
                        </div>
                    </div>

                    <button disabled={this.state.disabled} onClick={this.submitPaymentType} type="button" className="btn btn-primary Submit">
                        {/* {this.state.isLoading === true ? <img alt="loading..." style={{ height: '20px', width: '20px', marginRight: '8px' }} src="/assets/images/spinner3.gif"></img> : null} */}
                        PROCEED</button>
                    <button disabled={this.state.disabled} type="button" class="btn btn-secondary Cancel" onClick={this.props.onClosePaymentMethodModal}>Cancel</button>


                </Modal.Body>
            </Modal>

        );

    }

}
export default SelectPaypmentMethodModal
