// import { Modal } from "bootstrap";
import React from 'react';
import Modal from 'react-bootstrap/Modal'
import ErrorOutline from '@material-ui/icons/ErrorOutline';
import { Link } from "react-router-dom";
export default class GoToLoginAlert extends React.Component {

    render() {
        return (
            <Modal dialogClassName="ErrorAlert text-center"
                show={this.props.showGoToLoginModal}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <ErrorOutline />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h4>{this.props.title}</h4>
                    <p>{this.props.message}</p>
                    <Link style={{
                        borderRadius: '3px',
                        color: '#fff',
                        background: '#6C757D',
                        padding: '10px 30px',
                        display: 'inlineBlock',
                        marginRight: '10px',
                    }} type="button" onClick={this.props.onCloseGoToLoginModal} to="#" >Close</Link>

                    <Link className="Read-More" to="/login" >Go To Login</Link>


                </Modal.Body>
            </Modal>

        )
    }


}