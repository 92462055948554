

import JSEncrypt from 'jsencrypt';
import { CERTIFICATE_PUB } from '../security/certificate';
const { decodeAddress, encodeAddress } = require('@polkadot/keyring');
const { hexToU8a, isHex } = require('@polkadot/util');
const jsEncrypt = new JSEncrypt();
jsEncrypt.setPublicKey(CERTIFICATE_PUB);


export const isValidAddressPolkadotAddress = (address) => {
  try {
    encodeAddress(
      isHex(address)
        ? hexToU8a(address)
        : decodeAddress(address)
    );
    return true;
  } catch (error) {
    return false;
  }
};

export function encrypt(message) {
 
  if (typeof message === 'object') return { data: jsEncrypt.encrypt(JSON.stringify(message)) };
  else return jsEncrypt.encrypt(message);
}



export const getMinerFeesStatusInteger = minerFeesString => {
  switch (minerFeesString) {
    case 'Fees not received':
      return 0;

    case 'Fees received':
      return 1;

    case 'Fees exempted':
      return 2;

    default:
      return 0;
  }
}

export const getMinerFeesStatusString = (minerFeesInteger) => {
  switch (minerFeesInteger) {
    case 0:
      return 'Fees not received';

    case 1:
      return 'Fees received'

    case 2:
      return 'Fees exempted'

    default:
      return 'Fees not received'
  }
}

export const isFunction = func => {
  return func && typeof func === 'function';
}

export const customerAddressTableColumnsConfig = () => {
  return [
    { field: 'address_line1', headerName: 'Address Line 1', width: 175, editable: true },
    { field: 'address_line2', headerName: 'Address Line 2', width: 175, editable: true },
    { field: 'city_town_suburb', headerName: 'City/Town/Suburb', width: 175, editable: true },
    { field: 'state_province', headerName: 'State/Province', width: 175, editable: true },
    { field: 'post_code', headerName: 'Post Code', width: 175, type: 'number', editable: true },
    { field: 'country', headerName: 'Country', width: 175, editable: true },
    { field: 'region', headerName: 'Region', width: 175, editable: true },
    { field: 'address_type', headerName: 'Address Type', width: 175, editable: true },
  ]
}

export const customerBasicInfoTableColumnsConfig = () => {
  return [
    { field: 'first_name', headerName: 'First Name', width: 160, editable: true },
    { field: 'middle_name', headerName: 'Middle Name', width: 160, editable: true },
    { field: 'last_name', headerName: 'Last Name', width: 160, editable: true },
    { field: 'mobile_number', headerName: 'Mobile Number', width: 175, editable: true },
    { field: 'email', headerName: 'Email', width: 225, editable: true },
    { field: 'user_country', headerName: 'Country', width: 150, editable: true },
    { field: 'gender', headerName: 'Gender', width: 150, editable: true },
    { field: 'account_status', headerName: 'Account Status', width: 175, editable: true },
    { field: 'is_verified', headerName: 'Is Verified?', width: 150, type: 'number', editable: true },
    { field: 'miner_fees_received', headerName: 'Miner Fees Received?', width: 225 },
    { field: 'toggle_login_otp', headerName: 'Toggle Login OTP', width: 200 },
    { field: 'toggle_kyc', headerName: 'Toggle KYC', width: 175 },
    { field: 'create_time', headerName: 'Create Time', width: 225 },
    { field: 'update_time', headerName: 'Update Time', width: 225 },
  ]
}

export const customerInfoNonEditablePanelColumnsConfig = () => {
  return [
    { field: 'id', headerName: 'ID', width: 180 },
    { field: 'user_name', headerName: 'Username', width: 180 },
    { field: 'first_name', headerName: 'First Name', width: 180 },
    { field: 'middle_name', headerName: 'Middle Name', width: 180 },
    { field: 'last_name', headerName: 'Last Name', width: 180 },
    { field: 'mobile_number', headerName: 'Mobile Number', width: 180 },
    { field: 'platform_miner_category', headerName: 'Platform Miner Category', width: 250 },
    { field: 'wallets_count', headerName: 'Wallets', width: 150 },
    { field: 'email', headerName: 'Email', width: 250 },
    { field: 'country_name', headerName: 'Country', width: 180 },
    { field: 'gender', headerName: 'Gender', width: 180 },
    { field: 'account_status', headerName: 'Account Status', width: 180 },
    { field: 'isVerified', headerName: 'Is Verified?', width: 180 },
    { field: 'miner_fees_received', headerName: 'Miner Fees Received?', width: 225 },
    { field: 'create_time', headerName: 'Create Time', width: 225 },
    { field: 'update_time', headerName: 'Update Time', width: 225 },
  ]
};

export const endOfDayRewardTableColumnsConfig = () => {
  return [
    { field: 'id', headerName: 'Process ID', width: 150 },
    { field: 'user_name', headerName: 'Username', width: 150 },
    { field: 'first_name', headerName: 'First Name', width: 150 },
    { field: 'last_name', headerName: 'Last Name', width: 180 },
    { field: 'mobile_number', headerName: 'Mobile Number', width: 180 },
    { field: 'account_desc', headerName: 'Account Description', width: 250 },
    { field: 'process_desc', headerName: 'Process Description', width: 900 },
    { field: 'account_id', headerName: 'Account ID', width: 550 },
    { field: 'current_balance', headerName: 'Current Balance', width: 250 },
    { field: 'reward_value', headerName: 'Reward Value', width: 250 },
    { field: 'block_id', headerName: 'Block ID', width: 250 },
    { field: 'block_hash', headerName: 'Block Hash', width: 550 },
    { field: 'create_time', headerName: 'Create Time', width: 250 },
    { field: 'source_block_hash', headerName: 'Source Block Hash', width: 550 },
  ]
}

export const platformMinerTableColumnsConfig = () => {
  return [
    { field: 'id', headerName: 'ID', width: 150 },
    { field: 'user_name', headerName: 'Username', width: 150 },
    { field: 'account_id', headerName: 'Account ID', width: 550 },
    { field: 'category', headerName: 'Category', width: 150 },
    { field: 'miner_account_status', headerName: 'Miner Account Status', width: 250 },
    { field: 'usi_limit', headerName: 'USI Limit', type: 'number', width: 150 },
    { field: 'create_time', headerName: 'Create Time', width: 200 },
    { field: 'c_local_time', headerName: 'Create Local Time', width: 200 },
    { field: 'update_time', headerName: 'Update Time', width: 200 },
  ]
}

export const platformMinerBookingTableColumnsConfig = () => {
  return [
    { field: 'id', headerName: 'ID', width: 150 },
    { field: 'user_name', headerName: 'Username', width: 150 },
    { field: 'category', headerName: 'Category', width: 150 },
    { field: 'create_time', headerName: 'Create Time', width: 200 },
    { field: 'c_local_time', headerName: 'Create Local Time', width: 200 },
    { field: 'update_time', headerName: 'Update Time', width: 200 },
  ]
}