import React, { Component } from "react";

import './style.css'
import { WebcamCapture } from './Webcam'
import Modal from 'react-bootstrap/Modal'
import { toastMessageFailure } from '../../../utils/toastMessage';

class WebcamModal extends Component {
    state = {
        imagePath:""
    }

    closeModal = () => {
        this.props.onCloseWebcamModal()
        this.resetState()
    }
    resetState = ()=>{
        this.setState({
            imagePath:""
        })
    }
    onProceedModal = () => {
        if(!this.state.imagePath){
            toastMessageFailure("Please provide a selfie image to proceed further.")
            
        }
        else{
        this.props.onProceedWebcamModal(this.state.imagePath)
        this.resetState()
        }
    }
    render() {
        return (

            <Modal style={{ marginTop: 12, backgroundColor: 'black' }} dialogClassName="SetPass"
                show={this.props.showWebcamModal}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >

                <Modal.Body style={{


                }}>

                    <div className="home-container">

                        <div className="text">

                            <form className="form">
                                <WebcamCapture onImageCapture={(path) => {
                                    this.setState({imagePath:path})

                                }} />




                            </form>
                        </div>

                    </div>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <button onClick={this.closeModal} type="button" class="btn btn-secondary Cancel" >Cancel</button>

                        <button onClick={this.onProceedModal} type="button" className="btn btn-primary Submit">

                            PROCEED</button>

                    </div>
                </Modal.Body>
            </Modal>
        )
    }
}
export default WebcamModal