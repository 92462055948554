// import { Modal } from "bootstrap";
import React from 'react';
import Modal from 'react-bootstrap/Modal'
import InfoOutlined from '@material-ui/icons/InfoOutlined';
import DeleteIcon from '@material-ui/icons/Delete';
import InfoSuccess from "./InfoSuccess"
import { toastMessageFailure } from '../../utils/toastMessage';
import { logOutUser } from '../../actions/auth';
import { connect } from "react-redux";
import { makeWalletAutoMint } from '../../api/wallet/wallet';

class BecomeAutoMinterModal extends React.Component {
    state = {
        loading: false,
        disable: false,

    }

    closeModal = (value, mintValue) => {
        this.props.onCloseYesOrNoModal(value,mintValue)
    }
    makeWalletMintable = async () => {


        try {
            this.setState({ loading: true, disable: true })
            let { data } = await makeWalletAutoMint({ walletAddress: this.props.walletAddress })
            this.setState({ loading: false, disable: false })
            this.closeModal(true, data.data)
        }
        catch (err) {
            this.setState({ loading: false, disable: false })

            if (err?.response?.status === 403) {
                this.props.logOutUser(err?.response?.status)
            }
            else if (err.message === "Network Error" || err?.response?.status === 500) {
                toastMessageFailure("Sorry! We are facing some network issue.Please try again later.")

            }
            else if (err?.response?.data) {
                toastMessageFailure(err?.response?.data?.errors[0]?.message)

            }
        }
















    }


    render() {
        return (
            <Modal dialogClassName="InfoSuccess text-center"
                show={this.props.visible}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >

                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <InfoOutlined />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{
                    wordWrap: 'break-word',
                    paddingLeft: '16px',
                    paddingRight: '16px'
                }}>

                    <p style={{fontWeight:"500"}}>{this.props.msg}</p>
                    <p>Are you sure you want to continue?</p>
                    <button disabled={this.state.disable} type="button" class="btn btn-secondary Cancel" onClick={this.makeWalletMintable} style={{ marginRight: '10px' }}>

                        {this.state.loading ? <img alt="loading..." style={{ height: '20px', width: '20px', marginRight: '8px' }} src="/assets/images/spinner3.gif"></img> : null}


                        Yes


                    </button>
                    <button disabled={this.state.disable} type="button" class="btn btn-secondary Submit " onClick={() => { this.closeModal(false,0) }}>No</button>
                </Modal.Body>
            </Modal >
        )
    }


}


const mapStateToProps = (state) => ({

    auth: state.authReducer,
})

export default connect(mapStateToProps, { logOutUser })(BecomeAutoMinterModal)


