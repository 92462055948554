import React from 'react';
import Modal from 'react-bootstrap/Modal'
import PinInput from 'react-pin-input';
import { confirmPaymentPOSTerminal } from '../../../../api/pos/pos';

export default class ConfirmPaymentModal extends React.Component {

    state = {
        pin: '',
        isLoading: false,
        response: {}
    }

    async submit() {
        try {
            this.setState({ ...this.state, isLoading: true });
            const { data } = await confirmPaymentPOSTerminal({
                username: this.props.userDetail.user_name,
                items: this.props.items,
                pin: this.state.pin,
                terminal_wallet: localStorage.getItem('lastSelectWallet')
            });

            this.setState({ ...this.state, isLoading: false, response: data })
        } catch (e) {
            this.setState({ ...this.state, isLoading: false, response: { message: e.response && e.response.data && e.response.data.msg ? e.response.data.msg : e.message } })
        }
    }

    render() {
        return (
            <Modal
                dialogClassName="SetPass"
                show={this.props.showConfirmModal}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header>
                    <Modal.Title className="text-center WhiteColor" id="contained-modal-title-vcenter">
                        Payment Confirmation
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h5 className='WhiteColor'>Products:</h5>
                    {
                        this.props.items.map(item => (
                            <div>
                                <label style={{ color: "#1ff8f2" }}>{item.title} x 1 - {item.price} NUC</label><br/>
                            </div>
                        ))
                    }
                    <h5 className='WhiteColor'>Total Bill:</h5>
                    <p style={{ color: "#1ff8f2" }}>{this.props.totalBill} NUC</p>
                    <h5 className='WhiteColor'>User Wallet</h5>
                    <label style={{ color: "#1ff8f2" }}>Account: {this.props.userDetail.account_id}</label>
                    <label style={{ color: "#1ff8f2" }}>Balance: {this.props.userDetail.balance} NUC</label>
                    <h5 className='WhiteColor'>POS Terminal Wallet</h5>
                    <label style={{ color: "#1ff8f2" }}>Account Name: {localStorage.getItem('lastSelectWalletTitle') || 'N/A'}</label>
                    <br />
                    <label style={{ color: "#1ff8f2" }}>Account: {localStorage.getItem('lastSelectWallet') || 'N/A'}</label>
                    {
                        !this.state.response.blockHash && (
                            <div className="container-fluid">
                                <div className="row">
                                    <div style={{ width: '100%' }} className="text-center">
                                        <h5 className='WhiteColor' style={{ marginTop: '15px' }}>Enter PIN to Confirm</h5>
                                        <div className="mb-3">
                                            <PinInput
                                                length={4}
                                                initialValue=""
                                                secret
                                                onChange={(value, index) => {
                                                    this.setState({ ...this.state, pin: value });
                                                }}
                                                type="numeric"
                                                inputMode="number"
                                                style={{ padding: '10px', width: '100%' }}
                                                inputStyle={{ borderColor: '#7471d8', color: "#fff" }}
                                                inputFocusStyle={{ borderColor: 'blue' }}
                                                onComplete={(value, index) => { }}
                                                autoSelect={true}
                                                regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                    {
                        this.state.response.message && (
                            <div className="alert alert-danger">
                                <div className="text-center">
                                    <label className="text-danger text-center m-0" style={{ fontSize: '18px' }}>{this.state.response.message}</label>
                                </div>
                            </div>
                        )
                    }
                    {
                        this.state.response.blockHash && (
                            <div className='mb-4'>
                                <h5 className='WhiteColor text-center mb-4' style={{ marginTop: '15px' }}>Payment Reciept</h5>
                                <label style={{ color: "#1ff8f2" }}>Transaction hash <a href={`https://explorer.nucoin.com.au/block/${this.state.response.blockHash}`} target="_blank">{this.state.response.blockHash}</a></label><br/>
                                <label style={{ color: "#1ff8f2" }}>Amount {this.state.response.data.amount} NUC</label>
                                <label style={{ color: "#1ff8f2" }}>User Account {this.state.response.data.from}</label>
                                <label style={{ color: "#1ff8f2" }}>POS Terminal {this.state.response.data.to}</label>
                            </div>
                        )
                    }

                    <div style={{ width: '100%' }} className="text-center">
                        <button style={{ marginRight: '10px' }} type="button" className="btn btn-secondary Cancel" onClick={() => this.props.closeModal()}>Cancel</button>
                        {!this.state.response.blockHash && <button disabled={this.state.pin.length < 4} type="button" className="btn btn-primary Submit" onClick={this.submit.bind(this)}> {this.state.isLoading ? <img alt="loading..." style={{ height: '20px', width: '20px', marginRight: '8px' }} src="/assets/images/spinner3.gif"></img> : ''} Confirm</button>}
                        {
                            this.state.response.blockHash && <button onClick={() => {
                                this.props.closeModalAndRedirect();
                            }} type="button" class="btn btn-secondary Cancel" >Redirect to Scan</button>
                        }
                    </div>
                </Modal.Body>
            </Modal>
        )
    }
}