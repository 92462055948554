// import { Modal } from "bootstrap";
import React from 'react';
import Modal from 'react-bootstrap/Modal'
class MinerRegistrationInfo extends React.Component {
    render() {
        return (
            <Modal dialogClassName="SetPass"
                show={this.props.showMinerRegistrationModal}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body>
                    <h4 class="text-center mb-3">Book Miner Slot</h4>
                    <p style={{ color: 'white' }}>To register a wallet, you need to become a miner by choosing 1 membership category (SILVER / GOLD)</p>
                    <button onClick={() => { this.props.onCloseMinerRegistrationInfoModal(true) }} type="button" className="btn btn-primary Submit">PROCEED</button>
                    <button type="button" class="btn btn-secondary Cancel" onClick={() => { this.props.onCloseMinerRegistrationInfoModal(false) }}>Cancel</button>


                </Modal.Body>
            </Modal>
        );

    }

}


export default MinerRegistrationInfo
