import invoke from '../../utils/invoke';
import { encrypt } from '../../utils/utils';
import config from '../../_config';

export const getUserWallet = () => {

    return invoke({
        method: 'GET',
        //baseURL: config.apiUrl,
        baseURL: config.walletNucoinServiceApiUrl,
        route: 'v1/nucoin/wallet/get-user-wallet-details',
        headers: {
            Authorization: localStorage.getItem('jwtToken')
        }
    });
}

export const storeWallet = (data) => {

    data = encrypt(data);
    return invoke({
        method: 'POST',
        // baseURL: config.apiUrl,
        baseURL: config.walletNucoinServiceApiUrl,
        route: 'v1/nucoin/wallet/store-customer-wallet',
        data: data,
        headers: {
            Authorization: localStorage.getItem('jwtToken')
        }
    });
}

export const getCustomerWalletCount = () => {


    return invoke({
        method: 'GET',
        //baseURL: config.apiUrl,
        baseURL: config.walletNucoinServiceApiUrl,
        route: 'v1/nucoin/wallet/check-customer-wallet',

        headers: {
            Authorization: localStorage.getItem('jwtToken')
        }
    });
}


export const addAirdrop = (data) => {

    data = encrypt(data);
    return invoke({
        method: 'POST',
        //baseURL: config.apiUrl,
        baseURL: config.walletNucoinServiceApiUrl,
        route: `v1/nucoin/wallet/add-airdrop-wallet`,
        data: data,

        headers: {
            Authorization: localStorage.getItem('jwtToken')
        }
    });
}

export const fetchExistingReferralInfo = username => {
    return invoke({
        method: 'GET',
        baseURL: config.apiUrl,
        route: `referral_code/${username}`,
        headers: {
            Authorization: localStorage.getItem('jwtToken')
        }
    });
};

export const generateAndStoreReferralCodeWallet = data => {
    return invoke({
        method: 'POST',
        baseURL: config.apiUrl,
        route: `referral_code`,
        data: data,
        headers: {
            Authorization: localStorage.getItem('jwtToken')
        }
    });
};

export const updateReferralWallet = data => {
    return invoke({
        method: 'PUT',
        baseURL: config.apiUrl,
        route: `referral_code`,
        data: data,
        headers: {
            Authorization: localStorage.getItem('jwtToken')
        }
    });
};

export const fetchUserReferenceCode = () => {
    return invoke({
        method: 'GET',
        baseURL: config.walletNucoinServiceApiUrl,
        route: `v1/nucoin/wallet/get-reference-code`,
        headers: {
            Authorization: localStorage.getItem('jwtToken')
        }
    });
};

export const addEncryptedWallet = (data) => {
    //  data = encrypt(data);
    return invoke({
        method: 'POST',
        baseURL: config.walletNucoinServiceApiUrl,
        route: `v1/nucoin/wallet/add-encrypted-wallet`,
        data: data,
        headers: {
            Authorization: localStorage.getItem('jwtToken')
        }
    });
};

export const getShopRegisterWallet = () => {
    //  data = encrypt(data);
    return invoke({
        method: 'GET',
        baseURL: config.walletNucoinServiceApiUrl,
        route: `v1/nucoin/wallet/check-shop-wallet-already-register`,

        headers: {
            Authorization: localStorage.getItem('jwtToken')
        }
    });
};


export const getPaymentRegisterWalletProfile = (username) => {
    //  data = encrypt(data);
    return invoke({
        method: 'GET',
        baseURL: config.apiUrl,
        route: `v1/payment/user/profile/${username}`,

        headers: {
            Authorization: localStorage.getItem('jwtToken')
        }
    });
};


export const makeWalletAutoMint = (data) => {

    data = encrypt(data);
    return invoke({
        method: 'POST',
        // baseURL: config.apiUrl,
        baseURL: config.walletNucoinServiceApiUrl,
        route: 'v1/nucoin/wallet/make-wallet-auto-mintable',
        data: data,
        headers: {
            Authorization: localStorage.getItem('jwtToken')
        }
    });
}

export const getPendingUser = (data) => {
    // data = encrypt(data);

    return invoke({
        method: 'POST',
        // baseURL: config.apiUrl,
        baseURL: config.walletNucoinServiceApiUrl,
        route: 'v1/nucoin/wallet/pending-user',
        data: data,
        headers: {
            Authorization: localStorage.getItem('jwtToken')
        }
    });
}

export const updateFundingWallet = (data) => {
    // data = encrypt(data);

    return invoke({
        method: 'POST',
        // baseURL: config.apiUrl,
        baseURL: config.walletNucoinServiceApiUrl,
        route: 'v1/nucoin/wallet/update-funding-status',
        data: data,
        headers: {
            Authorization: localStorage.getItem('jwtToken')
        }
    });
}

export const transferLimitCheck = (data) => {
    // data = encrypt(data);

    return invoke({
        method: 'POST',
        // baseURL: config.apiUrl,
        baseURL: config.walletNucoinServiceApiUrl,
        route: 'v1/nucoin/wallet/check-transfer-limit',
        data: data,
        headers: {
            Authorization: localStorage.getItem('jwtToken')
        }
    });
}


export const updateTransferCoinLimit = (data) => {
    // data = encrypt(data);

    return invoke({
        method: 'POST',
        // baseURL: config.apiUrl,
        baseURL: config.walletNucoinServiceApiUrl,
        route: 'v1/nucoin/wallet/update-transfer-limit',
        data: data,
        headers: {
            Authorization: localStorage.getItem('jwtToken')
        }
    });
}

