// import { Modal } from "bootstrap";
import React from 'react';
import Modal from 'react-bootstrap/Modal'
import CheckCircle from '@material-ui/icons/CheckCircle';
import { Link } from "react-router-dom";
export default class ExchangeKycModal extends React.Component {

    render() {
        return (
            <Modal dialogClassName="InfoSuccess text-center"
                show={this.props.showExchangeModal}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <CheckCircle />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{

                    overflow: 'hidden',
                    paddingLeft: '16px'
                }}>

                    <p>{this.props.message}</p>
                    <h4>Please Note....</h4>
                    <p>{this.props.exchangeMessage}</p>
                    {/* <button type="button" class="btn btn-secondary Cancel" onClick={this.props.onOpenKyc}>Go To Kyc</button> */}

                    <Link style={{
                        borderRadius: '3px',
                        color: '#fff',
                        background: '#6C757D',
                        padding: '10px 30px',
                        display: 'inlineBlock',
                        marginRight: '10px',
                    }} type="button" onClick={this.props.onCloseExcahngeModal} to="#" >Close</Link>
                    {/* <button type="button" class="btn btn-secondary Cancel" onClick={this.props.onCloseExcahngeModal}>Close</button> */}
               
               
                    <Link className="Read-More" to="/kyc"
                        onClick={this.props.onOpenKyc}



                    >Go To Kyc</Link>
               
               
                </Modal.Body>
            </Modal>
        )
    }


}