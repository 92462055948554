import { Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

const PublicRoute = ({ component: Component, auth, ...rest }) => (
    <Route
        render={(props) => {
            if (auth.maintanceStatus === "true") {

                return <Redirect to={`/maintenance`} />
            }

            if (!auth.isAuthenticated) {
                return <Component {...props} />
            } else {
              
                if (auth.term === 0) {
                    return <Redirect to={`term-and-condition`} />
                }
                else {
                    return <Redirect to={`/`} />
                }
            }
        }}
        {...rest}
    />
)

PublicRoute.propTypes = {
    auth: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => ({

    auth: state.authReducer,
})

export default connect(mapStateToProps)(PublicRoute)
