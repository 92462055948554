import React, { Component } from "react";
import { fetchUserReferenceCode } from "../../api/wallet/wallet";
import Footer from "../Layout/Footer";
import ContentCopyIcon from '@material-ui/icons/FileCopy';
import { toastMessageInfo } from "../../utils/toastMessage";
import { getBankAccountDetailsApi, getBunddleDetailsApi } from "../../api/general/general";
import { connect } from "react-redux";
import LoadingOverlay from "../../Loader";

class BecomeMiner extends Component {

    state = {
        bundleDetails: [],
        bankAccounts: [],
        referenceCode: null,
        incremental: 1,
        selectedPackage: -1,
        refernceLoader: false,
        isLoading: false
    }

    async componentDidMount() {
        // return;
        try {
            this.setState({ refernceLoader: true, isLoading: true });
            let userReferenceCode = await fetchUserReferenceCode();
            this.setState({ ...this.state, refernceLoader: false, referenceCode: userReferenceCode?.data?.data?.referenceCode, isLoading: false })
        } catch (e) {
            localStorage.clear();
            // window.location.href = "/";
            this.setState({ ...this.state, isLoading: false, refernceLoader: false });
            // this.setState({ refernceLoader: false, referenceCode: userReferenceCode?.data?.data?.referenceCode })
        }

        getBankAccountDetailsApi().then(({ data }) => {
            this.setState({ ...this.state, bankAccounts: data.data });
        }).then(e => { });

        getBunddleDetailsApi().then(({ data }) => {
            this.setState({ ...this.state, bundleDetails: data.data, selectedPackage: data.data.length > 0 ? 0 : -1 });
        }).catch(e => { 
            this.setState({ ...this.state, bundleDetails: [
                { account_category: 'SILVER', account_establishment_fees: '5000', currency_symbol: 'AUD' },
                { account_category: 'GOLD', account_establishment_fees: '70000', currency_symbol: 'AUD' },
                { account_category: 'S-BUNDLE', account_establishment_fees: '25000', currency_symbol:'AUD' },
                { account_category: 'DIAMOND', account_establishment_fees: '50000', currency_symbol: 'AUD' }

            ], selectedPackage: 0 })
        });
    }

    copyReferenceCode = async () => {
        const el = document.createElement('textarea');
        el.value = this.state.referenceCode;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        toastMessageInfo("Payment reference code copied!")
    }

    render() {
        // if(this.state.isLoading)
        // return <LoadingOverlay></LoadingOverlay>;

        return (
            <>
                { this.state.isLoading && <LoadingOverlay></LoadingOverlay> }

                <div className="BlackStarsBack">
                    <div id="Blackstars" />
                    <div id="Blackstars2" />
                    <div id="Blackstars3" />
                </div>
                <div className="row" id="zIndexMob">
                    <div className="col-md-6 offset-md-3">
                        <div className="AccountAddress text-center">
                            <div className="MneMonics">
                                <h4 className="text-center">Payment and Bundle Details</h4>
                                {/* <p style={{ marginTop: '-20px', color: 'white', fontSize: '12px' }}>Please copy your payment reference code below, and add it as your bank transaction reference.</p>
                                <ul>
                                    <div>
                                        <p style={{ color: "white" }} className="mb-4" placeholder="Confirm wallet Password" ><span onClick={this.copyReferenceCode} style={{ marginLeft: '15px', color: 'white', cursor: 'pointer' }}>{this.state.refernceLoader === true ? <img alt="loading..." style={{ height: '20px', width: '20px', marginRight: '8px' }} src="/assets/images/spinner3.gif"></img> : <span style={{ fontWeight: "bold" }}>{" "}{this.state.referenceCode}</span>} {!this.state.refernceLoader && <ContentCopyIcon style={{ color: 'white', marginLeft: '5px' }} />}</span>
                                        </p>
                                    </div>
                                </ul> */}
                                <h5 className="mb-3" style={{ textAlign: "left", color: "#fff" }}>Choose Membership Type</h5>
                                <div name="minerType" onChange={this.onChange} className="form-group text-left">
                                    {
                                        this.state.bundleDetails.map((item, index) => (
                                            <div className="row">

                                                
                                                <div className="col-6 mt-3">
                                                    <div className="custom_radio mb-2">
                                                        <input onClick={() => {
                                                            this.setState({ ...this.state, selectedPackage: index, incremental: 1 });
                                                        }} className={this.state.selectedPackage === index ? "d-none" : 'd-none'} type="radio" defaultChecked={index === 0 ? true : false} id={item.account_category} name="minerType" value={item.account_category} /> <label for={item.account_category}>{item.account_category} - {item.account_establishment_fees} {item.currency_symbol}</label>
                                                    </div>
                                                </div>
                                                {
                                                    (item.account_category === 'S-BUNDLE' || item.account_category === 'DIAMOND') ? (
                                                        <div className="col-6">
                                                            <input defaultValue={1} disabled={this.state.selectedPackage !== index} onChange={(e) => {
                                                                e.preventDefault();
                                                                this.setState({ ...this.state, incremental: e.target.value })
                                                            }} min="1" max="10" type="number" className="form-control" style={{ background: "transparent", border: "none", borderBottom: "1px solid #fff", color: "#fff", borderRadius: "0px" }} />
                                                        </div>
                                                    ) : null
                                                }
                                                
                                            </div>
                                        ))
                                    }
                                </div>
                                <div className="row">
                                    <h5 className="mb-3" style={{ textAlign: "left", color: "#fff", marginTop: '12px' }}>Total to be Paid: {this.state.selectedPackage !== -1 ? `${this.state.bundleDetails[this.state.selectedPackage].account_establishment_fees * (this.state.incremental || 1)} ${this.state.bundleDetails[this.state.selectedPackage].currency_symbol}` : '' }</h5>
                                </div>
                                <hr style={{ borderColor: "#fff" }} />
                                {/* {
                                    !this.state.referenceCode && (
                                        <div className="row text-left">
                                            <div className="col-12"><p className="mb-1 text-center" style={{ color: "white" }}>Login to get payment reference code</p></div>
                                        </div>
                                    )
                                } */}
                                {
                                    <div style={{ display: this.state.referenceCode ? '' : 'none' }}>
                                        <h5 className="mb-3" style={{ textAlign: "left", color: "#fff" }}>Bank Details</h5>
                                        <div name="minerType" onChange={this.onChange} className=" d-flex align-items-center mb-20">
                                            {
                                                this.state.bankAccounts.map((item, index) => (
                                                    <div className="container-fluid">
                                                        <div className="row text-left">
                                                            <div className="col-4"><p className="mb-1" style={{ color: "white", fontWeight: "bold" }}>Bank Name: </p></div><div className="col-8"><p className="mb-1" style={{ color: "white" }}>{item.bank_institution_name}</p></div>
                                                        </div>
                                                        <div className="row text-left">
                                                            <div className="col-4"><p className="mb-1" style={{ color: "white", fontWeight: "bold" }}>Country:</p></div><div className="col-8"><p className="mb-1" style={{ color: "white" }}>{item.bank_country}</p></div>
                                                        </div>
                                                        <div className="row text-left">
                                                            <div className="col-4"><p className="mb-1" style={{ color: "white", fontWeight: "bold" }}>Account Name:</p></div><div className="col-8"><p className="mb-1" style={{ color: "white" }}>{item.bank_account_name}</p></div>
                                                        </div>
                                                        <div className="row text-left">
                                                            <div className="col-4"><p className="mb-1" style={{ color: "white", fontWeight: "bold" }}>BSP:</p></div><div className="col-8"><p className="mb-1" style={{ color: "white" }}>{item.bsb}</p></div>
                                                        </div>
                                                        <div className="row text-left">
                                                            <div className="col-4"><p className="mb-1" style={{ color: "white", fontWeight: "bold" }}>Account Number:</p></div><div className="col-8"><p className="mb-1" style={{ color: "white" }}>{item.bank_account_no}</p></div>
                                                        </div>
                                                        <div className="row text-left">
                                                            <div className="col-4"><p className="mb-1" style={{ color: "white", fontWeight: "bold" }}>Address:</p></div><div className="col-8"><p className="mb-1" style={{ color: "white" }}>{item.bank_account_address}</p></div>
                                                        </div>
                                                        <div className="row text-left">
                                                            <div className="col-4"><p className="mb-1" style={{ color: "white", fontWeight: "bold" }}>Reference Code:</p></div><div className="col-8"><p className="mb-1" style={{ color: "white" }}><span onClick={this.copyReferenceCode} style={{ color: 'white', cursor: 'pointer' }}>{this.state.refernceLoader === true ? <img alt="loading..." style={{ height: '20px', fontWeight: 'bold', width: '20px', marginRight: '8px' }} src="/assets/images/spinner3.gif"></img> : <span>{" "}{this.state.referenceCode}</span>} {!this.state.refernceLoader && <ContentCopyIcon style={{ color: 'white' }} />}</span></p></div>
                                                        </div>
                                                        <div className="row text-left">
                                                            <div className="col-4"><p className="mb-1" style={{ color: "white", fontWeight: "bold" }}></p></div><div className="col-8"><p className="mb-1" style={{ color: "white", fontSize: '12px' }}>Please copy your payment reference code, and add it as your bank transaction reference.</p></div>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                }
                                {
                                    !this.state.referenceCode && (
                                        <button onClick={() => {
                                            window.location.href = "/login";
                                        }} type="button" className="btn btn-primary Submit">Login to get payment reference code</button>
                                    )
                                }
                                {
                                    this.state.referenceCode && (
                                        <button onClick={() => {
                                            window.location.href = "/";
                                        }} type="button" className="btn btn-primary Submit">Back to Homepage</button>
                                    )
                                }
                                
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.authReducer
})

export default connect(mapStateToProps, {})(BecomeMiner);