import { combineReducers } from 'redux';
import { authReducer } from './auth';
import { walletReducer } from './wallet';
import { commonReducer } from "./commonReducer"
import { notificationReducer } from 'notificationsdk';
import { paymentPosReducer } from './payment-pos';

const reducer = combineReducers({
    authReducer: authReducer,
    walletReducer: walletReducer,
    commonReducer: commonReducer,
    Notification: notificationReducer,
    PaymentPosReduser: paymentPosReducer
});

export default reducer;