const countryConfig = {
    "code": true,
    "message": "SUCCESS",
    "recdata": [
        {
            "country_id": "1",
            "name": "Afghanistan",
            "currency": null,
            "code": "AF"
        },
        {
            "country_id": "2",
            "name": "Albania",
            "currency": null,
            "code": "AL"
        },
        {
            "country_id": "3",
            "name": "Algeria",
            "currency": null,
            "code": "DZ"
        },
        {
            "country_id": "4",
            "name": "American Samoa",
            "currency": null,
            "code": "AS"
        },
        {
            "country_id": "5",
            "name": "Andorra",
            "currency": null,
            "code": "AD"
        },
        {
            "country_id": "6",
            "name": "Angola",
            "currency": null,
            "code": "AO"
        },
        {
            "country_id": "7",
            "name": "Anguilla",
            "currency": null,
            "code": "AI"
        },
        {
            "country_id": "8",
            "name": "Antarctica",
            "currency": null,
            "code": "AQ"
        },
        {
            "country_id": "9",
            "name": "Antigua And Barbuda",
            "currency": null,
            "code": "AG"
        },
        {
            "country_id": "10",
            "name": "Argentina",
            "currency": null,
            "code": "AR"
        },
        {
            "country_id": "11",
            "name": "Armenia",
            "currency": null,
            "code": "AM"
        },
        {
            "country_id": "12",
            "name": "Aruba",
            "currency": null,
            "code": "AW"
        },
        {
            "country_id": "13",
            "name": "Australia",
            "currency": null,
            "code": "AU"
        },
        {
            "country_id": "14",
            "name": "Austria",
            "currency": null,
            "code": "AT"
        },
        {
            "country_id": "15",
            "name": "Azerbaijan",
            "currency": null,
            "code": "AZ"
        },
        {
            "country_id": "16",
            "name": "Bahamas The",
            "currency": null,
            "code": "BS"
        },
        {
            "country_id": "17",
            "name": "Bahrain",
            "currency": null,
            "code": "BH"
        },
        {
            "country_id": "18",
            "name": "Bangladesh",
            "currency": null,
            "code": "BD"
        },
        {
            "country_id": "19",
            "name": "Barbados",
            "currency": null,
            "code": "BB"
        },
        {
            "country_id": "20",
            "name": "Belarus",
            "currency": null,
            "code": "BY"
        },
        {
            "country_id": "21",
            "name": "Belgium",
            "currency": null,
            "code": "BE"
        },
        {
            "country_id": "22",
            "name": "Belize",
            "currency": null,
            "code": "BZ"
        },
        {
            "country_id": "23",
            "name": "Benin",
            "currency": null,
            "code": "BJ"
        },
        {
            "country_id": "24",
            "name": "Bermuda",
            "currency": null,
            "code": "BM"
        },
        {
            "country_id": "25",
            "name": "Bhutan",
            "currency": null,
            "code": "BT"
        },
        {
            "country_id": "26",
            "name": "Bolivia",
            "currency": null,
            "code": "BO"
        },
        {
            "country_id": "27",
            "name": "Bosnia and Herzegovina",
            "currency": null,
            "code": "BA"
        },
        {
            "country_id": "28",
            "name": "Botswana",
            "currency": null,
            "code": "BW"
        },
        {
            "country_id": "29",
            "name": "Bouvet Island",
            "currency": null,
            "code": "BV"
        },
        {
            "country_id": "30",
            "name": "Brazil",
            "currency": null,
            "code": "BR"
        },
        {
            "country_id": "31",
            "name": "British Indian Ocean Territory",
            "currency": null,
            "code": "IO"
        },
        {
            "country_id": "32",
            "name": "Brunei",
            "currency": null,
            "code": "BN"
        },
        {
            "country_id": "33",
            "name": "Bulgaria",
            "currency": null,
            "code": "BG"
        },
        {
            "country_id": "34",
            "name": "Burkina Faso",
            "currency": null,
            "code": "BF"
        },
        {
            "country_id": "35",
            "name": "Burundi",
            "currency": null,
            "code": "BI"
        },
        {
            "country_id": "36",
            "name": "Cambodia",
            "currency": null,
            "code": "KH"
        },
        {
            "country_id": "37",
            "name": "Cameroon",
            "currency": null,
            "code": "CM"
        },
        {
            "country_id": "38",
            "name": "Canada",
            "currency": null,
            "code": "CA"
        },
        {
            "country_id": "39",
            "name": "Cape Verde",
            "currency": null,
            "code": "CV"
        },
        {
            "country_id": "40",
            "name": "Cayman Islands",
            "currency": null,
            "code": "KY"
        },
        {
            "country_id": "41",
            "name": "Central African Republic",
            "currency": null,
            "code": "CF"
        },
        {
            "country_id": "42",
            "name": "Chad",
            "currency": null,
            "code": "TD"
        },
        {
            "country_id": "43",
            "name": "Chile",
            "currency": null,
            "code": "CL"
        },
        {
            "country_id": "44",
            "name": "China",
            "currency": null,
            "code": "CN"
        },
        {
            "country_id": "45",
            "name": "Christmas Island",
            "currency": null,
            "code": "CX"
        },
        {
            "country_id": "46",
            "name": "Cocos (Keeling) Islands",
            "currency": null,
            "code": "CC"
        },
        {
            "country_id": "47",
            "name": "Colombia",
            "currency": null,
            "code": "CO"
        },
        {
            "country_id": "48",
            "name": "Comoros",
            "currency": null,
            "code": "KM"
        },
        {
            "country_id": "51",
            "name": "Cook Islands",
            "currency": null,
            "code": "CK"
        },
        {
            "country_id": "52",
            "name": "Costa Rica",
            "currency": null,
            "code": "CR"
        },
        {
            "country_id": "53",
            "name": "Cote D'Ivoire (Ivory Coast)",
            "currency": null,
            "code": "CI"
        },
        {
            "country_id": "54",
            "name": "Croatia (Hrvatska)",
            "currency": null,
            "code": "HR"
        },
        {
            "country_id": "55",
            "name": "Cuba",
            "currency": null,
            "code": "CU"
        },
        {
            "country_id": "56",
            "name": "Cyprus",
            "currency": null,
            "code": "CY"
        },
        {
            "country_id": "57",
            "name": "Czech Republic",
            "currency": null,
            "code": "CZ"
        },
        {
            "country_id": "50",
            "name": "Democratic Republic Of The Congo",
            "currency": null,
            "code": "CD"
        },
        {
            "country_id": "58",
            "name": "Denmark",
            "currency": null,
            "code": "DK"
        },
        {
            "country_id": "59",
            "name": "Djibouti",
            "currency": null,
            "code": "DJ"
        },
        {
            "country_id": "60",
            "name": "Dominica",
            "currency": null,
            "code": "DM"
        },
        {
            "country_id": "61",
            "name": "Dominican Republic",
            "currency": null,
            "code": "DO"
        },
        {
            "country_id": "62",
            "name": "East Timor",
            "currency": null,
            "code": "TP"
        },
        {
            "country_id": "63",
            "name": "Ecuador",
            "currency": null,
            "code": "EC"
        },
        {
            "country_id": "64",
            "name": "Egypt",
            "currency": null,
            "code": "EG"
        },
        {
            "country_id": "65",
            "name": "El Salvador",
            "currency": null,
            "code": "SV"
        },
        {
            "country_id": "66",
            "name": "Equatorial Guinea",
            "currency": null,
            "code": "GQ"
        },
        {
            "country_id": "67",
            "name": "Eritrea",
            "currency": null,
            "code": "ER"
        },
        {
            "country_id": "68",
            "name": "Estonia",
            "currency": null,
            "code": "EE"
        },
        {
            "country_id": "69",
            "name": "Ethiopia",
            "currency": null,
            "code": "ET"
        },
        {
            "country_id": "70",
            "name": "External Territories of Australia",
            "currency": null,
            "code": "XA"
        },
        {
            "country_id": "71",
            "name": "Falkland Islands",
            "currency": null,
            "code": "FK"
        },
        {
            "country_id": "72",
            "name": "Faroe Islands",
            "currency": null,
            "code": "FO"
        },
        {
            "country_id": "73",
            "name": "Fiji Islands",
            "currency": null,
            "code": "FJ"
        },
        {
            "country_id": "74",
            "name": "Finland",
            "currency": null,
            "code": "FI"
        },
        {
            "country_id": "75",
            "name": "France",
            "currency": null,
            "code": "FR"
        },
        {
            "country_id": "76",
            "name": "French Guiana",
            "currency": null,
            "code": "GF"
        },
        {
            "country_id": "77",
            "name": "French Polynesia",
            "currency": null,
            "code": "PF"
        },
        {
            "country_id": "78",
            "name": "French Southern Territories",
            "currency": null,
            "code": "TF"
        },
        {
            "country_id": "79",
            "name": "Gabon",
            "currency": null,
            "code": "GA"
        },
        {
            "country_id": "80",
            "name": "Gambia The",
            "currency": null,
            "code": "GM"
        },
        {
            "country_id": "81",
            "name": "Georgia",
            "currency": null,
            "code": "GE"
        },
        {
            "country_id": "82",
            "name": "Germany",
            "currency": null,
            "code": "DE"
        },
        {
            "country_id": "83",
            "name": "Ghana",
            "currency": null,
            "code": "GH"
        },
        {
            "country_id": "84",
            "name": "Gibraltar",
            "currency": null,
            "code": "GI"
        },
        {
            "country_id": "85",
            "name": "Greece",
            "currency": null,
            "code": "GR"
        },
        {
            "country_id": "86",
            "name": "Greenland",
            "currency": null,
            "code": "GL"
        },
        {
            "country_id": "87",
            "name": "Grenada",
            "currency": null,
            "code": "GD"
        },
        {
            "country_id": "88",
            "name": "Guadeloupe",
            "currency": null,
            "code": "GP"
        },
        {
            "country_id": "89",
            "name": "Guam",
            "currency": null,
            "code": "GU"
        },
        {
            "country_id": "90",
            "name": "Guatemala",
            "currency": null,
            "code": "GT"
        },
        {
            "country_id": "91",
            "name": "Guernsey and Alderney",
            "currency": null,
            "code": "XU"
        },
        {
            "country_id": "92",
            "name": "Guinea",
            "currency": null,
            "code": "GN"
        },
        {
            "country_id": "93",
            "name": "Guinea-Bissau",
            "currency": null,
            "code": "GW"
        },
        {
            "country_id": "94",
            "name": "Guyana",
            "currency": null,
            "code": "GY"
        },
        {
            "country_id": "95",
            "name": "Haiti",
            "currency": null,
            "code": "HT"
        },
        {
            "country_id": "96",
            "name": "Heard and McDonald Islands",
            "currency": null,
            "code": "HM"
        },
        {
            "country_id": "97",
            "name": "Honduras",
            "currency": null,
            "code": "HN"
        },
        {
            "country_id": "98",
            "name": "Hong Kong S.A.R.",
            "currency": null,
            "code": "HK"
        },
        {
            "country_id": "99",
            "name": "Hungary",
            "currency": null,
            "code": "HU"
        },
        {
            "country_id": "100",
            "name": "Iceland",
            "currency": null,
            "code": "IS"
        },
        {
            "country_id": "101",
            "name": "India",
            "currency": null,
            "code": "IN"
        },
        {
            "country_id": "102",
            "name": "Indonesia",
            "currency": null,
            "code": "ID"
        },
        {
            "country_id": "103",
            "name": "Iran",
            "currency": null,
            "code": "IR"
        },
        {
            "country_id": "104",
            "name": "Iraq",
            "currency": null,
            "code": "IQ"
        },
        {
            "country_id": "105",
            "name": "Ireland",
            "currency": null,
            "code": "IE"
        },
        {
            "country_id": "107",
            "name": "Italy",
            "currency": null,
            "code": "IT"
        },
        {
            "country_id": "108",
            "name": "Jamaica",
            "currency": null,
            "code": "JM"
        },
        {
            "country_id": "109",
            "name": "Japan",
            "currency": null,
            "code": "JP"
        },
        {
            "country_id": "110",
            "name": "Jersey",
            "currency": null,
            "code": "XJ"
        },
        {
            "country_id": "111",
            "name": "Jordan",
            "currency": null,
            "code": "JO"
        },
        {
            "country_id": "112",
            "name": "Kazakhstan",
            "currency": null,
            "code": "KZ"
        },
        {
            "country_id": "113",
            "name": "Kenya",
            "currency": null,
            "code": "KE"
        },
        {
            "country_id": "114",
            "name": "Kiribati",
            "currency": null,
            "code": "KI"
        },
        {
            "country_id": "115",
            "name": "Korea North",
            "currency": null,
            "code": "KP"
        },
        {
            "country_id": "116",
            "name": "Korea South",
            "currency": null,
            "code": "KR"
        },
        {
            "country_id": "117",
            "name": "Kuwait",
            "currency": null,
            "code": "KW"
        },
        {
            "country_id": "118",
            "name": "Kyrgyzstan",
            "currency": null,
            "code": "KG"
        },
        {
            "country_id": "119",
            "name": "Laos",
            "currency": null,
            "code": "LA"
        },
        {
            "country_id": "120",
            "name": "Latvia",
            "currency": null,
            "code": "LV"
        },
        {
            "country_id": "121",
            "name": "Lebanon",
            "currency": null,
            "code": "LB"
        },
        {
            "country_id": "122",
            "name": "Lesotho",
            "currency": null,
            "code": "LS"
        },
        {
            "country_id": "123",
            "name": "Liberia",
            "currency": null,
            "code": "LR"
        },
        {
            "country_id": "124",
            "name": "Libya",
            "currency": null,
            "code": "LY"
        },
        {
            "country_id": "125",
            "name": "Liechtenstein",
            "currency": null,
            "code": "LI"
        },
        {
            "country_id": "126",
            "name": "Lithuania",
            "currency": null,
            "code": "LT"
        },
        {
            "country_id": "127",
            "name": "Luxembourg",
            "currency": null,
            "code": "LU"
        },
        {
            "country_id": "128",
            "name": "Macau S.A.R.",
            "currency": null,
            "code": "MO"
        },
        {
            "country_id": "129",
            "name": "Macedonia",
            "currency": null,
            "code": "MK"
        },
        {
            "country_id": "130",
            "name": "Madagascar",
            "currency": null,
            "code": "MG"
        },
        {
            "country_id": "131",
            "name": "Malawi",
            "currency": null,
            "code": "MW"
        },
        {
            "country_id": "132",
            "name": "Malaysia",
            "currency": null,
            "code": "MY"
        },
        {
            "country_id": "133",
            "name": "Maldives",
            "currency": null,
            "code": "MV"
        },
        {
            "country_id": "134",
            "name": "Mali",
            "currency": null,
            "code": "ML"
        },
        {
            "country_id": "135",
            "name": "Malta",
            "currency": null,
            "code": "MT"
        },
        {
            "country_id": "136",
            "name": "Man (Isle of)",
            "currency": null,
            "code": "XM"
        },
        {
            "country_id": "137",
            "name": "Marshall Islands",
            "currency": null,
            "code": "MH"
        },
        {
            "country_id": "138",
            "name": "Martinique",
            "currency": null,
            "code": "MQ"
        },
        {
            "country_id": "139",
            "name": "Mauritania",
            "currency": null,
            "code": "MR"
        },
        {
            "country_id": "140",
            "name": "Mauritius",
            "currency": null,
            "code": "MU"
        },
        {
            "country_id": "141",
            "name": "Mayotte",
            "currency": null,
            "code": "YT"
        },
        {
            "country_id": "142",
            "name": "Mexico",
            "currency": null,
            "code": "MX"
        },
        {
            "country_id": "143",
            "name": "Micronesia",
            "currency": null,
            "code": "FM"
        },
        {
            "country_id": "144",
            "name": "Moldova",
            "currency": null,
            "code": "MD"
        },
        {
            "country_id": "145",
            "name": "Monaco",
            "currency": null,
            "code": "MC"
        },
        {
            "country_id": "146",
            "name": "Mongolia",
            "currency": null,
            "code": "MN"
        },
        {
            "country_id": "147",
            "name": "Montserrat",
            "currency": null,
            "code": "MS"
        },
        {
            "country_id": "148",
            "name": "Morocco",
            "currency": null,
            "code": "MA"
        },
        {
            "country_id": "149",
            "name": "Mozambique",
            "currency": null,
            "code": "MZ"
        },
        {
            "country_id": "150",
            "name": "Myanmar",
            "currency": null,
            "code": "MM"
        },
        {
            "country_id": "151",
            "name": "Namibia",
            "currency": null,
            "code": "NA"
        },
        {
            "country_id": "152",
            "name": "Nauru",
            "currency": null,
            "code": "NR"
        },
        {
            "country_id": "153",
            "name": "Nepal",
            "currency": null,
            "code": "NP"
        },
        {
            "country_id": "154",
            "name": "Netherlands Antilles",
            "currency": null,
            "code": "AN"
        },
        {
            "country_id": "155",
            "name": "Netherlands The",
            "currency": null,
            "code": "NL"
        },
        {
            "country_id": "156",
            "name": "New Caledonia",
            "currency": null,
            "code": "NC"
        },
        {
            "country_id": "157",
            "name": "New Zealand",
            "currency": null,
            "code": "NZ"
        },
        {
            "country_id": "158",
            "name": "Nicaragua",
            "currency": null,
            "code": "NI"
        },
        {
            "country_id": "159",
            "name": "Niger",
            "currency": null,
            "code": "NE"
        },
        {
            "country_id": "160",
            "name": "Nigeria",
            "currency": null,
            "code": "NG"
        },
        {
            "country_id": "161",
            "name": "Niue",
            "currency": null,
            "code": "NU"
        },
        {
            "country_id": "162",
            "name": "Norfolk Island",
            "currency": null,
            "code": "NF"
        },
        {
            "country_id": "163",
            "name": "Northern Mariana Islands",
            "currency": null,
            "code": "MP"
        },
        {
            "country_id": "164",
            "name": "Norway",
            "currency": null,
            "code": "NO"
        },
        {
            "country_id": "165",
            "name": "Oman",
            "currency": null,
            "code": "OM"
        },
        {
            "country_id": "166",
            "name": "Pakistan",
            "currency": null,
            "code": "PK"
        },
        {
            "country_id": "167",
            "name": "Palau",
            "currency": null,
            "code": "PW"
        },
        {
            "country_id": "168",
            "name": "Palestinian Territory Occupied",
            "currency": null,
            "code": "PS"
        },
        {
            "country_id": "169",
            "name": "Panama",
            "currency": null,
            "code": "PA"
        },
        {
            "country_id": "170",
            "name": "Papua new Guinea",
            "currency": null,
            "code": "PG"
        },
        {
            "country_id": "171",
            "name": "Paraguay",
            "currency": null,
            "code": "PY"
        },
        {
            "country_id": "172",
            "name": "Peru",
            "currency": null,
            "code": "PE"
        },
        {
            "country_id": "173",
            "name": "Philippines",
            "currency": null,
            "code": "PH"
        },
        {
            "country_id": "174",
            "name": "Pitcairn Island",
            "currency": null,
            "code": "PN"
        },
        {
            "country_id": "175",
            "name": "Poland",
            "currency": null,
            "code": "PL"
        },
        {
            "country_id": "176",
            "name": "Portugal",
            "currency": null,
            "code": "PT"
        },
        {
            "country_id": "177",
            "name": "Puerto Rico",
            "currency": null,
            "code": "PR"
        },
        {
            "country_id": "178",
            "name": "Qatar",
            "currency": null,
            "code": "QA"
        },
        {
            "country_id": "49",
            "name": "Republic Of The Congo",
            "currency": null,
            "code": "CG"
        },
        {
            "country_id": "179",
            "name": "Reunion",
            "currency": null,
            "code": "RE"
        },
        {
            "country_id": "180",
            "name": "Romania",
            "currency": null,
            "code": "RO"
        },
        {
            "country_id": "181",
            "name": "Russia",
            "currency": null,
            "code": "RU"
        },
        {
            "country_id": "182",
            "name": "Rwanda",
            "currency": null,
            "code": "RW"
        },
        {
            "country_id": "183",
            "name": "Saint Helena",
            "currency": null,
            "code": "SH"
        },
        {
            "country_id": "184",
            "name": "Saint Kitts And Nevis",
            "currency": null,
            "code": "KN"
        },
        {
            "country_id": "185",
            "name": "Saint Lucia",
            "currency": null,
            "code": "LC"
        },
        {
            "country_id": "186",
            "name": "Saint Pierre and Miquelon",
            "currency": null,
            "code": "PM"
        },
        {
            "country_id": "187",
            "name": "Saint Vincent And The Grenadines",
            "currency": null,
            "code": "VC"
        },
        {
            "country_id": "188",
            "name": "Samoa",
            "currency": null,
            "code": "WS"
        },
        {
            "country_id": "189",
            "name": "San Marino",
            "currency": null,
            "code": "SM"
        },
        {
            "country_id": "190",
            "name": "Sao Tome and Principe",
            "currency": null,
            "code": "ST"
        },
        {
            "country_id": "191",
            "name": "Saudi Arabia",
            "currency": null,
            "code": "SA"
        },
        {
            "country_id": "192",
            "name": "Senegal",
            "currency": null,
            "code": "SN"
        },
        {
            "country_id": "193",
            "name": "Serbia",
            "currency": null,
            "code": "RS"
        },
        {
            "country_id": "194",
            "name": "Seychelles",
            "currency": null,
            "code": "SC"
        },
        {
            "country_id": "195",
            "name": "Sierra Leone",
            "currency": null,
            "code": "SL"
        },
        {
            "country_id": "196",
            "name": "Singapore",
            "currency": null,
            "code": "SG"
        },
        {
            "country_id": "197",
            "name": "Slovakia",
            "currency": null,
            "code": "SK"
        },
        {
            "country_id": "198",
            "name": "Slovenia",
            "currency": null,
            "code": "SI"
        },
        {
            "country_id": "199",
            "name": "Smaller Territories of the UK",
            "currency": null,
            "code": "XG"
        },
        {
            "country_id": "200",
            "name": "Solomon Islands",
            "currency": null,
            "code": "SB"
        },
        {
            "country_id": "201",
            "name": "Somalia",
            "currency": null,
            "code": "SO"
        },
        {
            "country_id": "202",
            "name": "South Africa",
            "currency": null,
            "code": "ZA"
        },
        {
            "country_id": "203",
            "name": "South Georgia",
            "currency": null,
            "code": "GS"
        },
        {
            "country_id": "204",
            "name": "South Sudan",
            "currency": null,
            "code": "SS"
        },
        {
            "country_id": "205",
            "name": "Spain",
            "currency": null,
            "code": "ES"
        },
        {
            "country_id": "206",
            "name": "Sri Lanka",
            "currency": null,
            "code": "LK"
        },
        {
            "country_id": "207",
            "name": "Sudan",
            "currency": null,
            "code": "SD"
        },
        {
            "country_id": "208",
            "name": "Suriname",
            "currency": null,
            "code": "SR"
        },
        {
            "country_id": "209",
            "name": "Svalbard And Jan Mayen Islands",
            "currency": null,
            "code": "SJ"
        },
        {
            "country_id": "210",
            "name": "Swaziland",
            "currency": null,
            "code": "SZ"
        },
        {
            "country_id": "211",
            "name": "Sweden",
            "currency": null,
            "code": "SE"
        },
        {
            "country_id": "212",
            "name": "Switzerland",
            "currency": null,
            "code": "CH"
        },
        {
            "country_id": "213",
            "name": "Syria",
            "currency": null,
            "code": "SY"
        },
        {
            "country_id": "214",
            "name": "Taiwan",
            "currency": null,
            "code": "TW"
        },
        {
            "country_id": "215",
            "name": "Tajikistan",
            "currency": null,
            "code": "TJ"
        },
        {
            "country_id": "216",
            "name": "Tanzania",
            "currency": null,
            "code": "TZ"
        },
        {
            "country_id": "217",
            "name": "Thailand",
            "currency": null,
            "code": "TH"
        },
        {
            "country_id": "218",
            "name": "Togo",
            "currency": null,
            "code": "TG"
        },
        {
            "country_id": "219",
            "name": "Tokelau",
            "currency": null,
            "code": "TK"
        },
        {
            "country_id": "220",
            "name": "Tonga",
            "currency": null,
            "code": "TO"
        },
        {
            "country_id": "221",
            "name": "Trinidad And Tobago",
            "currency": null,
            "code": "TT"
        },
        {
            "country_id": "222",
            "name": "Tunisia",
            "currency": null,
            "code": "TN"
        },
        {
            "country_id": "223",
            "name": "Turkey",
            "currency": null,
            "code": "TR"
        },
        {
            "country_id": "224",
            "name": "Turkmenistan",
            "currency": null,
            "code": "TM"
        },
        {
            "country_id": "225",
            "name": "Turks And Caicos Islands",
            "currency": null,
            "code": "TC"
        },
        {
            "country_id": "226",
            "name": "Tuvalu",
            "currency": null,
            "code": "TV"
        },
        {
            "country_id": "227",
            "name": "Uganda",
            "currency": null,
            "code": "UG"
        },
        {
            "country_id": "228",
            "name": "Ukraine",
            "currency": null,
            "code": "UA"
        },
        {
            "country_id": "229",
            "name": "United Arab Emirates",
            "currency": null,
            "code": "AE"
        },
        {
            "country_id": "230",
            "name": "United Kingdom",
            "currency": null,
            "code": "GB"
        },
        {
            "country_id": "231",
            "name": "United States",
            "currency": null,
            "code": "US"
        },
        {
            "country_id": "232",
            "name": "United States Minor Outlying Islands",
            "currency": null,
            "code": "UM"
        },
        {
            "country_id": "233",
            "name": "Uruguay",
            "currency": null,
            "code": "UY"
        },
        {
            "country_id": "234",
            "name": "Uzbekistan",
            "currency": null,
            "code": "UZ"
        },
        {
            "country_id": "235",
            "name": "Vanuatu",
            "currency": null,
            "code": "VU"
        },
        {
            "country_id": "236",
            "name": "Vatican City State (Holy See)",
            "currency": null,
            "code": "VA"
        },
        {
            "country_id": "237",
            "name": "Venezuela",
            "currency": null,
            "code": "VE"
        },
        {
            "country_id": "238",
            "name": "Vietnam",
            "currency": null,
            "code": "VN"
        },
        {
            "country_id": "239",
            "name": "Virgin Islands (British)",
            "currency": null,
            "code": "VG"
        },
        {
            "country_id": "240",
            "name": "Virgin Islands (US)",
            "currency": null,
            "code": "VI"
        },
        {
            "country_id": "241",
            "name": "Wallis And Futuna Islands",
            "currency": null,
            "code": "WF"
        },
        {
            "country_id": "242",
            "name": "Western Sahara",
            "currency": null,
            "code": "EH"
        },
        {
            "country_id": "243",
            "name": "Yemen",
            "currency": null,
            "code": "YE"
        },
        {
            "country_id": "244",
            "name": "Yugoslavia",
            "currency": null,
            "code": "YU"
        },
        {
            "country_id": "245",
            "name": "Zambia",
            "currency": null,
            "code": "ZM"
        },
        {
            "country_id": "246",
            "name": "Zimbabwe",
            "currency": null,
            "code": "ZW"
        }
    ]
}

export default countryConfig;
