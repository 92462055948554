import React, { Component } from "react";
// import { toastMessageSuccess, toastMessageInfo } from "../../../utils/toastMessage";
// import { storeWallet } from '../../../api/wallet/wallet';
import ErrorAlert from "../Modals/ErrorAlert";
import InfoSuccess from "../Modals/InfoSuccess";

import ResetPassword from "./WalletListing/Modals/ResetPassword";
import SendCoinAndSavePassword from "./WalletListing/Modals/SendCoinAndSavePassword";
import SendAndRegister from "./WalletListing/Modals/SendAndRegister"
import MintAndSavePassword from "./WalletListing/Modals/MintAndSavePassword"
import { showValidateMnemonicsScreen } from "../../actions/wallet";
import $ from "jquery"
import { connect } from "react-redux";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
const { Keyring } = require('@polkadot/api');

class ValidateMnemoncis extends Component {
    constructor(props) {
        super(props);
        this.state = {

            passwordResetModalShow: false,

            errorModalShow: false,
            mnemoncisValue: "",
            successModalShow: false,

            // error and suceses alert title and message
            alertTitlt: "",
            alertMessage: "",

            sendAndSavePasswordModalShow: false,
            mintModalShow: false




        }
    }

    componentWillMount() {
        if (this.props.isShowValidateMnemonicsScreen === false) {
            this.props.history.push("/wallet-listing")
            return
        }


    }
    loadMnemonics = () => {

        var string = $("#nValue").val().trim();
        const s = string.split(' ')
        var stringArray = []
        for (var i = 0; i < s.length; i++) {
            stringArray.push(s[i])
        }

        if (stringArray.length === 12) {
            $("#val1").val(stringArray[0]);
            $("#val2").val(stringArray[1]);
            $("#val3").val(stringArray[2]);
            $("#val4").val(stringArray[3]);
            $("#val5").val(stringArray[4]);
            $("#val6").val(stringArray[5]);
            $("#val7").val(stringArray[6]);
            $("#val8").val(stringArray[7]);
            $("#val9").val(stringArray[8]);
            $("#val10").val(stringArray[9]);
            $("#val11").val(stringArray[10]);
            $("#val12").val(stringArray[11]);

        }
        else {
            this.setState({ alertTitlt: "Warning!", errorModalShow: true, alertMessage: `Mnemonics should be consist of 12 word` })
        }
    }

    proceedAfterMnemoncisLoad = () => {

        let fillAllBox = true
        var string = '';
        var Text = $('#appendText').find('.form-group');

        let MnemonicsArray = [];
        $.each(Text, function (i) {

            var textVal = $(this).find('input').val();
            textVal = textVal.trim()

            if (textVal === "") {

                fillAllBox = false
            }

            MnemonicsArray.push(textVal);

            if (textVal) {
                if (!string) {

                    string = textVal;
                } else {

                    string += ' ' + textVal;
                }
            }
        });



        if (fillAllBox === true) {
            const keyring = new Keyring({
                type: 'sr25519',
                ss58Format: 42,
            });
            try {
                const newPair = keyring.addFromUri(string);
                if (newPair.address !== this.props.location.state.walletAddress) {

                    this.setState({ alertTitlt: "", errorModalShow: true, alertMessage: `Your mnemonics are not matched with your selected wallet address` })
                }
                else {

                    this.setState({
                        mnemoncisValue: string
                    }, async () => {

                        if (this.props.purpose === "transfer") {
                            // alert("transfer coin")
                            this.setState({ sendAndSavePasswordModalShow: true })

                        }
                        else if (this.props.purpose === "registerWallet") {
                            // alert("transfer coin")
                            this.setState({ sendAndRegisterModalShow: true })

                        }
                        else if (this.props.purpose === "mintCoin") {

                            this.setState({ mintModalShow: true })

                        }
                        else if (this.props.purpose === "passwordReset") {

                            this.setState({
                                passwordResetModalShow: true,

                                errorModalShow: false,
                                alertTitlt: "",
                                alertMessage: "",
                            })
                        }

                        else if (this.props.purpose === "paypal") {

                            this.setState({
                                passwordResetModalShow: true,

                                errorModalShow: false,
                                alertTitlt: "",
                                alertMessage: "",
                            })
                        }
                    })
                }//end else
            }
            catch (e) {
                //alert(e)
                this.setState({ alertTitlt: "", errorModalShow: true, alertMessage: e.message })
            }
        }
        else {
            this.setState({ alertTitlt: "Warning!", errorModalShow: true, alertMessage: `Please fill out all the boxes` })
        }
    }


    render() {


        return (

            <>
                <ErrorAlert showErroModal={this.state.errorModalShow} title={this.state.alertTitlt} message={this.state.alertMessage} onCloseErrorModal={() =>
                    this.setState({
                        errorModalShow: false,
                        alertTitlt: "",
                        alertMessage: ""
                    })} />

                <InfoSuccess showSuccessModal={this.state.successModalShow} title={this.state.alertTitlt} message={this.state.alertMessage} onCloseSuccessModal={() =>
                    this.setState({
                        successModalShow: false,
                        alertTitlt: "",
                        alertMessage: ""
                    }, () => {
                        this.props.showValidateMnemonicsScreen(false)
                        this.props.history.push("/wallet-listing")
                    })} />

                <SendCoinAndSavePassword
                    showSendAndSavePasswordModal={this.state.sendAndSavePasswordModalShow}
                    amount={this?.props?.location?.state?.amount}
                    fromAddress={this?.props?.location?.state?.walletAddress}
                    walletName={this?.props?.location?.state?.walletName}
                    toAddress={this?.props?.location?.state?.receiverAddress}
                    mnemoncisValue={this.state.mnemoncisValue}
                    history={this.props.history}
                    onSendAndSavePasswordModal={(hash, transferCoin) => {
                        //alert(transferCoin)
                        if (transferCoin === true) {
                            this.setState({ sendAndSavePasswordModalShow: false }, () => {
                                this.setState({ alertTitlt: "Transaction Success", successModalShow: true, alertMessage: "Your transaction hash " + hash })
                            })
                        }
                        else {
                            this.setState({ sendAndSavePasswordModalShow: false })
                        }
                    }}
                />

                <div className="BlackStarsBack">
                    <div id="Blackstars" />
                    <div id="Blackstars2" />
                    <div id="Blackstars3" />
                </div>

                <div className="row">
                    <div className="col-md-6 offset-md-3">
                        <div className="AccountAddress text-center">
                            <div className="MneMonics Box SetAccount">
                                <h4 className="text-center">Confirm Mnemonic</h4>
                                <p style={{
                                    color: 'white',
                                    wordWrap: 'break-word'
                                }}>Wallet:{this?.props?.location?.state?.walletAddress} ({this?.props?.location?.state?.walletName})</p>
                                <div className="row" id="appendText" style={{ marginBottom: '25px' }}>
                                    <div className="form-group col-4 mb-4">
                                        <input class="form-control" id="val1" placeholder="1" type="text" />
                                    </div>
                                    <div className="form-group col-4 mb-4">
                                        <input class="form-control" id="val2" placeholder="2" type="text" />
                                    </div>
                                    <div className="form-group col-4 mb-4">
                                        <input class="form-control" id="val3" placeholder="3" type="text" />
                                    </div>
                                    <div className="form-group col-4 mb-4">
                                        <input class="form-control" id="val4" placeholder="4" type="text" />
                                    </div>
                                    <div className="form-group col-4 mb-4">
                                        <input class="form-control" id="val5" placeholder="5" type="text" />
                                    </div>
                                    <div className="form-group col-4 mb-4">
                                        <input class="form-control" id="val6" placeholder="6" type="text" />
                                    </div>
                                    <div className="form-group col-4 mb-4">
                                        <input class="form-control" id="val7" placeholder="7" type="text" />
                                    </div>
                                    <div className="form-group col-4 mb-4">
                                        <input class="form-control" id="val8" placeholder="8" type="text" />
                                    </div>
                                    <div className="form-group col-4 mb-4">
                                        <input class="form-control" id="val9" placeholder="9" type="text" />
                                    </div>
                                    <div className="form-group col-4 mb-4">
                                        <input class="form-control" id="val10" placeholder="10" type="text" />
                                    </div>
                                    <div className="form-group col-4 mb-4">
                                        <input class="form-control" id="val11" placeholder="11" type="text" />
                                    </div>
                                    <div className="form-group col-4 mb-4">
                                        <input class="form-control" id="val12" placeholder="12" type="text" />
                                    </div>
                                    {/* <div className="text-div mnemonic-confirm-box"><div className="box-shadow p-0"><input id="val1" className="text-center p-1" type="text" /></div></div>
                                        <div className="text-div mnemonic-confirm-box"><div className="box-shadow p-0"><input id="val2" className="text-center p-1" type="text" /></div></div>
                                        <div className="text-div mnemonic-confirm-box"><div className="box-shadow p-0"><input id="val3" className="text-center p-1" type="text" /></div></div>
                                        <div className="text-div mnemonic-confirm-box"><div className="box-shadow p-0"><input id="val4" className="text-center p-1" type="text" /></div></div>
                                        <div className="text-div mnemonic-confirm-box"><div className="box-shadow p-0"><input id="val5" className="text-center p-1" type="text" /></div></div>
                                        <div className="text-div mnemonic-confirm-box"><div className="box-shadow p-0"><input id="val6" className="text-center p-1" type="text" /></div></div>
                                        <div className="text-div mnemonic-confirm-box"><div className="box-shadow p-0"><input id="val7" className="text-center p-1" type="text" /></div></div>
                                        <div className="text-div mnemonic-confirm-box"><div className="box-shadow p-0"><input id="val8" className="text-center p-1" type="text" /></div></div>
                                        <div className="text-div mnemonic-confirm-box"><div className="box-shadow p-0"><input id="val9" className="text-center p-1" type="text" /></div></div>
                                        <div className="text-div mnemonic-confirm-box"><div className="box-shadow p-0"><input id="val10" className="text-center p-1" type="text" /></div></div>
                                        <div className="text-div mnemonic-confirm-box"><div className="box-shadow p-0"><input id="val11" className="text-center p-1" type="text" /></div></div>
                                        <div className="text-div mnemonic-confirm-box"><div className="box-shadow p-0"><input id="val12" className="text-center p-1" type="text" /></div></div> */}
                                </div>

                                <div className='mnemonic-load-wrapper'>
                                    <input autoFocus={true} onChange={this.onChange}
                                        id="nValue" name="username" className="form-control mb-4" placeholder="Enter your mnemonics" type="text" />

                                </div>

                                <button onClick={this.loadMnemonics} style={{ marginRight: '20px' }} className="btn btn-info mb-20" >Load Mnemonic</button>
                                <button onClick={this.proceedAfterMnemoncisLoad} type="button" className="btn btn-primary Submit mb-20">
                                    {this.state.isLoading === true ? <img alt="loading..." style={{ height: '20px', width: '20px', marginRight: '8px' }} src="/assets/images/spinner3.gif"></img> : null}
                                    Continue
                                </button>
                                <button onClick={() => {
                                    this.props.history.push("/wallet-listing")
                                }} type="button" className="btn btn-secondary Cancel mb-20" data-dismiss="modal">Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>

                <Footer />

                {/* =========== Reset password modal show =========== */}
                <ResetPassword
                    showPasswordResetModal={this.state.passwordResetModalShow}
                    mnemoncis={this.state.mnemoncisValue}
                    walletAddress={this?.props?.location?.state?.walletAddress}
                    history={this?.props?.history}
                    onClosePasswordResetModal={() => {
                        this.setState({ passwordResetModalShow: false })
                    }} />
                {/* =========== Reset password modal show end =========== */}

                <SendAndRegister
                    showSendAndRegisterdModal={this.state.sendAndRegisterModalShow}
                    registerCategory={this?.props?.location?.state.category}
                    mnemoncis={this.state.mnemoncisValue}
                    walletAddress={this?.props?.location?.state?.walletAddress}
                    history={this.props.history}
                    onSendAndSavePasswordModalClose={(hash, transferCoin) => {
                        //alert(transferCoin)
                        if (transferCoin === true) {
                            this.setState({ sendAndSavePasswordModalShow: false }, () => {
                                this.setState({ alertTitlt: "Transaction Success", successModalShow: true, alertMessage: "Your wallet is successfully register as a miner.\nTransaction Hash : " + hash })
                            })
                        }
                        else {
                            this.setState({ sendAndRegisterModalShow: false })
                        }
                    }}
                />


                <MintAndSavePassword
                    showMintModal={this.state.mintModalShow}
                    walletAddress={this.props.location.state.walletAddress}
                    mnemoncis={this.state.mnemoncisValue}
                    history={this.props.history}
                    onCloseMintModal={(hash, mintSuccess, nextMint) => {
                        //alert(transferCoin)
                        if (mintSuccess === true) {
                            this.setState({ mintModalShow: false }, () => {
                                this.setState({ alertTitlt: "You have minted successfully", successModalShow: true, alertMessage: `Your next minting session will be in ${nextMint} hour(s).` })
                            })
                        }

                        else {
                            this.setState({ mintModalShow: false })
                        }
                    }}
                />



            </>


        )
    }

}


const mapStateToProps = (state) => ({

    isShowValidateMnemonicsScreen: state.walletReducer.isShowValidateMnemonicsScreen,
    purpose: state.walletReducer.purpose,
})
export default connect(mapStateToProps, { showValidateMnemonicsScreen })(ValidateMnemoncis)

