import React, { Component } from "react";
import { connect } from "react-redux";
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import StoreMallDirectoryOutlined from '@material-ui/icons/StoreMallDirectoryOutlined';
import ShoppingCart from '@material-ui/icons/ShoppingCart';


// import NorthIcon from '@mui/icons-material/North';
import SuccessModal from "../../Modals/SuccessModal";



import AddIcon from '@material-ui/icons/Add';
import SouthWestIcon from '@material-ui/icons/SaveAlt';
import ContentCopyIcon from '@material-ui/icons/FileCopy';
import EyeAltIcon from '@material-ui/icons/RemoveRedEyeRounded';
import SendCoins from "./Modals/SendCoins";

import BuyCoins from "./Modals/BuyCoins";
import ErrorAlert from "../../Modals/ErrorAlert";
import InfoSuccess from "../../Modals/InfoSuccess";
import MinerFeesReceivedAlert from "../../Modals/MinerFeesReceivedAlert";
import EmailDropDownDialogue from "../../Modals/EmailDropDownDialogue";
import MinerRegistrationInfo from "../../Modals/MinerRegistrationInfo";
import SelectPaypmentMethodModal from "../../Modals/SelectPaypmentMethodModal"
import ChooseMinerType from "../../Modals/ChooseMinerType"
import RegisterWallet from "../../Modals/RegisterWallet"
import SetPasswordForCoinTransfer from "./Modals/SetPasswordForCoinTransfer";
import RetreiveMnemoncisModal from "../../Modals/RetreiveMnemoncisModal"
import PasswordForMnemoncisRetreival from "../../Modals/PasswordForMnemoncisRetreival"
import OtpVerificationModal from "../../Modals/OtpVerificationModal"
import WebcamModal from "../../Modals/WebCam/WebcamModal"
import ChooseWalletModal from "../../Modals/WebCam/ChooseWalletModal"
import ConfirmationModal from "../../Modals/WebCam/ConfirmationModal"
import WalletPinModal from "../../Modals/WebCam/WalletPinModal"
import { Link } from "react-router-dom";
// import NorthIcon from '@material-ui/icons/NorthI';
import RedInfoDialogue from "../../Modals/RedInfoDialogue"
import PasswordForMint from "./Modals/PasswordForMint"
import { getUserWallet, getShopRegisterWallet, getPendingUser } from '../../../api/wallet/wallet';
import { getKycStatus, getToggleValue, getToggleValueByUsername, getUserAddressDetailForKyc } from "../../../api/kyc/kyc"
import { checkUserCanMint } from "../../../api/blockchainapi/blockchainapi"
import { getMinerCategory, getMinerFeesReceivedStatus } from '../../../api/miner/miner';
import { getAirdropToggleValue, getBuyToggleValue } from "../../../api/kyc/kyc"
import { getTransactionHistory } from '../../../api/account';
import { showValidateMnemonicsScreen } from "../../../actions/wallet";
import moment from "moment"
import LoadingOverlay from "../../../Loader";
import { toastMessageInfo, toastMessageFailure } from "../../../utils/toastMessage";
import Header from "../../Layout/Header";
import Footer from "../../Layout/Footer";
import { logOutUser } from "../../../actions/auth"
import ViewHistory from "./Modals/ViewHistory"
import Refresh from '@material-ui/icons/Refresh';
import AirDrop from "../../Modals/AirDrop"
import QRCode from "react-qr-code";
import config from "../../../_config/index"
import RefundModal from "../../Modals/RefundModal";
import BecomeAutoMinterModal from "../../Modals/BecomeAutoMinterModal";
import { storeUserWallet, showRegisterShopScreen } from '../../../actions/wallet';
import YesOrNoModal from "../../Modals/YesOrNoModal";
import MnemonicsModal from "../../Modals/MnemonicsModal";


class WalletListing extends Component {

    state = {
        modalShow: false,
        errorAlert: false,
        isError: false,
        message: "",
        mintBtnDisable: "none",
        // will contain list of user wallet and check if wallet is register as miner
        walletArray: [],
        registerAsMiner: false,

        // modal show
        sendCoinModalShow: false,
        buyCoinModalShow: false,
        passwordModalShow: false,
        successModalShow: false,
        refreshIcon: "fa fa-refresh",
        registerCategory: "",
        transactionHistory: [],
        histroryBtnLoader: "",
        // error and suceses alert title and message
        // Actual use
        minerFeesReceivedModalShow: false,
        emailDropDownModalShow: false,
        mintModalShow: false,
        //show loader on register btn click on wallet listing
        registerBtnLoader: "",
        emailMessagesArrayForMinerRegistration: [],
        minerRegistrationModalShow: false,
        chooseMinerTypeModalShow: false,
        transferSuccess: false,
        registeWalletName: "",
        registerWalletAddress: "",
        registerWalletType: "",
        registerWalletBalance: "",
        historyModalShow: false,
        histoySelectedAccount: "",
        currentPageNumber: 0,
        totalPages: 0,
        historyLoading: false,
        //State use for get all wallet
        isLoading: false,
        // We will set the user selected address amount name and mintable
        selectedWalletId: "",
        selectedWalletBalance: "",
        selectedWalletName: "",
        selectWalletMinttableStatus: "",
        // radio btn defaul value
        defaultCheck: "",
        //Modal title and message
        alertTitlt: "",
        alertMessage: "",
        // modal show variable
        errorModalShow: false,
        //for transfer of coin
        transferAmount: "",
        transferReceiverAddress: "",
        airDropModalShow: false,
        airdropNominated: true,
        showAirdropButton: false,
        airdropToggle: false,
        mnemonicdModalShow: false,
        passwordForMnemoncisRetreivalModalShow: false,
        otpVerificationModalShow: false,
        retreiveMnemoncis: "",
        walletForPaypal: [],
        buyToggleValue: false,
        bitpayToggleValue: false,
        paymentMethodModalShow: false,
        transferToggle: false,
        webcamModalShow: false,
        chooseWalletModalShow: false,

        confirmationModalShow: false,
        walletPinModalShow: false,
        // For card
        selfieImagepath: "",
        chooseWallet: "",
        chooseWalletMnemonics: "",
        paymentCardToggle: false,
        shopBtnLoader: false,
        shopBtnDisable: false,
        createwalletLoader: false,
        buyCoinBtnLoader: false,
        posBtnShow: false,
        //subscribe
        visible: false,
        autoMintableWallet: "",
        visibleSuccessModal: false,
        autoMinerMessage: "",
        autoMintableStatus: false,
        showRefundModal: false,
        proceedMnemonicsModal: false,
        refundAmount: "",
        refundWallet: ""

    }

    setTransferToggleValue = (e) => {

        return new Promise(resolve => {
            getUserAddressDetailForKyc().then(({ data }) => {
                console.log("ku", data)
                if (data.data.length > 0) {
                    getToggleValue('toggle_transfer_coin', data.data[0].country, this.props.auth.user.username).then(countryToggle => {
                        console.log("transfer toggle", countryToggle.data.calculated_toggle)
                        this.setState({ ...this.state, transferToggle: countryToggle.data.calculated_toggle == 1 }, resolve);
                    }).catch(resolve)
                } else {
                    getToggleValueByUsername('toggle_transfer_coin', this.props.auth.user.username).then(toggleValue => {
                        this.setState({ ...this.state, transferToggle: toggleValue.data.calculated_toggle == 1 }, resolve);
                    }).catch(resolve);
                }
            }).catch(resolve)
        })
    }


    goToShopStore = async (e) => {
        this.props.showRegisterShopScreen(true)
        try {
            this.setState({
                shopBtnLoader: true,
                shopBtnDisable: true
            })
            let registerShopWalletExist = await getShopRegisterWallet()

            this.setState({
                shopBtnLoader: false,
                shopBtnDisable: false
            })

            if (registerShopWalletExist?.data?.data?.total_wallet === 0) {
                this.props.history.push("/shop-register")
            }
            else {
                this.props.history.push("/shop-register-detail")

            }


        }
        catch (e) {
            toastMessageFailure("We are facing some issue to load this page")
            this.setState({
                shopBtnLoader: false,
                shopBtnDisable: false
            })

        }
    }

    goToPosTerminal = () => {
        setTimeout(() => {
            this.props.history.push("/pos/terminal")
        }, 200)
    }

    //==================== get user all the wallet if user kyc is done ============
    componentDidMount = async () => {
        try {
            let kycStatus = await getKycStatus();

            this.setTransferToggleValue();

            if (kycStatus?.data?.data?.kyc_status !== 1) {
                toastMessageFailure("Please complete your kyc to access wallet.")
                this.props.history.push("/")

            }
            else {

                this.getUserAllWallet()
                try {

                    // #TODO:Will move all toggle into one api instead of calling 4
                    let airdropToggle = await getAirdropToggleValue("toggle_airdrop", this.props.auth.user.username);
                    let buyToggle = await getBuyToggleValue("toggle_paypal_payment");
                    let waletRegistrationToggle = await getToggleValueByUsername("toggle_payment_customer_registration", this.props.auth.user.username);
                    let marchantToggle = await getToggleValueByUsername("toggle_payment_merchant_terminal", this.props.auth.user.username);

                    if (airdropToggle?.data?.toggle_airdrop?.calculated_toggle === 1) {
                        this.setState({ airdropToggle: true })
                    }

                    if (buyToggle?.data?.toggle_paypal_payment?.calculated_toggle === 1) {
                        this.setState({ buyToggleValue: true })
                    }

                    // }
                    if (waletRegistrationToggle?.data?.toggle_payment_customer_registration?.calculated_toggle === 1) { // TODO remove condition
                        this.setState({ paymentCardToggle: true })
                    }
                    if (marchantToggle?.data?.toggle_payment_merchant_terminal?.calculated_toggle === 1) { // TODO remove condition
                        this.setState({ posBtnShow: true })
                    }
                }
                catch (e) {
                    this.setState({ airdropToggle: false })
                    this.setState({ buyToggleValue: false })
                    this.setState({ paymentCardToggle: true })
                    this.setState({ posBtnShow: false })
                }

                try {
                    let buyBitpayToggle = await getBuyToggleValue("toggle_bitpay_payment");

                    if (buyBitpayToggle?.data?.toggle_bitpay_payment?.calculated_toggle === 1) {
                        this.setState({ bitpayToggleValue: true })
                    }
                } catch (e) { }
            }
        }
        catch (err) {
            if (err?.response?.status === 403) {

                this.props.logOutUser(err?.response?.status)
            }
            else if (err.message === "Network Error") {
                this.props.history.push("/")
                toastMessageFailure("Sorry! We are facing some network issues. Please try again later.")
            }
            else if (err?.response?.data) {
                toastMessageFailure(err?.response?.data?.errors[0]?.message)
                this.props.history.push("/")
            }
        }
    }
    //==================== get user all the wallet if user kyc is done ============

    //=============Method to get all user wallet ============
    getUserAllWallet = async () => {
        let lastSelectedWallet = localStorage.getItem("lastSelectWallet")
        this.setState({ isLoading: true });
        try {
            const { data } = await getUserWallet();
            this.props.storeUserWallet(data.data)
            // check airdrop is done or not
            if (data.data.airdrop === false) {
                this.setState({ showAirdropButton: true })
            }

            // we find the last selected wallet address in the array to make the radio btn active
            const found = data.data.results.find(element => element.account_id === lastSelectedWallet);
            // we  check if last selected wallet is mintable then mint btn is enable
            if (lastSelectedWallet) {
                if (found.mintable_wallet !== 0) {
                    this.setState({ mintBtnDisable: "" })
                }
                this.setState({
                    defaultCheck: found.account_id,
                    selectedWalletId: found.account_id,
                    selectedWalletBalance: found.balance,
                    selectedWalletName: found.account_name,
                    selectWalletMinttableStatus: found.mintable_wallet,
                    autoMintableStatus: data.data.autoMintableWallet,
                    registerAsMiner: data.data.registerAsMiner, walletArray: data.data.results, isLoading: false
                })
            }
            //if there is no last selected wallet then first wallet is selected and also check if it is mintable
            else {

                if (data.data.results[0].mintable_wallet !== 0) {
                    this.setState({ mintBtnDisable: "" })
                }
                localStorage.setItem("lastSelectWallet", data.data.results[0].account_id)
                localStorage.setItem("lastSelectWalletTitle", data.data.results[0].account_name)
                this.setState({
                    defaultCheck: data.data.results[0].account_id,
                    selectedWalletId: data.data.results[0].account_id,
                    selectedWalletBalance: data.data.results[0].balance,
                    selectedWalletName: data.data.results[0].account_name,
                    selectWalletMinttableStatus: data.data.results[0].mintable_wallet,
                    autoMintableStatus: data.data.autoMintableWallet,
                    registerAsMiner: data.data.registerAsMiner, walletArray: data.data.results, isLoading: false
                })
            }
        }
        catch (err) {

            this.setState({ isLoading: false });
            // jwt not authorizes logout the user
            if (err?.response?.status === 403) {

                // this.props.logOutUser(err?.response?.status)
            }
            else if (err.message === "Network Error" || err?.response?.status === 500) {
                this.setState({ alertTitlt: "Alert!", errorModalShow: true, alertMessage: "We are facing some network issue.Please try again later." })
            }
            else if (err?.response?.data) {
                this.setState({ alertTitlt: "Alert!", errorModalShow: true, alertMessage: err?.response?.data?.errors[0]?.message })
            }
        }
    }
    //=============End Method to get all user wallet ============


    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
            [e.target.name.concat('error')]: '',
        })

    }

    showTransactionHistory = async (accountId, page) => {
        // toastMessageInfo("This section is currently unavailable. You will soon be able to access its history")
        this.setState({ alertMessage: "We have temporarily disabled this feature as we are currently working on it. We will soon be launching it with exciting enhancements.", successModalShow: true, })
        // accountId = "5CPgqB68RDP6AodFKz17ADKM96E52evxYLC22Q2fxoSwGUDp"
        // this.setState({ histoySelectedAccount: accountId }, () => {
        //     this.setState({
        //         historyModalShow: true,
        //         historyLoading: true

        //     })
        // });
        // try {
        //     const trxHistory = await getTransactionHistory({ accountId, page });
        //     this.setState({ ...this.state, histroryBtnLoader: "" });
        //     this.setState({
        //         historyLoading: false,
        //         transactionHistory: trxHistory.data,
        //         currentPageNumber: page,
        //         totalPages: parseInt(trxHistory.total / 20)
        //     }, () => {

        //         this.setState({ historyModalShow: true })
        //     })
        // }
        // catch (e) {
        //     this.setState({
        //         historyLoading: false,
        //         historyModalShow: false
        //     })
        //     toastMessageFailure("We are failing to find the transaction history. Please try again.")

        // }
    }
    // ====================================== REGISTER AS MINER ============================
    // =====  Miner Fees Received ===========
    minerRegistration = async (value, walletName, walletType, balance) => {


        // this.setState({ alertMessage: "We have temporarily disabled this feature as we are currently working on it. We will soon be launching it with exciting enhancements.", successModalShow: true, })

        // return
        try {
            this.setState({ ...this.state, registerBtnLoader: value });
            const { data } = await getMinerFeesReceivedStatus();
            console.log("fffff", data)

            if (data.data[0].miner_fees_received === 1) {
                this.setState({ registerBtnLoader: "", registerWalletType: walletType, registeWalletName: walletName, registerWalletAddress: value, registerWalletBalance: balance }, () => {
                    this.setState({ minerRegistrationModalShow: true })
                });
            }
            else {
                this.setState({ registerBtnLoader: "", registerWalletType: walletType, registeWalletName: walletName, registerWalletAddress: value, registerWalletBalance: balance }, () => {
                    this.setState({ minerFeesReceivedModalShow: true, alertTitlt: "Miner Fees not paid yet", alertMessage: `Please pay it before proceeding with miner slot booking` })
                });

            }
        }
        catch (err) {

            if (err?.response?.status === 403) {
                this.setState({ registerBtnLoader: "" });
                this.props.logOutUser(err?.response?.status)
            }
            else if (err.message === "Network Error" || err?.response?.status === 500) {
                this.setState({ registerBtnLoader: "", errorModalShow: true, alertTitlt: "", alertMessage: "Sorry! We are facing some network issue.Please try again later." })
            }
            else if (err?.response?.data) {
                this.setState({ registerBtnLoader: "", errorModalShow: true, alertTitlt: "Oh! You got an error!", alertMessage: err?.response?.data?.errors[0]?.message })
            }
        }
    }



    makeWalletMintable = async (walletAddress) => {
        this.setState({ autoMintableWallet: walletAddress, visible: true })

    }

    // ====================================== END REGISTER AS MINER ==========================

    // copy address from the
    copyMainAddress = async () => {
        const el = document.createElement('textarea');
        el.value = this.state.selectedWalletId;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        toastMessageInfo("You successfully copy the wallet address!")
    }
    // copy address from each row
    copyAddressFromList = async (address) => {
        const el = document.createElement('textarea');
        el.value = address
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        toastMessageInfo("You successfully copy the wallet address!")
    }
    // radio btn switched between account
    radioBtnClickOnAccountSwitch = async (accountDetail) => {

        localStorage.setItem("lastSelectWallet", accountDetail.account_id)
        localStorage.setItem("lastSelectWalletTitle", accountDetail.account_name)
        // if account is not mintable then mint btn will be disable
        if (accountDetail.mintable_wallet === 0) {
            this.setState({ mintBtnDisable: "none" })
        }
        // if account is mintable then mint btn enable
        else {
            this.setState({ mintBtnDisable: "" })
        }
        // store each account record
        this.setState({
            selectedWalletId: accountDetail.account_id,
            selectedWalletBalance: accountDetail.balance,
            selectedWalletName: accountDetail.account_name,
            selectWalletMinttableStatus: accountDetail.mintable_wallet
        })
    }

    // minc coin click
    mintCoin = async () => {
        try {
            this.setState({ isLoading: true });

            let mintToggle = await getToggleValueByUsername("toggle_mint_coin", this.props.auth.user.username)

            if (mintToggle?.data?.toggle_mint_coin?.calculated_toggle === 0) {

                this.setState({ isLoading: false });
                this.setState({ alertTitlt: "", errorModalShow: true, alertMessage: "Hi, the mint coin feature is not available until further notice, we apologies for any inconvenience. You will be notified by the NuCoin Admin team once it becomes available again." })


            }
            else {

                const { data } = await getMinerCategory(this.state.selectedWalletId);

                if (data.data[0].category === "SILVER") {
                    // check if mint time available
                    try {
                        let response = await checkUserCanMint(this.state.selectedWalletId)

                        this.setState({ isLoading: false });
                        if (response.data.canMint === true) {

                            let fromExist = localStorage.getItem(this.state.selectedWalletId)
                            // if address not exist in local then show mnemonics modal
                            if (!fromExist) {

                                this.setState({
                                    redInfoModalShow: true,
                                    purposeOfRedInfoDialogue: "mintCoin"
                                })
                            }
                            // if address exist in local then show password modal
                            else {
                                this.setState({ mintModalShow: true })
                            }




                        } else {


                            this.setState({ alertTitlt: "You have already minted coin(s) for today.", errorModalShow: true, alertMessage: `You are allowed to mint again in ${response.data.hoursLeft} hour(s).` })
                        }
                    }
                    catch (err) {
                        this.setState({ isLoading: false });
                        this.setState({ alertTitlt: "", errorModalShow: true, alertMessage: err.message })
                    }
                }

                else {
                    this.setState({ isLoading: false });
                    this.setState({ alertTitlt: "", errorModalShow: true, alertMessage: `You are not allowed to mint, because your account is a ${(data.data[0].category)} staker account` })
                }
            }
        }
        catch (err) {
            this.setState({ isLoading: false });
            if (err?.response?.status === 403) {
                this.props.logOutUser(err?.response?.status)
            }
            else if (err.message === "Network Error") {
                this.setState({ alertTitlt: "", errorModalShow: true, alertMessage: "Sorry! We are facing some network issue.Please try again later." })
            }
            else if (err?.response?.data) {
                this.setState({ alertTitlt: "Oh! We got an error!", errorModalShow: true, alertMessage: err?.response?.data?.errors[0]?.message })
            }
        }
    }


    ShowMnemonics = async () => {
        // check if user address exist in local db

        let checkWalletExist = localStorage.getItem(this.state.selectedWalletId)
        if (checkWalletExist) {
            //open modal to take password
            this.setState({
                alertTitlt: "",
                passwordForMnemoncisRetreivalModalShow: true

            })
        }
        else {
            this.setState({ alertTitlt: "", errorModalShow: true, alertMessage: "We do apologize, your private key (mnemonics) are not available on this device, please try this feature on the device where you have created your wallet for the first time" })

        }
    }

    render() {
        console.log("transactionHistory", this.state.autoMintableStatus)

        return (
            <>
                {this.state.isLoading === true ? <LoadingOverlay></LoadingOverlay> : null}
                {/* Error Modal */}
                <ErrorAlert showErroModal={this.state.errorModalShow} title={this.state.alertTitlt} message={this.state.alertMessage} onCloseErrorModal={() =>
                    this.setState({
                        errorModalShow: false,
                        alertTitlt: "",
                        alertMessage: ""
                    })} />

                {/* ===================== Success Modal show =================== */}
                <InfoSuccess showSuccessModal={this.state.successModalShow} title={this.state.alertTitlt} message={this.state.alertMessage} onCloseSuccessModal={() => {

                    // if we open success modal on transfer coin then we will call getUserAllWallet to get the actual wallet balance after transfer
                    if (this.state.transferSuccess === true) {
                        this.setState({
                            successModalShow: false,
                            alertTitlt: "",
                            alertMessage: ""
                        }, () => {
                            this.getUserAllWallet()
                        })
                    }
                    else {
                        this.setState({
                            successModalShow: false,
                            alertTitlt: "",
                            alertMessage: ""
                        })
                    }
                }
                } />
                {/* ===================== Success Modal end =================== */}

                {/* ===================== Send Coin modal =================== */}

                <SendCoins
                    showSendCoinModal={this.state.sendCoinModalShow}
                    fromAddress={this.state.selectedWalletId}
                    walletName={this.state.selectedWalletName}
                    history={this.props.history}
                    onCloseSednCoinModal={(openPasswordDialoug, amount, receiverAddress) => {
                        //close the send coin modal
                        this.setState({ sendCoinModalShow: false }, () => {
                            // if user want to proceed and enter the amount and reciever address then we will open password dialogue
                            if (openPasswordDialoug === true) {
                                this.setState({ transferAmount: amount }, () => {
                                    this.setState({ transferReceiverAddress: receiverAddress }, () => {
                                        this.setState({ passwordModalShow: true })
                                    })
                                })
                            }
                        })
                    }}
                />
                {/* ============================== Send Coin modal end ============================= */}



                {/* ===================== Buy Coin modal =================== */}

                <BuyCoins
                    showBuyCoinModal={this.state.buyCoinModalShow}
                    walletForPaypal={this.state.walletArray}
                    onCloseBuyCoinsModal={() => {
                        this.setState({ buyCoinModalShow: false })


                    }}

                />
                {/* ============================== Buy Coin modal end ============================= */}



                {/* ============================== password dialogue for transaction  ========================== */}

                <SetPasswordForCoinTransfer
                    showPasswordModal={this.state.passwordModalShow}
                    amount={this.state.transferAmount}
                    fromAddress={this.state.selectedWalletId}
                    walletName={this.state.selectedWalletName}
                    toAddress={this.state.transferReceiverAddress}
                    history={this.props.history}
                    headerLabel={"Enter Password"}
                    onClosePasswordModal={(hash, transferCoin) => {
                        // if transaction is successfull then we will receive hash and transfercoin=true props from the transfer coin dialogue

                        if (transferCoin === true) {
                            // now we will hide this dialgoue and show success dialogue with transaction hash
                            this.setState({ passwordModalShow: false }, () => {
                                this.setState({ transferSuccess: true, alertTitlt: "Transaction Success", successModalShow: true, alertMessage: "Your transaction hash " + hash })
                            })
                        }
                        else {
                            this.setState({ passwordModalShow: false })
                        }
                    }}
                />
                {/* ============================== password dialogue for transaction end   ========================== */}


                {/* Miner fees received status modal */}

                <MinerFeesReceivedAlert showMinerFeesReceivedModal={this.state.minerFeesReceivedModalShow} title={this.state.alertTitlt} message={this.state.alertMessage}
                    onOpenEmailDropDownDialogue={(emailMessages) => {
                        console.log("messss", emailMessages)


                        this.setState({
                            minerFeesReceivedModalShow: false,
                            emailMessagesArrayForMinerRegistration: emailMessages,
                            alertTitlt: "",
                            alertMessage: ""
                        }, () => {

                            this.setState({ emailDropDownModalShow: true })
                        })
                    }}
                    onCloseMinerFeesReceivedModal={() =>
                        this.setState({
                            minerFeesReceivedModalShow: false,
                            alertTitlt: "",
                            alertMessage: ""
                        })} />

                {/* Email Drop down modal */}
                <EmailDropDownDialogue
                    walletName={this.state.registeWalletName}
                    walletAddress={this.state.registerWalletAddress}
                    walletBalance={this.state.registerWalletBalance}
                    emailMessage={this.state.emailMessagesArrayForMinerRegistration} showEmailDropDownModal={this.state.emailDropDownModalShow} onCloseEmailDropDownModal={(emailSend) => {
                        if (emailSend === true) {
                            this.setState({
                                emailDropDownModalShow: false,

                            }, () => {
                                this.setState({ alertTitlt: "Email Send Successfully", successModalShow: true, alertMessage: "We received your email we will reply back to you soon. Thanks" })

                            })
                        }
                        else {
                            this.setState({
                                emailDropDownModalShow: false,
                            })
                        }
                    }} />



                {/* Miner Registration info */}

                <MinerRegistrationInfo
                    showMinerRegistrationModal={this.state.minerRegistrationModalShow} onCloseMinerRegistrationInfoModal={(chooseMiner) => {
                        if (chooseMiner === true) {
                            // alert("becoe miner")
                            this.setState({
                                minerRegistrationModalShow: false,
                                chooseMinerTypeModalShow: true
                            })
                        }
                        else {
                            this.setState({
                                minerRegistrationModalShow: false
                            })
                        }

                    }}>
                </MinerRegistrationInfo>

                <ChooseMinerType showChooseMinerTypeModal={this.state.chooseMinerTypeModalShow}
                    fromAddress={this.state.registerWalletAddress}
                    onCloseChooseMinerTypeModal={(registerWallet, category) => {
                        if (registerWallet === true) {
                            this.setState({
                                registerCategory: category,
                                chooseMinerTypeModalShow: false,
                                minerRegistrationModalShow: false
                            }, () => {


                                let fromExist = localStorage.getItem(this.state.registerWalletAddress)
                                // if address not exist in local then show mnemonics modal

                                if (!fromExist) {

                                    this.setState({
                                        redInfoModalShow: true,
                                        purposeOfRedInfoDialogue: "registerWallet"
                                    })
                                }
                                // if address exist in local then show password modal
                                else {
                                    this.setState({ registerWalletModalShow: true })
                                }
                            })


                        }
                        else {
                            this.setState({
                                chooseMinerTypeModalShow: false,
                                minerRegistrationModalShow: true
                            })

                        }

                    }}>

                </ChooseMinerType>


                {/* register wallet */}

                <RegisterWallet
                    showRegisterWalletModal={this.state.registerWalletModalShow}
                    walletAddress={this.state.registerWalletAddress}
                    registerCategory={this.state.registerCategory}
                    walletName={this.state.registeWalletName}
                    walletType={this.state.registerWalletType}
                    history={this.props.history}
                    headerLabel={"Enter Password"}


                    onCloseShowRegisterWalletModal={(registerSuccess, hash) => {

                        if (registerSuccess === true) {
                            this.setState({
                                registerWalletModalShow: false
                            }, () => {
                                this.setState({
                                    registeWalletName: '',
                                    registerWalletAddress: "",
                                    registerWalletType: "",
                                    registerCategory: "",
                                    transferSuccess: true, alertTitlt: "Transaction Success", successModalShow: true, alertMessage: "Your wallet is successfully register as a miner.\nTransaction Hash : " + hash
                                })

                            })
                        }
                        else {
                            this.setState({
                                registerWalletModalShow: false, registeWalletName: '',
                                registerWalletType: "",
                                registerWalletAddress: ""
                            })
                        }
                    }}
                />


                {/* Mint modal */}
                <PasswordForMint
                    showMintModal={this.state.mintModalShow}
                    fromAddress={this.state.selectedWalletId}
                    walletName={this.state.selectedWalletName}
                    history={this.props.history}
                    headerLabel={"Enter Password"}
                    onCloseMintModal={(hash, mintSuccess, nextMint) => {
                        if (mintSuccess === true) {
                            this.setState({ mintModalShow: false }, () => {
                                this.setState({
                                    transferSuccess: false, alertTitlt: "You have minted successfully", successModalShow: true, alertMessage: `Your next minting session will be in ${nextMint} hour(s).`
                                })
                            })

                        }

                        else {
                            this.setState({ mintModalShow: false })

                        }
                    }
                    }
                ></PasswordForMint>
                <ViewHistory
                    showHistoryModal={this.state.historyModalShow}
                    transactionHistory={this.state.transactionHistory}
                    account={this.state.histoySelectedAccount}
                    currentPage={this.state.currentPageNumber}
                    totalPage={this.state.totalPages}
                    isLoading={this.state.historyLoading}
                    onNextBtnClick={(account, page) => {

                        this.showTransactionHistory(account, page)
                    }}
                    onPreviousBtnClick={(account, page) => {

                        this.showTransactionHistory(account, page)
                    }}
                    onCloseMintModal={() => {
                        this.setState({
                            historyModalShow: false,
                            currentPageNumber: 0,
                            totalPages: 0,
                            historyLoading: false,
                            transactionHistory: []
                        })
                    }
                    }>
                </ViewHistory>


                <AirDrop
                    wallet={this.state.selectedWalletId}
                    showAirDropModal={this.state.airDropModalShow} onCloseAirDropModal={(airdropSuccess) => {
                        if (airdropSuccess === true) {
                            this.setState({ showAirdropButton: false }, () => {
                                this.setState({ airDropModalShow: false })

                            })
                        }
                        else {
                            this.setState({ airDropModalShow: false })

                        }

                    }

                    }


                >

                </AirDrop>


                <PasswordForMnemoncisRetreival
                    showPasswordForMnemoncisRetreivalModal={this.state.passwordForMnemoncisRetreivalModalShow} fromAddress={this.state.selectedWalletId} onClosePasswordForMnemoncisRetreivalModal={(showMnemonicsRetreive, mnemonics) => {
                        if (showMnemonicsRetreive === true) {

                            this.setState({
                                passwordForMnemoncisRetreivalModalShow: false,
                                retreiveMnemoncis: mnemonics,
                                mnemonicdModalShow: true,

                            })
                        }
                        else {
                            this.setState({
                                passwordForMnemoncisRetreivalModalShow: false,
                                retreiveMnemoncis: ""
                            })
                        }

                    }}>
                </PasswordForMnemoncisRetreival>



                <RetreiveMnemoncisModal
                    showMnemonicdModalShow={this.state.mnemonicdModalShow} onCloseRetreiveMnemoncisModal={(showOtp) => {
                        if (showOtp === true) {


                            // alert("becoe miner")
                            this.setState({
                                mnemonicdModalShow: false,
                                retreiveMnemoncis: this.state.retreiveMnemoncis,
                                otpVerificationModalShow: true
                            })
                        }
                        else {
                            this.setState({
                                mnemonicdModalShow: false,
                                retreiveMnemoncis: ""
                            })
                        }

                    }}>
                </RetreiveMnemoncisModal>

                <OtpVerificationModal
                    showOtpVerificationModal={this.state.otpVerificationModalShow} retreiveMnemoncis={this.state.retreiveMnemoncis} onCloseOtpModal={() => {


                        this.setState({
                            otpVerificationModalShow: false,
                            retreiveMnemoncis: ""
                        })


                    }}>
                </OtpVerificationModal >


                <SelectPaypmentMethodModal showPaymentMethodModal={this.state.paymentMethodModalShow} onClosePaymentMethodModal={() => {

                    this.setState({
                        paymentMethodModalShow: false,

                    })

                }}

                    onProceddPaymentMethodModal={(value) => {
                        // alert(value)

                        this.setState({
                            paymentMethodModalShow: false,

                        }, () => {
                            if (value === config.paymentType[0]) {

                                this.props.history.push("/purchase-coins",
                                    {
                                        subscriber_id: config.paypalData.subscriber_id,
                                        payment_subscription_id: config.paypalData.payment_subscription_id
                                    }
                                )
                            }

                            if (value === config.paymentType[1]) {

                                this.props.history.push("/purchase-coins",
                                    {
                                        subscriber_id: config.bitpayData.subscriber_id,
                                        payment_subscription_id: config.bitpayData.payment_subscription_id
                                    }
                                )
                            }
                        })




                    }}

                ></SelectPaypmentMethodModal>


                {/* ============ Webcam modal =========== */}

                <WebcamModal
                    showWebcamModal={this.state.webcamModalShow}
                    onCloseWebcamModal={() => {
                        this.setState({ webcamModalShow: false })
                    }}
                    onProceedWebcamModal={(imagePath) => {
                        this.setState({ webcamModalShow: false, selfieImagepath: imagePath, chooseWalletModalShow: true }, () => {
                        })
                    }}
                ></WebcamModal>

                <ChooseWalletModal
                    showChooseWalletModal={this.state.chooseWalletModalShow}
                    onCloseChooseWalletModal={() => {
                        this.setState({ chooseWalletModalShow: false })
                    }}
                    onProceedChooseWalletModal={(mnemonics, wallet) => {



                        this.setState({ chooseWallet: wallet, chooseWalletMnemonics: mnemonics, confirmationModalShow: true }, () => {

                        })
                    }}
                ></ChooseWalletModal>

                <ConfirmationModal
                    showConfirmationModal={this.state.confirmationModalShow}
                    wallet={this.state.chooseWallet}
                    onCloseConfirmationModal={(value) => {
                        if (value) {

                            this.setState({ confirmationModalShow: false, chooseWalletModalShow: false, walletPinModalShow: true })

                        }
                        else {
                            this.setState({ confirmationModalShow: false })
                        }

                    }}
                ></ConfirmationModal>

                <WalletPinModal

                    showWaletPinModal={this.state.walletPinModalShow}
                    wallet={this.state.chooseWallet}
                    mnemonics={this.state.chooseWalletMnemonics}
                    imagePath={this.state.selfieImagepath}
                    onCloseWalletPinModal={() => {
                        this.setState({ walletPinModalShow: false })
                    }}
                    onSuccessConfirmationModal={() => {
                        this.setState({ walletPinModalShow: false }, () => {
                            this.setState({
                                transferSuccess: false, alertTitlt: "Congratulation!", successModalShow: true, alertMessage: `Your wallet succesffully register`
                            })
                        })
                    }}
                ></WalletPinModal>



                <BecomeAutoMinterModal walletAddress={this.state.autoMintableWallet} disable={this.state.makeWalletAutoMintableButtonDisable} loader={this.state.makeWalletAutoMintableLoader} visible={this.state.visible} msg="When you subscribe, you won't have to worry about manually minting coins, as the system will handle the process automatically on your behalf. This means that the system will generate new coins for you without requiring any manual intervention or effort from your side. It simplifies the process and ensures that you receive the benefits of your subscription without any extra steps or actions needed." onCloseYesOrNoModal={(value, mintValue) => {

                    if (value === true) {
                        if (mintValue === 1) {
                            this.setState({
                                visible: false,
                                autoMinerMessage: "The autoMinting subscription has been successfully activated.",
                                visibleSuccessModal: true
                            })
                        }
                        else if (mintValue === 2) {
                            this.setState({
                                visible: false,
                                autoMinerMessage: "Your autominting request has been sent to the admin. Until the admin approves it and your autominting starts, you can manually mint.",
                                visibleSuccessModal: true
                            })

                        }


                    }
                    else {
                        this.setState({
                            visible: false,

                        })

                    }






                }} />



                <SuccessModal visible={this.state.visibleSuccessModal} message={this.state.autoMinerMessage} onCloseModal={() =>
                    this.setState({
                        autoMinerMessage: "",
                        visibleSuccessModal: false

                    }, () => {
                        this.getUserAllWallet()

                    })} />





                {/* ============ Webcam modal =========== */}

                <div className="BlackStarsBack">
                    <div id="Blackstars" />
                    <div id="Blackstars2" />
                    <div id="Blackstars3" />
                </div>

                <div className="row" id="zIndexMob">
                    <div className="col-md-8 offset-md-2">
                        <div className="AccountAddress text-center">
                            <h4>Account Address</h4>
                            <div className="Box">
                                <img src="assets/images/nucoin-logo.png" className="mb-2" alt="" />
                                <h3 className="mb-4">{this.state.selectedWalletBalance} NUC</h3>
                                <ul className="list-inline d-flex justify-content-center">
                                    {
                                        !this.state.autoMintableStatus && <li>
                                            <Link to="#" style={{ pointerEvents: this.state.mintBtnDisable }} type="button" onClick={(event) => this.mintCoin(event)}>
                                                <span className="material-icons" style={{ background: '#037d50' }}>
                                                    {/* btn-mint */}
                                                    <SaveAltIcon />
                                                </span>
                                                Mint
                                            </Link>
                                        </li>
                                    }

                                    <li>
                                        <Link to="#" type="button" variant="primary" onClick={async () => {
                                            this.setState({ ...this.state, isLoading: true });


                                            //first check pending wallet exist
                                            try {



                                                let pendingRefund = await getPendingUser({ accountId: this.state.selectedWalletId })

                                                if (pendingRefund.data.data.refundableWallet) {

                                                    this.setState({ ...this.state, isLoading: false, showRefundModal: true, refundAmount: pendingRefund.data.data.refundInfo.refundAmount })

                                                }
                                                else {
                                                    this.setTransferToggleValue().then(() => {
                                                      
                                                        if (!this.state.transferToggle) {
                                                            this.setState({ ...this.state, alertTitlt: "", isLoading: false, errorModalShow: true, alertMessage: "Hi, the transfer feature is not available until further notice, we apologies for any inconvenience. You will be notified by the NuCoin Admin team once it becomes available again." })
                                                        } else {
                                                            this.setState({ sendCoinModalShow: true, isLoading: false }, () => {
                                                            })
                                                        }
                                                    })

                                                }

                                            }
                                            catch (err) {
                                                this.setState({ ...this.state, isLoading: false });

                                                if (err.message === "Network Error" || err?.response?.status === 500) {
                                                    this.setState({ alertTitlt: "Alert!", errorModalShow: true, alertMessage: "We are facing some network issue.Please try again later." })
                                                }
                                                else if (err?.response?.data) {
                                                    this.setState({ alertTitlt: "Alert!", errorModalShow: true, alertMessage: err?.response?.data?.errors[0]?.message })
                                                }

                                            }







                                        }
                                        }>
                                            <span className="material-icons">
                                                <ArrowUpward />
                                            </span>
                                            Send
                                        </Link>



                                    </li>



                                    <li>
                                        <Link to="#" onClick={async () => {
                                            try {
                                                this.setState({ createwalletLoader: true });
                                                let mintToggle = await getToggleValueByUsername("toggle_create_wallet", this.props.auth.user.username)
                                                this.setState({ createwalletLoader: false });
                                                if (mintToggle?.data?.toggle_create_wallet?.calculated_toggle === 0) {
                                                    this.setState({ alertTitlt: "", errorModalShow: true, alertMessage: "Hi, the wallet creation feature is not available until further notice, we apologies for any inconvenience. You will be notified by the NuCoin Admin team once it becomes available again." })
                                                }
                                                else {
                                                    this.props.history.push("/create-wallet")

                                                }

                                            }
                                            catch (e) {
                                                this.setState({ createwalletLoader: false });

                                                this.props.history.push("/create-wallet")
                                            }

                                        }

                                        }


                                        >
                                            <span className="material-icons">
                                                <AddIcon />
                                            </span>
                                            {this.state.createwalletLoader ? <img alt="loading..." style={{ height: '20px', width: '20px', marginRight: '8px' }} src="/assets/images/spinner3.gif"></img> : null}

                                            Wallet



                                        </Link>
                                    </li>
                                    <li>

                                        {/* onClick={() => this.setState({ buyCoinModalShow: true }, () => {
                                                // alert("this call")
                                            })} */}
                                        {
                                            this.state.buyToggleValue === true || this.state.bitpayToggleValue === true ? <Link to="#"
                                                onClick={async () => {
                                                    try {
                                                        this.setState({ buyCoinBtnLoader: true });
                                                        let mintToggle = await getToggleValueByUsername("toggle_buy_coin", this.props.auth.user.username)
                                                        this.setState({ buyCoinBtnLoader: false });
                                                        if (mintToggle?.data?.toggle_buy_coin?.calculated_toggle === 0) {
                                                            this.setState({ alertTitlt: "", errorModalShow: true, alertMessage: "Hi, the buy coin feature is not available until further notice, we apologies for any inconvenience. You will be notified by the NuCoin Admin team once it becomes available again." })
                                                        }
                                                        else {
                                                            this.props.history.push("/purchase-coins")

                                                        }

                                                    }
                                                    catch (e) {
                                                        this.setState({ buyCoinBtnLoader: false });

                                                        this.props.history.push("/purchase-coins")
                                                    }

                                                }

                                                }



                                                type="button" variant="primary" >
                                                <span className="material-icons">
                                                    <SouthWestIcon />
                                                </span>
                                                {this.state.buyCoinBtnLoader ? <img alt="loading..." style={{ height: '20px', width: '20px', marginRight: '8px' }} src="/assets/images/spinner3.gif"></img> : null}
                                                Buy
                                            </Link> :
                                                <a rel="noopener noreferrer" href="https://nugenesisou.myshopify.com/" target="_blank" variant="primary" onClick={() => this.setState({ errorAlert: true })}>
                                                    <span className="material-icons">
                                                        <SouthWestIcon />
                                                    </span>
                                                    Buy
                                                </a>
                                        }


                                        {/* <a rel="noopener noreferrer" href="https://nugenesisou.myshopify.com/" target="_blank" variant="primary" onClick={() => this.setState({ errorAlert: true })}>
                                                <span className="material-icons">
                                                    <SouthWestIcon />
                                                </span>
                                            Buy
                                        </a> */}
                                    </li>

                                    <li>
                                        <Link to="#" type="button" onClick={(event) => this.ShowMnemonics(event)} >
                                            <span className="material-icons btn-show-mnemonics">
                                                <EyeAltIcon />
                                            </span>
                                            Show Key
                                        </Link>
                                    </li>

                                    {
                                        this.state.paymentCardToggle && <li>
                                            <Link to="#" type="button"
                                                onClick={() => {
                                                    this.goToShopStore()
                                                    //    (event) => {

                                                    //      this.props.showRegisterShopScreen(true)
                                                    //      this.props.history.push("/shop-register")


                                                    //     // this.setState({ webcamModalShow: true })
                                                }
                                                }

                                            >
                                                <span className="material-icons btn-show-mnemonics" style={{ background: '#FF00FF' }}>
                                                    <StoreMallDirectoryOutlined />
                                                </span>
                                                {this.state.shopBtnLoader ? <img alt="loading..." style={{ height: '20px', width: '20px', marginRight: '8px' }} src="/assets/images/spinner3.gif"></img> : null}
                                                Store Reg.
                                            </Link>
                                        </li>
                                    }

                                    {
                                        this.state.posBtnShow &&
                                        <li>
                                            <Link to="/pos/terminal"
                                                onClick={() => {
                                                    this.goToPosTerminal()
                                                }
                                                }
                                            >
                                                <span className="material-icons btn-show-mnemonics" style={{ background: '#FF00FF' }}>
                                                    <ShoppingCart />
                                                </span>
                                                {this.state.shopBtnLoader ? <img alt="loading..." style={{ height: '20px', width: '20px', marginRight: '8px' }} src="/assets/images/spinner3.gif"></img> : null}
                                                POS
                                            </Link>
                                        </li>
                                    }






                                </ul>
                            </div>

                            <div className="d-flex justify-content-center text-color mb-4">
                                <div className="qr d-flex align-items-center justify-content-center">
                                    <div className="mr-4">
                                        <QRCode style={{ color: 'black' }} className="qr-code" fill="#fff" size={64} value={this.state.selectedWalletId}></QRCode>

                                    </div>
                                    <div className="text-left">
                                        <span className="qr-address text-break">{this.state.selectedWalletId}</span>
                                        <button className="btn btn-outline-light text-white bg-transparent border-0">
                                            <span className="material-icons copy-icon">
                                                <ContentCopyIcon onClick={this.copyMainAddress} />
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        {
                            this.state.showAirdropButton === true && this.state.airdropToggle === true ?
                                <div className="text-center mt-0 w-100"><Link className="Read-More" to="#" onClick={() => {
                                    this.setState({ airDropModalShow: true })
                                }}>CLICK TO CLAIM YOUR MISSED AIRDROP</Link></div>
                                : null
                        }

                        <div className="text-color" style={{ display: "flex", flexDirectior: "row", justifyContent: 'center', alignItems: 'center' }}>
                            <h4 className="my-3 font-weight-bold">Wallet List
                            </h4>

                            <Refresh className="font-weight-bold" style={{ cursor: 'pointer' }} onClick={() => { this.getUserAllWallet() }}></Refresh>

                        </div>

                        <div className="table-responsive">



                            <table className="table PanelBody text-center">
                                <thead>

                                    <tr>
                                        <th width="5%"><p>#</p></th>
                                        <th width="15%"><p>Account Name</p></th>
                                        <th width="15%"><p>Address</p></th>
                                        <th width="15%"><p>Created Date</p></th>
                                        <th width="8%"><p>Balance</p></th>
                                        <th width="8%"><p>Show Qr</p></th>
                                        <th width="12%"><p>Mintable Wallet</p></th>
                                        <th width="10%"><p>Mint Session</p></th>
                                        <th width="15%"><p>Transaction History</p></th>
                                        <th width="15%"><p>Auto Mint</p></th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {this.state.walletArray.map((item, index) => (
                                        <tr key={item.account_id}>
                                            <td className="Visit">
                                                <p>{index + 1}</p>
                                            </td>
                                            <td className="Visit">
                                                <p>{item.account_name}</p>
                                            </td>
                                            <td className="Visit Address">
                                                <p>
                                                    {item.account_id}
                                                </p>
                                                <ContentCopyIcon onClick={() => { this.copyAddressFromList(item.account_id) }} type="button" />
                                            </td>
                                            <td className="Visit">
                                                <p>{moment(item.create_time).format('dddd YYYY-MM-DD HH:mm:ss a')}</p>
                                            </td>
                                            <td className="Visit">
                                                <p>{item.balance}</p>
                                            </td>
                                            <td className="Visit ShowQr">
                                                {/* <label className="switch">
                                                        <input type="checkbox" />
                                                        <span className="slider round" />
                                                    </label> */}

                                                <label onClick={() => { this.radioBtnClickOnAccountSwitch(item) }}

                                                    className="switch" >

                                                    <input type="radio" name="kk" defaultChecked={(this.state.defaultCheck === item.account_id) ? 'checked' : ''} />
                                                    <span className="slider round"></span>
                                                </label>

                                            </td>
                                            <td className="Visit">
                                                {
                                                    // if register as miner is true it means user one of the account is miner account so will not show register btn
                                                    this.state.registerAsMiner === true ?
                                                        // will check miner type and give him name(1=silver,2=gold,3=Diamond,4=s,bundle) while other wallet represent as ___
                                                        item.mintable_wallet === 0 ? <p>___</p> :
                                                            item.mintable_wallet === 1 ? <p>Silver</p> :
                                                                item.mintable_wallet === 2 ? <p>Gold</p> :
                                                                    item.mintable_wallet === 3 ? <p>Diamond</p> :
                                                                        item.mintable_wallet === 4 ? <p>s-bundle</p> : <p>___</p> :

                                                        // if no account is register as a miner
                                                        <button type="button" onClick={() => { this.minerRegistration(item.account_id, item.account_name, item.wallet_type, item.balance) }} className="btn btn-primary RegisterWallet">
                                                            {this.state.registerBtnLoader === item.account_id ? <img alt="loading..." style={{ height: '20px', width: '20px', marginRight: '8px' }} src="/assets/images/spinner3.gif"></img> : null}
                                                            Register</button>}
                                            </td>
                                            <td className="Visit">
                                                {item.last_mint_session ? moment(item.last_mint_session).format('dddd YYYY-MM-DD HH:mm:ss a') : <p>___</p>}
                                            </td>



                                            <td className="Visit">
                                                <button onClick={() => { this.showTransactionHistory(item.account_id, 0) }} type="button" className="btn btn-primary ViewHistory">
                                                    History</button>
                                            </td>


                                            <td className="Visit">
                                                {
                                                    // if register as miner is true it means user one of the account is miner account so will not show register btn
                                                    this.state.registerAsMiner === true ?
                                                        // will check miner type and give him name(1=silver,2=gold,3=Diamond,4=s,bundle) while other wallet represent as ___
                                                        item.mintable_wallet === 0 ? <p>___</p> : item.mintable_wallet === 1 && item.auto_mint_wallet === 1 ? <p>Subscribed <span style={{ color: "green", fontWeight: "800", fontSize: 20 }}>✅</span></p> : item.mintable_wallet === 1 && item.auto_mint_wallet === 2 ? <p>Awaiting admin confirmation... <span style={{ color: "green", fontWeight: "800", fontSize: 20 }}>⏳</span></p> :
                                                            <button onClick={() => { this.makeWalletMintable(item.account_id) }} type="button" className="btn btn-primary ViewHistory">
                                                                Subscribe to autoMint</button>
                                                        :



                                                        // if no account is register as a miner

                                                        null

                                                }
                                            </td>



                                        </tr>
                                    ))}

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <Footer />
                <RefundModal onProceed={() => {
                    this.setState({ showRefundModal: false, proceedMnemonicsModal: true })

                }} refundModalShow={this.state.showRefundModal} onCloseRefundModal={
                    () => {
                        this.setState({ showRefundModal: false })
                    }
                } />
                <MnemonicsModal onClose={(val) => {
                    this.setState({ proceedMnemonicsModal: false }, () => {
                        if (val) {

                            this.setState({ alertTitlt: "Refund Successful", successModalShow: true, alertMessage: "We are pleased to inform you that the funds have been successfully refunded to the correct account. Thank you for your prompt cooperation in resolving this matter." })

                        }
                    })
                }} walletName={this.state.selectedWalletName} wallet={this.state.selectedWalletId} balance={this.state.selectedWalletBalance} amount={this.state.refundAmount} visible={this.state.proceedMnemonicsModal} />




                <RedInfoDialogue showRedInfoModal={this.state.redInfoModalShow} onCloseRedInfoModal={(proceed) => {


                    if (proceed === true && this.state.purposeOfRedInfoDialogue === "registerWallet") {

                        this.setState({
                            redInfoModalShow: false
                        }, () => {
                            this.props.showValidateMnemonicsScreen(true, "registerWallet")
                            this.props.history.push("/validate-mnemonics",
                                {
                                    category: this.state.registerCategory,
                                    walletAddress: this.state.registerWalletAddress,
                                    walletName: this.state.registeWalletName
                                })
                        })

                    }
                    else if (proceed === true && this.state.purposeOfRedInfoDialogue === "mintCoin") {

                        this.setState({
                            redInfoModalShow: false
                        }, () => {
                            this.props.showValidateMnemonicsScreen(true, "mintCoin")
                            this.props.history.push("/validate-mnemonics",
                                {
                                    walletAddress: this.state.selectedWalletId,
                                    walletName: this.state.selectedWalletName
                                })
                        })
                    }

                    else {
                        this.setState({
                            redInfoModalShow: false

                        })
                    }
                }
                } />

            </>

        )
    }

}

const mapStateToProps = (state) => ({

    auth: state.authReducer,
})

export default connect(mapStateToProps, { logOutUser, showValidateMnemonicsScreen, storeUserWallet, showRegisterShopScreen })(WalletListing)



