import React, {useEffect, useState} from 'react';
import Dropdown from "react-dropdown";
import {
    fetchExistingReferralInfo,
    generateAndStoreReferralCodeWallet,
    getUserWallet,
    updateReferralWallet
} from "../../api/wallet/wallet";
import { toast } from "react-toastify";
import ContentCopyIcon from "@material-ui/icons/FileCopy";
import { StyledReferralCodeSectionWrapper } from "./styles";
import {toastMessageFailure, toastMessageInfo, toastMessageSuccess} from "../../utils/toastMessage";
import { connect } from "react-redux";

const ReferralCode = ({ auth }) => {

    const [isLoading, setIsLoading] = useState(false);
    const [canSelectWallet, setCanSelectWallet] = useState(false);
    const [walletActionButtonText, setWalletActionButtonText] = useState('Update Wallet')

    // Data
    const [wallets, setWallets] = useState([]);
    const [selectedWallet, setSelectedWallet] = useState('');
    const [referralCode, setReferralCode] = useState(null);

    // Get user wallets
    useEffect(() => {
        const fetchWallets = async () => {
            try {
                const { data } = await getUserWallet();

                const { Success, data: { results: walletsData }, message } = data;
                if (Success && walletsData && Array.isArray(walletsData)) {
                    const mappedWalletsData = walletsData.map(wallet => {
                        return {
                            ...wallet,
                            label: `${wallet.account_name} - ${wallet.account_id}`,
                            value: wallet.account_id,
                        }
                    })
                    setWallets(mappedWalletsData);
                    return;
                }
                toast.error(message || 'Unable to fetch wallets details. Try again');
            } catch (e) {
                console.log('[fetchWallets]', e);
                toast.error('Unable to fetch wallets details. Try again');
            }
        };

        fetchWallets();
    }, []);

    // Get user current referral details
    useEffect(() => {
        const fetchExistingReferral = async () => {
            try {
                const { user: { user_name } = {} } = auth || {};

                if (!user_name) {
                    toastMessageFailure('Unable to retrieve username. Please logout and try again after logging in');
                    return;
                }

                const { data } = await fetchExistingReferralInfo(user_name);

                const {
                    success,
                    msg,
                    data: {
                        referralCode,
                        wallet
                    }
                } = data;

                if (!success) {
                    toastMessageFailure(msg)
                    return;
                }

                setReferralCode(referralCode);
                setSelectedWallet(wallet);

            } catch (e) {
                console.log('[fetchExistingReferralInfo]', e);
            }
        };

        fetchExistingReferral();
    }, []);

    const onGenerateReferralCodeClick = async event => {
        event.preventDefault();
        try {
            const { user: { user_name } = {} } = auth || {};

            if (!selectedWallet) {
                toastMessageFailure('Please select a wallet first');
                return;
            }

            if (!user_name) {
                toastMessageFailure('Unable to retrieve username. Please logout and try again after logging in');
                return;
            }

            const payload = {
                wallet_address: selectedWallet,
                username: user_name
            };

            setIsLoading(true);
            const { data } = await generateAndStoreReferralCodeWallet(payload);

            const {
                success,
                message,
                codeAlreadyExist,
                data: {
                    wallet: existingWallet,
                    reference_code: existingReferralCode,
                    referralCode: newReferralCode,
                }
            } = data;

            if (!success) {
                toastMessageFailure(message);
                setIsLoading(false);
                return;
            }

            toastMessageSuccess(message);
            setReferralCode(newReferralCode);
        } catch (e) {
            console.log('[onGenerateReferralCodeClick]', e);
            toastMessageFailure('Unable to generate referral code. Please try again');
        }

        setIsLoading(false);
    }

    const walletActionButtonClickHandler = async event => {
        event.preventDefault();

        // If drop down is not enabled previously, now allow to select wallet to that user can update
        if (!canSelectWallet) {
            setCanSelectWallet(true);
            setWalletActionButtonText('Save Wallet');
            return;
        }

        // If drop down is enabled it means user can update his/her referral wallet
        try {
            const { user: { user_name } = {} } = auth || {};

            if (!selectedWallet) {
                toastMessageFailure('Please select a wallet first');
                return;
            }

            if (!user_name) {
                toastMessageFailure('Unable to retrieve username. Please logout and try again after logging in');
                return;
            }

            const payload = {
                wallet_address: selectedWallet,
                username: user_name
            };

            setIsLoading(true);

            const { data } = await updateReferralWallet(payload);

            const { success, message } = data;

            if (!success) {
                toastMessageFailure(message);
                setIsLoading(false);
                return;
            }

            toastMessageSuccess(message);
            setCanSelectWallet(false);
            setWalletActionButtonText('Update Wallet');
        } catch (e) {
            console.log('[walletActionButtonClickHandler]', e);
            toastMessageFailure('Unable to update referral wallet. Please try again');
        }

        setIsLoading(false);
    }

    const copyReferralCodeClickHandler = () => {
        const el = document.createElement('textarea');
        el.value = referralCode;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        toastMessageInfo('Referral code copied successfully');
    }

    return (
        <StyledReferralCodeSectionWrapper
            walletDropdownDisabled={!canSelectWallet && referralCode}
            referralTextBoxDisabled={referralCode}
        >
            <div className="Box SetAccount kycverification">
                <h4>Referral Code</h4>
                <div className="row">

                    <div className="col-sm-12 col-lg-12">
                        <div className="form-group mb-4 select-wallet-wrapper">
                            <Dropdown
                                width='670px'
                                className='wallet-list-dropdown'
                                options={wallets}
                                onChange={({ value }) => setSelectedWallet(value)}
                                value={selectedWallet}
                                placeholder="Select wallet..."
                                disabled={!canSelectWallet && referralCode}
                            />
                            {
                                referralCode ? (
                                    <button
                                        className="btn btn-primary Submit m-0 ml-5"
                                        onClick={walletActionButtonClickHandler}
                                    >
                                        {
                                            isLoading &&
                                            <img
                                                className='loader-img'
                                                alt="Loading..."
                                                src="/assets/images/spinner3.gif"
                                            />
                                        }
                                        {walletActionButtonText}
                                    </button>
                                ) : <></>
                            }
                        </div>

                        {
                            referralCode ? (
                                <div className='referral-code-div-wrapper'>
                                    <input
                                        value={referralCode}
                                        name="referral-code-input-box"
                                        className="form-control mb-2 referral-code-input-box"
                                        disabled
                                    />
                                    <button
                                        className="btn btn-outline-light text-white bg-transparent border-0"
                                    >
                                    <span className="material-icons">
                                        <ContentCopyIcon
                                            className='copy-icon'
                                            onClick={copyReferralCodeClickHandler}
                                        />
                                    </span>
                                    </button>
                                </div>
                            ) : <></>
                        }

                    </div>
                </div>

                <div className="row">
                    <div style={{ marginTop: '20px' }} className="col-12 text-left">
                        <button
                            className="btn btn-primary Submit m-0"
                            disabled={!!referralCode || !selectedWallet}
                            onClick={onGenerateReferralCodeClick}
                        >
                            {
                                isLoading &&
                                <img
                                    className='loader-img'
                                    alt="Loading..."
                                    src="/assets/images/spinner3.gif"
                                />
                            }
                            Generate Referral Code
                        </button>
                    </div>
                </div>
            </div>
        </StyledReferralCodeSectionWrapper>
    );
};

const mapStateToProps = (state) => ({
    auth: state.authReducer,
})

export default connect(mapStateToProps)(ReferralCode);
