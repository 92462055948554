// import { Modal } from "bootstrap";
import React from 'react';
import Modal from 'react-bootstrap/Modal'

//import { isValidAddressPolkadotAddress } from "../../../../utils/utils";
import { getBalance } from '../../api/blockchainapi/blockchainapi';
import ErrorAlert from "./ErrorAlert"

import config from "../../_config";
class ChooseMinerType extends React.Component {
    // if receiver address ok then open the password dialogue
    // if password ok then open mnemonics and make transaction
    // reset password and make a 
    constructor(props) {
        super(props)
        this.state = {
            minerType: "",
            errorModalShow: false,
            alertTitlt: "",
            alertMessage: "",
            disabled: false,
            isLoading: false,
            showRegisterPassword: false

        }
    }
    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })

    }
    closeModal = () => {

        this.props.onCloseChooseMinerTypeModal(this.state.showRegisterPassword, this.state.minerType)
        //reset state
        this.setState({
            errorModalShow: false,
            alertTitlt: "",
            alertMessage: "",
            isLoading: false,
            disabled: false,
            minerType: "",
            showRegisterPassword: false


        })

    }
    submitMinerType = async () => {
        try {

            if (this.state.minerType === config.minerRegistrationType[0]) {

                this.setState({
                    isLoading: true,
                    disabled: true
                })

                let response = await getBalance(this.props.fromAddress)

                let fromAddressbalance = parseFloat(response.data.balance)

                if (fromAddressbalance < config.silverMinerFees) {
                    this.setState({
                        errorModalShow: true,
                        alertTitlt: "Warning!",
                        alertMessage: `You need an ${config.silverMinerFees} coin to register as a silver miner`,
                        isLoading: false,
                        disabled: false
                    })


                }
                else if (fromAddressbalance === config.silverMinerFees) {
                    this.setState({
                        errorModalShow: true,
                        alertTitlt: "Warning!",
                        alertMessage: "Please increase balance by at least 1 coin",
                        isLoading: false,
                        disabled: false
                    })
                }
                else {

                    this.setState({
                        isLoading: false,
                        disabled: false,
                        showRegisterPassword: true
                    }, () => {
                        this.closeModal()
                    })
                    //close modal with walletCategory

                }

            }

            if (this.state.minerType === config.minerRegistrationType[1]) {

                this.setState({
                    isLoading: true,
                    disabled: true
                })

                let response = await getBalance(this.props.fromAddress)

                let fromAddressbalance = parseFloat(response.data.balance)

                if (fromAddressbalance < config.goldMinerFees) {
                    this.setState({
                        errorModalShow: true,
                        alertTitlt: "Warning!",
                        alertMessage: `You need an ${config.goldMinerFees} coin to register as a gold miner`,
                        isLoading: false,
                        disabled: false
                    })


                }
                else if (fromAddressbalance === config.goldMinerFees) {
                    this.setState({
                        errorModalShow: true,
                        alertTitlt: "Warning!",
                        alertMessage: "Please increase balance by at least 1 coin",
                        isLoading: false,
                        disabled: false
                    })
                }
                else {

                    this.setState({
                        isLoading: false,
                        disabled: false,
                        showRegisterPassword: true
                    }, () => {
                        this.closeModal()
                    })
                }

            }


        }
        catch (e) {
            this.setState({
                errorModalShow: true,
                alertTitlt: "",
                alertMessage: e.message,
                isLoading: false,
                disabled: false
            })
        }

    }



    render() {

        return (
            <Modal dialogClassName="SetPass"
                show={this.props.showChooseMinerTypeModal}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >

                <ErrorAlert showErroModal={this.state.errorModalShow} title={this.state.alertTitlt} message={this.state.alertMessage} onCloseErrorModal={() =>
                    this.setState({
                        errorModalShow: false,
                        alertTitlt: "",
                        alertMessage: "",
                        isLoading: false,
                        disabled: false
                    })} />


                <Modal.Body>


                    <h4 class="text-center mb-3">Book Miner Slot</h4>

                    <ul style={{ color: 'white' }}>
                        <li>Please ensure you have enough NUC coins in your wallet as you will need to stake them per membership cost (SILVER = {config.silverMinerFees} NUC) / GOLD = {config.goldMinerFees} NUC)</li>
                        <li>Due to limited number of miner slots within the category you chose, you will need to check if you can book a slot but if non-available, then you will be added to our waiting list and you will be notified once a slot becomes available</li>


                    </ul>
                    <div name="minerType" onChange={this.onChange} className="form-group d-flex align-items-center">
                        <div className="custom_radio">
                            <input className="d-none" type="radio" id="Gold" name="minerType" value="GOLD" /> <label for="Gold">GOLD</label>
                        </div>
                        <div className="custom_radio">
                            <input className="d-none" type="radio" id="Silver" name="minerType" value="SILVER" /> <label for="Silver">SILVER</label>
                        </div>
                    </div>

                    <button disabled={this.state.disabled} onClick={this.submitMinerType} type="button" className="btn btn-primary Submit">
                        {this.state.isLoading === true ? <img alt="loading..." style={{ height: '20px', width: '20px', marginRight: '8px' }} src="/assets/images/spinner3.gif"></img> : null}
                        PROCEED</button>
                    <button disabled={this.state.disabled} type="button" class="btn btn-secondary Cancel" onClick={this.closeModal}>Cancel</button>


                </Modal.Body>
            </Modal>
        );

    }

}
export default ChooseMinerType
