// import { Modal } from "bootstrap";
import React from 'react';
import Modal from 'react-bootstrap/Modal'

class Video extends React.Component {



    render() {

        return (
            <Modal dialogClassName="SetPass"
                show={this.props.showVideoModal}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >



                <Modal.Body>
                    {/* <h4 class="text-center mb-5">Transaction History</h4>
                    <div className="Responsive"> */}

                    <iframe width="100%" height="400" src="https://www.youtube.com/embed/9HDj3jbbzgQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="w-100 text-right">
                            <button type="button" className="btn btn-primary Submit m-0 mt-2" onClick={this.props.onCloseVideoModal} >Close</button>

                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        );

    }

}


export default Video;
