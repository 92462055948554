import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import NewsTicker from "react-advanced-news-ticker";
import Marquee from "react-fast-marquee";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import Newspaper from '@material-ui/icons/Feedback';

let ref;

const NewsHeader = (props) => {

    return (
        <React.Fragment>
        {
            props.headerNotifications.length > 0 ?
                <Marquee
                    pauseOnHover={true}
                    // play={props.headerNotifications.length !== 1}
                    speed={50}
                    ref={ref}
                >
                    {props.headerNotifications.map((item, i) => (
                        <div className="row" key={i}>

                            <Newspaper className='mr-2'></Newspaper>
                            { ReactHtmlParser(`${item.message}`.replaceAll('p>', 'div>'))}

                        </div>
                    ))}
                    
                </Marquee>
            : null
        }
        </React.Fragment>
    )
}

const mapStateToProps = state => ({
    headerNotifications: state.Notification.header.data,
})

export default connect(mapStateToProps, {})(NewsHeader);