import invoke from '../../utils/invoke';
import { encrypt } from '../../utils/utils';
import config from '../../_config';


export const signup = (data) => {

    data = encrypt(data);
    return invoke({
        method: 'POST',
        //baseURL: config.apiUrl,
        baseURL: config.authNucoinServiceApiUrl,
        route: 'v1/nucoin/auth/register',
        data: data
    });
}

export const login = (data) => {
    data = encrypt(data);
    return invoke({
        method: 'POST',
        //baseURL: config.apiUrl,
        baseURL: config.authNucoinServiceApiUrl,
        route: 'v1/nucoin/auth/login',
        data: data
    });
}

export const sendOtpAgainApi = (data) => {
    data = encrypt(data);
    return invoke({
        method: 'POST',
        //baseURL: config.apiUrl,
        baseURL: config.authNucoinServiceApiUrl,
        route: 'v1/nucoin/auth/resend-otp-token',
        data: data
    });
}

export const verifyOtpApi = (data) => {

    data = encrypt(data);
    console.log("message encrypt response", data)
    return invoke({
        method: 'POST',
        // baseURL: config.apiUrl,
        baseURL: config.authNucoinServiceApiUrl,
        route: 'v1/nucoin/auth/verify-otp-token',
        data: data
    });
}

export const forgetPasswordValidationApi = (data) => {
    data = encrypt(data);
    return invoke({
        method: 'POST',
        //baseURL: config.apiUrl,
        baseURL: config.authNucoinServiceApiUrl,
        route: 'v1/nucoin/auth/authenticate-user-on-forget-credential',
        data: data
    });
}

export const updatePassword = (data) => {
    data = encrypt(data);
    return invoke({
        method: 'POST',
        // baseURL: config.apiUrl,
        baseURL: config.authNucoinServiceApiUrl,
        route: 'v1/nucoin/auth/update-user-password',
        data: data
    });
}

export const updatePasswordOnProfile = (data) => {
    data = encrypt(data);
    return invoke({
        method: 'POST',
        // baseURL: config.apiUrl,
        baseURL: config.authNucoinServiceApiUrl,
        route: 'v1/nucoin/auth/update-user-password-on-profile',
        data: data,
        headers: {
            Authorization: localStorage.getItem('jwtToken')
        }
    });
}


export const generateAndSendOtp = (data) => {
    //data = encrypt(data);
    return invoke({
        method: 'POST',
        // baseURL: config.apiUrl,
        baseURL: config.authNucoinServiceApiUrl,
        route: 'v1/nucoin/auth/generate-and-send-otp-on-email',
        data: data,
        headers: {
            Authorization: localStorage.getItem('jwtToken')
        }
    });
}



export const verifyOtpWithAuth = (data) => {
    // data = encrypt(data);
    return invoke({
        method: 'POST',
        // baseURL: config.apiUrl,
        baseURL: config.authNucoinServiceApiUrl,
        route: 'v1/nucoin/auth/verify-otp-token-with-auth',
        data: data,
        headers: {
            Authorization: localStorage.getItem('jwtToken')
        }
    });
}




export const verifyOtpAndLogin = (data) => {
    data = encrypt(data);
    return invoke({
        method: 'POST',
        // baseURL: config.apiUrl,
        baseURL: config.authNucoinServiceApiUrl,
        route: 'v1/nucoin/auth/verify-otp-and-login',
        data: data
    });
}

export const sendOtpAgainOnEmail = (data) => {
    data = encrypt(data);
    return invoke({
        method: 'POST',
        //baseURL: config.apiUrl,
        baseURL: config.authNucoinServiceApiUrl,
        route: 'v1/nucoin/auth/resend-otp-token-on-email',
        data: data
    });
}


export const getNotificationToken = (data) => {
    //data = encrypt(data);
    return invoke({
        method: 'POST',
        baseURL: config.notificationUrl,
        route: 'auth/jwt/create/',
        data: data
    });
}

export const updateTerm = () => {
    //data = encrypt(data);
    return invoke({
        method: 'POST',
        baseURL: config.authNucoinServiceApiUrl,
        route: 'v1/nucoin/auth/update-term',
        headers: {
            Authorization: localStorage.getItem('jwtToken')
        }


    });
}