// import { Modal } from "bootstrap";
import React from 'react';
import Modal from 'react-bootstrap/Modal'
import { WarningMessageAlert, ErrorMessageAlert, InfoMessageAlert } from '../../utils/alert';
import { updateFundingWallet } from '../../api/wallet/wallet';
import config from '../../_config';
import { getBalance, transferCoin } from '../../api/blockchainapi/blockchainapi';
console.log("config.config.sudoWallet", config.sudoWallet)
const { Keyring } = require('@polkadot/api');



class MnemonicsModal extends React.Component {

    state = {
        mnemonics: "",
        isError: false,
        disable: false,
        isErrorAlert: false
    }

    submitPassword = async () => {

        try {

            if (this.state.mnemonics === '' || this.state.mnemonics === null) {
                return this.setState({ isError: true, isErrorAlert: false, message: 'Mnemonics is required!' });
            }


            const keyring = new Keyring({
                type: 'sr25519',
                ss58Format: 42,
            });

            const newPair = keyring.addFromUri(this.state.mnemonics);
            if (newPair.address !== this.props.wallet) {
                this.setState({ isError: false, isErrorAlert: true, message: `Your mnemonics are not matched with your selected wallet address` })
                return
            }

            this.setState({ isError: false, isErrorAlert: false, message: null, isLoading: true, disable: true });
            let response = await getBalance(this.props.wallet)
            let fromAddressbalance = parseFloat(response.data.balance)

            // if your sending amount greater then your balance
            if (this.props.amount > fromAddressbalance) {
                this.setState({ isError: true, isLoading: false, isErrorAlert: false, disable: false, message: "Insufficient balance in your wallet.Your balance should be greater then your sending amount." })
                return
            }

            let transfer = {
                secret: this.state.mnemonics,
                recipient: config.sudoWallet,
                amount: this.props.amount
            }
            let transferObject = {
                secret: this.state.mnemonics,
                walletID: this.props.wallet,
                amount: this.props.amount,

            }
            await transferCoin(transfer)
            await updateFundingWallet(transferObject)
            this.setState({ isLoading: false, disable: false })
            this.closeModal(true)


        }
        catch (e) {

            this.setState({ isError: false, isErrorAlert: true, isLoading: false, disable: false, message: e.message })
        }
    }





    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
            [e.target.name.concat('error')]: '',
        })
    }
    closeModal = (val) => {


        // set all state to default after closing modal
        this.setState({
            mnemonics: "",
            isError: false,
            disable: false,
            isErrorAlert: false
        })
        this.props.onClose(val)

    }



    render() {
        console.log("props", this.props.walletName)
        return (
            <Modal dialogClassName="SetPass"
                show={this.props.visible}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >

                <Modal.Body>



                    <ul style={{ marginLeft: 12 }}>
                        <li style={{ color: "white" }}>
                            <strong>Wallet Name:</strong> {this.props.walletName}
                        </li>
                        <li style={{ color: "white", wordWrap: 'break-word' }}>
                            <strong>Wallet ID:</strong> {this.props.wallet}
                        </li>
                        <li style={{ color: "white" }}>
                            <strong>Refundable Amount:</strong> {this.props.amount}
                        </li>
                        <li style={{ color: "white" }}>
                            <strong>Current Balance:</strong> {this.props.balance}
                        </li>
                    </ul>



                    <p style={{ fontWeight: "bold", fontSize: 24, color: "white" }}>Enter Mnemonics to Confirm Refund</p>

                    <div class="form-group mb-4 position-relative">
                        <input autoFocus={true} onChange={this.onChange} name="mnemonics" className="form-control mb-2" placeholder="Enter Mnemonics" type="text" />

                    </div>

                    {
                        this.state.isError ? <WarningMessageAlert message={this.state.message}></WarningMessageAlert> : null
                    }
                    {
                        this.state.isErrorAlert ? <ErrorMessageAlert message={this.state.message}></ErrorMessageAlert> : null
                    }
                    <button disabled={this.state.disable} onClick={this.submitPassword} type="button" class="btn btn-primary Submit">
                        {this.state.isLoading === true ? <img alt="loading..." style={{ height: '20px', width: '20px', marginRight: '8px' }} src="/assets/images/spinner3.gif"></img> : null}
                        Confirm Refund

                    </button>
                    <button disabled={this.state.disable} type="button" class="btn btn-secondary Cancel" onClick={() => this.closeModal(false)}>Cancel</button>


                </Modal.Body>
            </Modal>
        );

    }

}


export default MnemonicsModal
