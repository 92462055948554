// import { Modal } from "bootstrap";
import React from 'react';
import Modal from 'react-bootstrap/Modal'
import CheckCircle from '@material-ui/icons/CheckCircle';
import DeleteIcon from '@material-ui/icons/Delete';

export default class YesOrNoModal extends React.Component {

    closeModal = (value) => {
        this.props.onCloseYesOrNoModal(value)
    }


    render() {
        return (
            <Modal dialogClassName="ErrorAlert text-center"
                show={this.props.showYesOrNoModal}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <DeleteIcon />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{
                    wordWrap: 'break-word',
                    paddingLeft: '2px',
                    paddingRight: '2px'
                }}>

                    <p>{this.props.msg}</p>
                    <button type="button" class="btn btn-secondary Cancel" onClick={() => { this.closeModal(true) }} style={{ marginRight: '10px' }}>Yes</button>
                    <button type="button" class="btn btn-secondary Submit " onClick={() => { this.closeModal(false) }}>No</button>
                </Modal.Body>
            </Modal >
        )
    }


}