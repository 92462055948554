
import { GET_ALL_NOTIFICATION, REMOVE_HEADER_NOTIFICATION, GET_ALL_HEADER_NOTIFICATION, TOTAL_NOTIFICATION_READ_COUNT, TOTAL_NOTIFICATION_COUNT, SOCKET_CONNECTION, ADD_NEW_NOTIFICATION, MARK_NOTIFICATION_AS_READ, CURRENT_COUNT, REMOVE_NOTIFICATION, SET_ALL_NOTIFICATION } from '../types/notification'
import { ADD_LOADER, REMOVE_LOADER } from '../types/commonType'
import axios from 'axios';
import config from '../_config';


export const fetchAllNotification = (username, pageNumber, pageSize) => async (dispatch) => {


    dispatch({
        type: ADD_LOADER,
        payload: 'fetch-notification-loader',
    })

    try {
        await axios.get(`${config.notificationUrl}/notifications/${config.projectId}/${username}/${pageSize}/?page=${pageNumber}`, { headers: { Authorization: localStorage.getItem("jwtToken") } })
            .then((response) => {
                console.log("response is", response.data, response.data.category)
                //seperate header and other message

                let headerNotification = response.data.data.filter(function (item) {
                    return item.category === config.NotificationCategory[0]
                })
                let simpleNotification = response.data.data.filter(function (item) {
                    return item.category !== config.NotificationCategory[0]
                })
                console.log("updatedNotificationState", headerNotification, "simpleNotification", simpleNotification)


                //Clear the Loader
                dispatch({
                    type: REMOVE_LOADER,
                    payload: 'fetch-notification-loader',
                })
                dispatch({
                    type: TOTAL_NOTIFICATION_READ_COUNT,
                    payload: response?.data?.unread_count,
                })
                dispatch({
                    type: TOTAL_NOTIFICATION_COUNT,
                    payload: response?.data.total_count,
                })

                dispatch({
                    type: CURRENT_COUNT,
                    payload: simpleNotification.length,
                })

                dispatch({
                    type: GET_ALL_NOTIFICATION,
                    payload: simpleNotification,
                })

                dispatch({
                    type: GET_ALL_HEADER_NOTIFICATION,
                    payload: headerNotification,
                })

            })
    } catch (err) {
        dispatch({
            type: REMOVE_LOADER,
            payload: 'fetch-notification-loader',
        })
        dispatch({
            type: GET_ALL_NOTIFICATION,
            payload: [],
        })
    }
}



export const addNewNotification = (notificationObject) => async (dispatch) => {

    dispatch({
        type: ADD_NEW_NOTIFICATION,
        payload: notificationObject,
    })
}

export const setNotificationList = (notificationObject) => async (dispatch) => {

    dispatch({
        type: SET_ALL_NOTIFICATION,
        payload: notificationObject
    })
}





export const markNotificationAsRead = (notificationID, action, username, isRead) => async (dispatch) => {

    try {

        // if message is already read then don't call the api just check if action exist then redirect
        if (isRead === true) {
            // if (action) {
            //     window.open(action, '_blank')
            // }
        }
        else {
            dispatch({
                type: MARK_NOTIFICATION_AS_READ,
                payload: notificationID,
            })
            axios.put(`${config.notificationUrl}/notifications/${notificationID}/${username}/read/`, {}, { headers: { Authorization: localStorage.getItem("jwtToken") } }).then((response) => {

                // if (action) {

                //     window.open(action, '_blank')
                // }
            })
        }
    }
    catch (e) {

        // if (action) {
        //     window.open(action, '_blank')
        // }

    }
}

export const removeNotification = (notificationID, username) => async (dispatch) => {

    dispatch({
        type: REMOVE_NOTIFICATION,
        payload: notificationID,
    })
    try {
        axios.put(`${config.notificationUrl}/notifications/${notificationID}/${username}/remove/`, {}, { headers: { Authorization: localStorage.getItem("jwtToken") } }).then((response) => {
        })
    }
    catch (e) {

    }
}

export const clearNotification = (notificationID) => async (dispatch) => {

    dispatch({
        type: REMOVE_NOTIFICATION,
        payload: notificationID,
    })

}

export const clearHeaderNotification = (notificationID) => async (dispatch) => {

    dispatch({
        type: REMOVE_HEADER_NOTIFICATION,
        payload: notificationID,
    })

}




export const setSocketConnection = (connection) => {

    return {
        type: SOCKET_CONNECTION,
        payload: connection
    }
}



export const fetchNotificationForHeader = (username, pageNumber, pageSize) => async (dispatch) => {


    dispatch({
        type: ADD_LOADER,
        payload: 'fetch-notification-loader',
    })


    try {
        await axios.get(`${config.notificationUrl}/notifications/${config.projectId}/${username}/${pageSize}/?page=${pageNumber}`)
            .then((response) => {
                console.log("response is", response.data)

                //Clear the Loader
                dispatch({
                    type: REMOVE_LOADER,
                    payload: 'fetch-notification-loader-header',
                })
                dispatch({
                    type: TOTAL_NOTIFICATION_READ_COUNT,
                    payload: response?.data?.unread_count,
                })

                dispatch({
                    type: GET_ALL_NOTIFICATION,
                    payload: response?.data.data,
                })

            })
    } catch (err) {
        dispatch({
            type: REMOVE_LOADER,
            payload: 'fetch-notification-loader-header',
        })
        dispatch({
            type: GET_ALL_NOTIFICATION,
            payload: [],
        })
    }
}
