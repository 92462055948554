import { Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

const SimpleRoute = ({ component: Component, auth, ...rest }) => (
    <Route
        render={(props) => {
            console.log("fvv",auth.isAuthenticated)
           
            if (auth.maintanceStatus === "true") {
                return <Redirect to={`/maintenance`} />
            }
            else {

                if (auth.isAuthenticated) {
                    console.log("fvv received",auth.term)
                    if (auth.term == 0) {
                        console.log("fvv reached inside")
                        return <Redirect to={`term-and-condition`} />
                    }
                    else {
                        return <Component {...props} />
                    }

                }
                else {
                    return <Component {...props} />
                }

            }
        }}
        {...rest}
    />
)



const mapStateToProps = (state) => ({

    auth: state.authReducer,
})

export default connect(mapStateToProps)(SimpleRoute)
