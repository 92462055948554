import invoke from '../../utils/invoke';
// import { encrypt } from '../../utils/utils';
import config from '../../_config';


export const predictDocumentQuality = (data) => {
    //data = encrypt(data);
    return invoke({
        method: 'POST',
        baseURL: config.apiUrlAIIdentity,
        route: 'predict_document_quality',
        data: data
    });
}

export const validatePassport = (data) => {

    //data = encrypt(data);
    return invoke({
        method: 'POST',
        baseURL: config.apiUrlAIIdentity,
        route: 'validate_passport',
        data: data
    });
}

export const validateLicence = (data) => {
   

    //data = encrypt(data);
    return invoke({
        method: 'POST',
        baseURL: config.apiUrlAIIdentity,
        route: 'validate_driver_license',
        data: data
    });
}



export const validatePhotoID = (data) => {


    //data = encrypt(data);
    return invoke({
        method: 'POST',
        baseURL: config.apiUrlAIIdentity,
        route: 'validate_photo_id',
        data: data
    });
}



export const validateSelfie = (data) => {


    //data = encrypt(data);
    return invoke({
        method: 'POST',
        baseURL: config.apiUrlAIIdentity,
        route: 'predict_selfie',
        data: data
    });
}




