// import { Modal } from "bootstrap";
import React from 'react';
import Modal from 'react-bootstrap/Modal'
import { verifyOtpWithAuth, generateAndSendOtp } from "../../api/authentication/authentication"
import { connect } from "react-redux";
import { toastMessageInfo, toastMessageSuccess, toastMessageFailure } from '../../utils/toastMessage';
import ContentCopyIcon from '@material-ui/icons/FileCopy';
import { WarningMessageAlert,ErrorMessageAlert } from "../../utils/alert"
import { logOutUser } from "../../actions/auth"


class OtpVerificationModal extends React.Component {
    state = {
        otpCode: "",
        isError: "",
        message: "",
        disable: "",
        showMnemoncis: false,
        isVerifyOtpLoading: false,
        retreiveMnemonicsLoader: "",
        display: "",
        isErrorAlert:false

    }


    resendOtp = async () => {
        try {
            this.setState({ retreiveMnemonicsLoader: true, disable: "none" })
            await generateAndSendOtp();
            this.setState({ retreiveMnemonicsLoader: false, disable: "" }, () => {
                toastMessageSuccess("OTP code send again to your email")

            })
        }
        catch (err) {
            this.setState({ retreiveMnemonicsLoader: false, disable: "" })

            if (err?.response?.status === 403) {
                this.props.logOutUser(err?.response?.status)
            }
            else if (err.message === "Network Error" || err?.response?.status === 500) {
                toastMessageFailure("Sorry! We are facing some network issue.Please try again later.")
            }
            else if (err?.response?.data) {
                toastMessageFailure(err?.response?.data?.errors[0]?.message)
            }


        }
    }


    verifyOtp = async () => {

        if (this.state.otpCode === '' || this.state.otpCode === null) {
            return this.setState({ isError: true, isErrorAlert: false, message: 'OTP Code required!' });
        } else if (this.state.otpCode.trim().length !== 6) {
            return this.setState({ isError: true, isErrorAlert: false, message: 'Please enter the 6 digit OTP code!' });
        }

        this.setState({ isError: null, isErrorAlert: false, message: null, isVerifyOtpLoading: true, disable: "none" });
        try {
            await verifyOtpWithAuth({
                "otpToken": this.state.otpCode.trim(),

            });
            toastMessageSuccess("You successfully retrieve your mnemonics.")
            this.setState({ isError: false, isVerifyOtpLoading: false, disable: "", message: "", display: "none" }, () => {
                this.setState({
                    showMnemoncis: true
                })
            });

        }
        catch (err) {


            this.setState({ disable: "", isVerifyOtpLoading: false });

            if (err.message === "Network Error") {

                return this.setState({ isError: true, isErrorAlert: false, message: "Sorry! We are facing some network issue.Please try again later." });

            }
            else if (err?.response?.status === 403) {
                this.props.logOutUser(err?.response?.status)
            }

            else if (err?.response?.data) {

                if (err?.response?.status === 500) {

                    return this.setState({ isError: false, message: err?.response?.data?.errors[0]?.message, isErrorAlert: true });
                }

                return this.setState({ isError: true, isErrorAlert: false, valueOf, message: err?.response?.data?.errors[0] ? err?.response?.data?.errors[0]?.message : err?.response?.data });
            }


        }






    }
    copyMainAddress = async () => {
        const el = document.createElement('textarea');
        el.value = this.props.retreiveMnemoncis;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        toastMessageInfo("You successfully copy the mnemonics!")
    }

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
            [e.target.name.concat('error')]: '',
        })
    }


    hideAndShowPassword = () => {
        if (this.state.passwordType === "text") {

            this.setState({
                passwordType: "password",
                hideAndShowLabelText: "Show"

            })
        }
        else if (this.state.passwordType === "password") {
            this.setState({
                passwordType: "text",
                hideAndShowLabelText: "Hide"

            })
        }
    }

    render() {
        return (
            <Modal dialogClassName="SetPass"
                show={this.props.showOtpVerificationModal}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >


                <Modal.Body>


                    <h4 className="text-center mb-4" style={{ color: 'white' }}>Enter the OTP  you received in your email.</h4>
                    <div class="form-group mb-4 position-relative">
                        <input autoFocus={true} onChange={this.onChange} name="otpCode" className="form-control mb-2" placeholder="Enter OTP Code" />
                        <label style={{ color: 'white', display: this.state.display }}>Didn't receive the code? <b onClick={this.resendOtp} style={{ color: 'white', cursor: 'pointer', pointerEvents: this.state.disable, display: this.state.display }}>Send again  {this.state.retreiveMnemonicsLoader === true ? <img alt="loading..." style={{ height: '20px', width: '20px', marginRight: '8px' }} src="/assets/images/spinner3.gif"></img> : null}</b></label>

                    </div>

                    {
                        this.state.isError ? <WarningMessageAlert message={this.state.message}></WarningMessageAlert> : null
                    }
                    {
                        this.state.isErrorAlert ? <ErrorMessageAlert message={this.state.message}></ErrorMessageAlert> : null
                    }

                    {
                        this.state.showMnemoncis === true ? <div className="alert alert-success" role="alert">

                            {this.props.retreiveMnemoncis} &nbsp;&nbsp;  <ContentCopyIcon style={{ cursor: 'pointer' }} onClick={this.copyMainAddress} /><span style={{ cursor: 'pointer' }} onClick={this.copyMainAddress}>Copy</span>
                        </div> : null



                    }

                    <div style={{ display: 'flex', justifyContent: 'right', alignItems: 'right' }}>
                        <button style={{ pointerEvents: this.state.disable, display: this.state.display }} onClick={this.verifyOtp} type="button" class="btn btn-primary Submit">

                            {this.state.isVerifyOtpLoading === true ? <img alt="loading..." style={{ height: '20px', width: '20px', marginRight: '8px' }} src="/assets/images/spinner3.gif"></img> : null}
                            Proceed

                        </button>

                        <button style={{ pointerEvents: this.state.disable, alignSelf: 'flex-end', background: '#6C757D' }} type="button" class="btn btn-secondary Cancel" onClick={() => {
                            this.setState({
                                otpCode: "",
                                isError: "",
                                message: "",
                                disable: "",
                                showMnemoncis: false,
                                isVerifyOtpLoading: false,
                                retreiveMnemonicsLoader: "",
                                display: ""
                            }, () => {
                                this.props.onCloseOtpModal()

                            })



                        }}>Close</button>
                    </div>





                </Modal.Body>
            </Modal >
        );

    }

}


export default connect(null, { logOutUser })(OtpVerificationModal)
