import React from 'react';
import Modal from 'react-bootstrap/Modal'
import { showValidateMnemonicsScreen } from "../../../../actions/wallet";
import { isValidAddressPolkadotAddress } from "../../../../utils/utils";
import RedInfoDialogue from "../../../Modals/RedInfoDialogue";
import { getBalance } from '../../../../api/blockchainapi/blockchainapi';
import { connect } from "react-redux";
import { transferLimitCheck } from '../../../../api/wallet/wallet';
import { WarningMessageAlert, ErrorMessageAlert, InfoMessageAlert } from "../../../../utils/alert"
import ErrorAlert from '../../../Modals/ErrorAlert';
import { ErrorMessage } from "../../../../messages/ErrorMessage"



class SendCoins extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            receiverAddress: "",
            amount: "",
            openPasswordModal: false,
            isError: "",
            message: "",
            disable: "",
            redInfoModalShow: false,
            isErrorAlert: false,
            errorModalShow: false,
            alertMessage: "",
            alertTitlt: ""
        }
    }

    closeModal = () => {
        this.props.onCloseSednCoinModal(this.state.openPasswordModal, this.state.amount, this.state.receiverAddress)
        // set all state to default after closing modal
        this.setState({
            receiverAddress: "", amount: "", openPasswordModal: false,
            isError: "", message: "", disable: "",
            redInfoModalShow: false,
            isErrorAlert: false,
            errorModalShow: false,
            alertMessage: "",
            alertTitlt: ""
        })

    }


    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
            [e.target.name.concat('error')]: '',
        })
    }
    sendCoin = async () => {

        if (this.state.receiverAddress === '' || this.state.receiverAddress === null) {
            return this.setState({ isError: true, isErrorAlert: false, message: 'Receiver address is required!' });
        } else if (this.state.amount === '' || this.state.amount === null) {
            return this.setState({ isError: true, isErrorAlert: false, message: 'Amount is required!' });
        }
        else if (this.state.amount === "0") {
            return this.setState({ isError: true, isErrorAlert: false, message: "It's look like you've entered the wrong amount value.Please make sure your amount value should be greater than 0." });
        }
        let validateAddress = isValidAddressPolkadotAddress(this.state.receiverAddress)
        if (!validateAddress) {
            return this.setState({ isError: true, isErrorAlert: false, message: "It's look like you've entered the wrong receiver address.Please make sure your receiver address is correct." });
        }
        this.setState({ isError: null, message: null, isErrorAlert: false, isLoading: true, disable: "none" });

        try {
            let response = await getBalance(this.props.fromAddress)
            let { data } = await transferLimitCheck({ receiverWallet: this.state.receiverAddress, transferredAmount: this.state.amount })
            let fromAddressbalance = parseFloat(response.data.balance)

            if (data.data.canTransfer === false) {

                this.setState({ isLoading: false, errorModalShow: true, disable: "", alertMessage: data.message, alertTitlt: data.data.title })
                return

            }
            // if your sending amount greater then your balance
            else if (this.state.amount > fromAddressbalance) {
                this.setState({ isError: true, isLoading: false, isErrorAlert: false, disable: "", message: "Insufficient balance in your wallet.Your balance should be greater then your sending amount." })
            }
            // if your sending amount equal to  your balance
            else if (this.state.amount === fromAddressbalance.toString()) {
                this.setState({ isError: true, isLoading: false, isErrorAlert: false, disable: "", message: "Please increase balance by at least 1 coin." })
            }

            else {


                this.setState({ isError: null, isErrorAlert: false, message: null, isLoading: false, disable: "" });

                let fromExist = localStorage.getItem(this.props.fromAddress)
                // if address exist in local then show password modal
                if (fromExist) {
                    this.setState({ openPasswordModal: true }, () => {
                        this.closeModal()
                    })
                }
                // if address not exist in local then show mnemonics modal
                else {
                    this.setState({
                        redInfoModalShow: true
                    })
                }

            }
        }
        catch (e) {
            this.setState({ isError: false, isErrorAlert: true, message: e.message, isLoading: false, disable: "" });
        }
    }

    render() {

        return (
            <Modal dialogClassName="SetPass"

                show={this.props.showSendCoinModal}
                size="md"

                aria-labelledby="contained-modal-title-vcenter"
                centered
            >

                <ErrorAlert showErroModal={this.state.errorModalShow} title={this.state.alertTitlt} message={this.state.alertMessage} onCloseErrorModal={() =>
                    this.setState({
                        errorModalShow: false,
                        alertTitlt: "",
                        alertMessage: ""
                    })} />


                <Modal.Body>


                    <h4 class="text-center mb-5">Send Coins</h4>
                    <div class="form-group mb-4">
                        <input onChange={this.onChange} name="receiverAddress" class="form-control mb-2" placeholder="Enter Receiver Address" type="text" />
                    </div>
                    <div class="form-group mb-4">
                        <input onChange={this.onChange} name="amount" class="form-control mb-2" placeholder="Enter Amount" type="number" />
                    </div>

                    {
                        this.state.isError ? <WarningMessageAlert message={this.state.message}></WarningMessageAlert> : null
                    }
                    {
                        this.state.isErrorAlert ? <ErrorMessageAlert message={this.state.message}></ErrorMessageAlert> : null
                    }

                    <button style={{ pointerEvents: this.state.disable }} onClick={this.sendCoin} type="button" className="btn btn-primary Submit">
                        {this.state.isLoading === true ? <img alt="loading..." style={{ height: '20px', width: '20px', marginRight: '8px' }} src="/assets/images/spinner3.gif"></img> : null}
                        Submit</button>
                    <button style={{ pointerEvents: this.state.disable }} type="button" class="btn btn-secondary Cancel" onClick={this.closeModal}>Cancel</button>

                    <RedInfoDialogue showRedInfoModal={this.state.redInfoModalShow} title={this.state.alertTitlt} message={this.state.alertMessage} onCloseRedInfoModal={(proceed) => {
                        if (proceed === true) {

                            this.setState({
                                redInfoModalShow: false
                            }, () => {
                                this.props.showValidateMnemonicsScreen(true, "transfer")
                                this.props.history.push("/validate-mnemonics", { walletAddress: this.props.fromAddress, walletName: this.props.walletName, receiverAddress: this.state.receiverAddress, amount: this.state.amount })

                            })

                        }

                        else {
                            this.setState({
                                redInfoModalShow: false

                            })
                        }
                    }
                    } />
                </Modal.Body>
            </Modal>
        );

    }

}

// const mapStateToProps = (state) => ({

//     isShowValidateMnemonicsScreen: state.walletReducer.isShowValidateMnemonicsScreen,
// })
export default connect(null, { showValidateMnemonicsScreen })(SendCoins)
