import { SHOW_RANDOM_MNEMONICS_SCREEN, SHOW_VALIDATED_MNEMONICS_SCREEN, SHOW_CREATE_WALLET_SCREEN, SHOW_COPY_MNEMONCIS_SCREEN,USER_WALLET_LIST,SHOW_SHOP_REGISTER_SCREEEN } from '../types/wallet'


const initialState = {
    isShowCreateWalletScreen: false,
    isShowCopyMnemonicsScreen: false,
    isShowRandomMnemonicsScreen: false,
    isShowValidateMnemonicsScreen: false,
    isShowShopRegisterScreen:false,
    user: {},
    userWallet:{}
}
export const walletReducer = (state = initialState, action) => {
    switch (action.type) {

        case SHOW_CREATE_WALLET_SCREEN:
            return {
                ...state,
                isShowCreateWalletScreen: action.payload,

            }

        case SHOW_COPY_MNEMONCIS_SCREEN:
            return {
                ...state,
                isShowCopyMnemonicsScreen: action.payload,

            }

        case SHOW_RANDOM_MNEMONICS_SCREEN:

            return {
                ...state,
                isShowRandomMnemonicsScreen: action.payload
            }


case SHOW_SHOP_REGISTER_SCREEEN:
    return {
        ...state,
        isShowShopRegisterScreen: action.payload
    }




        case SHOW_VALIDATED_MNEMONICS_SCREEN:
            return {
                ...state,
                isShowValidateMnemonicsScreen: action.payload.isRouteOpen,
                purpose: action.payload.purpose
            }
            case USER_WALLET_LIST:
                return {
                    ...state,
                    
                    userWallet: action.payload
                }

        default:
            return state
    }
}

