// import { Modal } from "bootstrap";
import React from 'react';
import Modal from 'react-bootstrap/Modal'
//import MneMonics from "../../MneMonics"
import { finalMintCoin } from '../../../../api/blockchainapi/blockchainapi';
import RedInfoDialogue from "../../../Modals/RedInfoDialogue";
import { showValidateMnemonicsScreen } from "../../../../actions/wallet";
import { updateMintSession } from "../../../../api/miner/miner"
import config from "../../../../_config"
import { connect } from "react-redux";
import { stringToU8a, u8aToHex } from '@polkadot/util';
import { WarningMessageAlert, ErrorMessageAlert, InfoMessageAlert } from "../../../../utils/alert"
import { ErrorMessage } from "../../../../messages/ErrorMessage"
import { Keyring } from '@polkadot/api';
import { signatureVerify } from '@polkadot/util-crypto';
const CryptoJS = require("crypto-js");




class PasswordForMint extends React.Component {
    state = {

        password: "",

        isError: "",
        message: "",
        alertTitlt: "",
        //alertMessage: "",
        disable: "",
        headerMessage: "",
        //errorModalShow: false,
        //successModalShow: false,
        transactionBlockHash: "",
        // hide and show password
        passwordType: "password",
        hideAndShowLabelText: "Show",
        mintSuccess: false,
        redInfoModalShow: false,
        purposeOfRedInfoDialogue: "",
        nextMintSession: "",
        isErrorAlert: false,
        infoAlert: false,
    }

    generateSignature = async (mnemonic_code, address, usi) => {

        if (`${mnemonic_code}`.includes("string")) mnemonic_code = JSON.parse(mnemonic_code).string;

        try {

            const keyring = new Keyring({
                type: 'sr25519',

            });

            const alice = keyring.addFromUri(mnemonic_code);


            if (alice.address !== address) {

                return false;
            }
            const message = stringToU8a(JSON.stringify({ usiCount: usi, accountId: address }));
            const signature = alice.sign(message);
            var hash = u8aToHex(signature);

            const { isValid } = signatureVerify(message, signature, alice.address);

            if (isValid === true) {
                return hash;
            }

            return false;
        } catch (e) {
            return false;
        }

    }


    submitPassword = async () => {


        if (this.state.password === '' || this.state.password === null) {
            return this.setState({ isError: true, isErrorAlert: false, infoAlert: false, message: 'Password is required!' });
        }
        // check if from address exist in local
        let fromExist = localStorage.getItem(this.props.fromAddress)
        //alert(fromExist)

        if (fromExist) {
            try {
                const encryptedData = localStorage.getItem(this.props.fromAddress)

                // decrypt data using password
                var mnemonicsValue = await CryptoJS.enc.Utf8.stringify(CryptoJS.AES.decrypt(encryptedData, this.state.password))



                // if we got the mnemoncs
                if (mnemonicsValue) {
                    try {

                        var signatureData = await this.generateSignature(mnemonicsValue, this.props.fromAddress, config.usi);

                        let mintObject = {
                            "usiCount": config.usi,
                            "accountId": this.props.fromAddress,
                            "signature": signatureData,
                        }


                        this.setState({ isError: false, isErrorAlert: false, infoAlert: false, message: null, headerMessage: "", isLoading: true, disable: "none" });
                        let response = await finalMintCoin(mintObject)
                        this.setState({ isLoading: false, disable: "", })

                        if (response.data.hash) {
                            try { await updateMintSession({ address: this.props.fromAddress }) }
                            catch (e) {
                                console.log("error in mint session updte", e)

                            }
                            this.setState({ transactionBlockHash: response.data.hash, mintSuccess: true, nextMintSession: response.data.hoursLeft }, () => {
                                this.closeModal()
                            })
                        }
                    }
                    catch (e) {
                        //alert(e?.response?.data)
                        if (e?.response?.data) {
                            if (e?.response?.data?.hoursLeft) {
                                //alert(e?.response?.data?.hoursLeft)
                                this.setState({ isError: false, isErrorAlert: false, infoAlert: true, isLoading: false, headerMessage: "", disable: "", message: e?.response?.data?.message + `You can mint after ${e?.response?.data?.hoursLeft} hour(s).` })
                            }
                            else {
                                this.setState({ isError: false, isErrorAlert: true, infoAlert: false, isLoading: false, headerMessage: "", disable: "", message: e.message })
                            }
                        }
                        else {
                            this.setState({ isError: false, isErrorAlert: true, infoAlert: false, isLoading: false, headerMessage: "", disable: "", message: e.message })

                        }
                    }
                }
                // if got null in decrypting
                else {
                    this.setState({ isError: true, isErrorAlert: false, infoAlert: false, headerMessage: "", message: "You are entering a wrong password" })
                }
            }
            // if we got mulformed in decrypting
            catch (e) {
                this.setState({ isError: true, isErrorAlert: false, infoAlert: false, headerMessage: "", message: "You are entering a wrong password" })
            }
        }
        else {

            this.setState({
                redInfoModalShow: true,
                purposeOfRedInfoDialogue: "mintCoin"
            })
        }


    }

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
            [e.target.name.concat('error')]: '',
        })
    }
    closeModal = () => {

        this.props.onCloseMintModal(this.state.transactionBlockHash, this.state.mintSuccess, this.state.nextMintSession)
        // set all state to default after closing modal
        this.setState({

            password: "",
            isError: false,
            message: "",
            alertTitlt: "",
            //alertMessage: "",
            disable: "",
            headerMessage: "",
            //errorModalShow: false,
            //successModalShow: false,
            transactionBlockHash: "",
            // hide and show password
            passwordType: "password",
            hideAndShowLabelText: "Show",
            mintSuccess: false,
            redInfoModalShow: false,
            purposeOfRedInfoDialogue: "",
            nextMintSession: "",
            isErrorAlert: false,
            infoAlert: false,
        })

    }

    hideAndShowPassword = () => {
        if (this.state.passwordType === "text") {

            this.setState({
                passwordType: "password",
                hideAndShowLabelText: "Show"

            })
        }
        else if (this.state.passwordType === "password") {
            this.setState({
                passwordType: "text",
                hideAndShowLabelText: "Hide"

            })
        }
    }

    render() {
        return (
            <Modal dialogClassName="SetPass"
                show={this.props.showMintModal}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <RedInfoDialogue showRedInfoModal={this.state.redInfoModalShow} title={this.state.alertTitlt} message={this.state.alertMessage} onCloseRedInfoModal={(proceed) => {
                    if (proceed === true && this.state.purposeOfRedInfoDialogue === "passwordReset") {

                        this.setState({
                            redInfoModalShow: false
                        }, () => {
                            this.props.showValidateMnemonicsScreen(true, "passwordReset")
                            this.props.history.push("/validate-mnemonics", { walletAddress: this.props.fromAddress, walletName: this.props.walletName })
                        })

                    }

                    else if (proceed === true && this.state.purposeOfRedInfoDialogue === "mintCoin") {

                        this.setState({
                            redInfoModalShow: false
                        }, () => {
                            this.props.showValidateMnemonicsScreen(true, "mintCoin")
                            this.props.history.push("/validate-mnemonics",
                                {
                                    walletAddress: this.props.fromAddress,
                                    walletName: this.props.walletName
                                })
                        })
                    }
                    else {

                        this.setState({
                            redInfoModalShow: false,


                        })
                    }
                }
                } />

                <Modal.Body>


                    <h4 className="text-center mb-5">{this.props.headerLabel}</h4>
                    <div class="form-group mb-4 position-relative">
                        <input autoFocus={true} onChange={this.onChange} name="password" className="form-control mb-2" placeholder="Enter Password" type={this.state.passwordType} />
                        <label style={{
                            cursor: "pointer", color: 'white',
                            position: 'absolute', top: '9px', right: '0px'
                        }} onClick={() => this.hideAndShowPassword("Password")}>{this.state.hideAndShowLabelText}</label>

                    </div>

                    {
                        this.state.isError ? <WarningMessageAlert message={this.state.message}></WarningMessageAlert> : null
                    }
                    {
                        this.state.isErrorAlert ? <ErrorMessageAlert message={this.state.message}></ErrorMessageAlert> : null
                    }
                    {
                        this.state.infoAlert ? <InfoMessageAlert message={this.state.message}></InfoMessageAlert> : null
                    }
                    <button style={{ pointerEvents: this.state.disable }} onClick={this.submitPassword} type="button" class="btn btn-primary Submit">
                        {this.state.isLoading === true ? <img alt="loading..." style={{ height: '20px', width: '20px', marginRight: '8px' }} src="/assets/images/spinner3.gif"></img> : null}
                            Click to mint

                        </button>
                    <button style={{ pointerEvents: this.state.disable }} type="button" class="btn btn-secondary Cancel" onClick={this.closeModal}>Cancel</button>



                    <a href={() => false} className="float-right mt-3" style={{ color: 'white', pointerEvents: this.state.disable, cursor: 'pointer' }} onClick={() => {
                        this.setState({
                            redInfoModalShow: true,
                            purposeOfRedInfoDialogue: "passwordReset"
                        })
                    }}>
                        Reset Password
                    </a>

                </Modal.Body>
            </Modal >
        );

    }

}

PasswordForMint.defaultProps = {
    headerLabel: "Set Password",

}

const mapStateToProps = (state) => ({

    isShowValidateMnemonicsScreen: state.walletReducer.isShowValidateMnemonicsScreen,
})
export default connect(mapStateToProps, { showValidateMnemonicsScreen })(PasswordForMint)
