// import { Modal } from "bootstrap";
import React from 'react';
import Modal from 'react-bootstrap/Modal'
import { Link } from "react-router-dom";
export default class RedInfoDialogue extends React.Component {
    state = {
        proceed: false
    }

    render() {
        return (
            <Modal dialogClassName="ErrorAlert text-center"
                show={this.props.showRedInfoModal}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                {/* <Modal.Header style={{ backgroundColor: '#dc3545' }}>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <ErrorOutline />
                    </Modal.Title>
                </Modal.Header> */}
                <Modal.Body style={{ backgroundColor: '#dc3545' }}>

                    <p style={{ color: 'white' }}>Please retrieve and enter your mnemonics in the same order when you created this wallet.</p>
                    {/* <button type="button" className="btn btn-primary Submit" onClick={() => {
                        this.setState({ proceed: true }, () => {
                            this.props.onCloseRedInfoModal(this.state.proceed)
                        })
                    }}>

                        Ok</button>
                    <button style={{ marginLeft: '8px' }} type="button" className="btn btn-secondary Cancel" onClick={() => { this.props.onCloseRedInfoModal(this.state.showResetPassword) }}>Cancel</button> */}

                    <Link className="Read-More" type="button" onClick={() => {
                        this.setState({ proceed: true }, () => {
                            this.props.onCloseRedInfoModal(this.state.proceed)
                        })
                    }}
                        to="#" >Ok</Link>

                    <Link style={{
                        borderRadius: '3px',
                        color: '#fff',
                        background: '#6C757D',
                        padding: '10px 30px',
                        display: 'inlineBlock',
                        marginRight: '10px',
                    }} onClick={() => { this.props.onCloseRedInfoModal(this.state.showResetPassword) }} className="Read-More" to="#" >Cancel</Link>





                </Modal.Body>
            </Modal>

        )
    }


}