// import { Modal } from "bootstrap";
import React from 'react';
import Modal from 'react-bootstrap/Modal'
import ErrorOutline from '@material-ui/icons/ErrorOutline';
import { getEmailMessageForMinerRegistration } from '../../api/miner/miner';
import { Link } from "react-router-dom";
import { toastMessageFailure } from "../../utils/toastMessage";
import { logOutUser } from "../../actions/auth"
import { connect } from 'react-redux';
class MinerFeesReceivedAlert extends React.Component {
    state = {
        emailLoader: false,
        disabled: ""
    }

    openEmailModal = async () => {
        try {
            this.setState({ emailLoader: true, disabled: "none" })
            const { data } = await getEmailMessageForMinerRegistration("wallet_registration_process", "FRONT_END");
            this.setState({ emailLoader: false, disabled: "" })
            this.props.onOpenEmailDropDownDialogue(data.data)


        }
        catch (err) {
            this.setState({ emailLoader: false, disabled: "" })

            if (err?.response?.status === 403) {
                this.props.logOutUser(err?.response?.status)
            }
            else if (err.message === "Network Error" || err?.response?.status === 500) {
                toastMessageFailure("Sorry! We are facing some network issue.Please try again later.")
            }
            else if (err?.response?.data) {
                toastMessageFailure(err?.response?.data?.errors[0]?.message)
            }


        }



        // onCloseEmailDropDownModal

    }

    render() {
        return (
            <Modal dialogClassName="ErrorAlert text-center"
                show={this.props.showMinerFeesReceivedModal}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <ErrorOutline />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h4>{this.props.title}</h4>
                    <p>{this.props.message}</p>
                    <p style={{ fontSize: '14px' }}><span style={{ fontFamily: 'bold' }}>Note:</span> If you have paid the fee and are still watching this dialogue email us or if you want details on how to become a miner email us we will look into your case.</p>
                    {/* <button disabled={this.state.disabled} type="button" className="btn btn-secondary Cancel" onClick={this.props.onCloseMinerFeesReceivedModal}>Close</button>
                    <button disabled={this.state.disabled} style={{ marginLeft: '8px' }} type="button" className="btn btn-primary Submit" onClick={this.openEmailModal}>
                        {this.state.emailLoader === true ? <img style={{ height: '20px', width: '20px', marginRight: '8px' }} src="/assets/images/spinner3.gif"></img> : null}
                        Send Email</button> */}

                    <Link style={{
                        borderRadius: '3px',
                        color: '#fff',
                        background: '#6C757D',
                        padding: '10px 30px',
                        display: 'inlineBlock',
                        marginRight: '10px',
                        pointerEvents: this.state.disabled
                    }} type="button" onClick={this.props.onCloseMinerFeesReceivedModal} to="#" >Close</Link>

                    <Link onClick={this.openEmailModal} style={{ pointerEvents: this.state.disabled }} className="Read-More" to="#" >
                        {this.state.emailLoader === true ? <img alt="loading..." style={{ height: '20px', width: '20px', marginRight: '8px' }} src="/assets/images/spinner3.gif"></img> : null}
                        Send Email</Link>



                </Modal.Body>
            </Modal>

        )
    }


}



export default connect(null, { logOutUser })(MinerFeesReceivedAlert)