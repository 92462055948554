import React from 'react';
import Modal from 'react-bootstrap/Modal';
import YesOrNoModal from "../../../Modals/YesOrNoModal"
import PasswordForPaypal from "./PasswordForPaypal"
import { WarningMessageAlert, ErrorMessageAlert, InfoMessageAlert } from "../../../../utils/alert"
import { ErrorMessage } from "../../../../messages/ErrorMessage"

class BuyCoins extends React.Component {

    constructor(props) {
        super(props)

        this.state = {

            walletAddress: "",
            amount: "",
            isError: "",
            message: "",
            disable: "",
            yesOrNoModalShow: false,
            confirmationMessage: "",
            passwordModalForPaypalShow: false
        }
    }

    closeModal = () => {

        //reset all state
        this.setState({
            walletAddress: "", amount: "", amount: "",
            isError: "", message: "", disable: ""
        }, () => {
            this.props.onCloseBuyCoinsModal()
        })

    }


    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
            [e.target.name.concat('error')]: '',
        })
    }

    paypalPaymentProceed = () => {
        if (this.state.walletAddress === "" || this.state.walletAddress === undefined || this.state.walletAddress === null || this.state.walletAddress === "Select wallet") {
            return this.setState({ isError: true, message: "Please select the wallet" })
        }
        else if (this.state.amount === "" || this.state.amount === undefined || this.state.amount === null) {
            return this.setState({ isError: true, message: "Please select the amount" })
        }
        this.setState({ confirmationMessage: `Are you sure you want to buy ${this.state.amount} nucoin from the wallet ${this.state.walletAddress}`, yesOrNoModalShow: true })

    }






    render() {

        return (
            <Modal dialogClassName="SetPass Paypal"

                show={this.props.showBuyCoinModal}
                size="md"

                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body>
                    <PasswordForPaypal fromAddress={this.state.walletAddress} amount={this.state.amount} showPasswordModalForPaypal={this.state.passwordModalForPaypalShow} onClosePasswordModalForPaypalShow={() => {

                        this.setState({ passwordModalForPaypalShow: false })

                    }}
                    ></PasswordForPaypal>

                    <YesOrNoModal msg={this.state.confirmationMessage} showYesOrNoModal={this.state.yesOrNoModalShow}

                        onCloseYesOrNoModal={(value) => {
                            if (value === true) {
                                this.setState({ yesOrNoModalShow: false, passwordModalForPaypalShow: true })



                            }
                            else {
                                this.setState({ yesOrNoModalShow: false })

                            }



                        }}
                    >
                    </YesOrNoModal>
                    <h4 class="text-center mb-3">Buy Coins</h4>
                    {/* <p>Please select Payment Method</p> */}
                    <div className="form-group mb-4">
                        <select id="sel"
                            class="form-control mb-2"
                            onChange={this.onChange} name="walletAddress"
                        >
                            <option>Select wallet</option>
                            {
                                this?.props?.walletForPaypal.map((item) => (
                                    <option key={item.account_id} value={item.account_id}>{item.account_name}:{item.account_id}</option>
                                ))}
                        </select>

                        <input autoFocus={true} onChange={this.onChange} name="amount" className="form-control mb-2" placeholder="Enter amount of coin you want to buy" type="text" />
                    </div>
                    

                    {
                        this.state.isError ? <WarningMessageAlert message={this.state.message}></WarningMessageAlert>  : null
                    }

                    <button onClick={this.paypalPaymentProceed} style={{ pointerEvents: this.state.disable }} type="button" class="btn btn-secondary Cancel Paypal">Pay<span>Pal</span></button>
                    <button style={{ pointerEvents: this.state.disable }} type="button" class="btn btn-secondary Cancel Paypal" onClick={this.closeModal}>Cancel</button>

                </Modal.Body>
            </Modal >
        );

    }

}

export default (BuyCoins)

