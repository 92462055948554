// import Header from "./Header"
// import Footer from "./Footer"

// import Circle from '@material-ui/icons/Adjust';

// export default function PageNotFound() {
    
    
    
    
//     return (
//         <>
//         <Header></Header>
//             <div className="BlackStarsBack">
//                 <div id="Blackstars" />
//                 <div id="Blackstars2" />
//                 <div id="Blackstars3" />
//             </div>
//             <div className="container">
//                 {/* <Header /> */}
//                 {/* <div id="notfound">
//                     <div className="notfound">
//                         <div className="notfound-404" />
//                         <h1>404</h1>
//                         <h2>Oops! Page Not Be Found</h2>
//                         <p>Sorry but the page you are looking for does not exist, have been removed. name changed or is temporarily unavailable</p>
//                         <a href="#">Back to homepage</a>
//                     </div>
//                 </div> */}
//                 <div className="row" id="zIndexMob">
//                     <div className="col-lg-12">
//                         <div className="text-center mb-5">
//                             <h1 className="display-2 fw-medium text-color d-flex justify-content-center align-items-center">4
//                                 {/* <Circle className="Circle" /> */}
//                                 <div class="circle-wrapper">
//                                     <div class="circle-zero"></div>
//                                     <div class="circle-one"></div>
//                                     <div class="circle-two"></div>
//                                     <div class="circle-three"></div>
//                                     <div class="circle-shadow"></div>
//                                 </div>
//                                 4
//                             </h1>
//                             <h4 className="text-uppercase text-color">Sorry, page not found</h4>
//                             <div className="mt-5 text-center">
//                                 <a className="btn btn-primary waves-effect waves-light" href="/">Back to Home</a>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//             <Footer />
//         </>
//     )
// }

import "./style.css";
import { React, Component } from "react";


class PageNotFound extends Component {
  componentDidMount() {
    console.log("Start i am")
   // setPageTitle(constant.pageTitle.nootFoundPage);
  }

  render() {
    return (
      <>
        <div className="not-found-container">
          <h1 className="not-found-title">404 - Page not found</h1>
          <p className="not-found-message">
            The page you are looking for does not exist.
          </p>
        </div>
      </>
    );
  }
}

export default PageNotFound;