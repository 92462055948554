import React, { Component } from "react";
import { Link } from 'react-router-dom';
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css'
import moment from "moment"
import { signup } from '../../../api/authentication/authentication';
import { toastMessageInfo } from "../../../utils/toastMessage"
import 'react-toastify/dist/ReactToastify.css';
import { ErrorMessage } from "../../../messages/ErrorMessage"
import { WarningMessageAlert,ErrorMessageAlert,InfoMessageAlert } from "../../../utils/alert"
class Register extends Component {
    state = {

        userName: "",
        email: "",
        fname: "",
        lname: "",
        middleName: "",
        mobileNumber: "",
        password: "",
        confirmPassword: "",
        gender: "",
        dateOfBirth: "",
        referral: "",
        countryCode: "",
        isError: false,
        message: "",
        isLoading: false,
        arrowClass: "fa fa-caret-down",
        showReferal: false,
        disable: "",
        // hide and show password and confirm password
        passwordType: "password",
        hideAndShowLabelTextPassword: "Show",
        confirmPasswordType: "password",
        hideAndShowLabelTextConfirmPassword: "Show",
        isErrorAlert:false
    }

    showAndHideReferalOnClick = async () => {

        if (this.state.showReferal === true) {
            this.setState({ showReferal: false, arrowClass: "fa fa-caret-down" })
        }
        if (this.state.showReferal === false) {
            this.setState({ showReferal: true, arrowClass: "fa fa-caret-up" })
        }
    }

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })

    }
    submitSignup = async (e) => {
        e.preventDefault();
        this.userSignup()
    };

    userSignup = async (e) => {

        const dateRes = moment().diff(moment(this.state.dateOfBirth, 'YYYYMMDD'), 'years')

        if (this.state.userName === '' || this.state.userName === null) {
            return this.setState({ isError: true,isErrorAlert:false , message: 'Username is required!' });
        }
        else if (this.state.fname === '' || this.state.fname === null) {
            return this.setState({ isError: true,isErrorAlert:false , message: 'First name is required!' });
        }
        // else if (this.state.middleName === '' || this.state.middleName === null) {
        //     return this.setState({ isError: true, message: 'Middle name is required!' });
        // }
        else if (this.state.lname === '' || this.state.lname === null) {
            return this.setState({ isError: true,isErrorAlert:false , message: 'Last name is required!' });
        }

        else if (this.state.dateOfBirth === '' || this.state.dateOfBirth === null) {
            return this.setState({ isError: true,isErrorAlert:false , message: 'Date of birth is required!' });
        }

        else if (this.state.gender === '' || this.state.gender === null) {
            return this.setState({ isError: true,isErrorAlert:false , message: 'Gender is required!' });
        }
        else if (this.state.mobileNumber === '' || this.state.mobileNumber === null) {
            return this.setState({ isError: true,isErrorAlert:false , message: 'Mobile number is required!' });
        }
        else if (this.state.email === '' || this.state.email === null) {
            return this.setState({ isError: true,isErrorAlert:false , message: 'Email is required!' });
        }
        else if (this.state.password === '' || this.state.password === null) {
            return this.setState({ isError: true,isErrorAlert:false , message: 'Password is required!' });
        }
        if (dateRes < 18) {
            return this.setState({ isError: true,isErrorAlert:false , message: `We’re not able to complete your registration at this time. To sign up for a nucoin account, you must meet certain age requirements which is 18 years and over.` });
        }

        else if (this.state.password !== this.state.confirmPassword) {
            return this.setState({ isError: true,isErrorAlert:false , message: 'Password and confirm password not matched!' });
        }

        let dataObject = {
            "userName": this.state.userName,
            "email": this.state.email,
            "fname": this.state.fname,
            "lname": this.state.lname,
            "middleName": this.state.middleName,
            "mobileNumber": this.state.countryCode ? this.state.countryCode + this.state.mobileNumber : "61" + this.state.mobileNumber,
            "password": this.state.password,
            "confirmPassword": this.state.confirmPassword,
            "gender": this.state.gender,
            "dateOfBirth": this.state.dateOfBirth,
            "referral": this.state.referral,
            "countryCode": this.state.countryCode || '61'
        }

        try {
            this.setState({ isError: null, message: null, isLoading: true, disable: "none",isErrorAlert:false });
            const { data } = await signup(dataObject);
            this.setState({ isError: false, isLoading: false, disable: "", message: "" });

            if (data.data.toggle === 1) {
                localStorage.setItem('phone', data.data.phone || '')
                localStorage.setItem('email', this.state.email || '')
                this.props.history.push("/verify-otp");
            }
            else {
                toastMessageInfo("You are successfully signup.now you can login")
                this.props.history.push("/login");
            }

        }
        catch (err) {
            if (err.message === "Network Error") {
                return this.setState({ isError: true, disable: "", isLoading: false, message: ErrorMessage.networkError,isErrorAlert:false });
            }
            else if (err?.response?.data) {
                if (err?.response?.status === 500) {

                    return this.setState({ isError: false, disable: "", isLoading: false, message: err?.response?.data?.errors[0]?.message,isErrorAlert:true });
                }
                return this.setState({ isError: true,isErrorAlert:false, disable: "", isLoading: false, message: err?.response?.data?.errors[0]?.message });
            }

        }
    }


    hideAndShowPassword = (value) => {

        if (value === "Password") {
            if (this.state.passwordType === "text") {
                this.setState({
                    passwordType: "password",
                    hideAndShowLabelTextPassword: "Show"
                })
            }
            else if (this.state.passwordType === "password") {
                this.setState({
                    passwordType: "text",
                    hideAndShowLabelTextPassword: "Hide"
                })
            }
        }
        else if (value === "confirmPassword") {

            if (this.state.confirmPasswordType === "text") {
                this.setState({
                    confirmPasswordType: "password",
                    hideAndShowLabelTextConfirmPassword: "Show"
                })
            }
            else if (this.state.confirmPasswordType === "password") {

                this.setState({
                    confirmPasswordType: "text",
                    hideAndShowLabelTextConfirmPassword: "Hide"
                })
            }
        }
    }


    onKeyPress = (e) => {
        const enterOrSpace =
            e.key === "Enter" ||
            e.which === 13
        if (enterOrSpace === true) {
            // this.userSignup()

        }
    };


    render() {
        return (
            <>
                <div className="LoginStyle RegisterStyle">
                    <div className="bg-img-fix">
                        <div className="row">
                            <div className="col-xl-4 col-lg-5 col-md-6 col-sm-12 bg-white z-index2 relative p-a0 content-scroll skew-section left-bottom">
                                <div className="LoginForm">
                                    <div className="logo-header text-center pt-5 pb-4">
                                        <Link to="/" className="navbar-brand d-flex align-items-center">
                                            <span className="Logo-Txt">NUC</span>
                                            <img src="/assets/images/nucoin-logo.png" alt="" />
                                            <span className="Logo-Txt">IN</span>
                                        </Link>

                                    </div>
                                    <Link to="/" className="BackArrow">Back To Home Page</Link>

                                    <p className="my-4">Sign Up </p>
                                    <div className="form-group">
                                        <input autoFocus={true} onKeyPress={this.onKeyPress} onChange={this.onChange} name="userName" className="form-control mb-2" placeholder="User Name" type="text" />
                                    </div>
                                    <div className="form-group">
                                        <input onChange={this.onChange} onKeyPress={this.onKeyPress} name="fname" className="form-control mb-2" placeholder="First Name" type="text" />
                                    </div>
                                    <div className="form-group">
                                        <input onChange={this.onChange} onKeyPress={this.onKeyPress} name="middleName" className="form-control mb-2" placeholder="Middle Name" type="text" />
                                    </div>
                                    <div className="form-group">
                                        <input onChange={this.onChange} onKeyPress={this.onKeyPress} name="lname" className="form-control mb-2" placeholder="Last Name" type="text" />
                                    </div>

                                    <div className="form-group">
                                        <input onChange={this.onChange} onKeyPress={this.onKeyPress} name="dateOfBirth" className="form-control mb-2" type="date" />
                                    </div>

                                    <div name="gender" onChange={this.onChange} className="form-group d-flex align-items-center">
                                        <input onKeyPress={this.onKeyPress} className="mr-2 h-auto" type="radio" name="gender" value="Male" /> <span className="f-5">Male</span>
                                        <input onKeyPress={this.onKeyPress} className="ml-4 mr-2 h-auto" type="radio" name="gender" value="Female" /> <span className="f-5">Female</span>
                                    </div>
                                    <div className="form-group">
                                        <PhoneInput
                                            name="phoneNumber"
                                            type="text"

                                            inputProps={{
                                                name: 'phone',
                                                placeholder: "+61 (40)3074 786",
                                                required: true,
                                                disabled: true

                                            }}
                                            country={'au'}
                                            dropdownStyle={{ color: '#8b8b8b' }}
                                            inputStyle={{
                                                width: '97%',
                                                height: '50px',
                                                disable: true,
                                                margin: '16px',

                                            }}
                                            value={this.state.countryCode}
                                            onChange={(countryCode) => {
                                                this.setState({ countryCode: countryCode })


                                            }}

                                            required />

                                    </div>
                                    <div className="form-group">
                                        <input onKeyPress={this.onKeyPress} onChange={this.onChange} name="mobileNumber" className="form-control mb-2" placeholder="Phone Number" type="text" />
                                    </div>
                                    <div className="form-group">
                                        <input onKeyPress={this.onKeyPress} onChange={this.onChange} name="email" className="form-control mb-2" placeholder="Email" type="text" />
                                    </div>
                                    <div className="form-group position-relative">
                                        <input onKeyPress={this.onKeyPress} onChange={this.onChange} name="password" className="form-control mb-2" placeholder="Password" type={this.state.passwordType} />
                                        <label style={{ cursor: "pointer" }} onClick={() => this.hideAndShowPassword("Password")}>{this.state.hideAndShowLabelTextPassword}</label>
                                    </div>



                                    <div className="form-group position-relative">
                                        <input onKeyPress={this.onKeyPress} onChange={this.onChange} name="confirmPassword" className="form-control mb-2" placeholder="Confirm Password" type={this.state.confirmPasswordType} />
                                        <label style={{ cursor: "pointer" }} onClick={() => this.hideAndShowPassword("confirmPassword")}>{this.state.hideAndShowLabelTextConfirmPassword}</label>

                                    </div>
                                    <div className="form-group">
                                        <span onClick={this.showAndHideReferalOnClick} style={{ color: 'black', cursor: 'pointer' }}>Reference User Code (Optional)<i style={{ cursor: 'pointer' }} onClick={this.showAndHideReferalOnClick} className={this.state.arrowClass}></i></span>
                                        {
                                            this.state.showReferal === true ? <input name="referral" onChange={this.onChange} className="form-control mb-2" placeholder="Reference Code" type="text" /> : null
                                        }
                                    </div>

                                    {
                                        this.state.isError  ?  <WarningMessageAlert message={this.state.message}></WarningMessageAlert> : null
                                    }
                                    {
                                        this.state.isErrorAlert ? <ErrorMessageAlert message={this.state.message}></ErrorMessageAlert> : null
                                    }

                                    <div className="text-center my-5">

                                        <a href={() => false} style={{ pointerEvents: this.state.disable }} onClick={(event) => this.submitSignup(event)} className="site-button button-md btn-block text-white" type="button">
                                            {this.state.isLoading === true ? <img alt="loading..." style={{ height: '20px', width: '20px', marginRight: '8px' }} src="/assets/images/spinner3.gif"></img> : null}
                                            Sign Up</a>
                                    </div>
                                    <span>Already have an account?<Link to="/login"> Login here</Link></span>
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-5 col-md-6 col-sm-12 content-scroll">
                                <div className="text-white ContentSide max-w400">
                                    <h1 className="mb-4">Have an account?

                                    </h1>
                                    <p>If you have registered account click <Link to="/login" style={{ cursor: 'pointer', color: 'white' }}>"Log in"</Link> and insert your Username and password in form.</p>
                                </div>



                            </div>
                        </div>
                    </div>
                </div>
            </>

        )
    }

}

export default Register
