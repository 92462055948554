// import { Modal } from "bootstrap";
import React from 'react';
import Modal from 'react-bootstrap/Modal'
//import MneMonics from "../../MneMonics"

import ErrorAlert from "../../../Modals/ErrorAlert";
import InfoSuccess from "../../../Modals/InfoSuccess";
import { showValidateMnemonicsScreen } from "../../../../actions/wallet";
import { WarningMessageAlert,ErrorMessageAlert,InfoMessageAlert } from "../../../../utils/alert"
import { ErrorMessage } from "../../../../messages/ErrorMessage"
import { connect } from "react-redux";
const CryptoJS = require("crypto-js");


class ResetPassword extends React.Component {
    state = {
        showMnemoncis: false,
        password: "",
        isError: "",
        message: "",
        disable: "",
        confirmPassword: "",
        alertTitlt: "",
        alertMessage: "",
        errorModalShow: false,
        successModalShow: false,

        // hide and show password and confirm password
        passwordType: "password",
        hideAndShowLabelTextPassword: "Show",

        confirmPasswordType: "password",
        hideAndShowLabelTextConfirmPassword: "Show",
        isErrorAlert: false

    }

    submitPassword = async () => {



        if (this.state.password === '' || this.state.password === null) {
            return this.setState({ isError: true, isErrorAlert: false, message: 'Password is required!' });
        }
        else if (this.state.confirmPassword === '' || this.state.confirmPassword === null) {
            return this.setState({ isError: true, isErrorAlert: false, message: 'Confirm password is required!' });
        }

        else if (this.state.password.length <= 6) {
            return this.setState({ isError: true, isErrorAlert: false, message: 'Please choose a more secure password. It should be longer than 6 characters, unique to you, and difficult for others to guess.' });
        }

        else if (this.state.password !== this.state.confirmPassword) {
            return this.setState({ isError: true, isErrorAlert: false, message: 'Password and confirm password not matched!' });
        }

        let mnemonnicsToString = this.props.mnemoncis.toString()
        try {
            let encryptedData = CryptoJS.AES.encrypt(mnemonnicsToString, this.state.password).toString();
            localStorage.setItem(this.props.walletAddress, encryptedData)
            this.setState({ alertTitlt: "Successs", successModalShow: true, alertMessage: "Your wallet password is updated now you can make transactions with your new password" })

        }
        catch (e) {
            this.setState({ alertTitlt: "", errorModalShow: true, alertMessage: e })
        }
    }

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
            [e.target.name.concat('error')]: '',
        })
    }


    hideAndShowPassword = (value) => {

        if (value === "Password") {
            if (this.state.passwordType === "text") {
                this.setState({
                    passwordType: "password",
                    hideAndShowLabelTextPassword: "Show"
                })
            }
            else if (this.state.passwordType === "password") {
                this.setState({
                    passwordType: "text",
                    hideAndShowLabelTextPassword: "Hide"
                })
            }
        }
        else if (value === "confirmPassword") {

            if (this.state.confirmPasswordType === "text") {
                this.setState({
                    confirmPasswordType: "password",
                    hideAndShowLabelTextConfirmPassword: "Show"
                })
            }
            else if (this.state.confirmPasswordType === "password") {

                this.setState({
                    confirmPasswordType: "text",
                    hideAndShowLabelTextConfirmPassword: "Hide"
                })
            }
        }
    }



    closeModal = () => {
        //alert(this.state.amount)


        this.props.onClosePasswordResetModal()
        // set all state to default after closing modal
        this.setState({
            password: "",
            isError: "",
            message: "",
            disable: "",
            alertTitlt: "",
            alertMessage: "",
            errorModalShow: false,
            successModalShow: false,
            passwordType: "password",
            hideAndShowLabelTextPassword: "Show",
            confirmPasswordType: "password",
            hideAndShowLabelTextConfirmPassword: "Show"

        })

    }

    render() {

        return (
            <Modal dialogClassName="SetPass"
                show={this.props.showPasswordResetModal}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <ErrorAlert showErroModal={this.state.errorModalShow} title={this.state.alertTitlt} message={this.state.alertMessage} onCloseErrorModal={() =>
                    this.setState({
                        errorModalShow: false
                    })} />
                <InfoSuccess showSuccessModal={this.state.successModalShow} title={this.state.alertTitlt} message={this.state.alertMessage} onCloseSuccessModal={() =>
                    this.setState({
                        successModalShow: false
                    }, () => {
                        this.props.showValidateMnemonicsScreen(false)
                        this.props.history.push("/wallet-listing")
                    })

                } />
                <Modal.Body>


                    <h4 className="text-center mb-5">Reset Password</h4>
                    <div class="form-group mb-4 position-relative">
                        <input autoFocus={true} onChange={this.onChange} name="password" className="form-control mb-2" placeholder="Enter Password" type={this.state.passwordType} />
                        <label style={{
                            cursor: "pointer", color: 'white',
                            position: 'absolute', top: '9px', right: '0px'
                        }} onClick={() => this.hideAndShowPassword("Password")}>{this.state.hideAndShowLabelTextPassword}</label>
                    </div>

                    <div class="form-group mb-4 position-relative">
                        <input onChange={this.onChange} name="confirmPassword" className="form-control mb-2" placeholder="Confirm your password" type={this.state.confirmPasswordType} />
                        <label style={{
                            cursor: "pointer", color: 'white',
                            position: 'absolute', top: '9px', right: '0px'
                        }} onClick={() => this.hideAndShowPassword("confirmPassword")}>{this.state.hideAndShowLabelTextConfirmPassword}</label>

                    </div>

                    {
                        this.state.isError ? <WarningMessageAlert message={this.state.message}></WarningMessageAlert> : null
                    }
                    <button style={{ pointerEvents: this.state.disable }} onClick={this.submitPassword} type="button" class="btn btn-primary Submit">

                        Submit

                        </button>
                    <button style={{ pointerEvents: this.state.disable }} type="button" class="btn btn-secondary Cancel" onClick={this.closeModal}>Cancel</button>






                </Modal.Body>
            </Modal>
        );

    }

}


export default connect(null, { showValidateMnemonicsScreen })(ResetPassword)
