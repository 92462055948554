// import { Route, Switch, BrowserRouter } from 'react-router-dom';
// import { withRouter } from 'react-router';
import { Route, Switch, BrowserRouter, withRouter, Redirect } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Provider } from 'react-redux';
import PrivateRoute from "./utils/PrivateRoute"
import KycProtection from "./utils/KycProtection"
//import setAuthToken from './utils/SetAuthToken'
import store from './store';
import jwt_decode from 'jwt-decode'
import { setCurrentUser } from './actions/auth'
import PublicRoute from './utils/PublicRoute';
import 'bootstrap/dist/css/bootstrap.min.css';
import Team from "./components/OurTeam/Team"
import SimpleRoute from "./utils/SimpleRoute";
//Component
import ScrollUpButton from "react-scroll-up-button";
import Maintenance from "./components/Maintenance/Maintenance";
import Login from './components/Auth/Login/Login';
import Register from './components/Auth/Register/Register';
import VerifyOtp from './components/Auth/VerifyOtp/VerifyOtp';
import UpdatePassword from './components/Auth/UpdatePassword/UpdatePassword';
import ForgotCredential from './components/Auth/ForgoCredentials/ForgotCredential';
import LoginVerification from "./components/Auth/LoginVerification/LoginVerification"
import Home from './components/Home/Home';
import WalletListing from './components/Wallet/WalletListing/WalletListing';
import CreateWallet from './components/Wallet/CreateWallet/CreateWallet';
import CopyMnemonics from './components/Wallet/CreateWallet/CopyMnemonics';
import ValidateMnemonics from './components/Wallet/ValidateMnemonics';
import MnemonicsTest from './components/Wallet/CreateWallet/MnemonicsTest';
//import NotFound from "./components/NotFound/NotFound"
import Profile from "./components/Profile/profile";
// import Kyc from "./components/KYC/kyc";
import { getToggleByColumnName } from "./api/general/general";
import kyc from "./components/KYC/kyc";
import NotificationList from './components/Notification/NotificationList';
import NotificationDetail from './components/Notification/NotificationDetail';
import ShopRegister from "./components/Shop/ShopRegister";
import ShopCardDetail from "./components/Shop/ShopCardDetail"

import SelectAccount from './components/PayId/SelectAccount';
import PageNotFound from "./components/Layout/PageNotFound";
import TermAndCondition from "./components/TermAndCondition/TermAndCondition";

import Header from "./components/Layout/Header";

import BecomeMiner from "./components/Miner/BecomeMiner"



//import "./app.css"

import PurchaseCoins from './components/PurchaseCoins/PurchaseCoins';
import NewsHeader from "./components/Layout/NewsHeader";
import { NotificationSDK } from 'notificationsdk';

import config from './_config/index';
import POSTerminal from "./components/Shop/POSTerminal/POSTerminal";
import UserDetail from "./components/Shop/POSTerminal/UserDetail/UserDetail";
import ProductList from "./components/Shop/POSTerminal/ProductList/ProductList";
import Cookies from 'js-cookie';
import { SET_TERM } from "./types/auth";
import TermProtection from "./utils/TermProtection";



if (localStorage.jwtToken) {
    // Set auth token header auth
    // Decode token and get user info 
    const decoded = jwt_decode(localStorage.jwtToken)
    let termValue = Cookies.get('myPersistentCookie');
    store.dispatch({ type: SET_TERM, payload: termValue })
    store.dispatch(setCurrentUser(decoded))
}

function RemoveClass() {

    if (document.getElementById('User') && document.getElementById('User').classList.contains('show')) {
        document.getElementById('zIndexMob').classList.remove("position-relative")
    }

}


function App() {
    const [maintenance, setMaintenance] = useState("")





    // useEffect(() => {
    //     store.dispatch({ type: "MAINTINANCE_STATUS", payload: localStorage.getItem("maitenanceValue") })
    // }, [])

    // useEffect(() => {
    //     const intervalId = setInterval(async () => {
    //         try {
    //             let { data } = await getToggleByColumnName("toggle_maintenance") // replace with maintenance toggle

    //             if (data.toggle_maintenance.calculated_toggle === 1) { //  toggle_signup_otp with originak
    //                 localStorage.setItem("maitenanceValue",true)
    //                 store.dispatch({ type: "MAINTINANCE_STATUS", payload: "true" })
    //                 setMaintenance(true)
    //             }
    //             if (data.toggle_maintenance.calculated_toggle === 0) {  //  toggle_signup_otp with originak
    //                 localStorage.setItem("maitenanceValue",false)
    //                 store.dispatch({ type: "MAINTINANCE_STATUS", payload: "false" })
    //                 setMaintenance(false)
    //             }
    //         }
    //         catch (e) {

    //         }
    //     }, 1000);

    //     // Clean up the interval when the component unmounts or when the dependencies change.
    //     return () => clearInterval(intervalId);
    // }, []);

    return (
        <Provider store={store}>
            <div className="position-relative" style={{ zIndex: 9 }}><NewsHeader /></div>
            <BrowserRouter>


                <Switch>
                    <Route exact={true} path="/maintenance" component={Maintenance} />


                    <div id="example" className="wrapper container" onClick={RemoveClass}>
                        <NotificationSDK connectionURL={config.socketConnectionUrl} apiBasePath={config.notificationUrl} projectKey={config.projectId} authToken={localStorage.getItem('jwtToken')} />
                        {/* <NewsHeader /> */}
                        {!maintenance && <Header />}
                        {/* <Header /> */}

                        <PublicRoute exact={true} path="/login" component={Login} />
                        <PublicRoute exact path="/register" component={Register} />
                        <PublicRoute exact path="/verify-otp" component={VerifyOtp} />
                        <PublicRoute exact path="/login-verification" component={LoginVerification} />
                        <PublicRoute exact path="/update-password" component={UpdatePassword} />
                        <PublicRoute exact path="/forgot-credential" component={ForgotCredential} />
                        <SimpleRoute exact path="/" component={Home} />
                        <PrivateRoute exact path="/wallet-listing" component={WalletListing} />
                        <PrivateRoute exact path="/create-wallet" component={CreateWallet} />
                        <PrivateRoute exact path="/purchase-coins" component={PurchaseCoins} />
                        <PrivateRoute exact path="/copy-mnemonics" component={CopyMnemonics} />
                        <PrivateRoute exact path="/mnemonics-test" component={MnemonicsTest} />
                        <PrivateRoute exact path="/validate-mnemonics" component={ValidateMnemonics} />
                        <KycProtection exact={true} path="/kyc" component={kyc} />
                        <PrivateRoute exact path="/profile" component={Profile} />
                        <SimpleRoute exact path="/team" component={Team} />
                        <PrivateRoute exact path="/notifications" component={NotificationList} />
                        <PrivateRoute exact path="/notification/detail/:id" component={NotificationDetail} />
                        <PrivateRoute exact path="/select-account" component={SelectAccount} />
                        <SimpleRoute exact path="/become-miner" component={BecomeMiner} />
                        <PrivateRoute exact path="/shop-register" component={ShopRegister} />
                        <PrivateRoute exact path="/pos/terminal" component={POSTerminal} />
                        <PrivateRoute exact path="/pos/terminal/:user_name" component={UserDetail} />
                        <PrivateRoute exact path="/product/list" component={ProductList} />
                        <PrivateRoute exact path="/shop-register-detail" component={ShopCardDetail} />
                        <TermProtection exact path="/term-and-condition" component={TermAndCondition} />

                        {/* <Route component={NotificationDetail} /> */}
                        <ScrollUpButton />

                    </div>

                </Switch>




            </BrowserRouter>
        </Provider>
    );
}
export default App;

