import config from "../_config";

export const validateFile = (file) => {
    if (!file || !file.file) {
        return {
            error: true,
            msg: 'Empty or invalid file. Please try again'
        }
    }

    const { size, type } = file.file;
    const { validFileMimes = [], maxKYCFileSizeMB = 10 } = config;

    if (!size) {
        return {
            error: true,
            msg: 'Unable to get file size. Please try again'
        }
    }

    if (!type) {
        return {
            error: true,
            msg: 'Unable to get file type. Please try again'
        }
    }

    const sizeInMegabytes = parseInt(size) / (1024 * 1024)

    if (sizeInMegabytes > maxKYCFileSizeMB) {
        return {
            error: true,
            msg: `File size should be less than ${maxKYCFileSizeMB} MB`
        }
    }

    if (!validFileMimes.includes(type)) {
        return {
            error: true,
            msg: `File type not supported.${validFileMimes.length > 0 ? ` It can be ${validFileMimes.join(',')} only` : ``}`
        }
    }

    return {
        error: false,
        msg: 'File verified'
    }
}