import invoke from '../../utils/invoke';
import { encrypt } from '../../utils/utils';
import config from '../../_config';

export const getPosTerminalUserProfile = (userName) => {

    return invoke({
        method: 'GET',
        //baseURL: config.apiUrl,
        baseURL: config.apiUrl,
        route: `v1/payment/user/profile/${userName}`
    });
}

export const getProductListing = (userName) => {

    return invoke({
        method: 'GET',
        baseURL: config.apiUrl,
        route: `v1/payment/items`
    });
}

export const getBalanceByAddress = (address) => {

    return invoke({
        method: 'GET',
        //baseURL: config.apiUrl,
        baseURL: config.walletBlockchainServiceApiUrl,
        route: `v1/bc/wallet/balance/${address}`
    });
}

export const confirmPaymentPOSTerminal = (data = {}) => {

    return invoke({
        method: 'POST',
        baseURL: config.apiUrl,
        route: `v1/payment/checkout`,
        data: data,
        headers: {
            Authorization: localStorage.getItem('jwtToken')
        }
    });
}