import React, { Component } from "react";

import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css'
import { forgetPasswordValidationApi } from '../../../api/authentication/authentication';
import { toastMessageInfo } from "../../../utils/toastMessage"
import 'react-toastify/dist/ReactToastify.css';
import queryString from 'query-string'
import { WarningMessageAlert,ErrorMessageAlert,InfoMessageAlert } from "../../../utils/alert"
import { ErrorMessage } from "../../../messages/ErrorMessage"

class ForgotCredential extends Component {
    state = {
        mobileNumber: "",
        authenticateMediumType: "",
        email: "",
        isError: false,
        message: "",
        isLoading: false,
        forgotType: "",
        disable: "",
        countryCode: "",
        isErrorAlert: false
    }
    onChangeMedium = (e) => {
        this.setState({ isError: null, message: null });
        this.setState({ authenticateMediumType: e.target.value })
    }
    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
            [e.target.name.concat('error')]: '',
        })

    }
    componentDidMount() {
        const value = queryString.parse(this.props.location.search);
        this.setState({ forgotType: value.forgotType })


    }
    submitForgotCredential = async (e) => {
        e.preventDefault();
        this.searchUserAccount()
    };

    searchUserAccount = async (e) => {

        let forgotCredentialConstructedObject
        if (this.state.authenticateMediumType === "Email") {
            if (this.state.email === '' || this.state.email === null) {
                return this.setState({ isError: true, isErrorAlert: false, message: 'Email is required!' });
            }
            forgotCredentialConstructedObject = {
                forgotType: this.state.forgotType,
                authenticateMediumType: this.state.authenticateMediumType,
                email: this.state.email
            }
        }
        else if (this.state.authenticateMediumType === "Phone") {
            if (this.state.mobileNumber === '' || this.state.mobileNumber === null) {
                return this.setState({ isError: true, isErrorAlert: false, message: 'Phone is required!' });
            }
            forgotCredentialConstructedObject = {
                forgotType: this.state.forgotType,
                authenticateMediumType: this.state.authenticateMediumType,
                phone: this.state.mobileNumber,
                countryCode: this.state.countryCode ? this.state.countryCode : "61"

            }
        }
        //console.log("phone", forgotCredentialConstructedObject)

        try {
            this.setState({ isError: null, message: null, isLoading: true, disable: "none", isErrorAlert: false });
            await forgetPasswordValidationApi(forgotCredentialConstructedObject);
            this.setState({ isError: false, isLoading: false, disable: "", message: "" });

            if (this.state.forgotType === "Username") {
                if (this.state.authenticateMediumType === "Email") {
                    toastMessageInfo("If your email address exists in our database, you will receive a username at your email address in a few minutes.")
                    this.props.history.push("/login");

                }
                else if (this.state.authenticateMediumType === "Phone") {
                    toastMessageInfo("If your phone number exists in our database, you will receive a username at your phone number in a few minutes.")
                    this.props.history.push("/login");
                }
            }
            else {

                this.props.history.push("/update-password");
            }

        }
        catch (err) {

            if (err?.response?.data) {
                if (err?.response?.status === 500) {

                    return this.setState({ isError: false, disable: "", isLoading: false, message: err?.response?.data?.errors[0]?.message, isErrorAlert: true });
                }
                return this.setState({ isError: true, isErrorAlert: false, disable: "", isLoading: false, message: err?.response?.data?.errors[0]?.message });
            }
            else if (err.message === "Network Error") {

                return this.setState({ isError: true, isErrorAlert: false, disable: "", isLoading: false, message: ErrorMessage.networkError });

            }
        }
    }


    onKeyPress = (e) => {
        const enterOrSpace =
            e.key === "Enter" ||
            e.which === 13
        if (enterOrSpace === true) {

            this.searchUserAccount()
        }
    };



    render() {
        return (
            <>
                <div className="LoginStyle RegisterStyle">
                    <div className="bg-img-fix">
                        <div className="row">
                            <div className="col-xl-4 col-lg-5 col-md-6 col-sm-12 bg-white z-index2 relative p-a0 content-scroll skew-section left-bottom">
                                <div className="LoginForm">
                                    <div className="logo-header text-center pt-5 pb-4">
                                        <a href="index" className="navbar-brand d-flex align-items-center">
                                            <span className="Logo-Txt">NUC</span>
                                            <img src="/assets/images/nucoin-logo.png" alt="" />
                                            <span className="Logo-Txt">IN</span>
                                        </a>
                                    </div>
                                    <a href="/" className="BackArrow">Back To Home Page</a>
                                    <p className="my-5"></p>
                                    <p>Please select email address or mobile number to search for your account.</p>

                                    <div name="gender" onChange={this.onChangeMedium} className="form-group d-flex align-items-center">
                                        <input className="mr-2 h-auto" type="radio" name="gender" value="Phone" /> <span className="f-5">Phone</span>
                                        <input className="ml-4 mr-2 h-auto" type="radio" name="gender" value="Email" /> <span className="f-5">Email</span>
                                    </div>
                                    {
                                        this.state.authenticateMediumType === 'Phone' ? <React.Fragment>
                                            <div className="form-group">
                                                <PhoneInput

                                                    name="phoneNumber"
                                                    type="text"

                                                    inputProps={{
                                                        name: 'phone',
                                                        placeholder: "+61 (40)3074 786",
                                                        required: true,
                                                        disabled: true

                                                    }}
                                                    country={'au'}
                                                    dropdownStyle={{ color: '#8b8b8b' }}
                                                    inputStyle={{
                                                        width: '120px',
                                                        height: '37px',
                                                        disable: true,
                                                        margin: '16px',

                                                    }}
                                                    value={this.state.countryCode}
                                                    onChange={(countryCode) => {
                                                        this.setState({ countryCode: countryCode })


                                                    }}

                                                    required />

                                            </div>
                                            <div className="form-group">
                                                <input autoFocus={true} onChange={this.onChange} onKeyPress={this.onKeyPress} name="mobileNumber" className="form-control mb-2" placeholder="Phone Number" type="text" />
                                            </div>
                                            {
                                                this.state.isError ? <WarningMessageAlert message={this.state.message}></WarningMessageAlert> : null
                                            }

                                            {
                                                this.state.isErrorAlert ? <ErrorMessageAlert message={this.state.message}></ErrorMessageAlert> : null
                                            }

                                            <div className="text-center">

                                                <a href={() => false} style={{ pointerEvents: this.state.disable }} onClick={(event) => this.submitForgotCredential(event)} className="site-button button-md btn-block text-white" type="button">
                                                    {this.state.isLoading === true ? <img alt="loading..." style={{ height: '20px', width: '20px', marginRight: '8px' }} src="/assets/images/spinner3.gif"></img> : null}
                                                    Search </a>
                                            </div>
                                        </React.Fragment> : this.state.authenticateMediumType === 'Email' ?
                                            <React.Fragment>
                                                <div className="form-group">
                                                    <input autoFocus={true} onChange={this.onChange} name="email" className="form-control mb-2" placeholder="Enter your email address" type="text" />
                                                </div>

                                                {
                                                    this.state.isError ? <WarningMessageAlert message={this.state.message}></WarningMessageAlert> : null
                                                }

                                                {
                                                    this.state.isErrorAlert ? <ErrorMessageAlert message={this.state.message}></ErrorMessageAlert> : null
                                                }

                                                <div className="text-center">

                                                    <a href={() => false} style={{ pointerEvents: this.state.disable }} onClick={(event) => this.submitForgotCredential(event)} className="site-button button-md btn-block text-white" type="button">
                                                        {this.state.isLoading === true ? <img alt="loading..." style={{ height: '20px', width: '20px', marginRight: '8px' }} src="/assets/images/spinner3.gif"></img> : null}
                                                        Search</a>
                                                </div>

                                            </React.Fragment>

                                            : null
                                    }

                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-5 col-md-6 col-sm-12 content-scroll">
                                <div className="text-white ContentSide max-w400">
                                    <h1 className="mb-4">Forgot Your Credential</h1>
                                    <p>Select one of the medium to receive your credential update.</p>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </>

        )
    }

}

export default ForgotCredential
