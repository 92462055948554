/* eslint-disable */
export default async (data = [], fileName = 'data.json') => {

    const json = JSON.stringify(data, null, '\t');
    const blob = new Blob([json],{type:'application/json'});
    const href = await URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = href;
    link.download = fileName + "_" + (new Date().toLocaleString()).replace(',', '-') + ".json";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
/* eslint-disable */
