// import { Modal } from "bootstrap";
import React from 'react';
import Modal from 'react-bootstrap/Modal'
//import MneMonics from "../../MneMonics"
//import { transferCoin } from '../../../../api/blockchainapi/blockchainapi';
import RedInfoDialogue from "../../../Modals/RedInfoDialogue";
import { showValidateMnemonicsScreen } from "../../../../actions/wallet";
import { WarningMessageAlert, ErrorMessageAlert, InfoMessageAlert } from "../../../../utils/alert"
import { connect } from "react-redux";
const CryptoJS = require("crypto-js");
const { Keyring } = require('@polkadot/api');


class PasswordForPaypal extends React.Component {
    state = {
        password: "",
        isError: "",
        message: "",
        disable: "",
        passwordType: "password",
        hideAndShowLabelText: "Show",
        showMnemonics: false,
        showMnemonicsText: "",
        showPassword: true,
        prvKey: ""

    }

    submitPassword = async () => {
        // alert(this.props.fromAddress)
        if (this.state.showPassword === true) {
            if (this.state.password === '' || this.state.password === null) {
                return this.setState({ isError: true, message: 'Password is required!' });
            }
            // check if from address exist in local
            let fromExist = localStorage.getItem(this.props.fromAddress)

            if (fromExist) {
                try {
                    const encryptedData = localStorage.getItem(this.props.fromAddress)

                    // decrypt data using password
                    var mnemonicsValue = await CryptoJS.enc.Utf8.stringify(CryptoJS.AES.decrypt(encryptedData, this.state.password))
                    //compenstate our previous user
                    if (`${mnemonicsValue}`.includes("string")) mnemonicsValue = JSON.parse(mnemonicsValue).string;

                    // if we got the mnemoncs
                    if (mnemonicsValue) {
                        try {
                            alert("go to paypal")
                            // you can continue with paypal redirection

                        }
                        catch (e) {

                            this.setState({ isError: true, isLoading: false, disable: "", message: e.message })
                        }
                    }
                    // if got null in decrypting
                    else {
                        this.setState({
                            isError: true, message: "You are entering a wrong password",
                            showMnemonicsText: "Click here to continue if you don't remember the password",
                        })
                    }
                }
                // if we got mulformed in decrypting
                catch (e) {
                    this.setState({
                        isError: true, message: "You are entering a wrong password", showMnemonics: false,
                        showMnemonicsText: "Click here to continue if you don't remember the password",
                    })
                }
            }
            else {
                this.setState({
                    isError: false, showMnemonics: true, showPassword: false, message: "",
                    showMnemonicsText: "Paste your wallet mnemonics here to continue",
                })

            }


        }

        else if (this.state.showMnemonics === true) {

            if (this.state.prvKey === '' || this.state.prvKey === null) {
                return this.setState({ isError: true, message: 'mnemonics is required!' });
            }
            const keyring = new Keyring({
                type: 'sr25519',
                ss58Format: 42,
            });

            try {
                const newPair = keyring.addFromUri(this.state.prvKey);
                //check wallet address match with mnemoncis
                if (newPair.address !== this.props.fromAddress) {
                    return this.setState({ isError: true, message: 'Your mnemonics not matched with your wallet address!' });
                }
                else {
                    // if match you can continue with paypal redirection
                    this.setState({ isError: false, message: '' });
                    alert("go to paypal")
                }
            }
            catch (e) {

                return this.setState({ isError: true, message: e.message });
            }


        }







    }

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
            [e.target.name.concat('error')]: '',
        })
    }
    closeModal = () => {

        this.props.onClosePasswordModalForPaypalShow()
        // set all state to default after closing modal
        this.setState({
            password: "",
            isError: "",
            message: "",

            disable: "",


            passwordType: "password",
            hideAndShowLabelText: "Show",
            showMnemonics: false,
            showMnemonicsText: "",
            showPassword: true,
            mnemonics: ""
        })

    }

    hideAndShowPassword = () => {
        if (this.state.passwordType === "text") {

            this.setState({
                passwordType: "password",
                hideAndShowLabelText: "Show"

            })
        }
        else if (this.state.passwordType === "password") {
            this.setState({
                passwordType: "text",
                hideAndShowLabelText: "Hide"

            })
        }
    }

    render() {
        return (
            <Modal dialogClassName="SetPass"
                show={this.props.showPasswordModalForPaypal}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <RedInfoDialogue showRedInfoModal={this.state.redInfoModalShow} onCloseRedInfoModal={(proceed) => {
                    if (proceed === true) {
                        this.setState({
                            redInfoModalShow: false
                        }, () => {

                            this.props.showValidateMnemonicsScreen(true, "passwordReset")
                            this.props.history.push("/validate-mnemonics", { walletAddress: this.props.fromAddress, walletName: this.props.walletName })
                        })
                    }
                    else {

                        this.setState({
                            redInfoModalShow: false,
                        })
                    }
                }
                } />

                <Modal.Body>
                    {
                        this.state.showPassword === true ?
                            <>
                                <h4 className="text-center mb-5">Set Password</h4>
                                <div class="form-group mb-4 position-relative">
                                    <input autoFocus={true} onChange={this.onChange} name="password" className="form-control mb-2" placeholder="Enter Password" type={this.state.passwordType} />
                                    <label style={{
                                        cursor: "pointer", color: 'white',
                                        position: 'absolute', top: '9px', right: '0px'
                                    }} onClick={() => this.hideAndShowPassword("Password")}>{this.state.hideAndShowLabelText}</label>

                                </div></> : null
                    }

                    <label style={{ color: 'white', cursor: 'pointer' }} onClick={() => {

                        this.setState({ showMnemonics: true, showPassword: false, isError: false, message: "", showMnemonicsText: "" })
                    }}>{this.state.showMnemonicsText}</label>

                    {
                        this.state.showMnemonics === true ? <div class="form-group mb-4 position-relative">
                            <input autoFocus={true} onChange={this.onChange} name="prvKey" className="form-control mb-2" placeholder="Paste your mnemmoncis heredddd" type="text" />

                        </div> : null
                    }


                    {
                        this.state.isError ? <WarningMessageAlert message={this.state.message}></WarningMessageAlert>  : null
                    }
                    <button style={{ pointerEvents: this.state.disable }} onClick={this.submitPassword} type="button" class="btn btn-primary Submit">
                        {this.state.isLoading === true ? <img alt="loading..." style={{ height: '20px', width: '20px', marginRight: '8px' }} src="/assets/images/spinner3.gif"></img> : null}
                        Click To Continue

                        </button>
                    <button style={{ pointerEvents: this.state.disable }} type="button" class="btn btn-secondary Cancel" onClick={this.closeModal}>Cancel</button>




                </Modal.Body>
            </Modal>
        );

    }

}


export default connect(null, { showValidateMnemonicsScreen })(PasswordForPaypal)
