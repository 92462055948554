// import { Modal } from "bootstrap";
import React from 'react';
import Modal from 'react-bootstrap/Modal'
import CheckCircle from '@material-ui/icons/CheckCircle';
import { toastMessageFailure, toastMessageSuccess } from "../../utils/toastMessage";
import { connect } from "react-redux";
import { addAirdrop } from '../../api/wallet/wallet';
import { logOutUser } from '../../actions/auth';
import { Link } from "react-router-dom";
class AirDrop extends React.Component {
    state = {
        emailMessageText: "",
        isEmailSelectError: false,
        emailErrorMessage: "",
        isNominateAirdropLoader: false,
        disabled: "",
        airdropNominated: false
    }
    closeModal = () => {
        this.props.onCloseAirDropModal(this.state.airdropNominated)

    }



    submitAirDrop = async () => {

        this.setState({ disabled: "none", isNominateAirdropLoader: true })
        try {
            await addAirdrop({ walletAddress: this.props.wallet })
            toastMessageSuccess("Airdrop wallet nominated successfully.")

            this.setState({ airdropNominated: true, disabled: "", isNominateAirdropLoader: false }, () => {
                this.closeModal(this.state.airdropNominated)
            })

        }
        catch (err) {
            this.setState({ disabled: "", isNominateAirdropLoader: false })
            if (err?.response?.status === 403) {
                this.props.logOutUser(err?.response?.status)
            }
            else if (err.message === "Network Error") {

                toastMessageFailure("Sorry! We are facing some network issue.Please try again later.")
            }
            else if (err?.response?.data) {
                toastMessageFailure(err?.response?.data?.errors[0]?.message)

            }


        }

    }

    render() {



        return (
            <Modal dialogClassName="InfoSuccess text-center"
                show={this.props.showAirDropModal}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <CheckCircle />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{

                    wordWrap: 'break-word',
                    paddingLeft: '4px',
                    paddingRight: '4px'
                }}>

                    <p>Thank you for nominating your wallet {this.props.wallet} to receive the Airdrop. Please make sure that you have not lost the mnemonics keys for this wallet address.</p>
                    <p>Please note that NUC Airdrop will be added your account within 36 hours, thank you.</p>

                    {/* <button disabled={this.state.disabled} style={{ marginRight: '8px' }} type="button" class="btn btn-primary submit" onClick={this.submitAirDrop}>
                        {this.state.isNominateAirdropLoader === true ? <img style={{ height: '20px', width: '20px', marginRight: '8px' }} src="/assets/images/spinner3.gif"></img> : null}
                    Confirm</button> */}

                    <Link onClick={this.submitAirDrop} style={{ pointerEvents: this.state.disabled }} className="Read-More" to="#" >
                        {this.state.isNominateAirdropLoader === true ? <img alt="loading..." style={{ height: '20px', width: '20px', marginRight: '8px' }} src="/assets/images/spinner3.gif"></img> : null}
                        Confirm</Link>

                    <Link style={{

                        borderRadius: '3px',
                        color: '#fff',
                        padding: '10px 30px',
                        display: 'inlineBlock',
                        marginRight: '10px',
                        pointerEvents: this.state.disabled

                    }} type="button" to="#" class="btn btn-secondary Submit" onClick={this.closeModal}>Cancel</Link>
                </Modal.Body>
            </Modal>
        )
    }


}


export default connect(null, { logOutUser })(AirDrop)

