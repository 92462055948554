import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { verifyOtpApi, sendOtpAgainApi } from '../../../api/authentication/authentication';
import { toastMessageInfo, toastMessageSuccess } from "../../../utils/toastMessage"
import { WarningMessageAlert,ErrorMessageAlert,InfoMessageAlert } from "../../../utils/alert"
import { ErrorMessage } from "../../../messages/ErrorMessage"

class VerifyOtp extends Component {
    state = {
        otpCode: "",
        isError: false,
        message: "",
        isLoading: false,
        isResendOtpLoading: false,
        isErrorAlert: false,
        disable: ""
    }
    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
            [e.target.name.concat('error')]: '',
        })

    }
    componentDidMount() {
        if (localStorage.getItem("phone") === null && localStorage.getItem("email") === null) {
            this.props.history.push("/register")
        }
    }

    verifyOtp = async () => {
        if (this.state.otpCode === '' || this.state.otpCode === null) {
            return this.setState({ isError: true, isErrorAlert: false, message: 'OTP Code required!' });
        } else if (this.state.otpCode.length !== 6) {
            return this.setState({ isError: true, message: 'Please enter the 6 digit OTP code!' });
        }
        this.setState({ isError: null, isErrorAlert: false, message: null, isLoading: true, disable: "none" });
        try {
            await verifyOtpApi({
                "otpCode": this.state.otpCode,
                "authValue": localStorage.getItem("phone")
            });
            this.setState({ isError: false, isLoading: false, disable: "", message: "", isErrorAlert: false });
            localStorage.removeItem('phone')
            localStorage.removeItem('email')
            toastMessageSuccess("Your account has been verified successfully just now.You can log in.")
            this.props.history.push("/login")
        }
        catch (err) {


            if (err?.response?.data) {
                if (err?.response?.status === 500) {

                    return this.setState({ isError: false, disable: "", isLoading: false, message: err?.response?.data?.errors[0]?.message, isErrorAlert: true });
                }

                return this.setState({ isError: true, disable: "", isErrorAlert: false, isLoading: false, message: err?.response?.data?.errors[0] ? err?.response?.data?.errors[0]?.message : err?.response?.data });
            }
            else if (err.message === "Network Error") {

                return this.setState({ isError: true, disable: "", isLoading: false, isErrorAlert: false, message: ErrorMessage.networkError });

            }

        }

    }

    sendOtpAgain = async (e) => {
        e.preventDefault();

        this.setState({ isError: null, message: null, isResendOtpLoading: true, disable: "none" });
        try {
            await sendOtpAgainApi({

                "mobileNumber": localStorage.getItem("phone"),
                "email": localStorage.getItem("email")

            });
            // toastMessageInfo("Otp code send again to your email and mobile number")
            toastMessageInfo("OTP code send again to your email")
            this.setState({ isError: false, isResendOtpLoading: false, disable: "", message: "" });

        }
        catch (err) {
            if (err?.response?.data) {
                if (err?.response?.status === 500) {

                    return this.setState({ isError: false, disable: "", isLoading: false, message: err?.response?.data?.errors[0]?.message, isErrorAlert: true });
                }

                return this.setState({ isError: true, isResendOtpLoading: false, disable: "", message: err?.response?.data?.errors[0]?.message, isErrorAlert: false });
            }
            else if (err.message === "Network Error") {

                return this.setState({ isError: true, isResendOtpLoading: false, disable: "", message: ErrorMessage.networkError });

            }

        }

    }

    submitOtp = async (e) => {
        e.preventDefault();
        this.verifyOtp()


    };

    onKeyPress = (e) => {
        const enterOrSpace =
            e.key === "Enter" ||
            e.which === 13
        if (enterOrSpace === true) {
            this.verifyOtp()
        }
    };


    render() {
        return (
            <>
                <div className="LoginStyle">
                    <div className="bg-img-fix">
                        <div className="row">
                            <div className="col-xl-5 col-lg-5 col-md-6 col-sm-12 bg-white z-index2 relative p-a0 content-scroll skew-section left-bottom">
                                <div className="LoginForm">
                                    <div className="logo-header text-center pt-5 pb-4">
                                        <a href="/" className="navbar-brand d-flex align-items-center">
                                            <span className="Logo-Txt">NUC</span>
                                            <img src="/assets/images/nucoin-logo.png" alt="" />
                                            <span className="Logo-Txt">IN</span>
                                        </a>
                                    </div>
                                    <a href="/" className="BackArrow">Back To Home Page</a>
                                    <p className="my-5">Verify OTP </p>
                                    <div className="form-group">
                                        <div className="alert alert-success" role="alert">
                                            <h4 className="alert-heading">Enter security code</h4>
                                            <p style={{ fontSize: '16px' }}>
                                                {/* We have sent you OTP code on your Mobile: <b>{localStorage.getItem("phone")} </b> and Email :<b>{localStorage.getItem("email")}</b><br></br>Please check your Phone or Email  and enter the code in below box to complete the signup process.Thanks ! */}

                                                We have sent a OTP code to your Email: <b className="alert-success">{localStorage.getItem("email")}</b><br></br>Please check your email and enter the code in the box below to complete the signup process.<br></br><p style={{ color: "#155724" }}>Thank you!</p>


                                            </p>

                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <input onChange={this.onChange}
                                            onKeyPress={this.onKeyPress} name="otpCode" className="form-control mb-2" placeholder="Please enter 6 digit otp code" type="text" />

                                    </div>
                                    {
                                        this.state.isError ? <WarningMessageAlert message={this.state.message}></WarningMessageAlert> : null
                                    }
                                    {
                                        this.state.isErrorAlert ? <ErrorMessageAlert message={this.state.message}></ErrorMessageAlert> : null
                                    }

                                    <div className="text-center my-5">

                                        <a href={() => false} style={{ pointerEvents: this.state.disable }} onClick={(event) => this.submitOtp(event)} className="site-button button-md btn-block text-white" type="button">
                                            {this.state.isLoading === true ? <img alt="loading..." style={{ height: '20px', width: '20px', marginRight: '8px' }} src="/assets/images/spinner3.gif"></img> : null}


                                            Verify OTP</a>
                                    </div>
                                    <span>Didn't receive the code? <Link style={{ pointerEvents: this.state.disable }} onClick={(event) => this.sendOtpAgain(event)}>
                                        Send again</Link> {this.state.isResendOtpLoading === true ? <img alt="loading..." style={{ height: '20px', width: '20px', marginRight: '8px' }} src="/assets/images/spinner3.gif"></img> : null}</span>
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-5 col-md-6 col-sm-12 content-scroll">
                                <div className="text-white ContentSide max-w400">
                                    {/* <h1 className="mb-4">Login To You Now</h1>
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry has been the industry.</p> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>

        )
    }

}

export default VerifyOtp
