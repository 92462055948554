// import { Modal } from "bootstrap";
import React from 'react';
import Modal from 'react-bootstrap/Modal'

import DeleteIcon from '@material-ui/icons/Delete';
import CheckCircle from '@material-ui/icons/CheckCircle';
export default class ConfirmationModal extends React.Component {

    closeModal = (value) => {
        this.props.onCloseConfirmationModal(value)
    }


    render() {
        return (
            <Modal dialogClassName="InfoSuccess text-center"
                show={this.props.showConfirmationModal}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                    <CheckCircle />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{
                    wordWrap: 'break-word',
                    paddingLeft: '2px',
                    paddingRight: '2px'
                }}>

                    <p>Are you sure you want to register {this.props.wallet} wallet ?</p>
                    <h4>Please Note....</h4>
                    <p>Make sure that you have not lost the mnemonics keys for this wallet address </p>
                    <button type="button" class="btn btn-secondary Submit " onClick={() => { this.closeModal(false) }}>No</button>
                    <button type="button" class="btn btn-secondary Cancel" onClick={() => { this.closeModal(true) }} style={{ marginLeft: '10px' }}>Yes</button>
                    
                </Modal.Body>
            </Modal >
        )
    }


}