// import { Modal } from "bootstrap";
import React from 'react';
import Modal from 'react-bootstrap/Modal'
import CheckCircle from '@material-ui/icons/CheckCircle';

class PaymentSuccessful extends React.Component {



    render() {

        return (
            <Modal dialogClassName="InfoSuccess text-center"
                show={this.props.showPaymentSuccessfulModal}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <CheckCircle />
                    </Modal.Title>
                </Modal.Header>


                <Modal.Body>
                    <h4 class="text-center mb-5">Payment Successfull, We are confirming your payment and will be compensate in few minutes.</h4>

                    <div className="d-flex justify-content-between align-items-center">
                        <div className="w-100 text-right">
                            <button type="button" className="btn btn-primary Submit m-0 mt-2" onClick={this.props.onClosePaymentSuccessfulModal} >Close</button>

                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        );

    }

}


export default PaymentSuccessful;