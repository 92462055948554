// import { Modal } from "bootstrap";
import React from 'react';
import Modal from 'react-bootstrap/Modal'
import CheckCircle from '@material-ui/icons/CheckCircle';

export default class RefundModal extends React.Component {

    render() {
        return (

            <Modal dialogClassName="SetPass"
                show={this.props.refundModalShow}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body >
                    <h4 class="text-center mb-3">Refund Required</h4>
                    <p style={{ color: "white" }}>
                        As part of our ongoing efforts to upgrade and maintain the integrity of our platform, we are currently in the process of transitioning from NuCoin 1 to NuCoin 2. To ensure a smooth and accurate transition of your holdings, we need your cooperation in the following process:
                    </p>
                    {/* <p style={{ color: 'white' }}>We have noticed that funds were accidentally sent to your account. To rectify this error, please refund the received amount immediately.</p>
                    */}

                    <ul style={{ color: 'white' }}>
                        <li><span style={{ fontWeight: "bold" }}>Incoming Coin Allocation:</span><br></br> &nbsp;  We have allocated all NuCoin deposits ever made to your account. This includes every transaction where NuCoin was sent to you.</li><br></br>
                        <li><span style={{ fontWeight: "bold" }}>Withdrawal and Transfer Verification:</span><br></br>&nbsp;  To ensure the accuracy of your total NuCoin holdings, our system has initiated a request for all NuCoin that was withdrawn or transferred from your account, as well as any NuCoin that was overallocated. This step is to make sure that every NuCoin you have withdrawn, transferred, or received in excess is properly accounted for in the new system.</li><br></br>
                        <li><span style={{ fontWeight: "bold" }}>Multiple Transfers Audit:</span><br></br>&nbsp;  Our audit has flagged some accounts due to multiple transfers, resulting in excess allocations of NuCoin. If your account falls into this category, you will be required to review and approve a refund of the excess NuCoin that has been transferred to your account.

                        </li>
                       
                        <br></br>
                        <span style={{ fontWeight: "bold" }}>Action Required – Approval of Requests:</span><br></br> &nbsp; You will receive a detailed request to approve: <br></br> <br></br>
                        <ul style={{ color: 'white' }}>
                            
                         <li>
                              <span style={{ fontWeight: "bold" }}>For standard reconciliation:</span><br></br>&nbsp;  A refund request to based on all withdrawals, transfers, and any overallocated NuCoin from your account.
                            </li> <br></br>
                            <li><span style={{ fontWeight: "bold" }}>For flagged accounts:</span><br></br>&nbsp;  A refund request for the excess NuCoin.</li>
                        </ul>


                    </ul>
                    <p style={{ color: 'white' }}>
                        Please review the relevant request carefully and send your approval to our treasury as soon as possible. These steps are crucial to ensure that all your NuCoin holdings are correctly allocated in NuCoin 2, maintaining the integrity and accuracy of your account.
                    </p>

                    <p style={{ color: 'white' }}>
                        Failure to comply with these requests may result in temporary suspension of your account until the matter is resolved.
                    </p>

                    <p style={{ color: 'white' }}>
                        We appreciate your prompt attention to this matter. If you have any questions or need further assistance, please do not hesitate to contact our support team.
                    </p>

                    <p style={{ color: 'white' }}>
                        Thank you for your understanding and cooperation.
                    </p>
                   
                    <p style={{ color: 'white' }}>Best regards,</p>


                    <div style={{ display: "flex",justifyContent:"flex-end"}}>

                        <button onClick={this.props.onProceed} type="button" className="btn btn-primary Submit">PROCEED</button>
                        <button type="button" class="btn btn-secondary Cancel" onClick={this.props.onCloseRefundModal}>Cancel</button>
                    </div>

                </Modal.Body>
            </Modal>
        )
    }


}