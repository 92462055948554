// import { Modal } from "bootstrap";
import React from 'react';
import Modal from 'react-bootstrap/Modal'
import { logOutUser } from '../../actions/auth';
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { SendEmailForMinerRegistrationRequest } from '../../api/miner/miner';
import {WarningMessageAlert} from "../../utils/alert"
class EmailDropDownDialogue extends React.Component {
    state = {
        emailMessageText: "",
        isEmailSelectError: false,
        emailErrorMessage: "",
        isEmailSendLoader: false,
        disabled: ""
    }


    handleChange = (e) => {

        this.setState({
            emailMessageText: e.target.value
        }, () => {
            if (this.state.emailMessageText === "" || this.state.emailMessageText === undefined || this.state.emailMessageText === null || this.state.emailMessageText === "Select Email Purpose") {
                this.setState({ isEmailSelectError: true, emailErrorMessage: "Please select the purpose of email" })
            }
            else {
                this.setState({ isEmailSelectError: false, emailErrorMessage: "" })
            }
        })
    }

    submitEmail = async () => {
        if (this.state.emailMessageText === "" || this.state.emailMessageText === undefined || this.state.emailMessageText === null || this.state.emailMessageText === "Select Email Purpose") {
            return this.setState({ isEmailSelectError: true, emailErrorMessage: "Please select the purpose of email" })
        }
        this.setState({ disabled: "none", isEmailSendLoader: true, isEmailSelectError: false, emailErrorMessage: "" })
        try {

            //send email
            await SendEmailForMinerRegistrationRequest({

                "walletName": this.props.walletName,
                "walletAddress": this.props.walletAddress,
                "walletBalance": this.props.walletBalance,
                "emailPurpose": this.state.emailMessageText,

            })

            this.setState({ disabled: "", isEmailSendLoader: false, isEmailSelectError: false, emailErrorMessage: "" })
            this.props.onCloseEmailDropDownModal(true)
        }
        catch (err) {

            if (err?.response?.status === 403) {
                this.setState({ disabled: "", isEmailSendLoader: false })
                this.props.logOutUser(err?.response?.status)
            }
            else if (err.message === "Network Error" || err?.response?.status === 500) {
                this.setState({ disabled: "", isEmailSendLoader: false, isEmailSelectError: true, emailErrorMessage: "Sorry! We are facing some network issue.Please try again later." })
            }
            else if (err?.response?.data) {
                return this.setState({ disabled: "", isEmailSendLoader: false, isEmailSelectError: true, emailErrorMessage: err?.response?.data?.errors[0]?.message })
            }

        }
    }

    render() {

        return (
            <Modal dialogClassName="ErrorAlert text-center"
                show={this.props.showEmailDropDownModal}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <h3 className="text-white mb-15"><i className="fa fa-envelope"></i> Send Email</h3>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{

                    overflow: 'hidden',

                }}>



                    <select id="sel"
                        class="form-control mb-2"

                        value={this.state.emailMessageText}
                        onChange={this.handleChange}
                    >
                        <option value="">Select purpose of email</option>

                        {
                            this?.props?.emailMessage.map((item) => (
                                <option value={item.message_text}>{item.message_text}</option>
                            ))}
                    </select>

                    {
                        this.state.isEmailSelectError ? <WarningMessageAlert message={this.state.message}></WarningMessageAlert> : null
                    }
                    {/* <button disabled={this.state.disabled} type="button" class="btn btn-secondary Cancel" onClick={() => {
                        this.setState({ emailMessageText: "" }, () => {
                            this.props.onCloseEmailDropDownModal(false)
                        })

                    }}>CANCEL</button>
                    <button disabled={this.state.disabled} style={{ marginLeft: '8px' }} type="button" class="btn btn-primary submit" onClick={this.submitEmail}>
                        {this.state.isEmailSendLoader === true ? <img style={{ height: '20px', width: '20px', marginRight: '8px' }} src="/assets/images/spinner3.gif"></img> : null}
                        PROCEED</button> */}
                    <Link style={{
                        borderRadius: '3px',
                        color: '#fff',
                        background: '#6C757D',
                        padding: '10px 30px',
                        display: 'inlineBlock',
                        marginRight: '10px',
                        pointerEvents: this.state.disabled
                    }} type="button" onClick={() => {
                        this.setState({ emailMessageText: "" }, () => {
                            this.props.onCloseEmailDropDownModal(false)
                        })

                    }} to="#" >Cancel</Link>

                    <Link disabled={this.state.disabled} onClick={this.submitEmail} style={{ pointerEvents: this.state.disabled }} className="Read-More" to="#" >
                        {this.state.isEmailSendLoader === true ? <img alt="loading..." style={{ height: '20px', width: '20px', marginRight: '8px' }} src="/assets/images/spinner3.gif"></img> : null}
                        PROCEED</Link>

                </Modal.Body>
            </Modal >
        )
    }


}
export default connect(null, { logOutUser })(EmailDropDownDialogue)

///this?.props?.emailMessage