import styled from "styled-components";

export const StyledReferralCodeSectionWrapper = styled.div`
    .select-wallet-wrapper {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      
      .button {
        margin-left: 20px;
      }
      
      .wallet-list-dropdown {
        ${({ walletDropdownDisabled }) => walletDropdownDisabled ? 'opacity: 0.5; pointer-events: none;' : ''};
        width: 670px;
              
        .Dropdown-placeholder {
          text-align: left;
        }
      }
    }
  
    .referral-code-div-wrapper {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 25%;
      
      .copy-icon {
        color: white;
        cursor: pointer;
      }
            
      .referral-code-input-box {
        ${({ referralTextBoxDisabled }) => referralTextBoxDisabled ? 'opacity: 0.5; pointer-events: none;' : ''};
      }
    }
        
    .loader-img {
      height: 20px;
      width: 20px;
      margin-right: 8px;
    }
`