import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { logOutUser } from '../../actions/auth';
import "./term.css";
import store from '../../store';
import { updateTerm } from '../../api/authentication/authentication';
import Cookies from 'js-cookie';
import { SET_TERM } from '../../types/auth';
import { termScreen } from '../../actions/auth';
import { toastMessageFailure } from '../../utils/toastMessage';
import { connect } from 'react-redux';

class TermAndCondition extends React.Component {
    state = {
        loading: false,
        disable: true, // Start with the button disabled
        scrolledToBottom: false,
        btnDisable: false,
        isMobile: false, // Detect mobile screen
        showModal: false // Show modal for confirmation
    }

    componentDidMount() {
        this.props.termScreen(true);
        this.detectMobile();
        window.addEventListener('resize', this.detectMobile);
    }

    detectMobile = () => {
        this.setState({ isMobile: window.innerWidth <= 768 });
    }

    handleScroll = (e) => {
        const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
        if (bottom) {
            this.setState({ disable: false, scrolledToBottom: true });
        }
    }

    acceptTermAndCondition = async (e) => {
        try {
            e.preventDefault();
            this.setState({ loading: true, disable: true, btnDisable: true });
            Cookies.set('myPersistentCookie', 1, { expires: 365 });
            store.dispatch({ type: SET_TERM, payload: 1 });

            await updateTerm();
            this.props.termScreen(false);
            this.setState({ loading: false, disable: false, btnDisable: false, showModal: false });
            // Redirect or perform necessary action upon acceptance
        } catch (e) {
            console.log("error", e);
            this.setState({ loading: false, disable: false, btnDisable: false, showModal: false });
            if (e?.response?.status === 403) {
                store.dispatch(logOutUser(e?.response?.status));
                return;
            }

            toastMessageFailure("Sorry! We are facing some network issue. Please try again later.");
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.detectMobile);
    }


    render() {
        const renderTooltip = (props) => (
            <Tooltip id="button-tooltip" {...props}>
                Scroll down to enable the button
            </Tooltip>
        );

        const { isMobile, showModal } = this.state;
        const iframeHeight = isMobile ? '70vh' : '85vh';

        return (
            <div className="supreme-container">
                <Modal
                    dialogClassName="InfoSuccess termsmodal text-center"
                    show={true}
                    size="xl"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Body>
                        <div
                            className="scroll-container"
                            onScroll={this.handleScroll}
                            style={{ width: '100%', height: iframeHeight, overflowY: 'scroll' }}
                        >
                            {/* <iframe
                                src="/assets/images/term.pdf"
                                title="Terms and Conditions"
                                style={{ width: '100%', height: '100%' }}
                            /> */}

                            <object
                                data="/assets/images/term.pdf"
                                type="application/pdf"
                                width="100%"
                                height="100%"
                            >
                                <div className="fallback-content">
                                <p>To view the Terms and Conditions, please download the PDF file using the link below:</p>
                                    <img src="/assets/images/pdfThumbnail.png" alt="Terms and Conditions" style={{ width: '80%', height: '55%' }} />
                                    <a href="/assets/images/term.pdf" className="btn btn-primary mt-3">Download Terms and Conditions</a>
                                </div>
                            </object>


                        </div>
                        <div className="mt-5">
                            {isMobile ? (
                                <button
                                    type="button"
                                    className="btn btn-secondary Cancel"
                                    onClick={() => this.setState({ showModal: true })}
                                    style={{ marginRight: '10px' }}
                                >
                                    Accept
                                </button>
                            ) : (
                                <OverlayTrigger
                                    placement="top"
                                    overlay={renderTooltip}
                                    show={!this.state.scrolledToBottom} // Show tooltip only if not scrolled to bottom
                                >
                                    <button
                                        disabled={this.state.disable}
                                        type="button"
                                        className="btn btn-secondary Cancel"
                                        onClick={(e) => this.acceptTermAndCondition(e)}
                                        style={{ marginRight: '10px' }}
                                    >
                                        {this.state.loading === true ? <img alt="loading..." style={{ height: '20px', width: '20px', marginRight: '8px' }} src="/assets/images/spinner3.gif"></img> : "Accept"}
                                    </button>
                                </OverlayTrigger>
                            )}
                            <button
                                disabled={this.state.btnDisable}
                                type="button"
                                className="btn btn-secondary Submit"
                                onClick={() => store.dispatch(logOutUser(600))}
                            >
                                Reject
                            </button>
                        </div>
                    </Modal.Body>
                </Modal>

                {/* Modal for mobile confirmation */}
                <Modal show={showModal} onHide={() => this.setState({ showModal: false })}>
                    <Modal.Header closeButton>
                        <Modal.Title>Confirm Acceptance</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>By clicking "Accept", you confirm that you have read and agreed to the terms and conditions.</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-secondary" onClick={() => this.setState({ showModal: false })}>
                            Close
                        </button>
                        <button className="btn btn-primary" onClick={this.acceptTermAndCondition}>
                            Accept
                        </button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}

export default connect(null, { termScreen })(TermAndCondition);
