// import { Modal } from "bootstrap";
import React from 'react';
import Modal from 'react-bootstrap/Modal'
//import MneMonics from "../../MneMonics"
import { transferCoin } from '../../../../api/blockchainapi/blockchainapi';
import { connect } from "react-redux";
import { WarningMessageAlert, ErrorMessageAlert, InfoMessageAlert } from "../../../../utils/alert"
import { ErrorMessage } from "../../../../messages/ErrorMessage"
import { updateTransferCoinLimit, transferLimitCheck } from '../../../../api/wallet/wallet';
const CryptoJS = require("crypto-js");


class SendCoinAndSavePassword extends React.Component {

    state = {
        password: "",
        isError: "",
        message: "",
        alertTitlt: "",
        //alertMessage: "",
        disable: "",
        transactionBlockHash: "",
        // hide and show password
        passwordType: "password",
        hideAndShowLabelText: "Show",
        transactionSuccess: false,
        isErrorAlert: false
    }

    submitPassword = async () => {



        if (this.state.password === '' || this.state.password === null) {
            return this.setState({ isError: true, isErrorAlert: false, message: 'Password is required!' });
        }
        try {
            let transferObject = {
                "secret": this.props.mnemoncisValue,
                "recipient": this.props.toAddress,
                "amount": this.props.amount
            }

            this.setState({ isError: null, isErrorAlert: false, message: null, isLoading: true, disable: "none" });

            // let { data } = await transferLimitCheck({ receiverWallet: this.props.toAddress, transferredAmount: this.props.amount })

            // if (data.data.canTransfer === false) {
            //     this.setState({ isLoading: false, isError: true, isErrorAlert: false, disable: "", message: data.message })
            //     return
            // }



            let response = await transferCoin(transferObject)
            //await updateTransferCoinLimit(({ receiverWallet: this.props.toAddress, transferredAmount: this.props.amount }))
            this.setState({ isLoading: false, disable: "", })

            if (response.data.transferred.status === "ExtrinsicSuccess") {
                // store in local storage
                let mnemonnicsToString = this.props.mnemoncisValue.toString()
                let encryptedData = CryptoJS.AES.encrypt(mnemonnicsToString, this.state.password).toString();
                localStorage.setItem(this?.props?.fromAddress, encryptedData)

                this.setState({ transactionBlockHash: response.data.transferred.blockHash, transactionSuccess: true }, () => {
                    this.closeModal()
                })
            }
            else {
                this.setState({ isError: false, isErrorAlert: true, isLoading: false, disable: "", message: "Sorry! We are facing some problem to transfer coin.Try to increase your balance atleast one coin greater then your transfer amount.Make sure your receiver address in correct" })
            }
        }
        catch (e) {

            this.setState({ isError: false, isErrorAlert: true, isLoading: false, disable: "", message: e.message })
        }
    }





    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
            [e.target.name.concat('error')]: '',
        })
    }
    closeModal = () => {

        this.props.onSendAndSavePasswordModal(this.state.transactionBlockHash, this.state.transactionSuccess)
        // set all state to default after closing modal
        this.setState({
            password: "",
            isError: "",
            message: "",
            disable: "",

            errorModalShow: false,
            successModalShow: false,
            transactionBlockHash: "",
            passwordType: "password",
            hideAndShowLabelText: "Show",
            transactionSuccess: false,
            isErrorAlert: false
        })

    }

    hideAndShowPassword = () => {
        if (this.state.passwordType === "text") {

            this.setState({
                passwordType: "password",
                hideAndShowLabelText: "Show"

            })
        }
        else if (this.state.passwordType === "password") {
            this.setState({
                passwordType: "text",
                hideAndShowLabelText: "Hide"

            })
        }
    }

    render() {
        return (
            <Modal dialogClassName="SetPass"
                show={this.props.showSendAndSavePasswordModal}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >

                <Modal.Body>


                    <h4 className="text-center mb-5">Set Password</h4>
                    <div class="form-group mb-4 position-relative">
                        <input autoFocus={true} onChange={this.onChange} name="password" className="form-control mb-2" placeholder="Enter Password" type={this.state.passwordType} />
                        <label style={{
                            cursor: "pointer", color: 'white',
                            position: 'absolute', top: '9px', right: '0px'
                        }} onClick={this.hideAndShowPassword}>{this.state.hideAndShowLabelText}</label>


                    </div>

                    {
                        this.state.isError ? <WarningMessageAlert message={this.state.message}></WarningMessageAlert> : null
                    }
                    {
                        this.state.isErrorAlert ? <ErrorMessageAlert message={this.state.message}></ErrorMessageAlert> : null
                    }
                    <button style={{ pointerEvents: this.state.disable }} onClick={this.submitPassword} type="button" class="btn btn-primary Submit">
                        {this.state.isLoading === true ? <img alt="loading..." style={{ height: '20px', width: '20px', marginRight: '8px' }} src="/assets/images/spinner3.gif"></img> : null}
                        Continue To Transfer

                    </button>
                    <button style={{ pointerEvents: this.state.disable }} type="button" class="btn btn-secondary Cancel" onClick={this.closeModal}>Cancel</button>


                </Modal.Body>
            </Modal>
        );

    }

}


export default SendCoinAndSavePassword
