// import { Modal } from "bootstrap";
import React from 'react';
import Modal from 'react-bootstrap/Modal'
import { logOutUser } from '../../../actions/auth';
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Select from 'react-select';
import { SendEmailForMinerRegistrationRequest } from '../../../api/miner/miner';
// import ContentCopyIcon from '@material-ui/icons/File';
import { toastMessageFailure } from '../../../utils/toastMessage';
const { Keyring } = require('@polkadot/api');

class ChooseWalletModal extends React.Component {
    state = {
        wallet: "",
        isEmailSelectError: false,
        emailErrorMessage: "",
        isEmailSendLoader: false,
        disabled: "",
        mnemonics: ""

    }
    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
            [e.target.name.concat('error')]: '',
        })
    }

    handleChange = (e) => {

        this.setState({
            wallet: e.target.value
        }, () => {
            if (this.state.wallet === "" || this.state.wallet === undefined || this.state.wallet === null || this.state.wallet === "Select Email Purpose") {
                this.setState({ isEmailSelectError: true, emailErrorMessage: "Please select the wallet" })
            }
            else {
                this.setState({ isEmailSelectError: false, emailErrorMessage: "" })
            }
        })
    }

    submitWallet = async () => {
        if (this.state.wallet === "" || this.state.wallet === undefined || this.state.wallet === null || this.state.wallet === "Select Email Purpose") {
            return this.setState({ isEmailSelectError: true, emailErrorMessage: "Please select the wallet" })
        }
        // this.setState({ disabled: "none", isEmailSendLoader: true, isEmailSelectError: false, emailErrorMessage: "" })
        try {


            let fromExist = localStorage.getItem(this.state.wallet)

            const keyring = new Keyring({
                type: 'sr25519',
                ss58Format: 42,
            });

            const newPair = keyring.addFromUri(this.state.mnemonics.trim());

            if (newPair.address !== this.state.wallet) {
                this.setState({ isEmailSelectError: true, emailErrorMessage: "Your mnemonics are not matched with your selected wallet address" })


            }
            else {
                this.props.onProceedChooseWalletModal(this.state.mnemonics.trim(), this.state.wallet)
            }



            // if (fromExist) {
            //     const encryptedData = localStorage.getItem(this.state.wallet)

            //     // decrypt data using password
            //     var mnemonicsValue = await CryptoJS.enc.Utf8.stringify(CryptoJS.AES.decrypt(encryptedData, this.state.password))

            //     alert("wallet  exist")

            // }
            // else {
            //     alert("wallet not exist")
            // }


        }
        catch (err) {
            toastMessageFailure(err.message)


        }
    }

    options = [
        { value: 'chocolate', label: 'Chocolate' },
        { value: 'strawberry', label: 'Strawberry' },
        { value: 'vanilla', label: 'Vanilla' },
    ];

    render() {
        const { results } = this.props.userWallet
        console.log("userWallet", results)

        return (
            <Modal dialogClassName="SetPass opacity-1"
                show={this.props.showChooseWalletModal}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <h3 className="text-white mb-15"><i className="fa fa-envelope"></i>Choose Wallet</h3>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='SetAccount' style={{

                    overflow: 'hidden',

                }}>


                    <div className='form-group'>
                        <Select
                            // id="sel"
                            // className="form-control mb-2"
                            // defaultValue={selectedOption}
                            onChange={(e) => this.handleChange({ target: { value: e.value }})}
                            options={results?.map(_ => ({ value: _.account_id, label: <div>{_.account_name}<br/><span style={{ fontSize: '12px' }}>{_.account_id}</span></div> }))}
                        />
                        {/* <select id="sel"
                            class="form-control mb-2"

                            value={this.state.walet}
                            onChange={this.handleChange}
                        >
                            <option className="form-group">Select wallet</option>
                            {
                                results?.map((item) => (
                                    <option value={item.account_id}>{item.account_name}<br/>{item.account_id}</option>
                                ))}
                        </select> */}
                    </div>


                    <div style={{ display: 'flex' }} className='mnemonic-load-wrapper form-group'>
                        <input autoFocus={true} onChange={this.onChange}
                            id="nValue" name="mnemonics" className="form-control mb-4 mt-4" placeholder="Enter your mnemonics" type="text" />
                        {/* <ContentCopyIcon style={{ color: 'white' }}  /> */}
                    </div>

                    {
                        this.state.isEmailSelectError ? <div style={{ display: 'flex' }} className="alert alert-danger" role="alert">

                            {this.state.emailErrorMessage}</div> : null
                    }

                    <Link style={{
                        borderRadius: '3px',
                        color: '#fff',
                        background: '#6C757D',
                        padding: '10px 30px',
                        display: 'inlineBlock',
                        marginRight: '10px',
                        pointerEvents: this.state.disabled
                    }} type="button" onClick={() => {
                        this.setState({ wallet: "" }, () => {
                            this.props.onCloseChooseWalletModal()
                        })

                    }} to="#" >Cancel</Link>

                    <Link disabled={this.state.disabled} onClick={this.submitWallet} style={{ pointerEvents: this.state.disabled }} className="Read-More" to="#" >

                        PROCEED</Link>

                </Modal.Body>
            </Modal >
        )
    }


}
const mapStateToProps = state => ({
    userWallet: state.walletReducer.userWallet
});

export default connect(mapStateToProps, { logOutUser })(ChooseWalletModal)

///this?.props?.emailMessage