// import { Modal } from "bootstrap";
import React from 'react';
import Modal from 'react-bootstrap/Modal'
//import MneMonics from "../../MneMonics"
import { finalMintCoin } from '../../../../api/blockchainapi/blockchainapi';
import { updateMintSession } from '../../../../api/miner/miner';

import ErrorAlert from "../../../Modals/ErrorAlert";

import { showValidateMnemonicsScreen } from "../../../../actions/wallet";
import { connect } from "react-redux";
import config from "../../../../_config"
import { stringToU8a, u8aToHex } from '@polkadot/util';
import { Keyring } from '@polkadot/api';
import { signatureVerify } from '@polkadot/util-crypto';
import { WarningMessageAlert, ErrorMessageAlert, InfoMessageAlert } from "../../../../utils/alert"
import { ErrorMessage } from "../../../../messages/ErrorMessage"
const CryptoJS = require("crypto-js");


class MintAndSavePassword extends React.Component {
    state = {

        password: "",
        isError: "",
        message: "",
        alertTitlt: "",
        //alertMessage: "",
        disable: "",
        headerMessage: "",
        //errorModalShow: false,
        //successModalShow: false,
        transactionBlockHash: "",
        // hide and show password
        passwordType: "password",
        hideAndShowLabelText: "Show",
        transactionSuccess: false,
        mintSuccess: false,
        nextMintSession: "",
        isErrorAlert: false,
        infoAlert: false,
    }


    generateSignature = async (mnemonic_code, address, usi) => {

        if (`${mnemonic_code}`.includes("string")) mnemonic_code = JSON.parse(mnemonic_code).string;

        try {

            const keyring = new Keyring({
                type: 'sr25519',

            });

            const alice = keyring.addFromUri(mnemonic_code);


            if (alice.address !== address) {

                return false;
            }
            const message = stringToU8a(JSON.stringify({ usiCount: usi, accountId: address }));
            const signature = alice.sign(message);
            var hash = u8aToHex(signature);

            const { isValid } = signatureVerify(message, signature, alice.address);

            if (isValid === true) {
                return hash;
            }

            return false;
        } catch (e) {
            return false;
        }

    }


    submitPassword = async () => {

        if (this.state.password === '' || this.state.password === null) {
            return this.setState({ isError: true, isErrorAlert: false, infoAlert: false, message: 'Password is required!' });
        }

        try {
            var mnemonicsValue = this.props.mnemoncis
            //alert(this.props.walletAddress)

            var signatureData = await this.generateSignature(mnemonicsValue, this.props.walletAddress, config.usi);

            let mintObject = {
                "usiCount": config.usi,
                "accountId": this.props.walletAddress,
                "signature": signatureData,
            }

            this.setState({ isError: false, isErrorAlert: false, infoAlert: false, message: null, headerMessage: "", isLoading: true, disable: "none" });
            let response = await finalMintCoin(mintObject)
            this.setState({ isLoading: false, disable: "", })

            if (response.data.hash) {
                let mnemonnicsToString = mnemonicsValue.toString()
                let encryptedData = CryptoJS.AES.encrypt(mnemonnicsToString, this.state.password).toString();
                localStorage.setItem(this?.props?.walletAddress, encryptedData)
                try { await updateMintSession({ address: this.props.walletAddress }) }
                catch (e) {

                }
                this.setState({ transactionBlockHash: response.data.hash, mintSuccess: true, nextMintSession: response.data.hoursLeft }, () => {
                    this.closeModal()
                })
            }

        }
        catch (e) {

            if (e?.response?.data) {
                if (e?.response?.data?.hoursLeft) {
                    this.setState({ isError: false, isErrorAlert: false, infoAlert: true, isLoading: false, headerMessage: "", disable: "", message: e?.response?.data?.message + `You can mint after ${e?.response?.data?.hoursLeft} hour` })
                }
                else {
                    this.setState({ isError: false, isErrorAlert: true, infoAlert: false, isLoading: false, headerMessage: "", disable: "", message: e.message })
                }
            }
            else {
                this.setState({ isError: false, isErrorAlert: true, infoAlert: false, isLoading: false, headerMessage: "", disable: "", message: e.message })

            }
        }


    }





    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
            [e.target.name.concat('error')]: '',
        })
    }
    closeModal = () => {

        this.props.onCloseMintModal(this.state.transactionBlockHash, this.state.mintSuccess, this.state.nextMintSession)
        // set all state to default after closing modal
        this.setState({
            password: "",
            isError: "",
            message: "",
            disable: "",
            headerMessage: "",
            //alertTitlt: "",
            //alertMessage: "",
            errorModalShow: false,
            successModalShow: false,
            transactionBlockHash: "",
            passwordType: "password",
            hideAndShowLabelText: "Show",
            transactionSuccess: false
        })

    }

    hideAndShowPassword = () => {
        if (this.state.passwordType === "text") {

            this.setState({
                passwordType: "password",
                hideAndShowLabelText: "Show"

            })
        }
        else if (this.state.passwordType === "password") {
            this.setState({
                passwordType: "text",
                hideAndShowLabelText: "Hide"

            })
        }
    }

    render() {
        return (
            <Modal dialogClassName="SetPass"
                show={this.props.showMintModal}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <ErrorAlert showErroModal={this.state.errorModalShow} title={this.state.alertTitlt} message={this.state.alertMessage} onCloseErrorModal={() =>
                    this.setState({
                        errorModalShow: false,
                        alertTitlt: "",
                        alertMessage: ""
                    })} />
                <Modal.Body>


                    <h4 className="text-center mb-5">Set Password</h4>
                    <div class="form-group mb-4 position-relative ">
                        <input autoFocus={true} onChange={this.onChange} name="password" className="form-control mb-2" placeholder="Enter Password" type={this.state.passwordType} />
                        <label style={{
                            cursor: "pointer", color: 'white',
                            position: 'absolute', top: '9px', right: '0px'
                        }} onClick={this.hideAndShowPassword}>{this.state.hideAndShowLabelText}</label>



                    </div>



                    {
                        this.state.isError ? <WarningMessageAlert message={this.state.message}></WarningMessageAlert> : null
                    }
                    {
                        this.state.isErrorAlert ? <ErrorMessageAlert message={this.state.message}></ErrorMessageAlert> : null
                    }
                    {
                        this.state.infoAlert ? <InfoMessageAlert message={this.state.message}></InfoMessageAlert> : null
                    }
                    <button style={{ pointerEvents: this.state.disable }} onClick={this.submitPassword} type="button" class="btn btn-primary Submit">
                        {this.state.isLoading === true ? <img alt="loading..." style={{ height: '20px', width: '20px', marginRight: '8px' }} src="/assets/images/spinner3.gif"></img> : null}
                        Continue To Mint

                    </button>
                    <button style={{ pointerEvents: this.state.disable }} type="button" class="btn btn-secondary Cancel" onClick={this.closeModal}>Cancel</button>
                    {/* {
                        this.state.showMnemoncis === true ? <MneMonics></MneMonics> : null
                    } */}





                </Modal.Body>
            </Modal>
        );

    }

}

const mapStateToProps = (state) => ({

    isShowValidateMnemonicsScreen: state.walletReducer.isShowValidateMnemonicsScreen,
    auth: state.authReducer,
})
export default connect(mapStateToProps, { showValidateMnemonicsScreen })(MintAndSavePassword)
