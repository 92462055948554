

import invoke from '../../utils/invoke';
import config from '../../_config/index';


export const getBankAccountDetailsApi = (data) => {

    return invoke({
        method: 'GET',
        baseURL: config.apiUrl,
        route: '/v1/bank/details'

    });
}


export const getBunddleDetailsApi = (data) => {

    return invoke({
        method: 'GET',
        baseURL: config.apiUrl,
        route: '/v1/account/details'
    });
}

export const contactUs = (data) => {

    // data = encrypt(data);
    return invoke({
        method: 'POST',
        baseURL: config.apiUrl,
        //baseURL: contactUs,
        route: 'contactUs',
        data: data
    });
}

export const nucToDollar = () => {

    // data = encrypt(data);
    return invoke({
        method: 'GET',
        baseURL: config.dollarConvertorUrl,
        //baseURL: contactUs,
        route: 'nuc'
    });
}


export const createNotification = (data) => {
    //console.log(" config.notificationSecretKey", config.notificationSecretKey)

    // data = encrypt(data);
    return invoke({
        method: 'POST',
        baseURL: config.notificationUrl,
        //baseURL: contactUs,
        route: 'notifications/publish/reward/note/',
        data: data,
        // headers: {
        //     "secret-key": config.notificationSecretKey
        // }
    });
}

export const registerSubscriber = (data) => {
    return invoke({
        method: 'POST',
        baseURL: config.notificationUrl,
        route: 'v2/news-letters/newsletter/create/',
        data: data,
        headers: {
            "platform-subscriber-id": config.projectId
        }
    });
}

export const getToggleValueByUsername = (toggleType, username) => {

    return invoke({
        method: 'GET',
        baseURL: config.apiUrl,
        route: `check_toggle_status?toggle_column=${toggleType}&&username=${username}`,

    });
}


export const getToggleByColumnName = (toggleType) => {

    return invoke({
        method: 'GET',
        baseURL: config.apiUrl,
        route: `check_toggle_status?toggle_column=${toggleType}`,

    });
}


export const addSubscribe = (data) => {

    return invoke({
        method: 'POST',
        baseURL: config.notificationUrl,
        //baseURL: contactUs,
        route: 'v2/emails/newsletter/create/',
        data: data,
        headers: {
            "platform-subscriber-id": config.projectId
        }
    });
}


export const getMerchantProduct = (data) => {

    return invoke({
        method: 'GET',
        baseURL: config.apiUrl,
      route: 'v1/payment/items?page=1&merchant_id=fahadhamza&filter=',
       
       
    });
}

export const getRoadMapDetailsApi = () => {
    return invoke({
        method: 'GET',
        baseURL: config.roadMapApiUrl,
        route: '/v0/apppyhbW5raHKtXmQ/Nugenesis%20work%20flow',
        headers: {
            Authorization: `Bearer ${config.roadMapAccessToken}`
        }
    });
}



