import React, { Component } from "react";
import { connect } from "react-redux";
import { getUserWallet } from "../../api/wallet/wallet";
import Footer from "../Layout/Footer";
import Header from "../Layout/Header";
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import LoadingOverlay from "../../Loader";
import { FIAT_PAYMENT_CERTIFICATE_PUB } from '../../security/certificate';
import JSEncrypt from 'jsencrypt';
import config from '../../_config/index';
import { mnemonicValidate, mnemonicGenerate } from '@polkadot/util-crypto';
import { Keyring } from '@polkadot/api';
import { nucToDollar } from "../../api/general/general"
import { getBuyToggleValue } from "../../api/kyc/kyc";
const CryptoJS = require("crypto-js");

class PurchaseCoins extends Component {

    state = {
        walletArray: [],
        selectedWallet: {},
        amount: 0,
        isLoading: true,
        step: 1,
        mnemonics: null,
        error: null,
        amountInDollar: 0,
        amountInputLoading: false,
        nucoinUpperLimit: 0,
        nucoinLowerLimit: 0,
        seletedPaymentMethod: -1,
        rate: null,
        bitpayToggleValue: false,
        paypalToggleValue: false
    }

    fiatJSEncrypt = new JSEncrypt();

    componentDidMount = async () => {

        try {
            const { data } = await nucToDollar();

            this.setState({ ...this.state, rate: data.rate });
        }
        catch (e) {
            this.setState({ ...this.state, rate: -1 });
        }

        try {
            let buyToggle = await getBuyToggleValue("toggle_paypal_payment");

            if (buyToggle?.data?.toggle_paypal_payment?.calculated_toggle === 1) {
                this.setState({ paypalToggleValue: true })
            }
        }
        catch (e) {
            this.setState({ paypalToggleValue: false })
        }

        try {
            let buyBitpayToggle = await getBuyToggleValue("toggle_bitpay_payment");

            if (buyBitpayToggle?.data?.toggle_bitpay_payment?.calculated_toggle === 1) {
                this.setState({ bitpayToggleValue: true })
            }
        } catch (e) { }

        // if (localStorage.getItem('lastSelectWallet') == null || localStorage.getItem('lastSelectWalletTitle') == null) {
        try {
            const { data } = await getUserWallet();
            if (localStorage.getItem('lastSelectWallet') == null || localStorage.getItem('lastSelectWalletTitle') == null) {
                this.setState({ ...this.state, walletArray: data.data.results, isLoading: false })
            } else this.setState({ ...this.state, walletArray: data.data.results, step: 2, selectedWallet: { account_id: localStorage.getItem('lastSelectWallet'), account_name: localStorage.getItem('lastSelectWalletTitle') }, isLoading: false });
        } catch (e) {
            this.setState({ ...this.state, isLoading: false });
        }
        // } else {
        //     this.setState({ ...this.state, step: 2, selectedWallet: { account_id: localStorage.getItem('lastSelectWallet'), account_name: localStorage.getItem('lastSelectWalletTitle') }, isLoading: false })
        // }
        this.fiatJSEncrypt.setPublicKey(FIAT_PAYMENT_CERTIFICATE_PUB);
    }

    encryptFiatPaymentData(message) {
        if (typeof message === 'object') return this.fiatJSEncrypt.encrypt(JSON.stringify(message)).toString();
        else return this.fiatJSEncrypt.encrypt(message);
    }

    onChange = (e) => {

        this.setState({
            ...this.state,
            [e.target.name]: e.target.value
            // [e.target.name.concat('error')]: '',
        })
    }

    render() {
        const { step } = this.state;
        const { bitpayToggleValue, paypalToggleValue } = this.state;
        return (
            <>
                {this.state.isLoading === true ? <LoadingOverlay></LoadingOverlay> : null}
                <div className="BlackStarsBack">
                    <div id="Blackstars" />
                    <div id="Blackstars2" />
                    <div id="Blackstars3" />
                </div>
                <div className="row" id="zIndexMob">
                    <div className="col-md-8 offset-md-2">
                        <div className="AccountAddress text-center">
                            {
                                step === 1 ? (
                                    <div className="Box SetAccount">
                                        <h4 className="text-center">Select Account to Continue</h4>
                                        <div className="form-group mb-2 table-responsive">
                                            <table className="table PanelBody text-center" style={{ marginBottom: '0px' }}>
                                                <thead>
                                                    <tr>
                                                        <th width="5%"><p>#</p></th>
                                                        <th width="15%"><p>Account Name</p></th>
                                                        <th width="15%"><p>Address</p></th>
                                                    </tr>
                                                </thead>
                                                {
                                                    this.state.walletArray.map((item, index) => (
                                                        <React.Fragment>
                                                            <tr onClick={() => {
                                                                this.setState({ ...this.state, selectedWallet: { account_id: item.account_id, account_name: item.account_name } })
                                                            }} style={{ cursor: 'pointer', background: this.state.selectedWallet.account_id === item.account_id ? '#ff8f00' : '' }} key={item.account_id}>
                                                                <td className="Visit">
                                                                    <p>{index + 1}</p>
                                                                </td>
                                                                <td className="Visit">
                                                                    <p>{item.account_name}</p>
                                                                </td>
                                                                <td className="Visit Address">
                                                                    <p>
                                                                        {item.account_id}
                                                                    </p>
                                                                </td>
                                                            </tr>
                                                        </React.Fragment>
                                                    ))
                                                }
                                            </table>
                                        </div>
                                        <div className="form-group mb-4 position-relative">
                                            <button disabled={!this.state.selectedWallet.account_id} onClick={() => { this.setState({ ...this.state, step: 2 }) }} type="button" class="btn btn-primary Submit">
                                                Continue</button>
                                        </div>
                                        {/* {
                                                this.state.isError ? <div className="alert alert-danger" role="alert">
                                                    {this.state.headerMessage && (<h3>{this.state.headerMessage}</h3>)}
                                                    {this.state.message}</div> : null
                                            } */}

                                        {/* <button style={{ pointerEvents: this.state.disable }} onClick={this.submitCreateWallet} type="button" class="btn btn-primary Submit">
                                                {this.state.isLoading === true ? <img alt="loading..." style={{ height: '20px', width: '20px', marginRight: '8px' }} src="/assets/images/spinner3.gif"></img> : null}
                                                Submit</button>
                                            <button style={{ pointerEvents: this.state.disable }} type="button" class="btn btn-secondary Cancel" onClick={() => this.props.history.push("/")}>Cancel</button> */}
                                    </div>
                                ) : step === 2 ? (
                                    <div className="Box SetAccount">
                                        <h3 className="text-center">Select Payment Method</h3>
                                        <div className="form-group mb-4">
                                            <div className="row mt-5">





                                                {
                                                    bitpayToggleValue && paypalToggleValue ? (
                                                        <React.Fragment>
                                                            <div className="col-4">
                                                                <div className="d-flex justify-content-between p-3 w-100 align-items-center Method_Select"
                                                                    onClick={() => this.setState({ ...this.state, seletedPaymentMethod: 1 })}
                                                                    style={{ cursor: 'pointer', border: this.state.seletedPaymentMethod !== 1 ? '1px solid #fff' : '1px solid #e37f00' }}>
                                                                    <div>
                                                                        <h2 className="m-0"
                                                                            style={{ fontWeight: 'bold', color: this.state.seletedPaymentMethod !== 1 ? '#fff' : '#e37f00' }}>
                                                                            Paypal
                                                                        </h2>
                                                                    </div>
                                                                    <div>
                                                                        <img style={{ maxWidth: "50px" }} className="w-100" src="/assets/images/paypal.png" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-4">
                                                                <div className="d-flex justify-content-between p-3 w-100 align-items-center Method_Select"
                                                                    onClick={() => this.setState({ ...this.state, seletedPaymentMethod: 2 })}
                                                                    style={{ cursor: 'pointer', border: this.state.seletedPaymentMethod !== 2 ? '1px solid #fff' : '1px solid #e37f00' }}>
                                                                    <div>
                                                                        <h2 className="m-0"
                                                                            style={{ fontWeight: 'bold', color: this.state.seletedPaymentMethod !== 2 ? '#fff' : '#e37f00' }}>
                                                                            Bitpay
                                                                        </h2>
                                                                    </div>
                                                                    <div>
                                                                        <img style={{ maxWidth: "50px" }} className="w-100" src="/assets/images/bitpay.png" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-4">
                                                                <div className="d-flex justify-content-between p-3 w-100 align-items-center Method_Select"
                                                                    onClick={() => this.setState({ ...this.state, seletedPaymentMethod: 3 })}
                                                                    style={{ cursor: 'pointer', border: this.state.seletedPaymentMethod !== 3 ? '1px solid #fff' : '1px solid #e37f00' }}>
                                                                    <div>
                                                                        <h2 className="m-0"
                                                                            style={{ fontWeight: 'bold', color: this.state.seletedPaymentMethod !== 3 ? '#fff' : '#e37f00' }}>
                                                                            PayID
                                                                        </h2>
                                                                    </div>
                                                                    <div>
                                                                        <img style={{ maxWidth: "91px" }} className="w-100" src="/assets/images/payID" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </React.Fragment>
                                                    ) : bitpayToggleValue ? (
                                                        <React.Fragment>
                                                            <div className="col-12">
                                                                <img className="text-center" onClick={() => this.setState({ ...this.state, seletedPaymentMethod: 2 })} style={{ cursor: 'pointer', width: '200px', filter: this.state.seletedPaymentMethod !== 2 ? 'grayscale(100%)' : 'grayscale(0%)' }} src="/assets/images/bitpay-icon.png" />
                                                            </div>
                                                        </React.Fragment>
                                                    ) : paypalToggleValue ? (
                                                        <React.Fragment>
                                                            <div className="col-12">
                                                                <img className="text-center" onClick={() => this.setState({ ...this.state, seletedPaymentMethod: 1 })} style={{ cursor: 'pointer', width: '200px', filter: this.state.seletedPaymentMethod !== 1 ? 'grayscale(100%)' : 'grayscale(0%)' }} src="/assets/images/paypal-icon.png" />
                                                            </div>
                                                        </React.Fragment>
                                                    ) : null
                                                }


                                            </div>
                                        </div>
                                        <div className="form-group mb-3 position-relative">
                                            <button disabled={this.state.selectedWallet === null} onClick={() => { this.setState({ ...this.state, step: 1, error: null }) }} type="button" class="btn btn-primary Submit">
                                                Back</button>
                                            <button disabled={this.state.seletedPaymentMethod === -1} onClick={async () => {
                                                this.setState({ ...this.state, step: 3 });
                                            }} type="button" class="btn btn-primary Submit">
                                                {this.state.amountInputLoading === true ? <img alt="loading..." style={{ height: '20px', width: '20px', marginRight: '8px' }} src="/assets/images/spinner3.gif"></img> : null}
                                                Continue</button>
                                        </div>
                                        {
                                            this.state.error ?
                                                <div className="alert alert-danger">
                                                    {this.state.error}
                                                </div> : null
                                        }
                                    </div>
                                ) : step === 3 ? (
                                    <div className="Box SetAccount">
                                        <h3 className="text-center">Selected account '{this.state.selectedWallet.account_name}'</h3>
                                        <h4 className="text-center">Enter amount to purchase</h4>
                                        <p className="text-center" style={{ color: 'white' }}>1 NuCoin has worth of {this.state.rate} USD </p>
                                        <div className="form-group mb-4 position-relative">
                                            <input onChange={this.onChange} name="amount" type="text" className="form-control mb-2" placeholder="Enter NuCoin amount to purchase" />
                                            {/* <label>Amount must be less then 30 NUC</label> */}
                                        </div>
                                        <p className="text-center" style={{ color: 'white' }}>Purchasing amount must be in between 1 USD to 30 USD</p>
                                        <div className="form-group mb-3 position-relative">
                                            <button disabled={this.state.selectedWallet === null} onClick={() => { this.setState({ ...this.state, step: 2, error: null }) }} type="button" class="btn btn-primary Submit">
                                                Back</button>
                                            <button disabled={this.state.selectedWallet === null} onClick={async () => {
                                                try {
                                                    this.setState({ amountInputLoading: true })
                                                    const { data } = await nucToDollar()
                                                    this.setState({ amountInputLoading: false })
                                                    console.log("data", data)
                                                    // alert(this.state.amount * data.rate)
                                                    this.setState({ amountInDollar: this.state.amount * data.rate }, () => {
                                                        if (this.state.amountInDollar >= 1 && this.state.amountInDollar <= 30) {
                                                            this.setState({ ...this.state, step: 4, error: null });
                                                        } else this.setState({ ...this.state, error: `Purchasing amount must be in between ${parseFloat(1 / this.state.rate).toFixed(2)} NUC to ${parseFloat(30 / this.state.rate).toFixed(2)} NUC` });
                                                    })
                                                }
                                                catch (e) {
                                                    this.setState({ amountInputLoading: false })
                                                }

                                            }} type="button" class="btn btn-primary Submit">
                                                {this.state.amountInputLoading === true ? <img alt="loading..." style={{ height: '20px', width: '20px', marginRight: '8px' }} src="/assets/images/spinner3.gif"></img> : null}
                                                Continue</button>
                                        </div>
                                        {
                                            this.state.error ?
                                                <div className="alert alert-danger">
                                                    {this.state.error}
                                                </div> : null
                                        }
                                    </div>
                                ) : step == 4 ? (
                                    <div className="Box SetAccount">
                                        <h3 className="text-center mb-4">Selected account '{this.state.selectedWallet.account_name}'</h3>
                                        {
                                            localStorage.getItem(this.state.selectedWallet.account_id) ? (
                                                <React.Fragment>
                                                    <h4 className="text-center">Enter password to unlock</h4>
                                                    <div className="form-group mb-4 position-relative">
                                                        <input type="password" onChange={this.onChange} name="password" className="form-control mb-2" placeholder="Enter Password" />
                                                    </div>
                                                </React.Fragment>
                                            ) : (
                                                <React.Fragment>
                                                    <div className='mnemonic-load-wrapper'>
                                                        <input autoFocus={true} onChange={this.onChange}
                                                            id="nValue" name="mnemonics" className="form-control mb-4" placeholder="Enter your mnemonics" type="text" />
                                                    </div>
                                                </React.Fragment>
                                            )

                                        }
                                        <div className="form-group mb-3 position-relative">
                                            <button disabled={this.state.selectedWallet === null} onClick={() => { this.setState({ ...this.state, step: 3 }) }} type="button" class="btn btn-primary Submit">
                                                Back</button>
                                            <button disabled={this.state.selectedWallet === null} onClick={async () => {


                                                if (localStorage.getItem(this.state.selectedWallet.account_id)) {

                                                    let mnemonicsValue = await CryptoJS.enc.Utf8.stringify(CryptoJS.AES.decrypt(localStorage.getItem(this.state.selectedWallet.account_id), this.state.password))

                                                    if (`${mnemonicsValue}`.includes("string")) mnemonicsValue = JSON.parse(mnemonicsValue).string;

                                                    if (mnemonicsValue) {
                                                        this.setState({ ...this.state, mnemonics: mnemonicsValue })
                                                    }
                                                    else {
                                                        return this.setState({ ...this.state, error: 'You are entering a wrong password' });
                                                    }
                                                }

                                                if (!mnemonicValidate(this.state.mnemonics)) {
                                                    return this.setState({ ...this.state, error: 'Please enter valid mnemonics!' });
                                                } else {
                                                    const keyring = new Keyring({
                                                        type: 'sr25519',
                                                        ss58Format: 42,
                                                    });
                                                    const newPair = keyring.addFromUri(this.state.mnemonics);
                                                    if (this.state.selectedWallet.account_id !== newPair.address) {
                                                        return this.setState({ ...this.state, error: 'Mnemonics are not match with wallet address!' })
                                                    } else this.setState({ ...this.state, error: null });
                                                }
                                                if (this.state.seletedPaymentMethod == 1) {
                                                    // config.paypalData.
                                                    // const encrptedData = this.encryptFiatPaymentData({ id: 0, title: this.state.selectedWallet.account_name, subscriber_id: this?.props?.location?.state?.subscriber_id, payment_subscription_id: this?.props?.location?.state?.payment_subscription_id, price: this.state.amount, user_id: this.props.auth.user.id, user_name: this.props.auth.user.username, mnemonics: this.state.mnemonics, redirect: `${config.domainName}` });
                                                    const encrptedData = this.encryptFiatPaymentData({ id: 0, title: this.state.selectedWallet.account_name, subscriber_id: config?.paypalData?.subscriber_id, payment_subscription_id: config?.paypalData?.payment_subscription_id, price: this.state.amount, user_id: this.props.auth.user.id, user_name: this.props.auth.user.username, mnemonics: this.state.mnemonics, redirect: `${config.domainName}` });
                                                    this.setState({ isLoading: true }, () => {
                                                        window.location.href = `${config.paypalData.paymentURL}?query=${encrptedData}`

                                                    })
                                                } else {
                                                    const encrptedData = this.encryptFiatPaymentData({ id: 0, title: this.state.selectedWallet.account_name, subscriber_id: config?.bitpayData?.subscriber_id, payment_subscription_id: config?.bitpayData?.payment_subscription_id, price: this.state.amount, user_id: this.props.auth.user.id, user_name: this.props.auth.user.username, mnemonics: this.state.mnemonics, redirect: `${config.domainName}` });
                                                    // const encrptedData = this.encryptFiatPaymentData({ id: 1, title: this.state.selectedWallet.account_name, subscriber_id: 2, payment_subscription_id: 1, price: this.state.amount, user_id: this.props.auth.user.id, user_name: this.props.auth.user.username, mnemonics: this.state.mnemonics, redirect: `${config.domainName}` });
                                                    this.setState({ isLoading: true }, () => {
                                                        window.location.href = `${config.bitpayData.paymentURL}?query=${encrptedData}`
                                                    })
                                                }

                                                // const encrptedData = this.encryptFiatPaymentData({ id: 0, title: this.state.selectedWallet.account_name, subscriber_id: this?.props?.location?.state?.subscriber_id, payment_subscription_id: this?.props?.location?.state?.payment_subscription_id, price: this.state.amount, user_id: this.props.auth.user.id, user_name: this.props.auth.user.username, mnemonics: this.state.mnemonics, redirect: `${config.domainName}` });
                                                // // const encrptedData = this.encryptFiatPaymentData({ id: 1, title: this.state.selectedWallet.account_name, subscriber_id: 2, payment_subscription_id: 1, price: this.state.amount, user_id: this.props.auth.user.id, user_name: this.props.auth.user.username, mnemonics: this.state.mnemonics, redirect: `${config.domainName}` });
                                                // this.setState({ isLoading: true }, () => {
                                                //     window.location.href = `${config.paypalPaymentURL}?query=${encrptedData}`

                                                // })
                                                // 
                                            }} type="button" class="btn btn-primary Submit">
                                                Confirm</button>
                                        </div>
                                        <div className="form-group mb-2 position-relative">
                                            <label className="text-danger">{this.state.error}</label>
                                        </div>
                                        {/* {
                                                this.state.isError ? <div className="alert alert-danger" role="alert">
                                                    {this.state.headerMessage && (<h3>{this.state.headerMessage}</h3>)}
                                                    {this.state.message}</div> : null
                                            } */}

                                        {/* <button style={{ pointerEvents: this.state.disable }} onClick={this.submitCreateWallet} type="button" class="btn btn-primary Submit">
                                                {this.state.isLoading === true ? <img alt="loading..." style={{ height: '20px', width: '20px', marginRight: '8px' }} src="/assets/images/spinner3.gif"></img> : null}
                                                Submit</button>
                                            <button style={{ pointerEvents: this.state.disable }} type="button" class="btn btn-secondary Cancel" onClick={() => this.props.history.push("/")}>Cancel</button> */}
                                    </div>
                                ) : null
                            }

                        </div>
                    </div>
                </div>

                <Footer />
            </>
        )
    }

}

const mapStateToProps = (state) => ({
    // isShowCopyMnemoncisScreen: state.walletReducer.isShowCopyMnemoncisScreen
    auth: state.authReducer
})

export default connect(mapStateToProps, {})(PurchaseCoins)
