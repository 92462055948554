// import { Modal } from "bootstrap";
import React from 'react';
import Modal from 'react-bootstrap/Modal'
import CheckCircle from '@material-ui/icons/CheckCircle';
import { generateAndSendOtp } from "../../api/authentication/authentication"
import { logOutUser } from "../../actions/auth"
import { connect } from 'react-redux';
import { toastMessageFailure } from '../../utils/toastMessage';
import { Link } from "react-router-dom";
class RetreiveMnemoncisModal extends React.Component {

    state = {
        retreiveMnemonicsLoader: false,
        disabled: ""
    }

    sendEmailOtpForMnemonics = async () => {
        try {
            this.setState({ retreiveMnemonicsLoader: true, disabled: "none" })
            await generateAndSendOtp();
            this.setState({ retreiveMnemonicsLoader: false, disabled: "" }, () => {

                this.props.onCloseRetreiveMnemoncisModal(true)
            })
        }
        catch (err) {
            this.setState({ retreiveMnemonicsLoader: false, disabled: "" })

            if (err?.response?.status === 403) {
                this.props.logOutUser(err?.response?.status)
            }
            else if (err.message === "Network Error" || err?.response?.status === 500) {
                toastMessageFailure("Sorry! We are facing some network issue.Please try again later.")
            }
            else if (err?.response?.data) {
                toastMessageFailure(err?.response?.data?.errors[0]?.message)
            }


        }
    }

    render() {
        return (


            <Modal dialogClassName="InfoSuccess text-center"
                show={this.props.showMnemonicdModalShow}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <CheckCircle />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{
                    wordWrap: 'break-word',
                    paddingLeft: '2px',
                    paddingRight: '2px'
                }}>

                    <p>Dear customer, for security reasons in protecting your private key, please click below to send an OTP (One Time Pin) to your email for which you will need to provide in the next screen.</p>

                    <Link className="Read-More" to="#" style={{ pointerEvents: this.state.disabled }}
                        onClick={this.sendEmailOtpForMnemonics}
                    >
                        {this.state.retreiveMnemonicsLoader === true ? <img alt="loading..." style={{ height: '20px', width: '20px', marginRight: '8px' }} src="/assets/images/spinner3.gif"></img> : null}

                        Proceed</Link>

                    <Link style={{
                        borderRadius: '3px',
                        color: '#fff',
                        background: '#6C757D',
                        padding: '10px 30px',
                        display: 'inlineBlock',
                        marginRight: '10px',
                        pointerEvents: this.state.disabled
                    }} type="button" onClick={() => { this.props.onCloseRetreiveMnemoncisModal(false, "") }} to="#" >Cancel</Link>



                </Modal.Body>
            </Modal >


        );

    }

}


export default connect(null, { logOutUser })(RetreiveMnemoncisModal)


