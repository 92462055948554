import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import LoadingOverlay from "../../Loader";
import React from "react";
import { toastMessageFailure, toastMessageSuccess } from "../../utils/toastMessage"
import { getUserPersonalDetailForKyc, getUserAddressDetailForKyc } from "../../api/kyc/kyc"
import { connect } from "react-redux";
import { updatePasswordOnProfile } from "../../api/authentication/authentication"
import {WarningMessageAlert} from "../../utils/alert"
import {ErrorMessage} from "../../messages/ErrorMessage"
import { logOutUser } from "../../actions/auth"
import ReferralCode from "./referral-code";
import { ErrorMessageAlert } from "../../utils/alert"

class Profile extends React.Component {
    state = {
        formFieledDisable: false,
        firstName: "",
        lastName: "",
        middleName: "",
        email: "",
        phone: "",
        addressLine1: "",
        addressLine2: "",
        city: "",
        province: "",
        addressType: "Select Address Type",
        postCode: "",
        country: "Select Country",
        isError: false,
        message: "",
        isLoading: false,
        disabled: "",
        isLoadingDocument: false,
        isLoadingProfile: false,
        oldPassword: "",
        newPassword: "",
        confirmNewPassword: "",
        disable: "",


        passwordTypeOld: "password",
        passwordTypeNew: "password",
        passwordTypeConfirm: "password",
        hideAndShowLabelTextOld: "Show",
        hideAndShowLabelTextNew: "Show",
        hideAndShowLabelTextConfirm: "Show",
        isErrorAlert: false
    }

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
            [e.target.name.concat('error')]: '',
        })

    }

    hideAndShowPassword = (value) => {
        // alert(value)
        if (value === "oldPassword") {
            if (this.state.passwordTypeOld === "text") {
                this.setState({
                    passwordTypeOld: "password",
                    hideAndShowLabelTextOld: "Show"

                })
            }
            else if (this.state.passwordTypeOld === "password") {
                this.setState({
                    passwordTypeOld: "text",
                    hideAndShowLabelTextOld: "Hide"

                })
            }

        }

        else if (value === "newPassword") {
            if (this.state.passwordTypeNew === "text") {
                this.setState({
                    passwordTypeNew: "password",
                    hideAndShowLabelTextNew: "Show"

                })
            }
            else if (this.state.passwordTypeNew === "password") {
                this.setState({
                    passwordTypeNew: "text",
                    hideAndShowLabelTextNew: "Hide"

                })
            }

        }

        else if (value === "confirmNewPassword") {

            if (this.state.passwordTypeConfirm === "text") {
                this.setState({
                    passwordTypeConfirm: "password",
                    hideAndShowLabelTextConfirm: "Show"

                })
            }
            else if (this.state.passwordTypeConfirm === "password") {
                this.setState({
                    passwordTypeConfirm: "text",
                    hideAndShowLabelTextConfirm: "Hide"

                })
            }

        }

    }

    updatePassword = async () => {


        if (this.state.oldPassword === '' || this.state.oldPassword === null) {
            return this.setState({ isError: true, isErrorAlert: false, message: 'Old password is required!' });
        }
        if (this.state.newPassword === '' || this.state.newPassword === null) {
            return this.setState({ isError: true, isErrorAlert: false, message: 'New password is required!' });
        }

        else if (this.state.newPassword !== this.state.confirmNewPassword) {
            return this.setState({ isError: true, isErrorAlert: false, message: 'Password and confirm password not matched!' });
        }
        this.setState({ isError: null, isErrorAlert: false, message: null, isLoading: true, disabled: "none" });

        try {

            await updatePasswordOnProfile({
                password: this.state.newPassword,
                confirmPassword: this.state.confirmNewPassword,
                oldPassword: this.state.oldPassword
            });
            this.setState({ newPassword: "", confirmNewPassword: "", oldPassword: "", isError: false, isLoading: false, disabled: "", message: "" }, () => {
                toastMessageSuccess("Your password updated successfully")
            })
        }
        catch (err) {

            this.setState({ isLoading: false, disabled: "" });
            if (err.message === "Network Error") {
                return this.setState({ isError: true, isErrorAlert: false, message: ErrorMessage.networkError });
            }
            if (err?.response?.status === 403) {
                this.props.logOutUser(err?.response?.status)
            }
            else if (err?.response?.data) {
                if (err?.response?.status === 500) {
                    return this.setState({ isError: false, isErrorAlert: true, message: err?.response?.data?.errors[0]?.message });

                }
                return this.setState({ isError: true, isErrorAlert: false, message: err?.response?.data?.errors[0]?.message });
            }

        }

    }

    componentDidMount = async () => {
        this.setState({ isLoadingProfile: true })
        try {
            const { data } = await getUserPersonalDetailForKyc();
            const addressDetail = await getUserAddressDetailForKyc();
            this.setState({ isLoadingProfile: false })
            this.setState({ email: data.data.email, firstName: data.data.first_name, middleName: data.data.middle_name, lastName: data.data.last_name, phone: data.data.mobile_number })
            if (addressDetail.data.data.length >= 1) {
                // alert("your cusomer address there")
                this.setState({
                    formFieledDisable: true,//disable all field
                    showCustomerAddressFilledSuccess: true,//show customer success text
                    submitButtonShow: false,//hide btn
                    addressLine1: addressDetail.data.data[0].address_line1,
                    addressLine2: addressDetail.data.data[0].address_line2,
                    city: addressDetail.data.data[0].city_town_suburb,
                    province: addressDetail.data.data[0].state_province,
                    addressType: addressDetail.data.data[0].address_type,
                    postCode: addressDetail.data.data[0].post_code,
                    country: addressDetail.data.data[0].country,

                })
            }

        }
        catch (err) {
            this.setState({ isLoadingProfile: false })

            if (err.message === "Network Error") {
                return this.setState({ isError: true, message: "Sorry! We are facing some network issues. Please try again later." });
            }
            else if (err?.response?.status === 403) {
                this.props.logOutUser(err?.response?.status)
            }
            else {
                toastMessageFailure("Sorry! We are facing some issue to get profile")

            }



        }
    }
    render() {
        return (

            <>
                <div className="BlackStarsBack">
                    <div id="Blackstars" />
                    <div id="Blackstars2" />
                    <div id="Blackstars3" />
                </div>
                {this.state.isLoadingProfile === true ? <LoadingOverlay></LoadingOverlay> : null}
                <div className="row" id="zIndexMob">
                    <div className="col-md-10 offset-md-1">
                        <div className="AccountAddress text-center">
                            <div className="Box SetAccount kycverification">
                                <h4>Personal Details</h4>
                                <div className="row">
                                    <div className="col-4">
                                        <div className="form-group mb-4">
                                            <input disabled={true} name="" value={this.state.firstName} className="form-control mb-2" placeholder="First Name" type="text" />
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div className="form-group mb-4">
                                            <input disabled={true} name="" value={this.state.middleName} className="form-control mb-2" placeholder="Middle Name" type="text" />
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div className="form-group mb-4">
                                            <input disabled={true} name="" value={this.state.lastName} className="form-control mb-2" placeholder="Last Name" type="text" />
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="form-group mb-4">
                                            <input disabled={true} name="" value={this.state.email} className="form-control mb-2" placeholder="Email" type="email" />
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="form-group mb-4">
                                            <input disabled={true} name="" value={this.state.phone} className="form-control mb-2" placeholder="Phone Number" type="text" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="Box SetAccount kycverification">
                                <h4>Address</h4>
                                <div className="row">
                                    <div className="col-6">
                                        <div className="form-group mb-4">
                                            <input disabled={true} value={this.state.addressLine1} name="" className="form-control mb-2" placeholder="Address 1" type="text" />
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="form-group mb-4">
                                            <input disabled={true} value={this.state.addressLine2} name="" className="form-control mb-2" placeholder="Address 2" type="text" />
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="form-group mb-4">
                                            <input disabled={true} name="" className="form-control mb-2" value={this.state.city} placeholder="City / Town / Suburb" type="text" />
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="form-group mb-4">
                                            <input disabled={true} name="" className="form-control mb-2" value={this.state.province} placeholder="State / Province" type="text" />
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="form-group mb-4">
                                            <select disabled={true} className="form-control">
                                                <option>{this.state.addressType}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="form-group mb-4">
                                            <select disabled={true} className="form-control">
                                                <option>{this.state.country}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>


                            </div>


                            <div className="Box SetAccount kycverification">
                                <h4>Update Password</h4>
                                <div className="row">
                                    <div className="col-sm-6 col-lg-4">
                                        <div className="form-group mb-4">
                                            <label style={{ cursor: 'pointer' }} onClick={() => this.hideAndShowPassword("oldPassword")}>{this.state.hideAndShowLabelTextOld}</label>
                                            <input onChange={this.onChange}
                                                onKeyPress={this.onKeyPress} value={this.state.oldPassword} name="oldPassword" className="form-control mb-2" placeholder="Enter old password" type={this.state.passwordTypeOld} />
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-lg-4">
                                        <div className="form-group mb-4">
                                            <label style={{ cursor: 'pointer' }} onClick={() => this.hideAndShowPassword("newPassword")}>{this.state.hideAndShowLabelTextNew}</label>
                                            <input onChange={this.onChange}
                                                onKeyPress={this.onKeyPress} value={this.state.newPassword} name="newPassword" className="form-control mb-2" placeholder="Enter new password" type={this.state.passwordTypeNew} />
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-lg-4">
                                        <div className="form-group mb-4">
                                            <label style={{ cursor: 'pointer' }} onClick={() => this.hideAndShowPassword("confirmNewPassword")}>{this.state.hideAndShowLabelTextConfirm}</label>
                                            <input onChange={this.onChange}
                                                onKeyPress={this.onKeyPress} value={this.state.confirmNewPassword} name="confirmNewPassword" className="form-control mb-2" placeholder="Confirm new password" type={this.state.passwordTypeConfirm} />
                                        </div>
                                    </div>

                                    {
                                    this.state.isError ? <WarningMessageAlert message={this.state.message}></WarningMessageAlert> : null
                                }
                                {
                                    this.state.isErrorAlert ? <ErrorMessageAlert message={this.state.message}></ErrorMessageAlert> : null
                                }

                                    <div style={{ marginTop: '20px' }} className="col-12 text-right"><button style={{ pointerEvents: this.state.disabled }} className="btn btn-primary Submit m-0" onClick={this.updatePassword}>
                                        {this.state.isLoading === true ? <img alt="loading..." style={{ height: '20px', width: '20px', marginRight: '8px' }} src="/assets/images/spinner3.gif"></img> : null}
                                        Update password

                                    </button></div>


                                </div>

                            </div>

                            <ReferralCode />

                        </div>
                    </div>
                </div>

                <Footer />
            </>

        )
    }
}




const mapStateToProps = (state) => ({

    auth: state.authReducer

})
export default connect(mapStateToProps, { logOutUser })(Profile)


