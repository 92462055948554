// import { Modal } from "bootstrap";
import React from 'react';
import Modal from 'react-bootstrap/Modal'
import OtpInput from 'react-otp-input';
import { addEncryptedWallet } from "../../../api/wallet/wallet"
import { toastMessageSuccess, toastMessageFailure } from '../../../utils/toastMessage';
import ErrorAlert from "../ErrorAlert"
const CryptoJS = require("crypto-js");

// import ErrorAlert from "./ErrorAlert"


class WalletPinModal extends React.Component {
    state = { otp: '', disabled: false, loader: false, isError: false, message: "", errorModalShow: false };
    resetState = () => {

        this.setState({
            otp: '', disabled: false, loader: false, isError: false, message: "", errorModalShow: false
        })
    }


    handleChange = (otp) => this.setState({ otp });

    submitOtp = async () => {
        const { wallet, mnemonics, imagePath } = this.props
        if (this.state.otp === "" || this.state.otp === undefined || this.state.otp === null) {
            return this.setState({ isError: true, message: "Please enter the otp pin." })
        }
        if (this.state.otp.length !== 4 ) {
            return this.setState({ isError: true, message: "Please fill all the box" })
        }

        // encrypt the mnemonics with otp key
        console.log("mnemonics here", mnemonics, this.state.otp)
        let encryptedData = CryptoJS.AES.encrypt(mnemonics, this.state.otp).toString();


        this.setState({ disabled: true, loader: true })


        try {
            await addEncryptedWallet({ wallet: wallet, privateKey: encryptedData, imagePath: imagePath })
            this.setState({ disabled: false, loader: false })
            this.props.onSuccessConfirmationModal()
            this.resetState()

        }
        catch (e) {
            this.setState({ disabled: false, loader: false })


            this.setState({ alertTitlt: "Alert!", errorModalShow: true, alertMessage: "We are facing some issue to add your wallet.Please try again later" })

        }
    }



    render() {


        return (
            <Modal dialogClassName="SetPass"
                show={this.props.showWaletPinModal}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >

                <ErrorAlert showErroModal={this.state.errorModalShow} title={this.state.alertTitlt} message={this.state.alertMessage} onCloseErrorModal={() =>
                    this.setState({
                        errorModalShow: false,
                        alertTitlt: "",
                        alertMessage: "",
                        isLoading: false,
                        disabled: false
                    })} />


                <Modal.Body>


                    <h4 class="text-center mb-3">Enter 4 Digit Pin Code</h4>


                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", paddingLeft: 40, paddingRight: 40 }}>


                        <p style={{ color: "white" }}>Please remember the pin which you are going to enter, or save it somewhere.</p>

                    </div>


                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <OtpInput
                            value={this.state.otp}
                            onChange={this.handleChange}
                            numInputs={4}
                            inputStyle={{ width: 70, height: 50, marginRight: 16 }}
                            containerStyle={{ marginBottom: 8 }}

                            isInputNum={true}

                        />

                    </div>

                    {
                        this.state.isError ? <div style={{ marginLeft: 38, width: 328 }} className="alert alert-danger" role="alert">

                            {this.state.message}</div> : null
                    }

                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: 40 }}>

                        <button disabled={this.state.disabled} onClick={this.submitOtp} type="button" className="btn btn-primary Submit">
                            {this.state.loader === true ? <img alt="loading..." style={{ height: '20px', width: '20px', marginRight: '8px' }} src="/assets/images/spinner3.gif"></img> : null}
                            PROCEED</button>
                        <button disabled={this.state.disabled} type="button" class="btn btn-secondary Cancel" onClick={() => {

                            this.props.onCloseWalletPinModal()
                            this.resetState()
                        }}>Cancel</button>
                    </div>

                </Modal.Body>
            </Modal>
        );

    }

}
export default WalletPinModal
