// import { Modal } from "bootstrap";
import React from 'react';
import Modal from 'react-bootstrap/Modal'
//import MneMonics from "../../MneMonics"
import { transferCoin } from '../../api/blockchainapi/blockchainapi';
import { bookMiner, rollbackBookMiner } from '../../api/miner/miner';
import ErrorAlert from "./ErrorAlert";
import RedInfoDialogue from "./RedInfoDialogue"
import moment from "moment"
import { showValidateMnemonicsScreen } from "../../actions/wallet";
import { addNewNotification } from "../../actions/notification"
import { createNotification } from "../../api/general/general"
import { WarningMessageAlert } from "../../utils/alert"
import { connect } from "react-redux";
import config from "../../_config";
const CryptoJS = require("crypto-js");


class RegisterWallet extends React.Component {
    state = {

        password: "",
        isError: "",
        message: "",
        alertTitlt: "",
        disable: "",
        headerMessage: "",
        transactionBlockHash: "",
        passwordType: "password",
        hideAndShowLabelText: "Show",
        transactionSuccess: false,
        errorModalShow: false,
        alertMessage: "",
        minerRegistrationSuccess: false,
        redInfoModalShow: false
    }

    submitPassword = async () => {






        let booking_row_id;
        let amount, usiLimit

        //if category is SILVER then we will transfer 3000
        if (this.props.registerCategory === config.minerRegistrationType[0]) {
            amount = config.silverMinerFees
            usiLimit = 86;
        }
        //if category is SILVER then we will transfer 10000
        else if (this.props.registerCategory === config.minerRegistrationType[1]) {
            amount = config.goldMinerFees
            usiLimit = 86;
        }

        if (this.state.password === '' || this.state.password === null) {
            return this.setState({ isError: true, message: 'Password is required!' });
        }
        // check if from address exist in local
        let fromExist = localStorage.getItem(this.props.walletAddress)
        if (fromExist) {
            try {
                var mnemonicsValue = await CryptoJS.enc.Utf8.stringify(CryptoJS.AES.decrypt(fromExist, this.state.password))
                if (`${mnemonicsValue}`.includes("string")) mnemonicsValue = JSON.parse(mnemonicsValue).string;
                // if we got the mnemoncs
                if (mnemonicsValue) {

                    let localtime = moment().format('YYYY-MM-DD HH:mm:ss');
                    let registerObject = {
                        "username": this.props.auth.user.username,
                        "category": this.props.registerCategory,
                        "localtime": localtime,
                        "walletAddress": this.props.walletAddress,
                        "createTime": Date.now(),
                        "usiLimit": usiLimit,

                    }

                    try {
                        this.setState({ isLoading: true, disable: "none" });
                        let bookingResponse = await bookMiner(registerObject)
                        const { isBookingDone, booking_id } = bookingResponse.data;
                        booking_row_id = booking_id;
                        // if booking fail because all slot are full
                        if (!(isBookingDone && booking_id && booking_id > 0)) {
                            this.setState({
                                isLoading: false, disable: "",
                                errorModalShow: true,
                                alertTitlt: "",
                                alertMessage: 'Slots are full. You have been added to waiting queue.'
                            })
                        }
                        // booking is done no we will transfer coin
                        else {

                            let transferObject = {
                                "secret": mnemonicsValue,
                                "recipient": config.recipientAddress,
                                "amount": amount
                            }

                            try {
                                let response = await transferCoin(transferObject)
                                if (response.data.transferred.status === "ExtrinsicSuccess") {

                                    this.setState({ isLoading: false, disable: "", transactionBlockHash: response.data.transferred.blockHash, minerRegistrationSuccess: true }, () => {
                                        this.closeModal()
                                    })
                                    let notifyObject = {
                                        "platform_subscriber_id": config.platform_subscriber_id,
                                        "sender": config.sender,
                                        "receiver": [this.props.auth.user.username],
                                        "message": `You have successfully registered your wallet (${this.props.walletAddress}) as a platform miner and your account category is (${this.props.registerCategory}) MINER`,
                                        "category": "success",
                                        "title": config.NotificationTitle[1],
                                        "action": "",
                                        "task": "",
                                        "is_read": false,
                                        "ttl": null,
                                        create_time: Date.now()
                                    }
                                    // console.log("notifyObject", notifyObject)

                                    //this.props.addNewNotification(notifyObject)
                                    //createNotification(notifyObject)

                                    // here we will send notification

                                }
                                else {

                                    try {
                                        rollbackBookMiner({ bookingId: booking_row_id, walletAddress: this.props.walletAddress, username: this.props.auth.user.username })
                                    }
                                    catch (e) { console.log("rllback", e) }

                                    //delete slot api
                                    this.setState({
                                        isLoading: false, disable: "",
                                        errorModalShow: true,
                                        alertTitlt: "Error",
                                        alertMessage: "Sorry! We are facing some problem to transfer coin.Try to increase your balance atleast one coin greater then your transfer amount."
                                    })

                                }
                            }
                            catch (e) {

                                // delete slot api
                                //cacncel slot
                                try {
                                    rollbackBookMiner({ bookingId: booking_row_id, walletAddress: this.props.walletAddress, username: this.props.auth.user.username })
                                }
                                catch (e) { console.log("rllback", e) }
                                this.setState({
                                    isLoading: false, disable: false,
                                    errorModalShow: true,
                                    alertTitlt: "Error",
                                    alertMessage: "Sorry! We are facing some problems to transfer coins. Try to increase your balance by at least one coin greater than your transfer amount.Make sure your receiver address is correct"
                                })
                            }
                        }
                    }
                    catch (error) {

                        const code = error?.response?.data?.err?.code;
                        if (code && code === "ER_DUP_ENTRY") {

                            this.setState({
                                errorModalShow: true,
                                isLoading: false, disable: "",
                                alertTitlt: "",
                                alertMessage: 'Slot already booked'
                            })

                            //alert('Slot already booked');
                        } else {
                            this.setState({
                                errorModalShow: true,
                                isLoading: false, disable: "",
                                alertTitlt: "",
                                alertMessage: error?.response?.data ? error?.response?.data?.errors[0]?.message : 'Unable to complete slot booking. Please try again'
                            })

                            // alert('Unable to complete slot booking. Please try again');
                        }
                    }
                }
                // mnemonics decrypt fail
                else {
                    this.setState({ isError: true, headerMessage: "", message: "We are currently experiencing internal problems, please attempt again at a later time.", isLoading: false, disable: "" })
                }
            }

            // if we got mulformed in decrypting
            catch (e) {

                //alert(e)
                this.setState({ isError: true, headerMessage: "", message:"We are currently experiencing internal problems, please attempt again at a later time.", isLoading: false, disable: "" })
            }
        }

        // address not exist
        else {
            // mnemoncis not exist in local db
            this.setState({
                redInfoModalShow: true,
                purposeOfRedInfoDialogue: "registerWallet"
            })
        }
    }

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
            [e.target.name.concat('error')]: '',
        })
    }
    closeModal = () => {

        this.props.onCloseShowRegisterWalletModal(this.state.minerRegistrationSuccess, this.state.transactionBlockHash)
        // set all state to default after closing modal
        this.setState({
            password: "",
            isError: "",
            message: "",
            disable: "",
            headerMessage: "",
            errorModalShow: false,
            successModalShow: false,
            transactionBlockHash: "",
            passwordType: "password",
            hideAndShowLabelText: "Show",
            transactionSuccess: false,
            minerSuccess: false
        })

    }

    hideAndShowPassword = () => {
        if (this.state.passwordType === "text") {

            this.setState({
                passwordType: "password",
                hideAndShowLabelText: "Show"

            })
        }
        else if (this.state.passwordType === "password") {
            this.setState({
                passwordType: "text",
                hideAndShowLabelText: "Hide"

            })
        }
    }

    render() {

        return (
            <Modal dialogClassName="SetPass"
                show={this.props.showRegisterWalletModal}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <ErrorAlert showErroModal={this.state.errorModalShow} title={this.state.alertTitlt} message={this.state.alertMessage} onCloseErrorModal={() =>
                    this.setState({
                        errorModalShow: false,
                        alertTitlt: "",
                        alertMessage: ""
                    })} />

                <RedInfoDialogue showRedInfoModal={this.state.redInfoModalShow} title={this.state.alertTitlt} message={this.state.alertMessage} onCloseRedInfoModal={(proceed) => {
                    if (proceed === true && this.state.purposeOfRedInfoDialogue === "passwordReset") {

                        this.setState({
                            redInfoModalShow: false
                        }, () => {
                            this.props.showValidateMnemonicsScreen(true, "passwordReset")
                            this.props.history.push("/validate-mnemonics", { walletAddress: this.props.walletAddress, walletName: this.props.walletName })
                        })

                    }

                    else if (proceed === true && this.state.purposeOfRedInfoDialogue === "registerWallet") {

                        this.setState({
                            redInfoModalShow: false
                        }, () => {
                            this.props.showValidateMnemonicsScreen(true, "registerWallet")
                            this.props.history.push("/validate-mnemonics",
                                {
                                    category: this.props.registerCategory,
                                    walletAddress: this.props.walletAddress,
                                    walletName: this.props.walletName
                                })
                        })

                    }

                    else {
                        this.setState({
                            redInfoModalShow: false

                        })
                    }
                }
                } />
                <Modal.Body>


                    <h4 className="text-center mb-5">{this.props.headerLabel}</h4>
                    <div class="form-group mb-4 position-relative">
                        <input onChange={this.onChange} name="password" className="form-control mb-2" placeholder="Enter Password" type={this.state.passwordType} />
                        <label style={{ cursor: "pointer", color: 'white', position: 'absolute', top: '9px', right: '0px' }} onClick={this.hideAndShowPassword}>{this.state.hideAndShowLabelText}</label>

                    </div>

                    {
                        this.state.isError ? <WarningMessageAlert message={this.state.message}></WarningMessageAlert> : null
                    }
                    <button style={{ pointerEvents: this.state.disable }} onClick={this.submitPassword} type="button" class="btn btn-primary Submit">
                        {this.state.isLoading === true ? <img alt="loading..." style={{ height: '20px', width: '20px', marginRight: '8px' }} src="/assets/images/spinner3.gif"></img> : null}
                        Click To Register

                    </button>
                    <button style={{ pointerEvents: this.state.disable }} type="button" class="btn btn-secondary Cancel" onClick={this.closeModal}>Cancel</button>

                    {/* <div style={{ textAlign: 'right', pointerEvents: this.state.disable, marginTop: '20px' }}><p onClick={() => {
                        this.setState({
                            redInfoModalShow: true,
                            purposeOfRedInfoDialogue: "passwordReset"
                        })
                    }} style={{ cursor: 'pointer', textDecoration: 'underline', color: 'white' }}>Rest password</p></div> */}

                    <a href={() => false} className="float-right mt-3" style={{ color: 'white', pointerEvents: this.state.disable, cursor: 'pointer' }} onClick={() => {
                        this.setState({
                            redInfoModalShow: true,
                            purposeOfRedInfoDialogue: "passwordReset"
                        })
                    }}>
                        Reset Password
                    </a>

                </Modal.Body>
            </Modal>
        );

    }

}
RegisterWallet.defaultProps = {
    headerLabel: "Set Password",

}
const mapStateToProps = (state) => ({

    isShowValidateMnemonicsScreen: state.walletReducer.isShowValidateMnemonicsScreen,
    auth: state.authReducer,
})
export default connect(mapStateToProps, { showValidateMnemonicsScreen, addNewNotification })(RegisterWallet)



