import { Component } from "react";
import Footer from "../../../Layout/Footer";
import "../../../Modals/WebCam/style.css"
import { QrReader } from 'react-qr-reader';
import { toastMessageFailure, toastMessageInfo } from "../../../../utils/toastMessage";
import { connect } from "react-redux";
import { getBalanceByAddress, getPosTerminalUserProfile } from "../../../../api/pos/pos";
import config from '../../../../_config/index';
import { setUserDetail } from '../../../../actions/paymentPos';


class POSTerminal extends Component {

    state = {
        data: {},
        isLoading: true
    }

    componentDidMount() {
        getPosTerminalUserProfile(this.props.match.params.user_name).then(({ data: { profile } }) => {
            
            if(!profile) {
                toastMessageInfo('User not found!');
                this.props.history.push('/pos/terminal');
                return;
            }
            this.setState({ ...this.state, data: profile, isLoading: false });
            getBalanceByAddress(profile.account_id).then(({ data }) => {
                this.setState({ ...this.state, data: { ...this.state.data, balance: data.balance } }, () => {
                    this.props.setUserDetail(this.state.data);
                });
            }).catch(e => { })
        }).catch(e => { this.setState({ ...this.state, isLoading: false }, () => {
            toastMessageInfo('User not found!');
            this.props.history.push('/pos/terminal');
        }) });
    }

    onProceedModal = () => {
        if (!this.state.imagePath) {
            toastMessageFailure("Please provide a selfie image to proceed further.")

        }
        else {
            this.setState({ chooseWalletModalShow: true })
        }
    }

    onProcess(e) {
        e.preventDefault();
        this.props.history.push('/product/list')
    }

    render() {
        const { first_name, middle_name, last_name, account_id, user_name, balance } = this.state.data;
        const { isLoading } = this.state;
        return (
            <>
                <div className="BlackStarsBack">
                    <div id="Blackstars" />
                    <div id="Blackstars2" />
                    <div id="Blackstars3" />
                </div>
                <div className="row" id="zIndexMob">
                    <div className="col-md-10 offset-md-1">
                        <div className="AccountAddress text-center">
                            <h4>User Detail Section</h4>
                            <div className="Box SetPass UserDetail">
                                <div className="container-fluid text-left">
                                    <div className="row">
                                        <div className="col-3">
                                            <img src={`${config.apiUrl}/v1/payment/user/profile/image/${this.props.match.params.user_name}`} alt="Profile Picture" />
                                        </div>
                                        <div className="col-9">



                                            <div className="form-group row mb-25">
                                                <div class="col-sm-3 d-flex align-items-center">
                                                    <label for="inputName" class=" col-form-label WhiteColor fs-14 fw-500 align-center">First Name</label>
                                                </div>
                                                <div class="col-sm-9 d-flex align-items-center">
                                                    <p className="WhiteColor mb-0">{first_name}</p>
                                                </div>
                                                <div class="col-sm-3 d-flex align-items-center">
                                                    <label for="inputName" class=" col-form-label WhiteColor fs-14 fw-500 align-center">Middle Name</label>
                                                </div>
                                                <div class="col-sm-9 d-flex align-items-center">
                                                    <p className="WhiteColor mb-0">{middle_name || ' -- '}</p>
                                                </div>
                                                <div class="col-sm-3 d-flex align-items-center">
                                                    <label for="inputName" class=" col-form-label WhiteColor fs-14 fw-500 align-center">Last Name</label>
                                                </div>
                                                <div class="col-sm-9 d-flex align-items-center">
                                                    <p className="WhiteColor mb-0">{last_name}</p>
                                                </div>
                                                <div class="col-sm-3 d-flex align-items-center">
                                                    <label for="inputName" class=" col-form-label WhiteColor fs-14 fw-500 align-center">User Name</label>
                                                </div>
                                                <div class="col-sm-9 d-flex align-items-center">
                                                    <p className="WhiteColor mb-0">{user_name}</p>
                                                </div>
                                                <div class="col-sm-3 d-flex align-items-center">
                                                    <label for="inputName" class=" col-form-label WhiteColor fs-14 fw-500 align-center">Account</label>
                                                </div>
                                                <div class="col-sm-9 d-flex align-items-center">
                                                    <p className="WhiteColor mb-0">{account_id}</p>
                                                </div>
                                                <div class="col-sm-3 d-flex align-items-center">
                                                    <label for="inputName" class=" col-form-label WhiteColor fs-14 fw-500 align-center">Balance</label>
                                                </div>
                                                <div class="col-sm-9 d-flex align-items-center">
                                                    <p className="WhiteColor mb-0">{balance} NUC</p>
                                                </div>
                                            </div>



                                            {/* <div className="row">
                                                <div className="col-4">
                                                    <div style={{ color: 'white' }} className="row">First Name</div>
                                                    <div style={{ color: 'white' }} className="row">Middle Name</div>
                                                    <div style={{ color: 'white' }} className="row">Last Name</div>
                                                    <div style={{ color: 'white' }} className="row">Username</div>
                                                    <div style={{ color: 'white' }} className="row">Account</div>
                                                    <div style={{ color: 'white' }} className="row">Balance</div>
                                                </div>
                                                <div className="col-6">
                                                    <div style={{ color: 'white' }} className="row">{first_name}</div>
                                                    <div style={{ color: 'white' }} className="row">{middle_name || ' -- '}</div>
                                                    <div style={{ color: 'white' }} className="row">{last_name}</div>
                                                    <div style={{ color: 'white' }} className="row">{user_name}</div>
                                                    <div style={{ color: 'white', fontSize: '12px' }} className="row">{account_id}</div>
                                                    <div style={{ color: 'white' }} className="row">{balance} NUC</div>
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                                <div style={{ display: "flex", justifyContent: "center", marginTop: '10px' }}>
                                    <button onClick={() => { this.props.history.push("/pos/terminal") }} type="button" class="btn btn-secondary Cancel" >
                                        Go Back
                                    </button>
                                    {
                                        account_id && <button onClick={this.onProcess.bind(this)} type="button" className="btn btn-primary Submit">
                                            Continue to Product List
                                        </button>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </>
        )
    }
}


const mapStateToProps = (state) => ({
    isShowShopRegisterScreen: state.walletReducer.isShowShopRegisterScreen
})

export default connect(mapStateToProps, { setUserDetail })(POSTerminal)
