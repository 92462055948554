import React, { Component } from "react";
import { connect } from "react-redux";
import { createWallet } from '../../../api/blockchainapi/blockchainapi';
import Header from "../../Layout/Header";
import Footer from "../../Layout/Footer";
import { getKycStatus } from "../../../api/kyc/kyc"
import { fetchUserReferenceCode } from "../../../api/wallet/wallet"
import { showCopyMnemoncisScreen } from "../../../actions/wallet"
import { toastMessageFailure, toastMessageInfo } from "../../../utils/toastMessage"
import { logOutUser } from "../../../actions/auth"
import { addNewNotification } from "../../../actions/notification"
import { createNotification } from "../../../api/general/general"
import config from "../../../_config"
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import ContentCopyIcon from '@material-ui/icons/FileCopy';
import { WarningMessageAlert,ErrorMessageAlert,InfoMessageAlert } from "../../../utils/alert"
import { ErrorMessage } from "../../../messages/ErrorMessage"

class CreateWallet extends Component {
    state = {
        accountName: "",
        walletPassword: "",
        confirmPassword: "",
        showMnemonics: false,
        //
        //create wallet value
        walletAddress: "",
        walletPublicKey: "",
        walletMnemonics: "",
        walletType: "",
        //
        isError: false,
        message: "",
        isLoading: false,
        disable: "",
        // hide and show password and confirm password
        passwordType: "password",
        hideAndShowLabelTextPassword: "Show",

        confirmPasswordType: "password",
        hideAndShowLabelTextConfirmPassword: "Show",
        isKycComplete: false,
        referenceCode: "",
        refernceLoader: false,
        isErrorAlert: false


    }

    componentDidMount = async () => {


        try {
            let kycStatus = await getKycStatus()




            if (kycStatus?.data?.data?.kyc_status !== 1) {
                toastMessageFailure("Please complete your kyc to access wallet")
                this.props.history.push("/")
            }
            try {
                this.setState({ refernceLoader: true })
                let userReferenceCode = await fetchUserReferenceCode()
                this.setState({ refernceLoader: false })
                this.setState({ referenceCode: userReferenceCode?.data?.data?.referenceCode })

            }
            catch (e) {
                this.setState({ refernceLoader: false })

            }

        }

        catch (err) {
            if (err?.response?.status === 403) {
                this.props.logOutUser(err?.response?.status)
            }
            else if (err.message === "Network Error") {
                this.props.history.push("/")
                toastMessageFailure(ErrorMessage.networkError)
            }
            else if (err?.response?.data) {
                this.props.history.push("/")
                toastMessageFailure(err?.response?.data?.errors[0]?.message)
            }

        }
    }
    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
            [e.target.name.concat('error')]: '',
        })

    }

    copyReferenceCode = async () => {
        const el = document.createElement('textarea');
        el.value = this.state.referenceCode;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        toastMessageInfo("Payment reference code copied!")
    }

    createWallet = async () => {

        if (this.state.accountName === '' || this.state.accountName === null) {
            return this.setState({ isError: true, isErrorAlert: false, message: 'Account name is required!' });
        }
        else if (this.state.accountName.length <= 4) {
            return this.setState({ isError: true, message: 'Account name must be greater than 4 characters' });
        }

        else if (this.state.walletPassword === '' || this.state.walletPassword === null) {
            return this.setState({ isError: true, isErrorAlert: false, message: 'Account password is required!' });
        }

        else if (this.state.walletPassword.length <= 6) {
            return this.setState({ isError: true, isErrorAlert: false, message: 'Please choose a more secure password. It should be longer than 6 characters, unique to you and difficult for others to guess.' });
        }
        else if (this.state.walletPassword !== this.state.confirmPassword) {
            return this.setState({ isError: true, isErrorAlert: false, message: 'Password and confirm password not matched!' });
        }


        this.setState({ isError: null, message: null, isErrorAlert: false, isLoading: true, disable: "none" });

        try {




            const { data } = await createWallet();

            this.setState({
                isError: false, isLoading: false, disable: "", message: "",
                walletAddress: data?.address,
                walletPublicKey: data?.publicKey,
                walletMnemonics: data?.mnemonic,
                walletType: data?.type,

            }, () => {
                // show CopyMnemocnis
                // console.log("mnemoncis create wallet", "mnemoncis", this.state.walletMnemonics, "walletaddress", this.state.walletAddress, "wallet public key", this.state.walletPublicKey, "accountName", this.state.accountName)
                this.props.showCopyMnemoncisScreen(true)
                this.props.history.push("/copy-mnemonics",
                    {
                        walletAddress: this.state.walletAddress,
                        walletPublicKey: this.state.walletPublicKey,
                        walletPassword: this.state.walletPassword,
                        walletType: this.state.walletType,
                        walletMnemonics: this.state.walletMnemonics,
                        accountName: this.state.accountName
                    }
                )

                this.setState({
                    accountName: "",
                    walletPassword: "",
                    confirmPassword: "",
                    showMnemoncis: false,
                    walletAddress: "",
                    walletPublicKey: "",
                    walletMnemoncis: "",
                    walletType: "",
                    isError: false,
                    message: "",
                    isLoading: false,
                    disable: "",
                    passwordType: "password",
                    hideAndShowLabelTextPassword: "Show",
                    confirmPasswordType: "password",
                    hideAndShowLabelTextConfirmPassword: "Show"
                })
            });
        }
        catch (e) {
            this.setState({ isError: false, message: e.message, isLoading: false, disable: "", isErrorAlert: true });
        }
    }

    submitCreateWallet = async (e) => {
        e.preventDefault();
        this.createWallet()
    };

    onKeyPress = (e) => {
        const enterOrSpace =
            e.key === "Enter" ||
            e.which === 13
        if (enterOrSpace === true) {
            this.createWallet()
        }
    };

    hideAndShowPassword = (value) => {

        if (value === "Password") {
            if (this.state.passwordType === "text") {
                this.setState({
                    passwordType: "password",
                    hideAndShowLabelTextPassword: "Show"
                })
            }
            else if (this.state.passwordType === "password") {
                this.setState({
                    passwordType: "text",
                    hideAndShowLabelTextPassword: "Hide"
                })
            }
        }
        else if (value === "confirmPassword") {

            if (this.state.confirmPasswordType === "text") {
                this.setState({
                    confirmPasswordType: "password",
                    hideAndShowLabelTextConfirmPassword: "Show"
                })
            }
            else if (this.state.confirmPasswordType === "password") {

                this.setState({
                    confirmPasswordType: "text",
                    hideAndShowLabelTextConfirmPassword: "Hide"
                })
            }
        }
    }





    render() {
        // const html = '<div>Example HTML string<b>nhhhh</b<button>bgggg</button></div>';
        return (
            <>

                <div className="BlackStarsBack">
                    <div id="Blackstars" />
                    <div id="Blackstars2" />
                    <div id="Blackstars3" />
                </div>


                {/* <div>{ ReactHtmlParser(html) }</div>; */}

                {/* <Header history={this.props.history} /> */}
                <div className="row" id="zIndexMob">
                    <div className="col-md-6 offset-md-3">
                        <div className="AccountAddress text-center">
                            <div className="Box SetAccount">
                                <h4 className="text-center">Create Wallet</h4>
                                <div className="form-group mb-4">
                                    <input autoFocus={true} onChange={this.onChange} name="accountName" class="form-control mb-2" placeholder="Enter Wallet Name" type="text" />
                                </div>
                                <div className="form-group mb-4 position-relative">
                                    <input onChange={this.onChange} name="walletPassword" className="form-control mb-2" placeholder="Enter wallet password" type={this.state.passwordType} />
                                    <label style={{ cursor: "pointer" }} onClick={() => this.hideAndShowPassword("Password")}>{this.state.hideAndShowLabelTextPassword}</label>
                                </div>
                                <div className="form-group mb-4 position-relative">
                                    <input onChange={this.onChange} name="confirmPassword" className="form-control mb-2" placeholder="Confirm wallet Password" type={this.state.confirmPasswordType} />
                                    <label style={{ cursor: "pointer" }} onClick={() => this.hideAndShowPassword("confirmPassword")}>{this.state.hideAndShowLabelTextConfirmPassword}</label>
                                </div>
                                <div style={{ display: 'flex' }}>
                                    <p style={{ color: "white" }} className="mb-4" placeholder="Confirm wallet Password" type={this.state.confirmPasswordType}>Your payment reference code is:
                                        <span onClick={this.copyReferenceCode} style={{ marginLeft: '15px', color: 'white', cursor: 'pointer' }}>{this.state.refernceLoader === true ? <img alt="loading..." style={{ height: '20px', width: '20px', marginRight: '8px' }} src="/assets/images/spinner3.gif"></img> : <span style={{ fontWeight: "bold" }}>{" "}{this.state.referenceCode}</span>} {!this.state.refernceLoader && <ContentCopyIcon style={{ color: 'white', marginLeft: '5px' }} />}</span>
                                        {/* {this.state.refernceLoader === true ? <img alt="loading..." style={{ height: '20px', width: '20px', marginRight: '8px' }} src="/assets/images/spinner3.gif"></img> :<span style={{fontWeight:"bold"}}>{" "}{this.state.referenceCode}</span>} */}
                                    </p>
                                    {/* <div className="d-flex justify-content-end mt-3">
                                    <span onClick={this.copyReferenceCode} style={{ color: 'white', cursor: 'pointer' }}>{this.state.referenceCode} <ContentCopyIcon style={{ color: 'white', marginLeft: '5px' }} /></span>
                                </div> */}
                                </div>

                                {/* <div className="form-group mb-4 position-relative">
                                    
                                       <label className="form-control mb-2" >Your reference code is:{this.state.referenceCode}</label>
                                    </div> */}

                                {
                                    this.state.isError ? <WarningMessageAlert message={this.state.message}></WarningMessageAlert> : null
                                }
                                {
                                    this.state.isErrorAlert ? <ErrorMessageAlert message={this.state.message}></ErrorMessageAlert> : null
                                }

                                {/* {
                                    this.state.isError ? <div className="alert alert-danger" role="alert">
                                        {this.state.headerMessage && (<h3>{this.state.headerMessage}</h3>)}
                                        {this.state.message}</div> : null
                                } */}

                                <button style={{ pointerEvents: this.state.disable }} onClick={this.submitCreateWallet} type="button" class="btn btn-primary Submit">
                                    {this.state.isLoading === true ? <img alt="loading..." style={{ height: '20px', width: '20px', marginRight: '8px' }} src="/assets/images/spinner3.gif"></img> : null}
                                    Submit</button>
                                <button style={{ pointerEvents: this.state.disable }} type="button" class="btn btn-secondary Cancel" onClick={() => this.props.history.push("/")}>Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </>
        )
    }

}

const mapStateToProps = (state) => ({
    auth: state.authReducer,
    isShowCopyMnemoncisScreen: state.walletReducer.isShowCopyMnemoncisScreen
})

export default connect(mapStateToProps, { showCopyMnemoncisScreen, logOutUser, addNewNotification })(CreateWallet)
