import React, { Component } from "react";

import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie';

import { login, sendOtpAgainApi } from '../../../api/authentication/authentication';
import { setUpUser } from '../../../actions/auth';
import config from "../../../_config";
import { fetchAllNotification } from '../../../actions/notification';
import { WarningMessageAlert, ErrorMessageAlert, InfoMessageAlert } from "../../../utils/alert"
import { ErrorMessage } from "../../../messages/ErrorMessage"
import store from "../../../store";
import { SET_TERM } from "../../../types/auth";

class Login extends Component {
  state = {
    username: "",
    password: "",
    showVerification: false,
    isError: false,
    message: "",
    isLoading: false,
    disable: "",
    emailOnVerification: "",
    phoneOnVerification: "",
    isResendOtpLoading: false,
    // hide and show password
    passwordType: "password",
    hideAndShowLabelText: "Show",
    isErrorAlert: false,
    infoAlert: false,


  }
  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      [e.target.name.concat('error')]: '',
    })

  }

  userLogin = async () => {
    if (this.state.username === '' || this.state.username === null) {
      return this.setState({ isError: true, isErrorAlert: false, infoAlert: false, message: 'Username required!' });
    } else if (this.state.password === '' || this.state.password === null) {
      return this.setState({ isError: true, isErrorAlert: false, infoAlert: false, message: 'Password required!' });
    }
    this.setState({ isError: null, isErrorAlert: false, infoAlert: false, message: null, isLoading: true, disable: "none" });

    try {
      //let notificationToken
      const { data } = await login({ userName: this.state.username, password: this.state.password });
      this.setState({ isError: false, isLoading: false, disable: "", message: "", isErrorAlert: false, infoAlert: false, });


      if (data?.data?.user?.toggle_login_otp === 0) {

        localStorage.setItem('jwtToken', data.data.token)
        if (data?.data?.user?.termAndConditionStatus === 0) {
          store.dispatch({ type: SET_TERM, payload: data?.data?.user?.termAndConditionStatus })
          Cookies.set('myPersistentCookie', data?.data?.user?.termAndConditionStatus, { expires: 365 });

           window.location.href = "/term-and-condition";
         
        }
        else {
          store.dispatch({ type: SET_TERM, payload: data?.data?.user?.termAndConditionStatus })
          Cookies.set('myPersistentCookie', data?.data?.user?.termAndConditionStatus, { expires: 365 });
          window.location.href = "/";
        }
        //  

      }
      else if (data?.data?.user?.toggle_login_otp === 1) {

        localStorage.setItem("loginEmail", data?.data?.user.email)
        this.props.history.push(`/login-verification?email=${data?.data?.user.email}`)
        return
      }

    }
    catch (err) {
      this.setState({ isLoading: false, disable: "" });
      if (err.message === "Network Error") {
        return this.setState({ isError: true, infoAlert: false, message: ErrorMessage.networkError, isErrorAlert: false });
      }
      else if (err?.response?.data) {
        if (err?.response?.status === 401) {

          return this.setState({
            message: err?.response?.data?.errors[0]?.message, showVerification: true, emailOnVerification: err?.response?.data?.data.email,
            phoneOnVerification: err?.response?.data?.data.phone, isError: false, isErrorAlert: false, infoAlert: true
          });
        }

        if (err?.response?.status === 500) {

          return this.setState({ isError: false, infoAlert: false, isLoading: false, message: err?.response?.data?.errors[0]?.message, isErrorAlert: true });
        }
        else {
          return this.setState({ isError: true, infoAlert: false, message: err?.response?.data?.errors[0]?.message, isErrorAlert: false });
        }

      }
    }

  }

  verifyAccount = async (e) => {
    e.preventDefault();
    this.setState({ isError: null, message: null, isResendOtpLoading: true, disable: true });
    try {
      await sendOtpAgainApi({
        "mobileNumber": this.state.phoneOnVerification,
        "email": this.state.emailOnVerification

      });
      localStorage.setItem("phone", this.state.phoneOnVerification)
      localStorage.setItem("email", this.state.emailOnVerification)
      //
      this.props.history.push("/verify-otp")
      this.setState({ isError: false, isResendOtpLoading: false, disable: false, message: "" });

    }
    catch (err) {
      if (err.message === "Network Error") {
        return this.setState({ isError: true, isErrorAlert: false, infoAlert: false, isResendOtpLoading: false, disable: false, message: ErrorMessage.networkError });
      }
      else if (err?.response?.data) {
        if (err?.response?.status === 500) {
          return this.setState({ isError: false, isErrorAlert: true, infoAlert: false, isResendOtpLoading: false, disable: false, message: err?.response?.data?.errors[0]?.message });

        }
        return this.setState({ isError: true, isErrorAlert: false, infoAlert: false, isResendOtpLoading: false, disable: false, message: err?.response?.data?.errors[0]?.message });
      }


    }
  };

  submitLogin = async (e) => {
    e.preventDefault();
    this.userLogin()
  };

  onKeyPress = (e) => {
    const enterOrSpace =
      e.key === "Enter" ||
      e.which === 13
    if (enterOrSpace === true) {
      this.userLogin()
    }
  };
  hideAndShowPassword = () => {
    if (this.state.passwordType === "text") {

      this.setState({
        passwordType: "password",
        hideAndShowLabelText: "Show"

      })
    }
    else if (this.state.passwordType === "password") {
      this.setState({
        passwordType: "text",
        hideAndShowLabelText: "Hide"

      })
    }
  }


  render() {
    return (
      <>
        <div className="LoginStyle">
          <div className="bg-img-fix">
            <div className="row">
              <div className="col-xl-4 col-lg-5 col-md-6 col-sm-12 bg-white z-index2 relative p-a0 content-scroll skew-section left-bottom">
                <div className="LoginForm">
                  <div className="logo-header text-center pt-5 pb-4">
                    <Link to="/" className="navbar-brand d-flex align-items-center">
                      <span className="Logo-Txt">NUC</span>
                      <img src="/assets/images/nucoin-logo.png" alt="" />
                      <span className="Logo-Txt">IN</span>
                    </Link>
                  </div>
                  <Link to="/" className="BackArrow">Back To Home Page</Link>
                  <p className="my-5">LogIn </p>
                  <div className="form-group">
                    <input autoFocus={true} onChange={this.onChange}
                      onKeyPress={this.onKeyPress} name="username" className="form-control mb-2" placeholder="User Name" type="text" />

                    <Link tabIndex={-1} to="/forgot-credential?forgotType=Username" className="text-right w-100 d-block">Forgot your username</Link>
                  </div>
                  <div className="form-group position-relative">
                    <input onChange={this.onChange}
                      onKeyPress={this.onKeyPress} name="password" className="form-control mb-2" placeholder="Password" type={this.state.passwordType}>
                    </input>
                    <label style={{ cursor: 'pointer' }} onClick={this.hideAndShowPassword}>{this.state.hideAndShowLabelText}</label>
                    <Link tabIndex={-1} to="/forgot-credential?forgotType=Password" className="text-right w-100 d-block" >Forgot your password</Link>
                  </div>
                  {
                    this.state.isError ? <WarningMessageAlert message={this.state.message}></WarningMessageAlert> : null
                  }
                  {
                    this.state.isErrorAlert ? <ErrorMessageAlert message={this.state.message}></ErrorMessageAlert> : null
                  }
                  {
                    this.state.infoAlert ? <InfoMessageAlert message={this.state.message}></InfoMessageAlert> : null
                  }
                  {
                    this.state.showVerification === true ? <p onClick={(event) => this.verifyAccount(event)} style={{ cursor: 'pointer', textDecoration: 'underline', textAlign: 'center', fontSize: '16px' }}>
                      {this.state.isResendOtpLoading === true ? <img alt="loading..." style={{ height: '20px', width: '20px', marginRight: '8px' }} src="/assets/images/spinner3.gif"></img> : null}

                      Verify your account now!</p> : null
                  }

                  <div className="text-center my-5">

                    <a href={() => false} style={{ pointerEvents: this.state.disable }} disable={true} onClick={(event) => this.submitLogin(event)} className="site-button button-md btn-block text-white" type="button">

                      {this.state.isLoading === true ? <img alt="loading..." style={{ height: '20px', width: '20px', marginRight: '8px' }} src="/assets/images/spinner3.gif"></img> : null}
                      Log In</a>
                  </div>
                  <span>Don’t have an account? <Link to="/register"> Sign up here</Link></span>
                </div>
              </div>
              <div className="col-xl-6 col-lg-5 col-md-6 col-sm-12 content-scroll">
                <div className="text-white ContentSide max-w400">
                  <h1 className="mb-4">Don't have an account?</h1>
                  <p>If you don't have a registered account click <Link to="/register" style={{ cursor: 'pointer', color: 'white' }}>"Sign up"</Link> and follow instructions in the form displayed.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>

    )
  }

}

export default connect(null, { setUpUser, fetchAllNotification })(Login)
