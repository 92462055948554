// import { Modal } from "bootstrap";
import React from 'react';
import Modal from 'react-bootstrap/Modal'
import ErrorOutline from '@material-ui/icons/ErrorOutline';

class PaymentError extends React.Component {



    render() {

        return (
            <Modal dialogClassName="ErrorAlert text-center"
                show={this.props.showPaymentErrorModal}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <ErrorOutline />
                    </Modal.Title>
                </Modal.Header>


                <Modal.Body>
                    <h4 class="text-center mb-5">Payment Successful Please Wait We Are Redirecting...</h4>

                    <div className="d-flex justify-content-between align-items-center">
                        <div className="w-100 text-right">
                            <button type="button" className="btn btn-primary Submit m-0 mt-2" onClick={this.props.onClosePaymentErrorModal} >Close</button>

                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        );

    }

}


export default PaymentError;