import Footer from "../Layout/Footer";
import Header from "../Layout/Header";
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import React, { Component } from "react";
import { checkLedger, getBuyToggleValue } from "../../api/kyc/kyc"
import GreenInfo from "../Modals/GreenInfo";
import ExchangeKycModal from "../Modals/ExchangeKycModal"
import Dropdown from 'react-dropdown';
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import GoToLoginAlert from "./GoToLoginAlert"
import GoToKycAlert from "./GoToKycAlert"
import { getKycStatus } from "../../api/kyc/kyc"
import ErrorAlert from "../Modals/ErrorAlert";
import { getCustomerWalletCount } from "../../api/wallet/wallet";
import { getRoadMapDetailsApi } from "../../api/general/general";
import { toastMessageFailure, toastMessageSuccess } from "../../utils/toastMessage";
import { showCreateWalletScreen } from "../../actions/wallet";
import { logOutUser, logOut, homeScreen, teamScreen } from "../../actions/auth";
import { fetchAllNotification } from "../../actions/notification"
import { contactUs, getToggleValueByUsername } from "../../api/general/general";
import Carousel from "react-multi-carousel";
import 'react-multi-carousel/lib/styles.css';
import Video from "./Modals/Video";
import RoadMap from "./Modals/RoadMap";
import PaymentSuccessful from "./Modals/PaymentSuccessful";
import PaymentError from "./Modals/PaymentError";
import { WarningMessageAlert, ErrorMessageAlert, InfoMessageAlert } from "../../utils/alert"
import { ErrorMessage } from "../../messages/ErrorMessage"
import AOS from "aos";
import "aos/dist/aos.css";
AOS.init();


const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 5
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 6
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};


const getDropdownOptions = () => {
    return [
        'PART A INTRO AND SUMMARY WHITE PAPER',
        'PART B TECH PAPER',
        'PART C Solving for mass adoption',
        'PART D The legal issues',
        'Part E Disclosures and Risks',
        'White paper combined',
        'Executive Roles',
        'Coin Release',
        'Burn Rate',
        'Platinum Reward',
        'Coin Release Updated Dual Minting',
        'Gold Staker Reward',
        //'Miner Minting Requirements',
        'Silver Miner Reward',
        'Treasury Structure',
        'The SKINNY',
        'Coin Mining Brochure',
        'NUGENESIS MINING AND STAKING',
    ]
}

const dropdownOptionSelectHandler = option => {

    const link = document.createElement('a');
    link.target = '_blank';

    switch (option.label) {
        case 'PART A INTRO AND SUMMARY WHITE PAPER':
            link.href = './assets/images/PART A INTRO AND SUMMARY WHITE PAPER.pdf';
            break;

        case 'PART B TECH PAPER':
            link.href = './assets/images/PART B TECH PAPER.pdf';
            break;

        case 'PART C Solving for mass adoption':
            link.href = './assets/images/PART C Solving for mass adoption.pdf';
            break;

        case 'PART D The legal issues':
            link.href = './assets/images/PART D The legal issues.pdf';
            break;

        case 'Part E Disclosures and Risks':

            link.href = './assets/images/Part E  Disclosures and Risks.pdf';
            break;

        case 'White paper combined':
            link.href = './assets/images/white paper combined.pdf';
            break;

        case 'Executive Roles':
            link.href = './assets/images/executive_roles_live_document.pdf';
            break;

        case 'Coin Release':
            link.href = './assets/images/sh_1_coin_release.pdf';
            break;

        case 'Burn Rate':
            link.href = './assets/images/sh_3_Burn_Rate.pdf';
            break;

        case 'Platinum Reward':
            link.href = './assets/images/sh_7_platinum_Reward_3.pdf';
            break;

        case 'Coin Release Updated Dual Minting':
            link.href = './assets/images/sh_2_coin_release_updated_dual_minti.pdf';
            break;

        case 'Gold Staker Reward':
            link.href = './assets/images/sh_6_gold_staker_Reward_2.pdf';
            break;

        case 'Silver Miner Reward':
            link.href = './assets/images/sh_5_Silver_Miner_Reward.pdf';
            break;

        case 'Treasury Structure':
            link.href = './assets/images/NUCOIN-Treasury-Structure-v1.pdf';
            break;

        case 'The SKINNY':
            link.href = './assets/images/THE_SKINNY.pdf';
            break;

        case 'Coin Mining Brochure':
            link.href = './assets/images/NU COIN mining brouchure final.pdf';
            break;

        case 'NUGENESIS MINING AND STAKING':
            link.href = 'https://nugenesisou.myshopify.com/';
            break;
        default: /* Optional */

    }

    document.body.appendChild(link);
    link.click();
}
const buttonStyle = {
    background: '#d10469',
    borderRadius: '3px',
    color: '#fff',
    fontWeight: 'bold',
    padding: '10px 0px',
    display: 'inline-block',
    marginRight: '10px',
    boxShadow: '0 0 1px transparent',
    transitionDuration: '0.3s',
    minWidth: '150px',
    textAlign: 'center',
};

class Home extends Component {

    carousel = null;

    state = {
        gotToLoginModalShow: false,
        isLoadingWalletCreate: false,
        alertTitlt: "",
        alertMessage: "",
        videoModalShow: false,
        RoadMapModalShow: false,
        PaymentSuccessfulModalShow: false,
        PaymentErrorModalShow: false,
        isLoading: false,
        email: "",
        phone: "",
        name: "",
        contactMessage: "",
        isError: false,
        message: "",
        disable: "",
        greenModalShow: false,
        exchangeMessage: "",
        toggle_become_miner: false,
        referenceCode: "",
        roadMap: [],
        refernceLoader: false,
        errorModalShow: false,
        isErrorAlert: false,
        currentSlide: 0,
        filter: 'all'

    }

    parseQueryParamsToObject(query) {

        if (!query) return {};

        query = query.replace('?', '');
        query = query.split('&');

        let object = {};

        for (let param of query) {
            const [key, value] = param.split('=');
            object[key] = value;
        }
        return object;
    }

    componentDidMount = async () => {
        this.props.logOut()
        this.props.homeScreen(true);

        if (this.parseQueryParamsToObject(this.props.location.search).payment_paypal === 'success' || this.parseQueryParamsToObject(this.props.location.search).bitpay_payment === 'success') {
            this.setState({ ...this.state, PaymentSuccessfulModalShow: true })
        }

        getBuyToggleValue('toggle_become_miner').then(({ data: { toggle_become_miner } }) => {
            if (toggle_become_miner.calculated_toggle === 1) {
                this.setState({ ...this.state, toggle_become_miner: toggle_become_miner.calculated_toggle === 1 });
            }
        }).then(e => { });


        getRoadMapDetailsApi().then(({ data }) => {
            this.setState({ ...this.state, roadMap: data.records });
        }).catch(e => {
        });

        // if (buyToggle?.data?.toggle_paypal_payment?.calculated_toggle === 1) {

        //console.log('tester ', this.props)
        // PaymentSuccessfulModalShow

        //TODO: will remove it later
        if (this.props.isAuthenticated === true) {

            //await SocketConnect(config.projectId, this.props.auth.user.username)

            if (this.props.auth.user.kycStatus === 0) {

                let dataObject = {
                    email: this.props.auth.user.emails,
                    phone: this.props.auth.user.mobile_number
                }


                // check ledger status
                let response = await checkLedger(dataObject)

                if (response?.data?.message === "user not exist") {
                }
                else {

                    if (localStorage.getItem("isShown") !== "true") {
                        this.setState({ exchangeModalShow: true, alertTitlt: "", exchangeMessage: "You are already a member of our Exchange LedgerX. If you want to trade on our Exchange LedgerX, complete your KYC here." })
                        localStorage.setItem("isShown", "true")

                    }

                }

            }
        }
    }


    filterRoadMap(param) {
        this.setState({ ...this.state, filter: param }, () => {
            console.log('filter ', this.state.filter)
        });
    }

    myWallet = async () => {


        if (this.props.auth.isAuthenticated === true) {
            // check if kyc is completed or not
            try {
                this.setState({ isLoading: true })
                let kycStatus = await getKycStatus()

                if (kycStatus?.data?.data?.kyc_status === 1) {


                    let walletCount = await getCustomerWalletCount()

                    if (walletCount.data.data.total_wallet > 0) {
                        this.setState({ isLoading: false })
                        this.props.homeScreen(false)
                        this.props.history.push("/wallet-listing")
                    }
                    else {

                        let createWalletToggle = await getToggleValueByUsername("toggle_create_wallet", this.props.auth.user.username)
                        this.setState({ isLoading: false })

                        if (createWalletToggle?.data?.toggle_create_wallet?.calculated_toggle === 0) {

                            return this.setState({
                                errorModalShow: true,
                                alertTitlt: "",
                                alertMessage: "Hi, the wallet creation feature is not available until further notice, we apologies for any inconvenience. You will be notified by the NuCoin Admin team once it becomes available again."

                            })
                        }
                        else {

                            this.props.homeScreen(false)
                            this.props.history.push("/create-wallet")



                        }
                    }

                }
                else {
                    this.setState({ isLoading: false })

                    return this.setState({
                        gotToKycModalShow: true,
                        alertTitlt: "KYC not completed",
                        alertMessage: "Please complete your KYC before accessing the wallet."
                    })

                }
            }
            catch (err) {
                this.setState({ isLoading: false })
                if (err?.response?.status === 403) {
                    this.props.logOutUser(err?.response?.status)
                }
                else if (err.message === "Network Error") {
                    toastMessageFailure("Sorry! We are facing some network issue.Please try again later.")
                }
                else if (err?.response?.data) {
                    toastMessageFailure(err?.response?.data?.errors[0]?.message)

                }

            }


        }
        else {
            return this.setState({
                gotToLoginModalShow: true,
                alertTitlt: "",
                alertMessage: "Please login first, to access your wallet"
            })
        }
    }


    createWallet = async () => {

        if (this.props.auth.isAuthenticated === true) {
            // check if kyc is completed or not
            try {
                this.setState({ isLoadingWalletCreate: true })
                let createWalletToggle = await getToggleValueByUsername("toggle_create_wallet", this.props.auth.user.username)

                if (createWalletToggle?.data?.toggle_create_wallet?.calculated_toggle === 0) {

                    this.setState({ isLoadingWalletCreate: false })
                    return this.setState({
                        errorModalShow: true,
                        alertTitlt: "",
                        alertMessage: "Hi, the wallet creation feature is not available until further notice, we apologies for any inconvenience. You will be notified by the NuCoin Admin team once it becomes available again."

                    })

                }
                else {
                    let kycStatus = await getKycStatus()
                    this.setState({ isLoadingWalletCreate: false })

                    if (kycStatus?.data?.data?.kyc_status === 1) {
                        this.props.homeScreen(false)
                        //show Create wallet screen
                        this.props.showCreateWalletScreen(true)
                        this.props.history.push("/create-wallet")
                        // if wallet exist or not

                    }
                    else {

                        return this.setState({
                            gotToKycModalShow: true,
                            alertTitlt: "KYC not completed",
                            alertMessage: "Please complete your KYC before creating a wallet."

                        })

                    }
                }

            }
            catch (err) {
                this.setState({ isLoadingWalletCreate: false })
                if (err?.response?.status === 403) {
                    this.props.logOutUser(err?.response?.status)
                }
                else if (err.message === "Network Error") {
                    toastMessageFailure("Sorry! We are facing some network issue.Please try again later.")

                }
                else if (err?.response?.data) {
                    toastMessageFailure(err?.response?.data?.errors[0]?.message)

                }
            }
        }
        else {
            return this.setState({
                gotToLoginModalShow: true,
                alertTitlt: "",
                alertMessage: "Please login first, to access your wallet"
            })
        }
    }

    becomeAMiner = () => {

        // if (this.props.auth.isAuthenticated === true) {
        //     setTimeout(() => {
        this.props.history.push("/become-miner");
        //     }, 200)
        // } else {
        //     return this.setState({
        //         gotToLoginModalShow: true,
        //         alertTitlt: "",
        //         alertMessage: "Please login first, to access your wallet"
        //     })
        // }
    }

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
            [e.target.name.concat('error')]: '',
        })

    }

    ///
    sendContactUsEmail = async () => {
        let emailValid = this.state.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);

        if (this.state.name === '' || this.state.name === null) {
            return this.setState({ isError: true, isErrorAlert: false, message: 'Please enter your name it is required!' });
        } else if (this.state.email === '' || this.state.email === null) {
            return this.setState({ isError: true, isErrorAlert: false, message: 'Please enter your email it is required!' });
        }
        else if (emailValid === null) {
            return this.setState({ isError: true, isErrorAlert: false, message: 'Please provide a valid email address!' });

        }

        else if (this.state.phone === '' || this.state.phone === null) {
            return this.setState({ isError: true, isErrorAlert: false, message: 'Please enter your phone it is required!' });
        }
        else if (this.state.contactMessage === '' || this.state.contactMessage === null) {
            return this.setState({ isError: true, message: 'Please enter the message you want to send it is required!' });
        }
        this.setState({ isError: null, isErrorAlert: false, message: null, isLoading: true, disable: "none" });


        try {
            await contactUs({ email: this.state.email, name: this.state.name, mobileNumber: this.state.phone, message: this.state.contactMessage });
            this.setState({ isLoading: false, disable: "", name: "", phone: "", contactMessage: "", email: "" });
            toastMessageSuccess("Your email is sent we will reply back to you soon..")
        }
        catch (err) {

            this.setState({ isLoading: false, disable: "" });
            if (err?.response?.data) {
                toastMessageFailure(err?.response?.data?.msg)
            }
            else {
                toastMessageFailure("We are facing some issues sending emails at the moment. Please try again later.")
            }
        }

    }

    render() {
        return (
            <>

                {/* Error Modal */}
                <GoToLoginAlert showGoToLoginModal={this.state.gotToLoginModalShow} title={this.state.alertTitlt} message={this.state.alertMessage} openLogin={() => {
                    this.setState({
                        gotToLoginModalShow: false,
                        alertTitlt: "",
                        alertMessage: ""
                    })
                    this.props.homeScreen(false)
                    this.props.history.push("/login")
                }} onCloseGoToLoginModal={() =>
                    this.setState({
                        gotToLoginModalShow: false,
                        alertTitlt: "",
                        alertMessage: ""
                    })} />

                <GoToKycAlert showGoToKycModal={this.state.gotToKycModalShow} title={this.state.alertTitlt} message={this.state.alertMessage}
                    onCloseGoToKycModal={() =>
                        this.setState({
                            gotToKycModalShow: false,
                            alertTitlt: "",
                            alertMessage: ""
                        })}

                    goToKyc={() => {
                        this.props.homeScreen(false)
                        this.props.history.push("/kyc")
                        this.setState({
                            gotToKycModalShow: false,
                            alertTitlt: "",
                            alertMessage: ""
                        })
                    }
                    }

                />

                <GreenInfo showGreenModal={this.state.greenModalShow} title={this.state.alertTitlt} exchangeMessage={this.state.exchangeMessage} exchangeHeading="Please Note...." onCloseGreenModal={() => {

                    this.setState({
                        greenModalShow: false
                    })

                }
                } />

                <ExchangeKycModal showExchangeModal={this.state.exchangeModalShow} title={this.state.alertTitlt} exchangeMessage={this.state.exchangeMessage} onOpenKyc={() => {
                    this.props.homeScreen(false)
                    this.props.history.push("/kyc")

                }} onCloseExcahngeModal={() => {

                    this.setState({
                        exchangeModalShow: false,
                        exchangeMessage: "",
                        alertTitlt: ""

                    })

                }
                } />





                {/* YouTube Video Modal */}

                <Video
                    showVideoModal={this.state.videoModalShow}
                    onCloseVideoModal={() => {
                        this.setState({
                            videoModalShow: false
                        })
                    }
                    }>
                </Video>

                <RoadMap
                    roadMapList={this.state.roadMap}
                    showVideoModal={this.state.RoadMapModalShow}
                    onCloseRoadMapModal={() => {
                        this.setState({
                            RoadMapModalShow: false
                        })
                    }
                    }>
                </RoadMap>


                {/* PaymentSuccessful Screen Modal */}

                <PaymentSuccessful
                    showPaymentSuccessfulModal={this.state.PaymentSuccessfulModalShow}
                    onClosePaymentSuccessfulModal={() => {
                        this.setState({
                            PaymentSuccessfulModalShow: false
                        })
                    }
                    }>
                </PaymentSuccessful>

                {/* PaymentSuccessful Screen Modal */}

                <PaymentError
                    showPaymentErrorModal={this.state.PaymentErrorModalShow}
                    onClosePaymentErrorModal={() => {
                        this.setState({
                            PaymentErrorModalShow: false
                        })
                    }
                    }>
                </PaymentError>

                <ErrorAlert showErroModal={this.state.errorModalShow} title={this.state.alertTitlt} message={this.state.alertMessage} onCloseErrorModal={() =>
                    this.setState({
                        errorModalShow: false,
                        alertTitlt: "",
                        alertMessage: ""
                    })} />




                <div className="BlackStarsBack">
                    <div id="Blackstars" />
                    <div id="Blackstars2" />
                    <div id="Blackstars3" />
                </div>
                <div className="row" id="zIndexMob">
                    <div className="col-lg-12 col-md-12" id="home">
                        <div style={{ maxWidth: "52%" }} className="InspiredQuote" data-aos="fade-right" data-aos-duration={1500}>
                            {/* <div className="InspiredQuote"> */}
                            <h1 className="mb-3">
                                Nucoin, the world's first decentralized blockchain inspired by&nbsp;
                                <Link style={{ cursor: 'none' }} to="#" className="px-3 py-2 bg-transparent btn text-color artificial-intelligence-btn">
                                    Artificial Intelligence
                                </Link>
                            </h1>
                            <h4>New Age Of Digital Currency</h4>
                            <Link onClick={this.createWallet} className="Create-Wallet mb-2" to="">
                                {this.state.isLoadingWalletCreate === true ? <img alt="loading..." style={{ height: '20px', width: '20px', marginRight: '8px' }} src="/assets/images/spinner3.gif"></img> : null}

                                Create Wallet</Link>
                            <Link onClick={this.myWallet} className="My-Wallet mb-2" to="">
                                {this.state.isLoading === true ? <img alt="laoding..." style={{ height: '20px', width: '20px', marginRight: '8px' }} src="/assets/images/spinner3.gif"></img> : null}
                                My Wallet
                            </Link>
                            {
                                this.state.toggle_become_miner && (
                                    <Link to="/become-miner" className="BecomeMiner mb-2">
                                        Become a Miner
                                    </Link>
                                )
                            }



                            <a target="_blank" href="https://multicoin.nucoin.com.au/" className="BecomeMulticoin mb-2">
                                Request Multicoin
                            </a>



                            <h4>NuGenesis Platform Documentation</h4>
                            <div className='documentation-div-wrapper'>
                                <Dropdown
                                    className='documentation-dropdown'
                                    options={getDropdownOptions()}
                                    onChange={(option) => dropdownOptionSelectHandler(option)}
                                    value={''}
                                    placeholder="NU Coin documents"
                                />
                            </div>
                        </div>
                        <div className="globe-video-container">
                            <video autoPlay muted loop id="nucoin-globe-video">
                                <source src={document.documentElement.getAttribute('data-theme') === 'dark' ? '/assets/video/dark3dglobe.mp4' : "/assets/video/off-white.mp4"} type="video/mp4" id="nucoin-3d-1" />
                            </video>
                        </div>
                    </div>
                </div>
                <div className="" id="roadmap" style={{ position: "relative", zIndex: -2 }}>
                    <section className="mb-0 mb-md-5 RoadMap">
                        <div className="mb-3 text-center">
                            <div className="mb-2 section-label">
                                PROCESS
                            </div>
                            <h1 className="text-color section-title">
                                Nucoin Roadmap
                            </h1>
                        </div>
                        <div className="justify-content-center mb-5 Road-Map-Tab" style={{ marginTop: '4%' }}>
                            <a className={`Filter-Btn ${this.state.filter === 'all' && 'Read-More'}`} onClick={(e) => { e.preventDefault(); this.filterRoadMap('all') }}>All</a>
                            <a className={`Filter-Btn ${this.state.filter === 'Planning' && 'Read-More'}`} onClick={(e) => { e.preventDefault(); this.filterRoadMap('Planning') }}>Planning</a>
                            <a className={`Filter-Btn ${this.state.filter === 'In progress' && 'Read-More'}`} onClick={(e) => { e.preventDefault(); this.filterRoadMap("In progress") }}>In progress</a>
                            <a className={`Filter-Btn ${this.state.filter === 'Complete' && 'Read-More'}`} onClick={(e) => { e.preventDefault(); this.filterRoadMap('Complete') }}>Complete</a>
                        </div>
                        <div>
                            <img src={'/assets/images/timelinered.png'} alt="" className="w-100" />
                        </div>

                        <Carousel autoPlaySpeed={1000000} autoPlay={false} className="mb-3" ref={ref => this.carousel = ref} responsive={responsive} >
                            {
                                this.state.filter === 'all' ? (
                                    this.state.roadMap.sort((a, b) => { return new Date(a.fields['Due date']) - new Date(b.fields['Due date']); }).map((item, index) => (
                                        <div key={item.id} className="item-min-length" data-aos="fade-up" data-aos-duration={1500}>
                                            <div className={`text-center text-color timeline-item item-${((index % 6) + 1)}`}>
                                                <div className="mb-4">
                                                    <span className="material-icons timeline-icon">
                                                        <RadioButtonCheckedIcon />
                                                    </span>
                                                </div>
                                                <div className="mb-3 timeline-date">
                                                    {`${item.fields['Due date']}`.split('-').reverse().join('-')}
                                                </div>
                                                <div className="timeline-description">
                                                    {item.fields.Goal} ({item.fields.Status})
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    this.state.roadMap.filter(_ => _.fields.Status === this.state.filter).sort((a, b) => { return new Date(a.fields['Due date']) - new Date(b.fields['Due date']); }).map((item, index) => (
                                        <div key={item.id} className="item-min-length" data-aos="fade-up" data-aos-duration={1500}>
                                            <div className={`text-center text-color timeline-item item-${((index % 6) + 1)}`}>
                                                <div className="mb-4">
                                                    <span className="material-icons timeline-icon">
                                                        <RadioButtonCheckedIcon />
                                                    </span>
                                                </div>
                                                <div className="mb-3 timeline-date">
                                                    {`${item.fields['Due date']}`.split('-').reverse().join('-')}
                                                </div>
                                                <div className="timeline-description">
                                                    {item.fields.Goal} ({item.fields.Status})
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                )
                            }
                        </Carousel>

                        <div className="text-center" data-aos="fade-up" data-aos-duration={1500}>
                            <button onClick={() => {
                                document.getElementById('roadmap-modal-btn').click();
                                if (document.getElementsByClassName('modal-backdrop fade ').length !== 0) document.getElementsByClassName('modal-backdrop fade ')[0].remove();
                            }} className="Filter-Btn Read-More Raod-Map-Board">Road Map Board</button>
                        </div>

                        {/* <div id="timeline">
                            <div className="h-auto">
                                <div className="d-flex" id="timeline-items">
                                    <div className="item-min-length" data-aos="fade-up" data-aos-duration={1500}>
                                        <div className="text-center text-color timeline-item item-1">
                                            <div className="mb-4">
                                                <span className="material-icons timeline-icon">
                                                    <RadioButtonCheckedIcon />
                                                </span>
                                            </div>
                                            <div className="mb-3 timeline-date">
                                                Nov 23rd 2020
                                            </div>
                                            <div className="timeline-description">
                                                Commence stage 2 blockchain development (Completed)
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item-min-length" data-aos="fade-up" data-aos-duration={1500}>
                                        <div className="text-center text-color timeline-item item-2">
                                            <div className="mb-4">
                                                <span className="material-icons timeline-icon">
                                                    <RadioButtonCheckedIcon />
                                                </span>
                                            </div>
                                            <div className="mb-3 timeline-date">
                                                Jan 13th 2021
                                            </div>
                                            <div className="timeline-description">
                                                Commence justsocial.tech crytpo/social media platform (Completed)
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item-min-length" data-aos="fade-up" data-aos-duration={1500}>
                                        <div className="text-center text-color timeline-item item-3">
                                            <div className="mb-4">
                                                <span className="material-icons timeline-icon">
                                                    <RadioButtonCheckedIcon />
                                                </span>
                                            </div>
                                            <div className="mb-3 timeline-date">
                                                Apr 4th 2021
                                            </div>
                                            <div className="timeline-description">
                                                248M coin minted Just social live, Tech white pages live, (Completed)
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item-min-length" data-aos="fade-up" data-aos-duration={1500}>
                                        <div className="text-center text-color timeline-item item-4">
                                            <div className="mb-4">
                                                <span className="material-icons timeline-icon">
                                                    <RadioButtonCheckedIcon />
                                                </span>
                                            </div>
                                            <div className="mb-3 timeline-date">
                                                May 13th 2021
                                            </div>
                                            <div className="timeline-description">
                                                NUCOIN miner service agreement for sale via justsocial.tech<br></br> (Completed)
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item-min-length" data-aos="fade-up" data-aos-duration={1500}>
                                        <div className="text-center text-color timeline-item item-5">
                                            <div className="mb-4">
                                                <span className="material-icons timeline-icon">
                                                    <RadioButtonCheckedIcon />
                                                </span>
                                            </div>
                                            <div className="mb-3 timeline-date">
                                                May 14th 2021
                                            </div>
                                            <div className="timeline-description">
                                                Staking and Mining protocols launched and staking contracts issued through
                                                (Completed)
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item-min-length" data-aos="fade-up" data-aos-duration={1500}>
                                        <div className="text-center text-color timeline-item item-6">
                                            <div className="mb-4">
                                                <span className="material-icons timeline-icon">
                                                    <RadioButtonCheckedIcon />
                                                </span>
                                            </div>
                                            <div className="mb-3 timeline-date">
                                                Jul 1st 2021
                                            </div>
                                            <div className="timeline-description">
                                                Exchange services live subject to license approval
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </section>
                </div>
                <div className="row mb-40" id="about">
                    <div className="col-lg-6 d-lg-block d-md-none d-sm-none d-xs-none">
                        <div data-aos="fade-right" data-aos-duration={1500}>
                            <div className="coin-video-container">
                                <video autoPlay muted loop id="nucoin-coin-video">
                                    <source src={document.documentElement.getAttribute('data-theme') === 'dark' ? '/assets/video/dark3dcoin.mp4' : "/assets/video/light3dcoin.mp4"} type="video/mp4" id="nucoin-3d-1" />
                                </video>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12">
                        <div className="About-Details" data-aos="fade-left" data-aos-duration={1500}>
                            <h4>Business Solution</h4>
                            <h1 className="mb-4">About NuCoin</h1>
                            <p className="mb-5">
                                NuGenesis is a fully completed native blockchain originally built for Government and
                                transnational corporate applications. In the context of building a blockchain for Central Bank
                                Digital Currencies (CBDC’s), and an exchange clearing house for settlement, limitations to
                                scaling and speed, latency and reliance on human miners and validators had to be eliminated.
                                Security had to be enhanced, its integrity underscored by Artificial Intelligence (AI) and
                                carbon neutral in its efficiency.
                            </p>
                            <Link target="_blank" to="assets/images/PART%20A%20INTRO%20AND%20SUMMARY%20WHITE%20PAPER.pdf" className="Read-More">Read More</Link>
                        </div>
                    </div>
                </div>
                <div className="row mb-40">
                    <div className="col-12">
                        <div className="Video-Card text-center" data-aos="fade-up" data-aos-duration={1000}>
                            <div className="card-body">
                                <div className="mb-4">
                                    <a onClick={() => { this.setState({ videoModalShow: true }) }} href={() => false}>
                                        <svg xmlns="http://www.w3.org/2000/svg" height="70px" viewBox="0 0 24 24" width="70px" fill="#11CFF3">
                                            <path d="M0 0h24v24H0z" fill="none" />
                                            <path d="M10 16.5l6-4.5-6-4.5v9zM12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z">
                                            </path>
                                        </svg>
                                    </a>
                                </div>
                                <div className="mb-2 text-white video-title">
                                    Nugenesis
                                </div>
                                <div className="text-white video-description">
                                    Enhancing blockchain protocol. +440 faster and more efficient then the competition.
                                    Expanding blockchain network.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mb-40" id="services">
                    <div className="col-12">
                        <div className="mb-5 text-center">
                            <div className="mb-2 section-label">
                                PROCESS
                            </div>
                            <h1 className="text-color section-title">
                                Nucoin Services
                            </h1>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12">
                        <div className="Services-Details" data-aos="fade-left" data-aos-duration={1500}>
                            <p className="mb-5">
                                Focus on the user experience and substance Rather that compete with ingenious new blockchain
                                technologies, our technology is to be the platform interfacing with them all. Rather than risk
                                “betting” on which technology will prevail on an on-going basis, we intend to focus on what
                                matters most, and where the value is:
                            </p>
                            <ul className="text-color mb-5">
                                <li className="d-flex align-items-start">
                                    <span className="mr-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 0 24 24" width="16px" fill="#1FF8F2" className="navigation-icon">
                                            <path d="M0 0h24v24H0z" fill="none" />
                                            <path d="M12 2L4.5 20.29l.71.71L12 18l6.79 3 .71-.71z" />
                                        </svg>
                                    </span>
                                    <p className="mb-0">
                                        The “user friendly” experience, including participating in a community all mutually
                                        benefiting from each-others’ success.
                                    </p>
                                </li>
                                <li className="d-flex align-items-start">
                                    <span className="mr-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 0 24 24" width="16px" fill="#1FF8F2" className="navigation-icon">
                                            <path d="M0 0h24v24H0z" fill="none" />
                                            <path d="M12 2L4.5 20.29l.71.71L12 18l6.79 3 .71-.71z" />
                                        </svg>
                                    </span>
                                    <p className="mb-0">
                                        Intrinsic value, globally recognised, providing confidence, security and stability.
                                    </p>
                                </li>
                                <li className="d-flex align-items-start">
                                    <span className="mr-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 0 24 24" width="16px" fill="#1FF8F2" className="navigation-icon">
                                            <path d="M0 0h24v24H0z" fill="none" />
                                            <path d="M12 2L4.5 20.29l.71.71L12 18l6.79 3 .71-.71z" />
                                        </svg>
                                    </span>
                                    <p className="mb-0">
                                        Through this experience NuGenesis was developed to address the needs of real world
                                        scalability.
                                    </p>
                                </li>
                            </ul>
                            <p className="mb-5">
                                Countries cannot rely on human validators, inefficient or excessive resource use. They require
                                zero carbon emission. These are the conditions to funnel mass adoption of the new economy.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-6 d-lg-block d-md-none d-sm-none d-xs-none">
                        <div data-aos="fade-right" data-aos-duration={1500}>
                            <img src="/assets/images/Block.png" height="435px" className="w-100" alt="" />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <section className="text-center border-0 rounded feature-card-first">
                            <div className="py-5 text-color card-body">
                                <div className="row align-items-end">
                                    <div className="col-md-4 col-sm-6" data-aos="fade-left" data-aos-duration={1500}>
                                        <div className="feature-item">
                                            <div className="mb-4">
                                                <img src={"/assets/images/Block1.png"} alt="" />
                                            </div>
                                            <div className="feature-description">
                                                Unlimited Scalability
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-sm-6" data-aos="fade-up" data-aos-duration={1500}>
                                        <div className="feature-item">
                                            <div className="mb-4">
                                                <img src={"/assets/images/Block2.png"} alt="" />
                                            </div>
                                            <div className="feature-description">
                                                Exchanges
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-sm-12" data-aos="fade-right" data-aos-duration={1500}>
                                        <div className="feature-item">
                                            <div className="mb-4">
                                                <img src={"/assets/images/Block3.png"} alt="" />
                                            </div>
                                            <div className="feature-description">
                                                Artificial Intelligence
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
                <div className="row mb-40">
                    <div className="col-12">
                        <div className="mb-5 text-center">
                            <div className="mb-2 section-label">
                                What You Get
                            </div>
                            <h1 className="text-color section-title">
                                Benefits of using Our Solution
                            </h1>
                        </div>
                    </div>
                    <div className="col-lg-6 d-lg-block d-md-none d-sm-none d-xs-none">
                        <div data-aos="fade-right" data-aos-duration={1500}>
                            <img src={"/assets/images/Block4.png"} height="600px" className="w-100" alt="" />
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12">
                        <div className="Services-Details" data-aos="fade-left" data-aos-duration={1500}>
                            <p className="mb-5">
                                The NuGenesis ecosystem is the most conducive interface to welcome mainstream capital flow. We
                                cater for mainstream expectations by providing:
                            </p>
                            <ul className="text-color mb-5">
                                <li className="d-flex align-items-start mb-4">
                                    <span className="mr-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 0 24 24" width="16px" fill="#1FF8F2" className="navigation-icon">
                                            <path d="M0 0h24v24H0z" fill="none" />
                                            <path d="M12 2L4.5 20.29l.71.71L12 18l6.79 3 .71-.71z" />
                                        </svg>
                                    </span>
                                    <p className="mb-0">
                                        New frontiers for the creation of crypto assets including beyond tokens and in virtual
                                        reality.
                                    </p>
                                </li>
                                <li className="d-flex align-items-start mb-4">
                                    <span className="mr-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 0 24 24" width="16px" fill="#1FF8F2" className="navigation-icon">
                                            <path d="M0 0h24v24H0z" fill="none" />
                                            <path d="M12 2L4.5 20.29l.71.71L12 18l6.79 3 .71-.71z" />
                                        </svg>
                                    </span>
                                    <p className="mb-0">
                                        A true interoperable experience agnostic as between blockchain assets and dApps.
                                    </p>
                                </li>
                                <li className="d-flex align-items-start mb-4">
                                    <span className="mr-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 0 24 24" width="16px" fill="#1FF8F2" className="navigation-icon">
                                            <path d="M0 0h24v24H0z" fill="none" />
                                            <path d="M12 2L4.5 20.29l.71.71L12 18l6.79 3 .71-.71z" />
                                        </svg>
                                    </span>
                                    <p className="mb-0">
                                        A decentralised, and safer, fiat-crypto-crypto-fiat exchange through which to invest.
                                    </p>
                                </li>
                                <li className="d-flex align-items-start mb-4">
                                    <span className="mr-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 0 24 24" width="16px" fill="#1FF8F2" className="navigation-icon">
                                            <path d="M0 0h24v24H0z" fill="none" />
                                            <path d="M12 2L4.5 20.29l.71.71L12 18l6.79 3 .71-.71z" />
                                        </svg>
                                    </span>
                                    <p className="mb-0">
                                        Through serialised Digital assets, hack-proof confidence in building assets for personal
                                        and commercial use including multi-sig wallets and such conditions for mortgaging new
                                        and existing asset acquisitions in a modern real defi capital market (lost of stolen
                                        mnemonics, wallets and crypto assets can, subject to verification, be burned and true
                                        ownership restored).
                                    </p>
                                </li>
                                <li className="d-flex align-items-start mb-4">
                                    <span className="mr-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 0 24 24" width="16px" fill="#1FF8F2" className="navigation-icon">
                                            <path d="M0 0h24v24H0z" fill="none" />
                                            <path d="M12 2L4.5 20.29l.71.71L12 18l6.79 3 .71-.71z" />
                                        </svg>
                                    </span>
                                    <p className="mb-0">
                                        Off-line satellite payment systems and local remittance offices.
                                    </p>
                                </li>
                                <li className="d-flex align-items-start mb-4">
                                    <span className="mr-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 0 24 24" width="16px" fill="#1FF8F2" className="navigation-icon">
                                            <path d="M0 0h24v24H0z" fill="none" />
                                            <path d="M12 2L4.5 20.29l.71.71L12 18l6.79 3 .71-.71z" />
                                        </svg>
                                    </span>
                                    <p className="mb-0">
                                        Legal and commercial certainty in the status of their crypto,identity integrity, duties
                                        and standards of conduct and disclosure and access to arbitration justice.
                                    </p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <section className="text-center border-0 rounded feature-card-first">
                            <div className="py-5 text-color card-body">
                                <div className="row align-items-end">
                                    <div className="col-md-4 col-sm-6" data-aos="fade-left" data-aos-duration={1500}>
                                        <div className="feature-item">
                                            <div className="mb-4">
                                                <img src={"/assets/images/Block5.png"} alt="" />
                                            </div>
                                            <div className="feature-description">
                                                Safe and Secure
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-sm-6" data-aos="fade-up" data-aos-duration={1500}>
                                        <div className="feature-item">
                                            <div className="mb-4">
                                                <img src={"/assets/images/Block6.png"} alt="" />
                                            </div>
                                            <div className="feature-description">
                                                Instant Exchange
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-sm-12" data-aos="fade-right" data-aos-duration={1500}>
                                        <div className="feature-item">
                                            <div className="mb-4">
                                                <img src={"/assets/images/Block7.png"} alt="" />
                                            </div>
                                            <div className="feature-description">
                                                World Coverage
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
                {/* <div className="row mb-40" id="team">
                    <div className="col-12">
                        <div className="mb-5 text-center">
                            <div className="mb-2 section-label">
                                OUR EXPERTS
                            </div>
                            <h1 className="section-title">
                                Team Members
                            </h1>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-sm-6">
                        <div data-aos="fade-left" data-aos-duration={1500}>
                            <a href="/team" className="rounded-lg card bg-member-card mb-20">
                                <div className="TeamImg">
                                    <img src={"/assets/images/member-1.jpeg"} alt="" className="rounded-top img-fluid" />
                                </div>
                                <div className="text-center border-0 card-body px-2">
                                    <div className="BlackColor">
                                        <div className="member-name">
                                            Hussein Faraj
                                        </div>
                                        <div className="member-position">
                                            Chief Executive Officer
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-sm-6">
                        <div data-aos="fade-left" data-aos-duration={1500}>
                            <a href="/team" className="rounded-lg card bg-member-card mb-20">
                                <div className="TeamImg">
                                    <img src={"/assets/images/member-2.png"} alt="" className="rounded-top img-fluid" />
                                </div>
                                <div className="text-center border-0 card-body px-2">
                                    <div className="BlackColor">
                                        <div className="member-name">
                                            N. James Dimitrious Peterson
                                        </div>
                                        <div className="member-position">
                                            Strategy and Legal Affairs
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-sm-6">
                        <div data-aos="fade-right" data-aos-duration={1500}>
                            <a href="/team" className="rounded-lg card bg-member-card mb-20">
                                <div className="TeamImg">
                                    <img src={"/assets/images/member-3.png"} alt="" className="rounded-top img-fluid" />
                                </div>
                                <div className="text-center border-0 card-body px-2">
                                    <div className="BlackColor">
                                        <div className="member-name">
                                            Luay Mohsen
                                        </div>
                                        <div className="member-position">
                                            Chief Technology Officer
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-sm-6">
                        <div data-aos="fade-right" data-aos-duration={1500}>
                            <a href="/team" className="rounded-lg card bg-member-card mb-20">
                                <div className="TeamImg">
                                    <img src={"/assets/images/member-4.jpeg"} alt="" className="rounded-top img-fluid" />
                                </div>
                                <div className="text-center border-0 card-body px-2">
                                    <div className="BlackColor">
                                        <div className="member-name">
                                            Muzammil Abbas
                                        </div>
                                        <div className="member-position">
                                            COO & Prresident - GCC Operations
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="text-center w-100">
                        <Link onClick={() => {
                            this.props.homeScreen(false)
                            this.props.teamScreen(true)
                            this.props.history.push("/team")

                        }} to="#" className="Read-More">Our Team</Link>
                    </div>
                </div> */}
                <div className="row mb-40 RegisterStyle" id="contact">
                    <div className="col-lg-8 offset-lg-2">
                        <div className="mb-5 text-center">
                            <h1 className="section-title">
                                Contact Us
                            </h1>
                        </div>
                        <div className="row">
                            <div className="col-lg-6 col-sm-12">
                                <div class="form-group">
                                    <input onChange={this.onChange} name="name" className="form-control mb-2" placeholder="Name" type="text" value={this.state.name} />
                                </div>
                            </div>
                            <div className="col-lg-6 col-sm-12">
                                <div class="form-group">
                                    <input onChange={this.onChange} name="email" className="form-control mb-2" placeholder="Email" type="email" value={this.state.email} />
                                </div>
                            </div>
                            <div className="col-lg-12 col-sm-12">
                                <div class="form-group">
                                    <input onChange={this.onChange} name="phone" className="form-control mb-2" placeholder="Mobile Number" type="text" value={this.state.phone} />
                                </div>
                            </div>
                            <div className="col-lg-12 col-sm-12">
                                <div class="form-group">
                                    <textarea onChange={this.onChange} name="contactMessage" className="form-control mb-2" rows="10" cols="30" value={this.state.contactMessage} placeholder="Message"></textarea>
                                </div>
                            </div>
                        </div>
                        {/* {
                            this.state.isError ? <div className="alert alert-danger" role="alert">{this.state.message}</div> : null
                        } */}

                        {
                            this.state.isError ? <WarningMessageAlert message={this.state.message}></WarningMessageAlert> : null
                        }
                        {
                            this.state.isErrorAlert ? <ErrorMessageAlert message={this.state.message}></ErrorMessageAlert> : null
                        }

                    </div>



                    <div className="text-center mt-4 w-100">
                        <button style={{ pointerEvents: this.state.disable }} onClick={this.sendContactUsEmail} className="Read-More">
                            {this.state.isLoading === true ? <img alt="loading..." style={{ height: '20px', width: '20px', marginRight: '8px' }} src="/assets/images/spinner3.gif"></img> : null}
                            Send
                        </button>
                    </div>
                </div>
                <Footer />
                {/* <div className="container">
                    <Header history={this.props.history} />
                    
                    <Footer />
                </div> */}
            </>
        )
    }

}



const mapStateToProps = (state) => ({
    auth: state.authReducer,
    isAuthenticated: state.authReducer.isAuthenticated,
})

export default connect(mapStateToProps, { showCreateWalletScreen, logOutUser, homeScreen, teamScreen, logOut, fetchAllNotification })(Home)