import { ADD_LOADER, REMOVE_LOADER } from '../types/commonType'
//import { isEmpty } from 'lodash'

const initialState = {
    waitingFor: [],

}

export const commonReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADD_LOADER:
            return {
                ...state,
                waitingFor: [...state.waitingFor, action.payload],
            }
        case REMOVE_LOADER:
            return {
                ...state,
                waitingFor: state.waitingFor.filter((l) => l !== action.payload),
            }
        default:
            return state
    }
}