import { SET_CURRENT_USER, HOME_SCREEN, TEAM_SCREEN } from '../types/auth'
import { isEmpty } from 'lodash'

const initialState = {
    userDetails: {},
}
export const paymentPosReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_USER_DETAIL':
            return {
                ...state, userDetails: action.payload
            }
        default:
            return state;
    }
}
