import invoke from '../../utils/invoke';
import { encrypt } from '../../utils/utils';
import config from '../../_config';


export const getMinerCategory = (address) => {

    return invoke({
        method: 'GET',
        // baseURL: config.apiUrl,
        baseURL: config.minerNucoinServiceApiUrl,
        route: `v1/nucoin/miner/get-miner-category?walletAddress=${address}`,
        headers: {
            Authorization: localStorage.getItem('jwtToken')
        }
    });
}

export const getMinerFeesReceivedStatus = () => {
    return invoke({
        method: 'GET',
        // baseURL: config.apiUrl,
        baseURL: config.minerNucoinServiceApiUrl,
        route: `v1/nucoin/miner/get-user-to-check-miner-fees-status`,
        headers: {
            Authorization: localStorage.getItem('jwtToken')
        }
    });
}

export const getEmailMessageForMinerRegistration = (emailMessageCategory, usedBy) => {
    return invoke({
        method: 'GET',
        //baseURL: config.apiUrl,
        baseURL: config.minerNucoinServiceApiUrl,
        route: `v1/nucoin/miner/get-email-messages-for-miner-registration?messageCategory=${emailMessageCategory}&&usedBy=${usedBy}`,
        headers: {
            Authorization: localStorage.getItem('jwtToken')
        }
    });
}

export const SendEmailForMinerRegistrationRequest = (data) => {
    data = encrypt(data);
    return invoke({
        method: 'POST',
        //baseURL: config.apiUrl,
        baseURL: config.minerNucoinServiceApiUrl,
        route: `v1/nucoin/miner/send-email-for-miner-registration`,
        data: data,
        headers: {
            Authorization: localStorage.getItem('jwtToken')
        }
    });
}

export const bookMiner = (data) => {
    console.log("daya miner", data)

    data = encrypt(data);
    return invoke({
        method: 'POST',
        //baseURL: config.apiUrl,
        baseURL: config.minerNucoinServiceApiUrl,
        route: `v1/nucoin/miner/book-miner-slot-v1`,
        data: data,
        headers: {
            Authorization: localStorage.getItem('jwtToken')
        }
    });
}

export const rollbackBookMiner = (data) => {

    data = encrypt(data);
    return invoke({
        method: 'POST',
        // baseURL: config.apiUrl,
        baseURL: config.minerNucoinServiceApiUrl,
        route: `v1/nucoin/miner/rollback-miner-slot`,
        data: data,
        headers: {
            Authorization: localStorage.getItem('jwtToken')
        }
    });
}

export const updateMintSession = (data) => {
    data = encrypt(data);
    return invoke({
        method: 'POST',
        baseURL: config.minerNucoinServiceApiUrl,
        route: `v1/nucoin/miner/update-mint-session`,
        data: data,

        headers: {
            Authorization: localStorage.getItem('jwtToken')
        }
    });
}





