import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import React, { Component } from "react";
import timeAgo from 'time-ago';
import Launch from '@material-ui/icons/Launch';
import DeleteIcon from '@material-ui/icons/Delete';
import { connect } from "react-redux";
import { fetchAllNotification, markNotificationAsRead, removeNotification, clearNotification } from "../../actions/notification"
import { toastMessageSuccess } from "../../utils/toastMessage"
import YesOrNoModal from "../Modals/YesOrNoModal"
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import { getNotificationById, markNotificationAsRemoveById } from 'notificationsdk';


class NotificationDetail extends Component {

    constructor(props) {
        super(props)
        this.state = {
            data: {
                message: '',
                create_time: null
            },
            yesOrNoModalShow: false,
            deleteNotificationID: "",
            category: "",
            message: "",
            time: "",
            action: "",
            title: "",
            timer: "",
            ttlTime: "",
            timeDiff: "",
            isLoading: true,
            notFound: false
        }
    }
    componentDidMount() {
        getNotificationById(this.props.match.params.id).then((data) => {
            this.setState({ ...this.state, data: data, isLoading: false });
            this.timeBetweenDates();
        }).catch(e => {
            this.setState({ ...this.state, isLoading: false, notFound: true })
        })

    }
    checkRtl = (string)=>{
        const charsToCheck = string?.slice(0,10)
        var RTL = ['ا', 'ب', 'پ', 'ت', 'س', 'ج', 'چ', 'ح', 'خ', 'د', 'ذ', 'ر', 'ز', 'ژ', 'س', 'ش', 'ص', 'ض', 'ط', 'ظ', 'ع', 'غ', 'ف', 'ق', 'ک', 'گ', 'ل', 'م', 'ن', 'و', 'ه', 'ی'];
       
        for(let i=0;i<charsToCheck?.length; i++){
            if(RTL.indexOf(charsToCheck[i]) > -1)
                return true;
        }
        return false;
      };
    timeBetweenDates = () => {
        var moment = require('moment');
        var localDate = new Date();
        var currentTime = moment.utc(localDate).format();

        var diff = moment.duration(moment(new Date(this.state.data.ttl)).diff(moment(currentTime)));

        if (diff.get("months") > 0) {

            this.setState({ timeDiff: diff.get("months") === 1 ? `${diff.get("months")} month remaining` : `${diff.get("months")} months remaining` })

        } else if (diff.get("days") > 0) {

            this.setState({ timeDiff: diff.get("days") === 1 ? `${diff.get("days")} day remaining` : `${diff.get("days")} days remaining` })

        } else if (diff.get("hours") > 0) {

            this.setState({ timeDiff: diff.get("hours") === 1 ? `${diff.get("hours")} hour remaining` : `${diff.get("hours")} hours remaining` })

        } else if (diff.get("minutes") > 0) {

            this.setState({ timeDiff: diff.get("minutes") === 1 ? `${diff.get("minutes")} minute remaining` : `${diff.get("minutes")} minutes remaining` })

        } else if (diff.get("seconds") > 0) {

            this.setState({ timeDiff: diff.get("seconds") === 1 ? `${diff.get("seconds")} second remaining` : `${diff.get("seconds")} seconds remaining` })

        } else if (diff.get("seconds") <= 0 || diff.get("months") <= 0 || diff.get("days") <= 0 || diff.get("hours") <= 0 || diff.get("minutes") <= 0) {

            // this.props.clearNotification(this?.props?.location?.state?.notificationID)
            // toastMessageSuccess("Notification removed.")
            // this.props.history.push("/")
        }
    }

    componentWillReceiveProps(prevProps) {
        if (prevProps.match.params.id !== this.props.match.params.id) {
            setTimeout(this.componentDidMount.bind(this), 100);
            // this.componentDidMount();
        }
    }

    onClickNotification = (action) => {
        if (action) {
            window.open(action, '_blank')
        }
    }

    removeClick = () => {
        this.setState({ confirmationMessage: `Are you sure you want to remove this notification?`, yesOrNoModalShow: true })
    }

    removeNotification = () => {
        markNotificationAsRemoveById(this.props.match.params.id)
        toastMessageSuccess("Notification removed.")
        this.props.history.push("/")
    }

    render() {
        return (
            <>
                <div className="BlackStarsBack">
                    <div id="Blackstars" />
                    <div id="Blackstars2" />
                    <div id="Blackstars3" />
                </div>

                <YesOrNoModal msg={this.state.confirmationMessage} showYesOrNoModal={this.state.yesOrNoModalShow}
                    onCloseYesOrNoModal={(value) => {
                        if (value === true) {
                            this.setState({ yesOrNoModalShow: false }, () => {
                                this.removeNotification()
                            })
                        } else {
                            this.setState({ yesOrNoModalShow: false })
                        }
                    }}
                >
                </YesOrNoModal>
                <div className="row" id="zIndexMob">
                    {
                        <div className="col-md-6 offset-md-3 col-sm-12">
                            <div className="NotificationList">
                                <div className={`card Box ${this.checkRtl(this.state.data.message) ? 'arabic' :"english"} `}>
                                    <div className="card-header bg-transparent border-bottom pl-0">
                                        <div className="d-flex flex-wrap align-items-start">
                                            <div className="me-2">
                                                <h3 className="card-title mt-1 mb-0 WhiteColor"><strong>Notification Detail {this.state.isLoading && <img alt="laoding..." style={{ height: '20px', width: '20px', marginRight: '8px' }} src="/assets/images/spinner3.gif"></img>}</strong></h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body p-0" style={{ cursor: 'pointer' }}>
                                        <div className="d-flex py-3">
                                            <div className="mr-3">
                                                <img src="/assets/images/nucoin-logo.png" alt="" className="avatar-md h-auto d-block rounded" />
                                            </div>
                                            <div className={`align-self-center overflow-hidden me-auto`}>
                                                <div>
                                                    <h5 class="mb-0 font-16 WhiteColor">
                                                        <strong>{this.state.data.title}</strong>
                                                    </h5>
                                                    <p style={{ marginTop: '10px' }} className="WhiteColor font-16">
                                                        {/* {
                                                                this.state.data.message < 600 ? <span>{this.state.data.message}</span> :
                                                                    <span>{this.state.data.message.substring(0, 600)} <span style={{ cursor: 'pointer' }} onClick={() => {
                                                                        Swal(`${this.state.data.message}`)
                                                                    }}>... More</span></span>

                                                            } */}
                                                        {/* <span>{this.state.data.message}</span> */}
                                                        {ReactHtmlParser(this.state.data.message)}
                                                        {this.state.notFound ? <strong>Oops! No Notification Found</strong> : ''}
                                                    </p>
                                                    <p className="mb-0 WhiteColor font-16">
                                                        <span>{this.state.data.create_time ? timeAgo.ago(this.state.data.create_time) : this.state.isLoading ? 'Please wait...' : ''}
                                                            {/* {
                                                                    this?.props?.location?.state?.action === "" || this?.props?.location?.state?.action === null ? null :
                                                                        <Launch onClick={() => { this.onClickNotification(this?.props?.location?.state?.action) }} style={{ marginLeft: '10px', width: '20px', height: '20px' }}></Launch>
                                                                } */}
                                                        </span>
                                                    </p>
                                                    {
                                                        this.state.data.ttl ? <p style={{ color: 'red' }}>{this.state.timeDiff}</p> : ""
                                                        // this.state.data.ttl ? <p style={{ color: '#D6AD60', fontWeight: 'bold' }}>{this.state.timeDiff}</p> : null
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        <div style={{ position: 'absolute', bottom: '10px', right: '20px', cursor: 'pointer' }}>
                                            <React.Fragment>
                                                {
                                                    !this.state.notFound ? this.state.data.category !== 'announcement' && !this.state.data.ttl ? <DeleteIcon onClick={this.removeClick} style={{ color: '#808080', width: '30px', height: '30px' }} /> : '' : ''
                                                }
                                            </React.Fragment>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    }
                </div>

                <Footer />
            </>
        )

    }
}

const mapStateToProps = (state) => ({
    waitingFor: state.commonReducer.waitingFor,
    auth: state.authReducer
})



export default connect(mapStateToProps, { fetchAllNotification, markNotificationAsRead, removeNotification, clearNotification })(NotificationDetail)











