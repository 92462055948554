import { SET_CURRENT_USER, HOME_SCREEN, TEAM_SCREEN, MAINTINANCE_STATUS, SET_TERM,TERM_SCREEN } from '../types/auth'
import { isEmpty } from 'lodash'

const initialState = {
    isAuthenticated: false,
    isScreenHome: false,
    isTeamScreen: false,
    user: {},
    maintanceStatus: "",
    term: 0,
    isTermScreen: false,
}
export const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_CURRENT_USER:
            return {
                ...state,
                //#TODO: //To update the Logic for Signout
                isAuthenticated: !isEmpty(action.payload),
                user: action.payload,
            }
        case HOME_SCREEN:
            return {
                ...state,
                isScreenHome: action.payload,

            }
        case TEAM_SCREEN:
            return {
                ...state,
                isTeamScreen: action.payload,

            }
        case MAINTINANCE_STATUS:
            return {
                ...state,
                maintanceStatus: action.payload,

            }
        case SET_TERM:
            console.log("reachecheer",action.payload)
            return {
                ...state,
                term: action.payload,

            }
            case TERM_SCREEN:
             
                return {
                    ...state,
                    isTermScreen: action.payload,
    
                }

        default:
            return state
    }
}
