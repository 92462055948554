import { Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { useLocation } from "react-router-dom"
import { logOutUser, setUpUser } from "../actions/auth"

const KycProtection = ({ component: Component, auth, setUpUser, ...rest }) => {
    const { search } = useLocation()
    const redirect = new URLSearchParams(search).get("redirect")


    if (redirect !== null) {

        return (
            <Route
                render={(props) => {
                    return <Component {...props} />
                }}

            />)
    }
    else {
        return (
            <Route
                render={(props) => {
                    if (auth.isAuthenticated) {
                        return <Component {...props} />
                    } else {

                        return <Redirect to={`/`} />
                    }
                }}
                {...rest}
            />
        )

    }
}



KycProtection.propTypes = {
    auth: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => ({

    auth: state.authReducer,
})

export default connect(mapStateToProps, { logOutUser, setUpUser })(KycProtection)
