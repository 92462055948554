
import { SHOW_RANDOM_MNEMONICS_SCREEN, SHOW_VALIDATED_MNEMONICS_SCREEN, SHOW_CREATE_WALLET_SCREEN, SHOW_COPY_MNEMONCIS_SCREEN,USER_WALLET_LIST,SHOW_SHOP_REGISTER_SCREEEN } from '../types/wallet'


export const showCreateWalletScreen = (value) => {

    return {
        type: SHOW_CREATE_WALLET_SCREEN,
        payload: value
    }
}

export const showCopyMnemoncisScreen = (value) => {

    return {
        type: SHOW_COPY_MNEMONCIS_SCREEN,
        payload: value
    }
}

export const showRandomMnemonicsScreen = (value) => {
    return {
        type: SHOW_RANDOM_MNEMONICS_SCREEN,
        payload: value,
    }
}

export const storeUserWallet = (value) => {
    console.log("Wallet in action",value)
    return {
        type: USER_WALLET_LIST,
        payload: value,
    }
}

export const showValidateMnemonicsScreen = (openRoute, purpose) => {

    return {
        type: SHOW_VALIDATED_MNEMONICS_SCREEN,
        payload: { isRouteOpen: openRoute, purpose: purpose }
    }
}

export const showRegisterShopScreen = (value) => {

    return {
        type: SHOW_SHOP_REGISTER_SCREEEN,
        payload: value,
    }
}




