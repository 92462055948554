import invoke from '../../utils/invoke';
import { encrypt } from '../../utils/utils';
import config from '../../_config';


export const getKycStatus = () => {
    return invoke({
        method: 'GET',
        // baseURL: config.apiUrl,
        baseURL: config.kycNucoinServiceApiUrl,
        route: `v1/nucoin/kyc/get-kyc-status`,
        headers: {
            Authorization: localStorage.getItem('jwtToken')
        }
    });
}

export const getUserPersonalDetailForKyc = () => {

    return invoke({
        method: 'GET',
        // baseURL: config.apiUrl,
        baseURL: config.kycNucoinServiceApiUrl,
        route: `v1/nucoin/kyc/get-user-detail-for-kyc`,
        headers: {
            Authorization: localStorage.getItem('jwtToken')
        }
    });
}


export const getUserAddressDetailForKyc = () => {

    return invoke({
        method: 'GET',
        // baseURL: config.apiUrl,
        baseURL: config.kycNucoinServiceApiUrl,
        route: `v1/nucoin/kyc/get-user-address-detail-for-kyc`,
        headers: {
            Authorization: localStorage.getItem('jwtToken')
        }
    });
}




export const addCustomerDetail = (data) => {
    data = encrypt(data)

    return invoke({
        method: 'POST',
        ///baseURL: config.apiUrl,
        baseURL: config.kycNucoinServiceApiUrl,
        route: `v1/nucoin/kyc/store-customer-address-detail`,
        data: data,
        headers: {
            Authorization: localStorage.getItem('jwtToken')
        }
    });
}

export const getKycProgress = () => {

    return invoke({
        method: 'POST',
        //baseURL: config.apiUrl,
        baseURL: config.kycNucoinServiceApiUrl,
        route: `v1/nucoin/kyc/get-kyc-progress`,
        headers: {
            Authorization: localStorage.getItem('jwtToken')
        }
    });
}

export const loginNft = (authToken) => {

    return invoke({
        method: 'POST',
        //baseURL: config.apiUrl,
        baseURL: config.kycNucoinServiceApiUrl,
        route: `v1/nucoin/kyc/login-nft`,

        headers: {
            Authorization: authToken
        }

    });
}

export const checkLedger = (data) => {

    data = encrypt(data)
    return invoke({
        method: 'POST',
        baseURL: config.kycNucoinServiceApiUrl,
        route: `v1/nucoin/kyc/check_user_register_on_exchange_ledger`,
        data: data,
        headers: {
            Authorization: localStorage.getItem('jwtToken')
        }
    });
}
export const getToggleValue = (toggleType, country, username) => {

    return invoke({
        method: 'GET',
        baseURL: config.apiUrl,
        route: `check_toggle_status?toggle_column=${toggleType}&&country=${country}&&username=${username}`,

    });
}

export const getToggleValueByUsername = (toggleType, username) => {

    return invoke({
        method: 'GET',
        baseURL: config.apiUrl,
        route: `check_toggle_status?toggle_column=${toggleType}&&username=${username}`,

    });
}

export const getAirdropToggleValue = (toggleType, username) => {

    return invoke({
        method: 'GET',
        baseURL: config.apiUrl,
        route: `check_toggle_status?toggle_column=${toggleType}&&username=${username}`,

    });
}

export const getBuyToggleValue = (toggleType) => {

    return invoke({
        method: 'GET',
        baseURL: config.apiUrl,
        route: `check_toggle_status?toggle_column=${toggleType}`,

    });
}


export const updateAccountStatus = (data) => {

    return invoke({
        method: 'POST',
        baseURL: config.kycNucoinServiceApiUrl,
        route: `v1/nucoin/kyc/update_account_status`,
        data: data

    });
}



